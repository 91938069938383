import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { OfflineBaseService } from './offline-base.service';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map, switchMap, tap } from 'rxjs/operators';
import { EventGroup } from '../../models/event-group';
import { EventModel } from '../../models/event.model';
import { OfflineEventService } from './offline-event.service';
import { Work } from '../../models/work.model';
import { CompanyModel } from '../../models/company.model';
import { OfflineTravelGroupService } from './offline-travel-group.service';

@Injectable()
export class OfflineEventGroupService {

  public  offlineEventsService: OfflineEventService;
  constructor(
      $injector: Injector,
      private bas: OfflineBaseService,

  ) {
    setTimeout(() => this.offlineEventsService = $injector.get(OfflineEventService));
  }

  get(id: number, includeEvents = true): Observable<EventGroup> {
    return fromPromise(this.bas.connection.select({
      from: 'event_groups',
      where: {
        id: id
      }
    })).pipe(
      map((value: any[]) => {
        return value.map(this.createFromIdb)[ 0 ];
      }),
      switchMap((value: EventGroup) => {
        return this.addWork(value)
      }),
      switchMap((value: EventGroup) => {
        return this.addLocation(value)
      }),
      switchMap((eventGroup: EventGroup) => {
        if (!includeEvents) {
          return of(eventGroup);
        }
        return this.offlineEventsService.getEventsOfEventGroup(eventGroup)
          .pipe(
            map((events) => {
              eventGroup.events = events;
              return eventGroup
            })
          )
      }),
    );

  }

  private addWork(eventGroup: EventGroup): Observable<EventGroup> {
    if (!eventGroup || eventGroup.work_id === null || eventGroup.work_id === undefined) {
      return of(eventGroup);
    }
    return fromPromise(this.bas.connection.select({
      from: 'works',
      where: {
        id: eventGroup.work_id
      }
    })).pipe(
      map((value: any[]) => value[ 0 ]),
      map((value: any) => {
        const work = (new Work());
        work.id = value.id;
        work.name = value.name;
        work.category = value.category;
        work.short_name = value.short_name;
        work.roles = []; // is empty
        work.composer = value.composer;
        work.composer_short_name = value.composer_short_name;
        eventGroup.work = work;
        return eventGroup
      })
    )
  }

  private addLocation(eventGroup: EventGroup): Observable<EventGroup> {
    if (!eventGroup || eventGroup.location_id === null || eventGroup.location_id === undefined) {
      return of(eventGroup);
    }
    return fromPromise(this.bas.connection.select({
      from: 'companies',
      where: {
        id: eventGroup.location_id
      }
    })).pipe(
      map((value: any[]) => value[ 0 ]),
      map((value: any) => {
        const company = (new CompanyModel());
        company.createFromDatabase(value);

        eventGroup.location = company;
        return eventGroup
      })
    )
  }

  private createFromIdb(data: any): EventGroup {
    const model = new EventGroup();


    data.updated_at = EventModel.parseDateFromDatabaseUnixTimestamp(data.updated_at);
    data.created_at = EventModel.parseDateFromDatabaseUnixTimestamp(data.created_at);
    data.all_in = data.all_in === '1';

    data.use_custom_title = data.use_custom_title === '1';
    data.visible_for_artist = data.visible_for_artist === '1';
    data.is_guest_performance = data.is_guest_performance === '1';
    data.is_new_production = data.is_new_production === '1';
    data.is_other_agency = data.is_other_agency === '1';
    data.fee_different_for_events = data.fee_different_for_events === '1';

    data.events = [];

    data.contract_invoice_sent = data.contract_invoice_sent === '1';
    data.contract_invoice_sent_time = !data.contract_invoice_sent_time
      ?
      null : EventGroup.parseDateFromDatabaseUnixTimestamp(data.contract_invoice_sent_time);

    data.contract_received = data.contract_received === '1';
    data.contract_received_time = !data.contract_received_time ? null : EventGroup.parseDateFromDatabaseUnixTimestamp(data.contract_received_time);

    data.contract_requested = data.contract_requested === '1';
    data.contract_requested_time = !data.contract_requested_time ? null : EventGroup.parseDateFromDatabaseUnixTimestamp(data.contract_requested_time);

    data.contract_sent_to_artist = data.contract_sent_to_artist === '1';
    data.contract_sent_to_artist_time = !data.contract_sent_to_artist_time ? null : EventGroup.parseDateFromDatabaseUnixTimestamp(data.contract_sent_to_artist_time);

    data.contract_sent_to_location = data.contract_sent_to_location === '1';
    data.contract_sent_to_location_time = !data.contract_sent_to_location_time ? null : EventGroup.parseDateFromDatabaseUnixTimestamp(data.contract_sent_to_location_time);

    data.contract_signed_by_artist = data.contract_signed_by_artist === '1';
    data.contract_signed_by_artist_time = !data.contract_signed_by_artist_time ? null : EventGroup.parseDateFromDatabaseUnixTimestamp(data.contract_signed_by_artist_time);

    data.contract_signed_by_location = data.contract_signed_by_location === '1';
    data.contract_signed_by_location_time = !data.contract_signed_by_location_time ? null :
      EventGroup.parseDateFromDatabaseUnixTimestamp(data.contract_signed_by_location_time);

    model.createFromDatabase(data);
    return model;
  }


}
