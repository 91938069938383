import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { EventGroup } from '../../../models/event-group';
import { EventModel } from '../../../models/event.model';
import * as moment from 'moment';
import { Moment } from 'moment';
import { CurrencyEnum, EventTypeEnum, TravelTypeEnum, UserRoleArtist } from '../../../models/enums';
import { EventService } from '../../../services/event.service';
import { AuthService } from '../../../services/auth/auth.service';
import { TravelGroup } from '../../../models/travel-group';
import { TravelGroupService } from '../../../services/travel-group.service';
import { OfflineModeService } from '../../../services/offline/offline-mode.service';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { debounceTime, distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { fromEvent, Observable, Subscription } from 'rxjs';


@Component({
    changeDetection: ChangeDetectionStrategy.Default,
    selector: 'app-event-list-editor',
    templateUrl: './event-list-editor.component.html',
    styles: [ '.eventRow.cancelled {text-decoration: line-through; color: #999 }' ]
})
export class EventListEditorComponent implements OnInit {

    @ViewChild('contextMenu') contextMenu: TemplateRef<any>;
    public overlayRef: OverlayRef | null;
    public closeContextMenuSub: Subscription;

    public readOnly = false;
    public offline = false;
    public _event_group: EventGroup;
    public _travel_group: TravelGroup;
    @Input() public groupFormIsDirty = false;

    @Input()
    public set event_group(event_group: EventGroup) {
        if (!event_group) {
            return;
        }
        this._event_group = event_group;
        this.createForm(event_group);
    }

    @Input()
    public set travel_group(travel_group: TravelGroup) {
        if (!travel_group) {
            return;
        }
        this._travel_group = travel_group;
        this.createForm(travel_group);
    }
    @Input() public defaultDate: Moment = null;
    @Input() public requestDirtyCheck: Observable<void> = null;


    @Output() public eventGroupDeleted = new EventEmitter<number>();
    @Output() public travelGroupDeleted = new EventEmitter<number>();
    @Output() public eventCreated = new EventEmitter<EventModel>();
    @Output() public eventUpdated = new EventEmitter<EventModel>();
    @Output() public eventDeleted = new EventEmitter<number>();


    public travel_types = [
        TravelTypeEnum.outbound,
        TravelTypeEnum.inbound,
        TravelTypeEnum.hotel
    ];

    public eventTypes = [
        EventTypeEnum.rehearsal,
        EventTypeEnum.premiere,
        EventTypeEnum.performance,
        EventTypeEnum.concert,
        EventTypeEnum.performanceOrConcert,
    ];
    public form: FormArray;

    public currencies = [
        CurrencyEnum.eur,
        CurrencyEnum.chf,
        CurrencyEnum.usd,
        CurrencyEnum.jpy,
        CurrencyEnum.gbp,
        CurrencyEnum.aud,
        CurrencyEnum.cad,
        CurrencyEnum.cny,
    ];


    constructor(private eventService: EventService,
                private travelGroupService: TravelGroupService,
                private eventGroupService: EventService,
                private offlineModeService: OfflineModeService,
                public overlay: Overlay,
                public viewContainerRef: ViewContainerRef,
                private fb: FormBuilder,
                authService: AuthService) {
        if (authService.getUser().role === UserRoleArtist) {
            this.readOnly = true;
        }
        this.form = this.fb.array([]);


        this.offlineModeService.offlineStatusChange$.subscribe(
            (isOffline) => {
                this.offline = isOffline;
                if (this.offline) {
                    this.form.disable()
                } else {
                    this.form.enable();
                }
            }
        );
        this.offline = this.offlineModeService.offline.getValue();
        if (this.offline) {
            this.form.disable()
        }

    }

    ngOnInit(): void {
    }

    public deleteEvent(index: number) {
        const doAction = confirm('Möchten Sie diesen Termin wirklich löschen?');
        if (doAction !== true) {
            return;
        }

        const id: number = this.form.at(index).get('id').value;


        console.log('delete id', id);
        if (id === null) {
            this.form.removeAt(index);
            return;
        }

        if ((this.form && this.form.controls.length === 1)) {
            const wantToDeleteTheLast = confirm('Dies ist der letzte Termin. Die gesamte Gruppe wird gelöscht!');
            if (wantToDeleteTheLast !== true) {
                return
            }
        }

        this.eventService.deleteEventById(id).subscribe(
            (res) => {

                if (this._event_group) {
                    this.form.removeAt(index);
                    if (this.form.controls.length === 0) {
                        this.eventGroupDeleted.next(this._event_group.id);
                    }
                }

                if (this._travel_group) {
                    this.form.removeAt(index);

                    if (this.form.controls.length === 0) {
                        this.eventGroupDeleted.next(this._event_group.id);

                        this.travelGroupService.deleteTravelGroup(this._travel_group).subscribe(
                            (deleted) => {
                                this.travelGroupDeleted.next(this._travel_group.id);
                                this._travel_group = null;
                            }
                        )
                    }
                }

                this.eventDeleted.emit(id);


            }
        );
    }

    public copyFormIndex(index: number) {
        if (this.readOnly) {
            return;
        }
        const form: AbstractControl = this.cloneAbstractControl(this.form.at(index));
        form.patchValue({
            id:  null
        });

        const event: EventModel = new EventModel();
        // @ts-ignore
        event.createFromFormModel(form);
        this.eventService.create(event).subscribe(
            (createdEvent: EventModel) => {
                this.form.push(this.createFormForEvent(createdEvent));
                this.eventCreated.emit(createdEvent);
            }
        )


    }

    public deleteFormIndex(index: number) {

        if (this.readOnly) {
            return;
        }
        this.deleteEvent(index);
    }

    public updateEventAtIndex(index: number) {
        const form = this.form.at(index);
        const event = new EventModel();
        // @ts-ignore
        event.createFromFormModel(form);
        this.eventService.update(event).subscribe(
            (updatedEvent: EventModel) => {
                form.patchValue({id: updatedEvent.id });
                this.eventUpdated.emit(updatedEvent);
                form.markAsUntouched();
                form.markAsPristine();
            }
        )
    }

    public createEventFromFormIndex(index: number) {
        const form = this.form.at(index)
        const event = new EventModel();
        // @ts-ignore
        event.createFromFormModel(form);
        this.eventService.create(event).subscribe(
            (createdEvent: EventModel) => {
                this.eventCreated.emit(createdEvent);
                form.patchValue({id: [createdEvent.id]});
                form.markAsUntouched();
                form.markAsPristine();
            }
        )
    }
    public saveAll() {
        this.form.controls.map(
            (control: FormGroup, index) => {
                console.log(control, control.get('id').value)
                if (control.get('id').value === null) {
                    this.createEventFromFormIndex(index);
                } else {
                    this.updateEventAtIndex(index);
                }
            }
        )
    }

    public addEvent() {
        if (this.readOnly) {
            return;
        }
        const eventForm = this.fb.group({
            id: [ null, [] ],
            type: [ this._travel_group ? EventTypeEnum.travel : EventTypeEnum.concert, {
                updateOn: 'change',
                validators: [ Validators.required ]
            } ],
            travel_group: [ null ],
            travel_group_id: [ this._travel_group ? this._travel_group.id : null ],
            travel_type: [ null, { updateOn: 'change', validators: [] } ],
            cancelled: [ false, [] ],
            notes: [ null, [] ],
            fee: [ null, [ Validators.pattern('^[0-9,]*$') ] ],
            fee_currency: [ CurrencyEnum.eur, { updateOn: 'change' } ],
            event_group_id: [ this._event_group ? this._event_group.id : null || null, [] ],
            artist_id: [ this._event_group ? this._event_group.artist_id : this._travel_group.artist_id, [] ],
            name: [ null, [] ],
            use_name_as_title: [ false, { updateOn: 'change' } ],
            startTime: [ { hour: 0, minute: 0, second: 0 } ],
            endTime: [ { hour: 23, minute: 59, second: 59 } ],
            start: [ this.formatForDatepicker(this.defaultDate), [ Validators.required ] ],
            end: [ this.formatForDatepicker(this.defaultDate), [ Validators.required ] ],
        }, { updateOn: 'blur' });

        this.markControlsAsTouchedAndDirty(eventForm)
        this.form.push(eventForm);
    }


    public setFeeForAllEvents(form: FormGroup) {
        this.form.controls.forEach(
            (formControl: FormGroup) => {
                if (formControl.get('id').value !== form.get('id').value) {
                    formControl.get('fee').setValue(form.get('fee').value);
                    formControl.get('fee').markAsDirty();
                    formControl.get('fee_currency').setValue(form.get('fee_currency').value);
                    formControl.get('fee_currency').markAsDirty();
                }

            }
        );
        this.closeContextMenu();
    }
    public onCurrencyContextMenu(mouseEvent: MouseEvent, form) {
        mouseEvent.preventDefault();
        this.closeContextMenu();
        const positionStrategy = this.overlay.position()
            .flexibleConnectedTo(mouseEvent)
            .withPositions([
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top',
                }
            ]);

        this.overlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.close()
        });

        this.overlayRef.attach(new TemplatePortal(this.contextMenu, this.viewContainerRef, {
            $implicit: form
        }));

        this.closeContextMenuSub = fromEvent<MouseEvent>(document , 'click')
            .pipe(
                filter(event => {
                    const clickTarget = event.target as HTMLElement;
                    return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
                }),
                take(1)
            ).subscribe(() => this.closeContextMenu());

        return false
    }

    closeContextMenu() {
        if (this.closeContextMenuSub) {
            this.closeContextMenuSub.unsubscribe();
        }
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
    }

    private formatForDatepicker(value: Moment) {
        const date = moment(value);
        return {
            day: parseInt(date.format('DD'), 10),
            month: parseInt(date.format('M'), 10),
            year: parseInt(date.format('YYYY'), 10),
        }
    }

    private datePickerToMoment(value: { day: number, month: number, year: number }): Moment {
        const date: Moment = moment();
        date.year(value.year);
        date.month(value.month);
        date.date(value.day);
        date.hours(0);
        date.minutes(0);
        date.seconds(0);
        return date;

    }


    private createForm(event_group: EventGroup|TravelGroup) {
        this.form = this.fb.array([]);

        event_group.events.forEach((event: EventModel) => {
            const eventForm = this.createFormForEvent(event);
            this.form.push(eventForm)
        });


    }

    private createFormForEvent(event: EventModel): FormGroup {
        const eventForm = this.fb.group({
            id: [ event.id ],
            type: [ event.type, { updateOn: 'change', validators: [ Validators.required ] } ],
            travel_group: [ event.travel_group ],
            travel_group_id: [ event.travel_group_id ],
            travel_type: [ event.travel_type, { updateOn: 'change', validators: [] } ],
            cancelled: [ event.cancelled, [] ],
            notes: [ event.notes, { updateOn: 'change', validators: [] } ],
            fee: [ event.fee, { updateOn: 'change', validators: [ Validators.pattern('^[0-9,]*$') ]}  ],
            fee_currency: [ event.fee_currency ? event.fee_currency : CurrencyEnum.eur, { updateOn: 'change' } ],
            event_group_id: [ event.event_group_id, [] ],
            artist_id: [ event.artist_id, [] ],
            name: [ event.name, { updateOn: 'change', validators: event.use_name_as_title ? [ Validators.required ] : [] } ],
            use_name_as_title: [ event.use_name_as_title, { updateOn: 'change' } ],
            startTime: [ { hour: 0, minute: 0, second: 0 } ],
            endTime: [ { hour: 23, minute: 59, second: 59 } ],
            start: [ this.formatForDatepicker(event.start), [ Validators.required ] ],
            end: [ this.formatForDatepicker(event.end), [ Validators.required ] ],
        }, { updateOn: 'blur' });

        eventForm.get('use_name_as_title').valueChanges.subscribe(
            (use_name_as_title) => {
                if (use_name_as_title === true) {
                    eventForm.get('name').setValidators([ Validators.required ]);
                } else {
                    eventForm.get('name').setValidators(null);
                }
                eventForm.get('name').updateValueAndValidity({ onlySelf: false, emitEvent: true });
            }
        );

        eventForm.valueChanges
            .pipe(

                debounceTime(100),
                // strip of the notes
                map((values) => {
                    return { end: values.end, start: values.start, type: values.type, use_name_as_title: values.use_name_as_title };
                }),
                distinctUntilChanged(),

            )

            .subscribe(
                (values) => {

                    if (values.end !== values.start &&
                        (values.type !== EventTypeEnum.rehearsal) && (values.type !== EventTypeEnum.travel)
                    ) {
                        eventForm.get('end').setValue(eventForm.get('start').value);
                        return;
                    } else {

                        if (this.datePickerToMoment(values.start) > this.datePickerToMoment(values.end)) {
                            eventForm.get('end').setValue(eventForm.get('start').value);
                        }
                    }
                }
            );

        return eventForm;

    }

    public toggleCancelEvent(form: FormGroup) {
        form.get('cancelled').setValue(!form.get('cancelled').value);
        form.get('cancelled').markAsDirty();
    }


    /**
     * Deep clones the given AbstractControl, preserving values, validators, async validators, and disabled status.
     * @param control AbstractControl
     * @returns AbstractControl
     */
    private cloneAbstractControl<T extends AbstractControl | FormGroup>(control: T): AbstractControl {
        let newControl: T;

        if (control instanceof FormGroup) {
            const formGroup = new FormGroup({}, control.validator, control.asyncValidator);
            const controls = control.controls;

            Object.keys(controls).forEach(key => {
                formGroup.addControl(key, this.cloneAbstractControl(controls[ key ]));
            });

            newControl = formGroup as any;
        } else if (control instanceof FormArray) {
            const formArray = new FormArray([], control.validator, control.asyncValidator);

            control.controls.forEach(formControl => formArray.push(this.cloneAbstractControl(formControl)))

            newControl = formArray as any;
        } else if (control instanceof FormControl) {
            newControl = new FormControl(control.value, control.validator, control.asyncValidator) as any;
        } else {
            throw new Error('Error: unexpected control value');
        }

        if (control.disabled) {
            newControl.disable({ emitEvent: false });
        }


        return newControl;
    }

    /**
     * Iterates over a FormGroup or FormArray and mark all controls as
     * touched, including its children.
     *
     * @param {(FormGroup | FormArray)} rootControl - Root form
     * group or form array
     * @param {boolean} [visitChildren=true] - Specify whether it should
     * iterate over nested controls
     */
    public markControlsAsTouchedAndDirty(rootControl: AbstractControl, visitChildren: boolean = true) {

        const stack: (FormGroup | FormArray)[] = [];

        // Stack the root FormGroup or FormArray
        if (rootControl &&
            (rootControl instanceof FormGroup || rootControl instanceof FormArray)) {
            stack.push(rootControl);
        }

        while (stack.length > 0) {
            const currentControl = stack.pop();
            (<any>Object).values(currentControl.controls).forEach((control) => {
                // If there are nested forms or formArrays, stack them to visit later
                if (visitChildren &&
                    (control instanceof FormGroup || control instanceof FormArray)
                ) {
                    stack.push(control);
                } else {
                    control.markAsTouched();
                    control.markAsDirty();
                }
            });
        }
    }

}
