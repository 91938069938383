<form [formGroup]="form"  autocomplete="off" *ngIf="_event_groupLoaded">
    <div class="row">
      <div class="col-12 col-md-4">


            <div class="form-group row">
                <label class="form-control-label col-form-label-sm col-3" for="custom_title">Titel</label>
                <div class="col-9">
                    <span class="input-group input-group-sm">



                        <input formControlName="custom_title"
                               class="form-control-sm form-control"
                               [attr.disabled]="readOnly||null"

                               id="custom_title">
                        <label class="input-group-addon border-right-0" for="use_custom_title">Im Kal. anz.</label>
                        <span class="input-group-addon border-left-0">
                          <input type="checkbox"
                                 [attr.disabled]="readOnly||null"
                                   class="form-control-sm"
                                   formControlName="use_custom_title"
                                   aria-label="use_custom_title" id="use_custom_title">
                        </span>



                    </span>
                </div>
            </div>



            <div class="form-group row"

                 [class.has-danger]="form.get('state').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="state">Status</label>

                                <div class="col-9">
                                    <select formControlName="state"
                                            [attr.disabled]="readOnly||null"

                                            id="state"
                                            class="form-control form-control-sm">
                                        <option *ngFor="let state of event_groupStates" [ngValue]="state">{{state|event_groupState}}</option>
                                    </select>
                                       </div>




                <div class="col-12">
                    <div class="form-control-feedback"
                         *ngIf="form.get('state').invalid">
                        Status ist ein Pflichtfeld
                    </div>
                </div>
            </div>



          <div class="form-group row" [class.hidden]="readOnly">
            <label class="form-control-label col-form-label-sm col-4" for="visible_for_artist">Sichtbar f. Künstler</label>

            <div class="col-2">
              <input type="checkbox"
                     [attr.disabled]="readOnly||null"
                     class="form-control-sm"
                     formControlName="visible_for_artist"
                     aria-label="Sichtbar f. Künstler" id="visible_for_artist">
            </div>

            <label class="form-control-label col-form-label-sm col-4" for="is_other_agency">Andere Agentur</label>
            <div class="col-2">
              <input type="checkbox"
                     [attr.disabled]="readOnly||null"
                     class="form-control-sm"
                     formControlName="is_other_agency"
                     aria-label="Andere Agentur? " id="is_other_agency">
            </div>

          </div>


            <input type="hidden" formControlName="artist_id"/>


            <div class="form-group row"
                 [class.has-danger]="form.get('location_id').invalid">
                <label class="form-control-label col-form-label-sm col-3">Veranstalter</label>
                <div class="col-9">

                    <app-company-selector
                            [readOnly]="readOnly"
                            [dirty]="form.get('location_id').dirty"
                            [showDelete]="true"
                            [showEdit]="true"
                            [companyId]="form.get('location_id').value"
                            [type]="companyTypeFilterForCompanySelector"
                            (onSubModalShow)="onModalShow.next(true)"
                            (onSubModalHide)="onModalHide.next(true)"
                            (companyChange)="setLocation($event)"></app-company-selector>



                </div>

                <div class="col-12">
                    <div class="form-control-feedback"
                         *ngIf="form.get('location_id').invalid">
                        Veranstalter ist ein Pflichtfeld
                    </div>
                </div>
            </div>


            <div class="form-group row"
                 [class.has-danger]="form.get('location_contact_id').invalid">
                <label class="form-control-label col-form-label-sm col-3">Ansprechpartner (intern)</label>
                <div class="col-9">
                    <app-contact-selector
                            [readOnly]="readOnly"
                            [dirty]="form.get('location_contact_id').dirty"
                            [filterToCompanyId]="filterContactsToCompany"
                            [contactTypeFilter]="contactTypeFilterForSelectContactComponent"
                            [selectedContactId]="form.get('location_contact_id').value"
                            (onSubModalShow)="onModalShow.next(true)"
                            (onSubModalHide)="onModalHide.next(true)"

                            (contactChange)="setLocationContact($event)"></app-contact-selector>

                </div>

                <div class="col-12">
                    <div class="form-control-feedback"
                         *ngIf="form.get('location_contact_id').invalid">
                        Ansprechpartner ist ein Pflichtfeld
                    </div>
                </div>

            </div>

        <div class="form-group row"
             [class.has-danger]="form.get('location_contact_for_artist_id').invalid">
            <label class="form-control-label col-form-label-sm col-3">Ansprechpartner f. Künstler</label>
            <div class="col-9">
              <app-contact-selector
                [readOnly]="readOnly"
                [dirty]="form.get('location_contact_for_artist_id').dirty"
                [filterToCompanyId]="filterContactsToCompany"
                [contactTypeFilter]="contactTypeFilterForSelectContactComponent"
                [selectedContactId]="form.get('location_contact_for_artist_id').value"
                (onSubModalShow)="onModalShow.next(true)"
                (onSubModalHide)="onModalHide.next(true)"

                (contactChange)="setLocationContactForArtist($event)"></app-contact-selector>

            </div>

          <div class="col-12">
            <div class="form-control-feedback"
                 *ngIf="form.get('location_contact_for_artist_id').invalid">
              Ansprechpartner ist ein Pflichtfeld
            </div>
          </div>

        </div>
        </div>
      <div class="col-12 col-md-4">


            <div class="form-group row">

                <label class="form-control-label col-form-label-sm col-2">Werk</label>

                <div class="col-10">
                     <app-select-work-role
                             [disabled]="readOnly"
                             (onSubModalShow)="onModalShow.next(true)"
                             (onSubModalHide)="onModalHide.next(true)"
                             (workSelected)="setWork($event)"
                             (roleSelected)="setRole($event)"
                             [workKey]="form.get('work_id').value"
                             [roleKey]="form.get('role_id').value"

                     ></app-select-work-role>
                </div>

            </div>
            <div class="form-group row">
                <label class="form-control-label col-form-label-sm col-3" for="is_new_production">Neuproduktion</label>
                <div class="col-1">
                        <input type="checkbox"
                               [attr.disabled]="readOnly||null"
                               class="form-control-sm"
                               formControlName="is_new_production"
                               aria-label="Neuproduktion? " id="is_new_production">
                </div>

            <label class="form-control-label col-form-label-sm col-3 text-right" for="is_new_production">Gastspiel</label>
            <div class="col-1">
              <input type="checkbox"
                     [attr.disabled]="readOnly||null"
                     class="form-control-sm"
                     formControlName="is_guest_performance"
                     aria-label="Gastspiel? " id="is_guest_performance">
            </div>
            <div class="col-4">
              <input type="text"
                     [class.hidden]="!form.get('is_guest_performance').value"
                     placeholder="Gastspiel Ort"
                     class="form-control-sm"
                     formControlName="guest_performance_name"
                     aria-label="Gastspiel Ort " id="guest_performance_name">
            </div>
          </div>



            <div class="form-group row"
                 [class.has-danger]="form.get('director_id').invalid">
                <label class="form-control-label col-form-label-sm col-2">Regie</label>
                <div class="col-10">

                    <app-contact-selector
                            [readOnly]="readOnly"
                            [dirty]="form.get('director_id').dirty"
                            [contactTypeFilter]="7"
                            [selectedContactId]="form.get('director_id').value"
                            (onSubModalShow)="onModalShow.next(true)"
                            (onSubModalHide)="onModalHide.next(true)"
                            (contactChange)="setDirectorContact($event)"></app-contact-selector>

                </div>
            </div>

            <div class="form-group row"
                 [class.has-danger]="form.get('conductor_id').invalid">
                <label class="form-control-label col-form-label-sm col-2">Dirigent</label>
                <div class="col-10">

                    <app-contact-selector
                            [readOnly]="readOnly"
                            [dirty]="form.get('conductor_id').dirty"
                            [contactTypeFilter]="8"
                            [selectedContactId]="form.get('conductor_id').value"
                            (onSubModalShow)="onModalShow.next(true)"
                            (onSubModalHide)="onModalHide.next(true)"
                            (contactChange)="setConductorContact($event)"></app-contact-selector>

                </div>
            </div>


            <div class="form-group row"
                 [class.has-danger]="form.get('fee').invalid">
                <label class="form-control-label col-form-label-sm col-2" for="fee">Gage</label>

                <div class="col-10">
                    <div class="row">
                        <div class="col-12">
                            <div class="input-group input-group-sm" *ngIf="form.get('fee_different_for_events').value === false">

                                <input type="text"

                                       [attr.disabled]="readOnly||form.get('fee_different_for_events').value||null"
                                       class="form-control form-control-sm "
                                       formControlName="fee"
                                       placeholder="Gage"
                                       [class.form-control-danger]="form.get('fee').invalid"
                                       id="fee">


                                <select formControlName="currency"
                                        style="font-size: 17px;padding: 0 18px 0 5px;"
                                        [attr.disabled]="readOnly||form.get('fee_different_for_events').value||null"
                                        class="custom-select">
                                    <option *ngFor="let currency of currencies" [ngValue]="currency">{{ currency}}</option>
                                </select>

                            </div>

                            <div class="input-group input-group-smr"  *ngIf="form.get('fee_different_for_events').value === true">
                                <label class="w-100 input-group-addon border-right-0" for="fee_different_for_events">
                                    Gage siehe oben
                                </label>
                            </div>
                        </div>
                        <div class="col-12">

                            <div class="input-group input-group-sm">
                                <label class="input-group-addon border-right-0 w-100" for="all_in" *ngIf="form.get('fee_different_for_events').value === false">
                                {{
                                form.get('fee').value?.replace('.','').replace(',','.')|currency:(form.get('currency').value):'symbol':'1.2-2':'de'
                                }}
                                </label>

                                <label class="text-right w-100 input-group-addon border-right-0" for="all_in">
                                    all in
                                </label>
                                <span class="input-group-addon border-left-0">
                                    <input type="checkbox"
                                           [attr.disabled]="readOnly||null"
                                           class="form-control-sm"
                                           formControlName="all_in"
                                           aria-label="Reisekosten Inkl? " id="all_in">
                                </span>

                                <label class="input-group-addon border-right-0" for="fee_different_for_events">
                                    Gage verschieden
                                </label>
                                <span class="input-group-addon border-left-0">
                                    <input type="checkbox"
                                           [attr.disabled]="readOnly||null"
                                           class="form-control-sm"
                                           formControlName="fee_different_for_events"
                                           aria-label="Reisekosten Inkl? " id="fee_different_for_events">

                                </span>
                            </div>


                        </div>
                    </div>

                </div>
            </div>


          <div class="form-group row mb-0"
               *ngIf="form.get('all_in').value !== true"
               [class.has-danger]="form.get('rehearsal_fee').invalid">
              <label class="form-control-label col-form-label-sm col-2" for="rehearsal_fee">Probengeld</label>
              <div class="col-10">
                  <div class="input-group input-group-sm">
                      <input type="text"
                             [attr.disabled]="readOnly||null"
                             class="form-control form-control-sm "
                             formControlName="rehearsal_fee"
                             placeholder="Probengeld"
                             [class.form-control-danger]="form.get('rehearsal_fee').invalid"
                             id="rehearsal_fee">

                      <select formControlName="rehearsal_fee_currency"
                              style="font-size: 17px;padding: 0 18px 0 5px;"
                              [attr.disabled]="readOnly||null"
                              class="custom-select">
                          <option *ngFor="let currency of currencies" [ngValue]="currency">{{ currency}}</option>
                      </select>

                  </div>
              </div>
              <div class="col-10 offset-2">
                  {{
                  form.get('rehearsal_fee').value?.replace('.','').replace(',','.')|currency:(form.get('rehearsal_fee_currency').value):'symbol':'1.2-2':'de'
                  }}
              </div>
          </div>

          <div class="form-group row mt-0"
               *ngIf="form.get('all_in').value !== true"
               [class.has-danger]="form.get('rehearsal_fee_annotation').invalid">

              <label class="form-control-label col-form-label-sm col-2" for="rehearsal_fee_annotation"></label>
              <div class="col-10">
                  <input type="text"
                         [attr.disabled]="readOnly||null"
                         class="form-control form-control-sm "
                         formControlName="rehearsal_fee_annotation"
                         placeholder="Anmerkung zu Probengeld"
                         [class.form-control-danger]="form.get('rehearsal_fee_annotation').invalid"
                         id="rehearsal_fee_annotation">
              </div>

          </div>


            <div class="form-group row"
                 *ngIf="form.get('all_in').value !== true"
                 [class.has-danger]="form.get('travel_cost').invalid">
                <label class="form-control-label col-form-label-sm col-2" for="travel_cost">Reisekosten</label>
                <div class="col-10">
                    <div class="input-group input-group-sm">
                        <input type="text"
                               [attr.disabled]="readOnly||null"
                               class="form-control form-control-sm "
                               formControlName="travel_cost"
                               placeholder="Reisekosten"
                               [class.form-control-danger]="form.get('travel_cost').invalid"
                               id="travel_cost">


                        <select formControlName="travel_cost_currency"
                                style="font-size: 17px;padding: 0 18px 0 5px;"
                                [attr.disabled]="readOnly||null"
                                class="custom-select">
                            <option *ngFor="let currency of currencies" [ngValue]="currency">{{ currency}}</option>
                        </select>


                        <select
                                [attr.disabled]="readOnly||null"
                                formControlName="travel_cost_type" class="custom-select">
                            <option *ngFor="let costType of costTypes" [ngValue]="costType">{{ costType|costType }}</option>
                        </select>

                    </div>
                </div>
                <div class="col-10 offset-2">
                  {{
                  form.get('travel_cost').value?.replace('.','').replace(',','.')|currency:(form.get('travel_cost_currency').value):'symbol':'1.2-2':'de'
                  }}
                </div>
            </div>







            <div class="form-group row"
                 *ngIf="form.get('all_in').value !== true"
                 [class.has-danger]="form.get('hotel_cost').invalid">
                <label class="form-control-label col-form-label-sm col-2" for="hotel_cost">Hotelkosten</label>
                <div class="col-10">
                    <div class="input-group input-group-sm">
                        <input type="text"
                               [attr.disabled]="readOnly||null"
                               class="form-control form-control-sm "
                               formControlName="hotel_cost"
                               placeholder="Hotelkosten"
                               [class.form-control-danger]="form.get('hotel_cost').invalid"
                               id="hotel_cost">

                        <select formControlName="hotel_cost_currency"
                                style="font-size: 17px;padding: 0 18px 0 5px;"
                                [attr.disabled]="readOnly||null"
                                class="custom-select">
                            <option *ngFor="let currency of currencies" [ngValue]="currency">{{ currency}}</option>
                        </select>

                        <select   [attr.disabled]="readOnly||null"
                                formControlName="hotel_cost_type"
                                class="custom-select">
                            <option *ngFor="let costType of costTypes" [ngValue]="costType">{{ costType|costType }}</option>
                        </select>

                    </div>
                </div>
                <div class="col-10 offset-2">
                  {{
                  form.get('hotel_cost').value?.replace('.','').replace(',','.')|currency:(form.get('hotel_cost_currency').value):'symbol':'1.2-2':'de'
                  }}
                </div>
            </div>
        </div>




        <div class="col-12 col-md-4">
          <div class="row pt-1" *ngFor="let control  of contractFields" style="border-bottom: 1px solid #f3f3f3">

            <div class="col-8">
              <div class="form-check">
                <label class="form-check-label"
                       [class.form-control-danger]="form.get(control).invalid"
                       for="id-{{control}}">
                  <input class="form-check-input"
                         id="id-{{control}}"
                         type="checkbox"
                         [formControlName]="control"
                  >
                  {{ control| contractState}}
                </label>
              </div>
            </div>


            <div class="col-4  text-muted p-0" >
              {{ form.get( control + '_time').value| date :'dd.MM.y HH:mm': 'Europe/Berlin'  }}
            </div>

          </div>
          <div class="row pt-2">

            <label class="form-control-label col-form-label-sm col-4" for="contract_number">Vertragsnummer</label>
            <div class="col-8">
              <input formControlName="contract_number"
                     id="contract_number"
                     class="form-control form-control-sm">
            </div>
          </div>




        </div>



    </div>
    <div class="row">

        <div class="col-12 col-md-6 bg-faded">

            <div class="form-group row"
                 [class.has-danger]="form.get('notes_pub').invalid">
                <label class="form-control-label col-form-label-sm col-12" for="notes_pub">Notizen
                    <span *ngIf="!readOnly">(sichtbar für Künstler)</span></label>
                <div class="col-12">
                    <textarea type="text"
                              autosize
                              class="form-control form-control-sm "
                              formControlName="notes_pub"
                              placeholder="Notizen"
                              [attr.disabled]="readOnly||null"
                              [class.form-control-danger]="form.get('notes_pub').invalid"
                              id="notes_pub"></textarea>
                </div>
            </div>


          <div class="row">

            <div class="col  bg-faded ">
              <div class="row pt-3" *ngIf="!readOnly">
                <div class="col-12">
                  <strong>Datei  (sichtbar für Künstler)</strong>
                </div>
                <div class="col-12">


                  <dropzone
                    #dropZone
                    [useDropzoneClass]="true"
                    [disabled]="offline || readOnly"
                    (success)="uploadFinished($event)"
                  ></dropzone>

                </div>
              </div>

              <ng-container  *ngFor="let file of form.get('attachments').value">
                <app-file-details *ngIf="file.group !== 'private'"
                                  class="pb-1"
                                  [file]="file"
                                  (fileDeleted)="removeFile($event)"
                ></app-file-details>
              </ng-container>

            </div>

          </div>


        </div>


        <div class="col-12 col-md-6 bg-faded " *ngIf="!readOnly" >
            <div class="form-group row"
                 [class.has-danger]="form.get('notes_int').invalid">
                <label class="form-control-label col-form-label-sm col-12" for="notes_int">Notizen
                    (intern)</label>
                <div class="col-12">
                                      <textarea type="text"
                                                autosize
                                                class="form-control form-control-sm "
                                                formControlName="notes_int"
                                                [attr.disabled]="readOnly||null"
                                                placeholder="Notizen (intern)"
                                                [class.form-control-danger]="form.get('notes_int').invalid  && (form.get('notes_int').touched || submitted)"
                                                id="notes_int"></textarea>
                </div>
            </div>



          <div class="row">

            <div class="col  ">
              <div class="row pt-3" *ngIf="!readOnly">
                <div class="col-12">
                  <strong>Datei hochladen (Nicht sichtbar für Künstler)</strong>
                </div>
                <div class="col-12">
                  <dropzone
                    #dropZone
                    [useDropzoneClass]="true"
                    [disabled]="offline || readOnly"
                    (success)="uploadFinished($event, false)"
                  ></dropzone>

                </div>
              </div>

              <ng-container  *ngFor="let file of form.get('attachments').value">
                <app-file-details *ngIf="file.group === 'private'"
                                  class="pb-1"
                                  [file]="file"
                                  (fileDeleted)="removeFile($event)"
                ></app-file-details>
              </ng-container>

            </div>

          </div>


        </div>


    </div>
    <input type="hidden" formControlName="id">


    <div class="row pt-2 " style="border-top: 1px solid #8c8c8c">
        <div class="col-6">

            <span class="text-muted">
              Erstellt von {{ _event_group.audit_first?.user?.first_name}}  {{ _event_group.audit_first?.user?.last_name}} am  {{ _event_group.created_at|date:'medium'}}   <br>
              Bearbeitet von {{ _event_group.audit_latest?.user?.first_name}}  {{ _event_group.audit_latest?.user?.last_name}} am {{ _event_group.updated_at|date:'medium'}}
            </span>
        </div>

            <!-- 4 === EventGroupStateEnum.cancelled -->



        <div class="col-6">
          <button type="button" *ngIf="form.get('state').value === 4" class="btn btn-danger" (click)="deleteGroup()">Termin Gruppe löschen</button>
            <button type="button" class="btn pull-right btn-primary"

                    [disabled]="!form.valid || readOnly || offline"
                    (click)="save()">Speichern <i class="fa fa-spin fa-spinner" *ngIf="isSaving"></i>

            </button>

          <button type="button" class="btn pull-right btn-primary"

                    [disabled]="!form.valid || readOnly || offline"
                    (click)="save(true)">Speichern und Schließen <i class="fa fa-spin fa-spinner" *ngIf="isSaving"></i></button>
        </div>

        <div class="col-12">

        </div>


    </div>

</form>
