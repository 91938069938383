<form autocomplete="off" [formGroup]="companyForm" >

<div class="modal-body">


  <div class="row">

    <div class="col-6">

      <div class="form-group row"
           [class.has-danger]="companyForm.get('name').invalid || companyForm.get('name').invalid">
        <label class="form-control-label col-form-label-sm col-2" for="name">Name</label>
        <div class="col-10">
          <input type="text"
                 class="form-control form-control-sm "
                 formControlName="name"
                 placeholder="Name"
                 [class.form-control-danger]="companyForm.get('name').invalid"
                 id="name">

        </div>

        <div class="col-12">
          <div class="form-control-feedback"
               *ngIf="companyForm.get('name').hasError('required')">
            Name ist ein Pflichtfeld
          </div>
          <div class="form-control-feedback"
               *ngIf="companyForm.get('name').hasError('minlength')">
            Mindestens 2 Zeichen.
          </div>

        </div>
      </div>






      <div class="form-group row"
           [class.has-danger]="companyForm.get('street').invalid">
        <label class="form-control-label col-form-label-sm col-2" for="street">Adresse</label>



        <div class="col-10">
          <input type="text"
                 class="form-control  form-control-sm"
                 formControlName="street"
                 placeholder="Adresse"
                 [class.form-control-danger]="companyForm.get('street').invalid"
                 id="street">
        </div>
      </div>

      <div class="form-group row"
           [class.has-danger]="companyForm.get('city').invalid">
        <label class="form-control-label col-form-label-sm col-2" for="city">PLZ/ Stadt</label>
        <div class="col-3">
          <input type="text"
                 class="form-control form-control-sm "
                 formControlName="zip"
                 placeholder="PLZ"
                 [class.form-control-danger]="companyForm.get('zip').invalid"
                 id="zip">

        </div>
        <div class="col-7">
          <input type="text"
                 class="form-control  form-control-sm"
                 formControlName="city"
                 placeholder="Stadt"
                 [class.form-control-danger]="companyForm.get('city').invalid"
                 id="city">
        </div>

      </div>



      <div class="form-group row"
           [class.has-danger]="companyForm.get('country').invalid">
        <label class="form-control-label col-form-label-sm col-2" for="country">Land</label>



        <div class="col-10">
          <input type="text"
                 class="form-control  form-control-sm"
                 formControlName="country"
                 placeholder="Land"
                 [class.form-control-danger]="companyForm.get('country').invalid"
                 id="country">
        </div>
      </div>



      <div class="form-group row"
           [class.has-danger]="companyForm.get('city_for_calendar').invalid">
        <label class="form-control-label col-form-label-sm col-2" for="city">Stadt (für Kalenderansicht)</label>
        <div class="col-10">
          <input type="text"
                 class="form-control  form-control-sm"
                 formControlName="city_for_calendar"
                 placeholder="Stadt (für Kalender)"
                 [class.form-control-danger]="companyForm.get('city_for_calendar').invalid"
                 id="city_for_calendar">
        </div>
      </div>


      <div class="form-group row"
           [class.has-danger]="companyForm.get('email').invalid">
        <label class="form-control-label col-form-label-sm col-2" for="email">E-Mail</label>




        <div class="col-10">
          <div class="input-group input-group-sm">
            <input type="email"
                   class="form-control  form-control-sm"
                   formControlName="email"
                   placeholder="E-Mail"
                   [class.form-control-danger]="companyForm.get('email').invalid"
                   id="email">

            <span class="input-group-btn">
                      <a *ngIf="companyForm.get('email').value !== '' && companyForm.get('email').valid"
                         href="mailto:{{companyForm.get('email').value}}"
                         class="btn btn-sm btn-outline-success"><i class="fa fa-at"></i></a>
                    </span>
          </div>
        </div>
      </div>


      <div class="form-group row"
           [class.has-danger]="companyForm.get('phone').invalid ">
        <label class="form-control-label col-form-label-sm col-2" for="phone">Telefon</label>

        <div class="col-10">
          <div class="input-group input-group-sm">

            <input type="tel"
                   class="form-control  form-control-sm"
                   formControlName="phone"
                   placeholder="Telefon"
                   [class.form-control-danger]="companyForm.get('phone').invalid"
                   id="phone">

            <span class="input-group-btn">
                      <a *ngIf="companyForm.get('phone').value !== ''"
                         href="tel:{{companyForm.get('phone').value}}" class="btn btn-sm btn-outline-success"><i class="fa fa-phone"></i></a>
                    </span>
          </div>

        </div>
      </div>

      <div class="form-group row"
           [class.has-danger]="companyForm.get('fax').invalid">
        <label class="form-control-label col-form-label-sm col-2" for="fax">Fax</label>

        <div class="col-10">
          <input type="tel"
                 class="form-control  form-control-sm"
                 formControlName="fax"
                 placeholder="Fax"
                 [class.form-control-danger]="companyForm.get('fax').invalid"
                 id="fax">
        </div>
      </div>



    </div>


    <div class="col-6">

      <div class="form-group row"
           [class.has-danger]="companyForm.get('type').invalid">
        <label class="form-control-label col-form-label-sm col-2"
               for="type">Kategorie</label>
        <div class="col-10">
          <select class="form-control form-control-sm"
                  formControlName="type"
                  [class.form-control-danger]="companyForm.get('type').invalid"
                  id="type">
            <option *ngFor="let type of companyTypes" [ngValue]="type">
              {{type|companyType}}
            </option>

          </select>
        </div>
      </div>

      <div class="form-group row"
           [class.has-danger]="companyForm.get('phone2').invalid">
        <label class="form-control-label col-form-label-sm col-2" for="phone2">Telefon 2</label>

        <div class="col-10">
          <div class="input-group input-group-sm">
            <input type="text"
                   class="form-control  form-control-sm"
                   formControlName="phone2"
                   placeholder="Telefon 2"
                   [class.form-control-danger]="companyForm.get('phone2').invalid"
                   id="phone2">

            <span class="input-group-btn">
                                                <a *ngIf="companyForm.get('phone2').value !== ''" href="tel:{{companyForm.get('phone2').value}}" class="btn btn-sm btn-outline-success"><i class="fa fa-phone"></i></a>
                                            </span>

          </div>
        </div>
      </div>

      <div class="form-group row"
           [class.has-danger]="companyForm.get('website').invalid">
        <label class="form-control-label col-form-label-sm col-2" for="website">Website</label>

        <div class="col-10">
          <input type="text"
                 class="form-control  form-control-sm"
                 formControlName="website"
                 placeholder="Website"
                 [class.form-control-danger]="companyForm.get('website').invalid"
                 id="website">
        </div>
      </div>


      <div class="form-group"
           [class.has-danger]="companyForm.get('internal_comment').invalid">
        <label class="form-control-label " for="website">Kommentar</label>


        <textarea formControlName="internal_comment"
                  class="form-control  form-control-sm"
                  placeholder="Interner Kommentar"
                  [class.form-control-danger]="companyForm.get('website').invalid"
                  id="internal_comment"></textarea>

      </div>

    </div>
  </div>

</div>
<div class="modal-footer">
          <span class="alert-danger alert" *ngIf="companyForm.get('id').value === null">
                            Noch nicht gespeichert
                        </span>
  <button type="button" (click)="deleteCompany()" class="btn btn-sm btn-outline-danger">Löschen</button>
  <button type="button" (click)="updateOrCreate(false)" class="btn btn-primary">Speichern</button>
  <button type="button" (click)="updateOrCreate(true)" class="btn btn-primary">Speichern & Schließen</button>
</div>


</form>
