import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { CalendarComponent } from '../../pages/calendar/calendar.component';

@Injectable()
export class NoAuthenticatedUsersGuard implements CanActivate {

  constructor(
      public authService: AuthService,
      private router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (this.authService.isLoggedIn() === true) {
      console.log('NoAuthenticatedUsersGuard User is logged in, redirect to modals')
      this.router.navigate(['/']);
      return false;
    }
    return true;

  }
}
