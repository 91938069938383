<form [formGroup]="form" autocomplete="off" *ngIf="travel_groupLoaded">
  <div class="row">
    <div class="col-12">
      <h4>Reise Termine</h4>
    </div>




    <input type="hidden" formControlName="artist_id"/>





  </div>
  <div class="row">

    <div class="col-4">

      <div class="form-group row"
           [class.has-danger]="form.get('notes_pub').invalid && (form.get('notes_pub').touched || submitted)">
        <label class="form-control-label col-form-label-sm col-12" for="notes_pub">Notizen
          <span *ngIf="!readOnly">(sichtbar für Künstler)</span></label>
        <div class="col-12">
                                      <textarea type="text"
                                                autosize
                                                class="form-control form-control-sm "

                                                formControlName="notes_pub"
                                                placeholder="Notizen"
                                                [class.form-control-danger]="form.get('notes_pub').invalid  && (form.get('notes_pub').touched || submitted)"

                                                id="notes_pub"></textarea>
        </div>
      </div>


    </div>


    <div class="col-4" *ngIf="!readOnly">
      <div class="form-group row"
           [class.has-danger]="form.get('notes_int').invalid && (form.get('notes_int').touched || submitted)">
        <label class="form-control-label col-form-label-sm col-12" for="notes_int">Notizen
          (intern)</label>
        <div class="col-12">
                                      <textarea type="text"
                                                autosize
                                                class="form-control form-control-sm "
                                                formControlName="notes_int"

                                                placeholder="Notizen (intern)"
                                                [class.form-control-danger]="form.get('notes_int').invalid  && (form.get('notes_int').touched || submitted)"
                                                id="notes_int"></textarea>
        </div>
      </div>


    </div>
    <div class="col-4 bg-faded pt-4">

      <app-file-details
              [readOnly]="readOnly || offline"
              class="pb-1"
              *ngFor="let file of form.get('attachments').value"
              [file]="file"
              (fileDeleted)="removeFile($event)"
      ></app-file-details>


      <div class="row pt-3" *ngIf="!readOnly">

        <div class="col-12">
          <strong>Datei hochladen</strong>
        </div>
        <div class="col-12">
          <dropzone
                  [useDropzoneClass]="true"
                  [disabled]="readOnly || offline"
                  (success)="uploadFinished($event)"
          ></dropzone>
        </div>
      </div>



    </div>


  </div>



  <div class="row" >
    <div class="col-6">

            <span class="text-muted" *ngIf="_travel_group">
              Erstellt von {{ _travel_group.audit_first?.user.first_name}}  {{ _travel_group.audit_first?.user.last_name}} am  {{ _travel_group.created_at|date:'medium'}}   <br>
              Bearbeitet von {{ _travel_group.audit_latest?.user.first_name}}  {{ _travel_group.audit_latest?.user.last_name}} am {{ _travel_group.updated_at|date:'medium'}}
            </span>
    </div>

    <div class="col-6">
      <button type="button" class="btn pull-right btn-primary"
              [ngClass]=" form.pristine ?  'btn-default' : 'btn-success'"
              [disabled]="!form.valid || readOnly || offline"
              (click)="save()">Speichern </button>

      <button type="button" class="btn pull-right btn-primary"

              [disabled]="!form.valid || readOnly || offline"
              (click)="save(true)">Speichern und Schließen</button>

    </div>




  </div>


  <input type="hidden" formControlName="id">

</form>
