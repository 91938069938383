import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';


export class BaseModel {


    /**
     *
     * @param date string
     * @returns {moment.Moment}
     */
    public static parseDateFromDatabase(date: any): Moment {

        return moment.utc(date).tz('Europe/Berlin');
    }

    public static parseDateFromDatabaseUnixTimestamp(date: any): Moment {

        return moment.utc(date * 1000).tz('Europe/Berlin');
    }

    public static parseDateForDatabase(date: Moment): string {
        return moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
    }
}
