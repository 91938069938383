import { Pipe, PipeTransform } from '@angular/core';
import { LangEnum } from '../models/enums';

@Pipe({
  name: 'language'
})
export class LangPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
        case LangEnum.de: return 'Deutsch';
        case LangEnum.en: return 'Englisch';
        case LangEnum.fr: return 'Französisch';
        case LangEnum.it: return 'Italienisch';
        case LangEnum.ru: return 'Russisch';
    }
  }

}
