<div bsModal #modal="bs-modal" class="modal"  tabindex="-1" role="dialog"
     aria-hidden="true"
     [config]="{backdrop: 'static'}">


    <div class="modal-dialog">
      <div class="modal-content" *ngIf="modal.isShown && event">


        <div class="modal-header">

          <!-- event with event group type in 1, 2, 3 -->
          <h5 class="pb-0 mb-0 modal-title ">
            {{ event.getDisplayName()}}
          </h5>



          <button type="button" class="close pull-right" (click)="modal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>


        <div class="modal-body">

          <div class="row">

            <div class="col-12">

              <small *ngIf="artist" >Künstler: {{artist?.first_name}} {{artist?.last_name}} <span *ngIf="artist.artist_name_last_name">({{artist.artist_name_first_name}} {{artist.artist_name_last_name}}</span></small>


              <!-- event with event group type in 1, 2, 3 Rehearsal, Concert/, premiere-->
              <div *ngIf="(event.type !== 5 && event.type !== 4) && event.event_group">
                <strong>{{event.type|eventType}} am {{event.start|date:'longDate':'Europe/Berlin' }}</strong><br>
                  <span *ngIf="event.event_group.location">
                      <strong>Veranstalter:</strong> {{event.event_group.location.name}} <br>
                     <strong>Ort:</strong> {{event.event_group.location?.city}} <br>
                     <strong>Dirigent:</strong> {{(event.event_group.conductor_id|contactIdToContact|async)?.getName()}} <br>
                     <strong>Regiesseur:</strong> {{(event.event_group.director_id|contactIdToContact|async)?.getName()}} <br>
                     <strong>Werk:</strong> {{(event.event_group.work?.name)}} <br>
                     <strong>Rolle:</strong> {{(event.event_group.role_id|roleIdToRoleName|async)}} <br>
                  </span>

                <span *ngIf="event.event_group.is_guest_performance">
                    <strong>Gastspiel:</strong> {{event.event_group.guest_performance_name}}<br>
                  </span>

                <hr>

                <span *ngIf="event.event_group.location_contact_for_artist">
                    <strong>Ansprechpartner: </strong> {{ event.event_group.location_contact_for_artist.first_name}} {{ event.event_group.location_contact_for_artist.last_name}}<br>
                    <strong>Kontakt: </strong> <br>
                     <a *ngIf="event.event_group.location_contact_for_artist.phone"
                        [href]="'tel:' + event.event_group.location_contact_for_artist.phone">Tel: {{event.event_group.location_contact_for_artist.phone}}<br></a>
                     <a *ngIf="event.event_group.location_contact_for_artist.phone2"
                        [href]="'tel:' + event.event_group.location_contact_for_artist.phone2">Tel2: {{event.event_group.location_contact_for_artist.phone2}}<br></a>
                     <a *ngIf="event.event_group.location_contact_for_artist.mobile"
                        [href]="'tel:' + event.event_group.location_contact_for_artist.mobile">Mobil: {{event.event_group.location_contact_for_artist.mobile}}<br></a>
                     <a *ngIf="event.event_group.location_contact_for_artist.email"
                        [href]="'mailto:' + event.event_group.location_contact_for_artist.email">Mail: {{event.event_group.location_contact_for_artist.email}}<br></a>
                  </span>

                <hr>
                <span *ngIf="event.notes">
                      <strong>Notizen zum Termin:</strong> <span [innerHtml]="event.notes|nl2br"></span> <br>
                </span>
                <span *ngIf="event.event_group.notes_pub">
                    <strong>Notizen zum Engagement:</strong> <span
                  [innerHtml]="event.event_group.notes_pub|nl2br"></span><br>
                  </span>

                <hr>

                <span *ngIf="event.event_group.notes_int && currentUser.role !== 'artist'">
                    <strong>Interne Notizen zum Engagement:</strong> <span
                  [innerHtml]="event.event_group.notes_int|nl2br"></span><br>
                  </span>

                <hr>
                <span *ngIf="event.event_group.fee">
                      <strong>Gage:</strong> {{event.event_group.fee|currency:event.event_group.currency}} <br>
                  </span>
                <span *ngIf="event.event_group.all_in">
                      All in<br>
                  </span>
                <span *ngIf="!event.event_group.all_in">
                    <strong>Reisekosten:</strong> {{event.event_group.travel_cost|currency:event.event_group.currency}}  {{event.event_group.travel_cost_type|costType}}
                    <br>
                    <strong>Hotelkosten</strong>: {{event.event_group.hotel_cost|currency:event.event_group.currency}} {{event.event_group.hotel_cost_type|costType}}
                    <br>
                  </span>

                <hr>
                <strong>Dateien:</strong>

                <ng-container   *ngFor="let file of event.event_group.attachments">
                  <app-file-details *ngIf="file.group !== 'private'"
                                    class="pb-1"
                                    [file]="file"
                                    [readOnly]="true"
                  ></app-file-details>
                </ng-container>



              </div>




              <!-- event with event group type in  travel = 4,   simpleEvent = 5,-->
              <div *ngIf="event.type  === 4 && event.travel_group" >

                <strong>{{event.travel_type|travel_type}} am {{event.start|date:'longDate':'Europe/Berlin'}}</strong><br>

                <span *ngIf="event.notes">
                      <strong>Notizen zum Termin:</strong> <span [innerHtml]="event.notes|nl2br"></span> <br>
                </span>
                <span *ngIf="event.travel_group.notes_pub">
                    <strong>Notizen zum Engagement:</strong> <span
                  [innerHtml]="event.travel_group.notes_pub|nl2br"></span><br>
                  </span>

                <hr>
                <strong>Dateien:</strong>
                <app-file-details
                  class="pb-1"
                  *ngFor="let file of event.travel_group.attachments"
                  [file]="file"
                  [readOnly]="true"
                ></app-file-details>
              </div>





              <!-- event with event group type simpleEvent = 5,-->
              <div *ngIf="event.type  === 5 " >

                <strong>Am {{event.start|date:'longDate':'Europe/Berlin'}}</strong><br>

                <span *ngIf="event.notes">
                      <strong>Notizen zum Termin:</strong> <span [innerHtml]="event.notes|nl2br"></span> <br>
                </span>

                <hr>


              </div>


            </div>


          </div>


        </div>


        <div class="modal-footer" >



          <div class="col-6 ">
            <button type="button" class="btn btn-success btn-sm pull-right" (click)="modal.hide()">Schließen</button>
          </div>


        </div>
      </div>
    </div>

</div>
