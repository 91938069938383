<div class="input-group input-group-sm" *ngIf="isLoadingWork || isLoadingRole">
  <i class="fa fa-spinner fa-spin"></i>
</div>


<div class="input-group input-group-sm" *ngIf="!isLoadingWork &&  !isLoadingRole">

  <ng-template #customItemTemplateWork let-work="item" let-index="index">
    {{work.short_name}}
    <small>
      {{ work.composer }} ({{work.short_name}})
    </small>
  </ng-template>


  <input placeholder="Werk suchen..."
         [disabled]="disabled || offline"
         required
         id="searchWork"
         class="form-control has-feedback"
         type="text"
         [(ngModel)]="queryWork"
         (typeaheadOnSelect)="onWorkSelected($event.item)"
         typeaheadOptionField="short_name"
         [typeaheadItemTemplate]="customItemTemplateWork"
         [typeaheadMinLength]="0"
         [typeaheadOptionsLimit]="100"
         [typeahead]="searchResultsWork"
  >


  <ng-template #customItemTemplateRole let-role="item" let-index="index">
    {{role.name}}
    <small>({{ role.voice }})</small>
  </ng-template>

  <span class="input-group-addon">Rolle</span>


  <input placeholder="Rolle suchen..."
         [disabled]="! (work?.id) || disabled || offline"
         required
         id="searchRole"
         class="form-control has-feedback"
         type="text"
         [(ngModel)]="queryRole"
         (typeaheadOnSelect)="onRoleSelected($event.item)"
         typeaheadOptionField="name"
         [typeaheadItemTemplate]="customItemTemplateRole"
         [typeaheadMinLength]="0"
         [typeaheadOptionsLimit]="100"
         [typeahead]="searchResultsRole"
  >


  <span class="input-group-btn" *ngIf="work && !disabled && !offline">
        <button class="btn btn-sm  btn-outline-primary" type="button"
                (click)="editWork()"><i class="fa fa-edit"></i>
        </button>
  </span>

  <span class="input-group-btn" *ngIf="!disabled && !offline">
        <button class="btn btn-sm btn-outline-primary"
                type="button"
                (click)="addWork()">
            <i class="fa fa-plus"></i>
        </button>
  </span>
  <span class="input-group-btn" *ngIf="!disabled && !offline">
        <button class="btn btn-sm btn-outline-danger"
                type="button"
                (click)="deleteWorkAndRole()">
            <i class="fa fa-trash"></i>
        </button>
    </span>
</div>


<ng-template #workModalView>
  <app-work-modal
    #workModal
    (onShow)="subModalShow($event)"
    (onHide)="subModalHide($event)"
    (onWorkEdited)="onWorkEdited($event)"

  ></app-work-modal>
</ng-template>
