<form action="" [formGroup]="form">

<table class="table table-hover" *ngIf="(_travel_group||_event_group)?.events.length > 0">

    <thead>
    <tr>
        <th>Art</th>
        <th>Datum</th>
        <th *ngIf="_event_group">Name</th>
        <th  *ngIf="_event_group && _event_group.fee_different_for_events">Gage</th>
        <th>Notizen</th>
        <th *ngIf="!readOnly"></th>

    </tr>
    </thead>
    <tbody>


    <ng-container *ngFor="let eventForm of form.controls|sortFormArray: 'start'; let i = index;">
        <tr [class.cancelled]="eventForm.get('cancelled').value"
            class="eventRow"
            [formGroupName]="i">



            <!-- normal events -->
            <td class="p-1"  *ngIf="form.controls[i].get('type').value !== 4">
                <div class="input-group input-group-sm">
                    <select class="form-control custom-select" id="eventType" formControlName="type">
                        <ng-container  *ngFor="let type of eventTypes" >
                            <option [ngValue]="type" *ngIf="type !== 2 || (type === 2  && form.controls[i].get('type').value === 2)" >{{type|eventType}}</option>
                        </ng-container>
                    </select>

                </div>
            </td>


            <!-- travel events -->
            <td class="p-1"  *ngIf="eventForm.get('type').value === 4">
                <select class="form-control custom-select" id="travel_type"  formControlName="travel_type">
                    <option *ngFor="let type of travel_types" [ngValue]="type">{{type|travel_type}}</option>
                </select>
            </td>


            <td class="p-1">

                <div class="form-group has-feedback"
                     [class.has-danger]="eventForm.get('start').invalid && ( eventForm.get('start').touched ||  eventForm.get('start').dirty)">

                        <div class="input-group input-group-sm">
                            <div class="input-group-addon" (click)="startElem.toggle()" *ngIf="!readOnly && !offline">
                                <i class="fa fa-calendar"></i>
                            </div>
                            <input type="text"
                                   class="form-control"
                                   #startElem="ngbDatepicker"
                                   ngbDatepicker
                                   formControlName="start"
                            >

                            <ng-container  *ngIf="eventForm.get('type').value === 1 || eventForm.get('type').value === 4">
                                <div class="input-group-addon" (click)="endElem.toggle()" *ngIf="!readOnly && !offline">
                                    bis
                                </div>
                                <input type="text"
                                       class="form-control"
                                       #endElem="ngbDatepicker"
                                       ngbDatepicker
                                       formControlName="end"
                                >

                            </ng-container>

                        </div>

                    <span class="form-control-feedback has-danger" *ngIf="eventForm.get('start').invalid && (eventForm.get('start').touched )">
                        Pflichtfeld
                    </span>

                    <span class="form-control-feedback has-danger" *ngIf="eventForm.get('end').invalid && ( eventForm.get('end').touched  )">
                        Pflichtfeld
                    </span>
                </div>



            </td>
            <td class="p-1"  *ngIf="_event_group">

                <span class="input-group input-group-sm">
                    <input formControlName="name"
                       class="form-control-sm form-control"
                       id="name">
                    <label class="input-group-addon border-right-0" for="use_name_as_title">Im Kal. anz.</label>

                    <span class="input-group-addon border-left-0">
                               <input type="checkbox"
                                      class="form-control-sm"
                                      formControlName="use_name_as_title"
                                      aria-label="use_name_as_title" id="use_name_as_title">
                </span>
            </span>


            </td>

            <td class="p-1" *ngIf="_event_group && _event_group?.fee_different_for_events">


                    <div class="input-group input-group-sm"  (contextmenu)="onCurrencyContextMenu($event, eventForm)">
                        <input type="text"  formControlName="fee" id="fee" class="form-control form-control-sm" />

                        <select formControlName="fee_currency"
                                class="custom-select">
                            <option *ngFor="let currency of currencies" [ngValue]="currency">{{ currency}}</option>
                        </select>
                        <label for="fee" class="input-group-addon"
                              >
                            {{
                            eventForm.get('fee').value?.replace('.','').replace(',','.')|currency:(eventForm.get('fee_currency').value):'symbol':'1.2-2':'de'
                            }}
                        </label>

                    </div>


            </td>
            <td class="p-1">

                <textarea autosize  formControlName="notes" id="notes" class="form-control"></textarea>


            </td>
            <td class="p-1" *ngIf="!readOnly">
                <span class="btn-group btn-group-sm">

                    <button type="button" *ngIf="form.controls[i].get('cancelled').value !== true" class="btn btn-sm btn-outline-primary input-group-btn input-group-addon"   (click)="toggleCancelEvent(eventForm)">Findet statt</button>
                    <button type="button" *ngIf="form.controls[i].get('cancelled').value === true"  class="btn btn-sm btn-danger input-group-btn input-group-addon"   (click)="toggleCancelEvent(eventForm)">Abgesagt</button>



                    <button type="button" class="btn btn-sm btn-outline-danger" (click)="deleteFormIndex(i)"
                            *ngIf="!eventForm.get('id').value || (eventForm.get('cancelled').value && !offline)"
                    ><i class="fa fa-trash"></i></button>



                    <button type="button" class="btn btn-sm btn-outline-primary"
                            *ngIf="!offline && eventForm.get('id').value"
                            (click)="copyFormIndex(i)"><i class="fa fa-copy"></i></button>

                    <button type="button" class="btn btn-sm btn-primary"
                            *ngIf="!offline && eventForm.get('id').value === null"
                            (click)="createEventFromFormIndex(i)">Erstellen</button>


                    <button type="button" class="btn btn-sm btn-primary"
                            *ngIf="!offline && eventForm.dirty && eventForm.get('id').value !== null"
                            [disabled]="eventForm.invalid"
                            (click)="updateEventAtIndex(i)">Speichern</button>

                </span>
            </td>
        </tr>
        </ng-container>


    </tbody>
</table>
</form>

<div class="btn-group pull-right">
    <button class="btn btn-sm btn-outline-primary" (click)="addEvent()" *ngIf="!readOnly && !offline">
        <i class="fa fa-plus"></i> Termin hinzufügen
    </button>
    <button class="btn btn-sm btn-primary " (click)="saveAll()"
            *ngIf="form.dirty && !readOnly && !offline"
            [disabled]="form.invalid"
    >
        <i class="fa fa-save"></i> Alle Speichern
    </button>
</div>





<ng-template #contextMenu let-form>
    <section class="context-menu">
        <div (click)="setFeeForAllEvents(form)">Für alle Termine übernehmen</div>
    </section>
</ng-template>
