import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LoginComponent } from '../../pages/register/login/login.component';
import { AuthService } from '../../services/auth/auth.service';

@Injectable()
export class AuthenticatedOnlyOrRedirectGuard implements CanActivate {

  constructor(
      public authService: AuthService,
      private router: Router
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.log('AuthenticatedOnlyOrRedirectGuard')
    if ( this.authService.isLoggedIn() === false) {
      console.error('user is NOT logged in, redirecting')
      this.router.navigate(['/' + LoginComponent.url]);
      return false;
    } else {
      return true;
    }
  }
}
