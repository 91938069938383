import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContactModel } from '../../models/contact.model';
import { ContactsService } from '../../services/contacts.service';
import { map } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'app-select-managed-contacts',
  templateUrl: './select-managed-contacts.html'
})
export class SelectManagedContactComponent implements OnInit {

  @Output() public artistSelected = new EventEmitter<ContactModel>();

  @Input() public selectedArtist: ContactModel;
  @Input() public showDeselectBtn: boolean = false;


  public allContacts: any;
  public query: string;

  constructor(
    private contactsService: ContactsService
  ) {
    this.contactsService.searchContacts(
      '',
      false,
      null,
      null,
      200,
      true
    )
      .pipe(
        map((contacts: ContactModel[]) => {
          const resp = [];
          contacts.map((cont) => {

            resp.push({ displayName: cont.getName(), ...cont});

          });

          resp.sort(((a, b) => a.displayName.localeCompare(b.displayName)));
          return resp;
        })
      )
      .subscribe(
      (contacts: []) => {
        this.allContacts = contacts;
      }
    )


  }

  ngOnInit() {


  }

  public deselected() {
      this.selectedArtist = null;
      this.artistSelected.emit(null);
  }

  public contactSelected(id) {
    if (id) {
      this.contactsService.get(id).subscribe(
        (cont) => {
          this.artistSelected.emit(cont);
        }
      )
    }

  }


}
