import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'app-audit',
  templateUrl: './audits.component.html',
})
export class AuditsComponent implements OnInit {

  public static url = 'audits';


  @ViewChild(DataTableDirective)
  dataTable: DataTableDirective;
  dtOptions: DataTables.Settings | any = {};

  constructor(private http: HttpClient) {
  }

  ngOnInit() {
    this.dtOptions = {
      order: [ [ 0, 'desc' ] ],
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      responsive: false,
      language: {
        'sEmptyTable': 'Keine Daten in der Tabelle vorhanden',
        'sInfo': '_START_ bis _END_ von _TOTAL_ Einträgen',
        'sInfoEmpty': '0 bis 0 von 0 Einträgen',
        'sInfoFiltered': '(gefiltert von _MAX_ Einträgen)',
        'sInfoPostFix': '',
        'sInfoThousands': '.',
        'sLengthMenu': '_MENU_ Einträge anzeigen',
        'sLoadingRecords': 'Wird geladen...',
        'sProcessing': 'Bitte warten...',
        'sSearch': 'Suchen',
        'sZeroRecords': 'Keine Einträge vorhanden.',
        'oPaginate': {
          'sFirst': 'Erste',
          'sPrevious': 'Zurück',
          'sNext': 'Nächste',
          'sLast': 'Letzte'
        },
        'oAria': {
          'sSortAscending': ': aktivieren, um Spalte aufsteigend zu sortieren',
          'sSortDescending': ': aktivieren, um Spalte absteigend zu sortieren'
        },
        select: {
          rows: {
            _: '%d Zeilen ausgewählt',
            0: 'Zum Auswählen auf eine Zeile klicken',
            1: '1 Zeile ausgewählt'
          }
        }
      },
      ajax: (request, drawCallback, settings, test) => {
        const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
        this.http.post('dt/audits', request, { headers: headers }).subscribe(
          (data) => {
            console.log('returned data', data);
            drawCallback(data);
          },
          (err) => {
            console.error('error', err)
          }
        )
      },


      rowCallback: (row: Node, data, index: number) => {


      },
      columns: [
        { data: 'id', visible: true, type: 'num', orderable: true },
        { data: 'user_type', visible: false },
        { data: 'user_id', searchable: 'true', visible: true, orderable: true },
        { data: 'user.first_name', searchable: 'false', visible: true, orderable: false},
        { data: 'user.last_name', searchable: 'false', visible: true, orderable: false },
        { data: 'user.email', searchable: 'false', visible: true, orderable: false },
        { data: 'event', searchable: 'true' },
        { data: 'auditable_type', searchable: 'true' },
        { data: 'auditable_type', searchable: 'true' },
        { data: 'old_values', searchable: 'true' },
        { data: 'new_values', searchable: 'true' },
        { data: 'url', searchable: 'false', visible: false },
        { data: 'ip_address', searchable: 'false', },
        { data: 'user_agent', searchable: 'false', },
        { data: 'tags', searchable: 'false', visible: false },
        { data: 'created_at', searchable: 'false', },
        { data: 'updated_at', searchable: 'false', visible: false },
      ],
    };
  }


}
