import { ChangeDetectionStrategy, Component, OnInit, ViewContainerRef } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { LoginComponent } from './pages/register/login/login.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { ContactModel } from './models/contact.model';

import { UserModel } from './models/user.model';
import { UserRoleArtist } from './models/enums';
import { CalendarComponent } from './pages/calendar/calendar.component';
import * as moment from 'moment-timezone';
import { ContactsService } from './services/contacts.service';
import { CompanyModel } from './models/company.model';
import { SwUpdate } from '@angular/service-worker';
import { OfflineModeService } from './services/offline/offline-mode.service';
import { Moment } from 'moment';
import { ConnectionService } from 'ng-connection-service';


/**
 * App Component
 * Top Level Component
 */
@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'app-app',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  public name = 'Kalender';
  public user: UserModel;
  public navIsCollapsed = true;
  public auth: AuthService;
  public offline: boolean;
  public showArtistName: boolean;
  public lastUpdate: Moment | null;
  public currentArtist: null | ContactModel;


  public status = 'Online';
  public connectionIndicatorRight = true;
  public isConnected = true;

  constructor(
    auth: AuthService,
    public viewContainerRef: ViewContainerRef,
    private contactService: ContactsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private localStorage: LocalStorageService,
    private swUpdate: SwUpdate,
    private offlineModeService: OfflineModeService,
    private connectionService: ConnectionService
  ) {
   this.offlineModeService.offline.asObservable().subscribe(
     (offline) => this.offline = offline
   );
    this.offlineModeService.lastUpdate.asObservable().subscribe(
      (lastUpdate) => this.lastUpdate = lastUpdate
    );

    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = 'Online';
      } else {
        this.status = 'Kein Internet';
      }
    });

    this.auth = auth;
    // all times are shown as utc
    moment.tz.setDefault('Europe/Berlin');

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {

        setTimeout(() => {
          if (confirm('Es ist eine neue Version dieser Seite verfügbar. Die Seite wird nun neu geladen. Abbrechen um Änderungen zu speichern.')) {
            window.location.reload();
          }
        }, 100);
      });
    }

    this.activatedRoute.queryParamMap.subscribe(
      (queryParams) => {
        this.showArtistName = queryParams.has('artist')
      }
    )



    this.localStorage.observe('user').subscribe(
      (user) => {
        this.user = user;

        if (user === null) {
          this.router.navigate([ LoginComponent.url ])
        } else {
          if (user.role === UserRoleArtist) {
            this.contactService.get(user.contact_id).subscribe(
              (cont: ContactModel) => {
                this.router.navigate([ '/calendar' ],
                  {
                    queryParams: { 'artist': cont.id },
                    replaceUrl: true
                  });
              }
            )

          } else {

          }
        }
      }
    );

    this.user = this.localStorage.retrieve('user');

    const artistId = localStorage.retrieve('last_artist_id') || null;
    if (artistId) {
      this.contactService.get(artistId).subscribe(
        (art) => {
          if (art) {
            this.currentArtist = art
          }

        }
      )
    }


  }

  public ngOnInit() {
    this.localStorage.observe('loggedIn')
      .subscribe(
        (isLoggedIn) => {
          if (isLoggedIn === false) {
            this.router.navigate([ LoginComponent.url ])
          } else {
            console.log('goto');
            this.router.navigate([ CalendarComponent.url ])
          }
        }
      );
  }

  public toggleConnectionIndicatorPosition() {
    this.connectionIndicatorRight = !this.connectionIndicatorRight;
  }

  public selectArtist(artist: ContactModel) {
    this.currentArtist = artist;
    this.localStorage.store('last_artist_id', this.currentArtist.id);
    this.router.navigate([ '/calendar' ], { queryParams: { 'artist': artist.id }, replaceUrl: true });
  }

  public selectLocation(company: CompanyModel) {
    this.router.navigate([ '/calendar' ], { queryParams: { 'location': company.id }, replaceUrl: true });
  }

  public toggleOffline() {
    this.offlineModeService.toggleOffline();
  }


  public logout() {

    if (confirm('Wollen Sie sich wirtklich abmelden?')) {
      this.auth.logout();
      this.router.navigate([ '/' ])
    }
  }


}
