<div class="row-fluid justify-content-center mt-5">


  <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6 push-md-3">
          <h2>E-Mail bestätigen</h2>
          <hr>
          <p>Wir haben Ihnen eine E-Mail gesendet. Bitte bestätigen Sie Ihre E-Mail Adresse</p>
        </div>
      </div>

      <div class="row-fluid" *ngIf="errorMessage">
        <div class="col-sm-12 col-md-6  push-md-3 alert alert-danger">

          <strong>Fehler</strong><br>
          {{ errorMessage }}
        </div>
      </div>

    <div class="row-fluid" *ngIf="successMessage">
      <div class="col-sm-12 col-md-6  push-md-3 alert alert-success">

        <strong>OK:</strong> {{ successMessage }}
      </div>
    </div>

      <div class="row pt-2">
        <div class="col-sm-12 col-md-6   push-md-3">
          <button class="btn btn-sm btn-outline-danger" (click)="logout()"> Logout</button>
          <button type="submit" class="btn  btn-sm btn-outline-success" (click)="resendMail()"><i class="fa fa-mail-forward"></i> E-Mail erneut senden</button>
          <button class="btn  btn-primary pull-right" (click)="didVerify()"> Bereits bestätigt</button>

        </div>
      </div>

  </div>
</div>