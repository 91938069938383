<div class="container-fluid">
    <div class="row-fluid pt-4 row justify-content-between">
        <div class="col-3">
            <h2>Kontakte</h2>
        </div>
        <div class="col-auto mr-3">
            <div class="form-inline">

                <button class="form-control btn btn-primary" (click)="newContact()">+ Neuer Kontakt</button>
            </div>
        </div>
    </div>

    <div class="row  pt-4">
        <div class="col-12">


          <table #dataTable datatable [dtOptions]="dtOptions" class="table table-striped row-border hover table-responsive">
                <thead>
                <tr>

                    <th>id</th>
                    <th>isnew</th>
                    <th>Name</th>
                    <th>Vorname</th>
                    <th>Künstlername</th>
                    <th>Firma</th>
                    <th>Abteilung</th>
                    <th>Funktion</th>
                    <th>Telefon</th>
                    <th>Handy</th>
                    <th>E-Mail</th>
                    <th>Aktionen<th>
                </tr>
                </thead>

            </table>


        </div>











    </div>

</div>

<app-modal #contactModal
           [title]="'Kontakt'"
           [modalFooter]="modalFooter"
           [modalContent]="mc"
           (onHide)="editContactComponent.reset()"
></app-modal>

<ng-template #mc>
  <app-edit-contact
          #editContactComponent
    [contact]="_contact"
    (hide)="contactModal.hide()"
    (contactUpdated)="refreshPage()"
    (contactCreated)="refreshPage()"
    (contactDeleted)="refreshPage();contactModal.hide()"
  ></app-edit-contact>
</ng-template>
<ng-template #modalFooter></ng-template>
