import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '../../pages/modals/modal/modal.component';
import { ContactsService } from '../../services/contacts.service';
import { OfflineModeService } from '../../services/offline/offline-mode.service';

@Component({
  selector: 'app-delete-contact-btn',
  templateUrl: './delete-contact-btn.component.html'
})
export class DeleteContactBtnComponent implements OnInit {


  public offline = false;
  @Input() public contactId: number;
  @Output() public onDelete: EventEmitter<any> = new EventEmitter();

  @ViewChild('deleteContactModal') public deleteContactModal: ModalComponent;

  constructor(
    private contactService: ContactsService,
    private offlineModeService: OfflineModeService
  ) {
    this.offlineModeService.offlineStatusChange$.subscribe(
      (isOffline) => {
        this.offline = isOffline;
      }
    );
    this.offline = this.offlineModeService.offline.getValue();
  }

  public openDeleteContactModal() {
    this.deleteContactModal.show();
  }



  ngOnInit() {
  }

  public cancel() {
    this.deleteContactModal.hide();
  }

  public deleteContact() {
    this.contactService.remove(this.contactId).subscribe(
      () => {
        this.onDelete.emit('deleted');
        this.deleteContactModal.hide()
      },
      (error) => {
        alert('Konnte Kontakt nicht löschen: ' + error.error.errors.message)
      }
    );
  }

}
