<ng-container *ngIf="file">
    <div class="row p-1 pb-1" >
        <div class="col-8">
            {{ file.filename  }}
        </div>

        <div class="col-4">
            <div class="btn-group btn-group-sm">


                <!-- no local filesystem -->
                <button (click)="downloadFile()" class="btn btn-sm btn-outline-primary"><i class="fa fa-cloud-download"></i></button>

                <button class="btn btn-sm btn-outline-danger" (click)="deleteFile()" *ngIf="!readOnly"><i class="fa fa-trash"></i></button>
            </div>
        </div>
    </div>
</ng-container>
