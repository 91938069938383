import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {

  public static url = 'passwort-vergessen';

  public email: string;
  public errorMessage = null;
  public successMessage = null;

  constructor(
        private router: Router,
        private auth: AuthService,
        private localStorage: LocalStorageService
  ) {
  }

  ngOnInit() {
    this.localStorage.observe('user').subscribe(
        (user) =>  {
          if (user !== null) {
            this.router.navigate(['/'])
          }
        }
    );
  }
  public sendMail() {
    this.successMessage = null;
    this.errorMessage = null;
    if (this.email === '' || this.email === null) {
      this.errorMessage = 'Bitte geben Sie Ihre E-Mail Adresse an';
      return;
    }

    this.auth.requestNewPasswort(this.email)
        .subscribe(
        (resp) => this.successMessage = 'Wir haben Ihnen eine E-Mail gesendet. Bitte folgen Sie den Anweisungen',
        (resp) => {
          this.errorMessage = 'Diese E-Mail Adresse ist uns nicht bekannt.'
        },
    )
  }

}
