<button type="button"
        class="btn btn-outline-danger btn-sm"

        [disabled]="offline"
        (click)="openDeleteContactModal()">Löschen
</button>



<app-modal title="Kontakt löschen"
           [smallModal]="true"
           #deleteContactModal
           [modalContent]="mc"
           [modalFooter]="modalFooter">
</app-modal>
<ng-template #mc>
  <div class="p-4">
    <p>
      Wollen Sie diesen Kontakt wirklich löschen?
    </p>
  </div>
</ng-template>

<ng-template #modalFooter>
  <button type="button" (click)="cancel()" class="btn btn-outline-primary">Abbrechen</button>
  <button type="button" (click)="deleteContact()" class="btn btn-danger">Löschen</button>
</ng-template>

