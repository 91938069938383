import { CompanyTypeEnum } from './enums';
export class CompanyModel {
    id: number;
    name: string;
    street: string;
    zip: string;
    city: string;
    city_for_calendar: string;
    country: string;
    email: string;
    phone: string;
    fax: string;
    phone2: string;
    website: string;
    type: CompanyTypeEnum;
    internal_comment: string;
    createdAt: Date;
    updatedAt: Date;

    public createFromDatabase(data: any): CompanyModel {
            this.id         = data.id;
            this.name           = data.name;
            this.street         = data.street;
            this.zip            = data.zip;
            this.city           = data.city;
            this.city_for_calendar           = data.city_for_calendar;
            this.country            = data.country;
            this.email          = data.email;
            this.phone          = data.phone;
            this.fax            = data.fax;
            this.phone2         = data.phone2;
            this.website            = data.website;
            this.type           = data.type;
            this.internal_comment            = data.internal_comment;
            this.createdAt          = data.created_at;
            this.updatedAt          = data.updated_at;
            return this;
    }
}
