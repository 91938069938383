<div class="container mt-4">
  <div class="row ">

    <div class="col-12">

      <h2>Offline Synchronisierung</h2>
      <div class="alert alert-warning">
        <h4>Achtung</h4>
        <ul>
          <li>Diese Funktion darf <strong>nur auf Firmen-eigenen Computern</strong> verwendet werden, da eine
            vollständige Kopie der Datenbank in Ihrem Browser gespeichert wird.
          </li>
          <li>Diese Funktion steht nur Admins zur Verfügung.</li>
          <li>Bei Logout werden alle Daten von Ihrem Computer gelöscht</li>
        </ul>
      </div>

      <div class="alert alert-info">
        <h4>Aktueller Stand </h4>
        <button class="btn btn-sm btn-primary" (click)="updateTableCounts()" [disabled]="isUpdatingTableCount" >Zahlen aktualisieren</button>
        <table class="table table-sm">
          <thead>
          <tr>
            <th>Datentyp</th>
            <th>Server</th>
            <th>Lokal</th>
          </tr>
          </thead>
          <tbody>

          <tr>
            <td>Termine</td>
            <td>{{ countOnline.events|number}}</td>
            <td>{{ countOffline.events|number}}</td>
          </tr>

          <tr>
            <td>Engagements</td>
            <td>{{ countOnline.event_groups|number}}</td>
            <td>{{ countOffline.event_groups|number}}</td>
          </tr>
          <tr>
            <td>Reisen</td>
            <td>{{ countOnline.travel_groups|number}}</td>
            <td>{{ countOffline.travel_groups|number}}</td>
          </tr>

          <tr>
            <td>Kontakte</td>
            <td>{{ countOnline.contacts|number}}</td>
            <td>{{ countOffline.contacts|number}}</td>
          </tr>

          <tr>
            <td>Firmen</td>
            <td>{{ countOnline.companies|number}}</td>
            <td>{{ countOffline.companies|number}}</td>
          </tr>


          <tr>
            <td>Rollen</td>
            <td>{{ countOnline.roles|number}}</td>
            <td>{{ countOffline.roles|number}}</td>
          </tr>

          <tr>
            <td>Werke</td>
            <td>{{ countOnline.works|number}}</td>
            <td>{{ countOffline.works|number}}</td>
          </tr>

          <tr>
            <td>Kontakt-Notizen</td>
            <td>{{ countOnline.contact_notes|number}}</td>
            <td>{{ countOffline.contact_notes|number}}</td>
          </tr>

          <tr>
            <td>Künstler-Rollen Beziehungen</td>
            <td>{{ countOnline.contact_role|number}}</td>
            <td>{{ countOffline.contact_role|number}}</td>
          </tr>

          <tr>
            <td>Informationen über Dateien</td>
            <td>{{ countOnline.file_event_group|number}}</td>
            <td>{{ countOffline.file_event_group|number}}</td>
          </tr>

          <tr>
            <td><strong>Summe</strong></td>
            <td><strong>{{ countOnline.sum|number}} </strong></td>
            <td><strong>{{ countOffline.sum|number}}</strong></td>
          </tr>


          </tbody>
        </table>

      </div>

      <div class="alert alert-info">
        <h4>Daten synchronisieren</h4>
        <p>Achtung: Die lokale Datenbank wird gelöscht. Datenverbrauch ca. {{ (countOnline.sum * 0.0013)|number:0}} MB. Es werden
          keine Daten vom Server gelöscht</p>

        <div class="text-center">
          <button class="btn btn-primary btn-lg" (click)="createDbBackup()" [disabled]="btnDisabled">Datenbank jetzt synchronisieren</button>
        </div>



        <div *ngIf="(importRunning || importError || importFinished)&& countOffline" class="mt-4">
          <h4 *ngIf="importRunning">Synchronisierung läuft</h4>
          <h4 *ngIf="importError">Fehler</h4>
          <h4 *ngIf="importFinished">Fertig</h4>
          <p>
            {{status}} <br>
            Anzahl der Importierten Datensätze: {{numberOfImported}} / {{countOnline.sum}}



          </p>
          <div class="">


            <ngb-progressbar *ngIf="countOnline?.sum > 0" [max]="countOnline.sum"  [striped]="true" [animated]="true" [value]="numberOfImported"></ngb-progressbar>

          </div>

        </div>


      </div>


    </div>



  </div>

</div>
