import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { AppUserService } from '../../services/app-user.service';
import { UserModel } from '../../models/user.model';
import { Moment } from 'moment';

@Component({
    selector: 'app-user-manager',
    templateUrl: './user-manager.component.html',
})
export class UserManagerComponent implements OnInit {

    public static url = 'user-manager';
    public allUsers: UserModel[];
    public openInvitations: {
        id: number,
        email: string,
        first_name: string,
        last_name: string,
        role: string,
        valid_till: Moment
    }[];

    constructor(
        private auth: AuthService,
        private userService: AppUserService
    ) {
        this.userService.allUsers().subscribe(
            (allUsers) => {
                this.allUsers = allUsers;
            },
            (error) => console.log(error),
            () => console.log('subscription complete')
        );

        this.userService.openInvitations().subscribe(
            (invitations) => {
                console.log(invitations)
                this.openInvitations = invitations
            }
        )
    }

    ngOnInit() {
    }

    public resendInvitation(id: number) {

        this.userService.resendInviation(id)
            .subscribe(
                (invitations) => {
                    alert('Einladung erneut verschickt')
                    this.openInvitations = invitations
                }
            )
    }

    public deleteInvitation(id: number) {
        this.userService.deleteInvitation(id)
            .subscribe(
                (invitations) => {
                    alert('Einladung zurückgezogen')
                    this.openInvitations = invitations
                }
            )
    }

    public getCurrentUserId(): number {
        return this.auth.getUser().id;
    }

    public deleteUser(id: number): void {
        const doit = confirm('Wollen Sie diesen User wirklich löschen?');
        if (doit) {
            this.userService.deleteUser(id).subscribe(
                () => {
                    this.userService.allUsers().subscribe(
                        (allUsers) => {
                            alert('User gelöscht')
                            this.allUsers = allUsers;
                        },
                        (error) => console.log(error),
                        () => console.log('subscription complete')
                    );
                }
            );
        }
    }

    protected copy(text: string): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = text;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    protected assignRole(role: 'admin' | 'artist' | 'logistic' | 'agent', userObject: UserModel) {
        userObject.role = role;
        if (role !== 'artist') {
            userObject.contact = null;
        }

        this.userService.update(userObject).subscribe(
            (user) => {
                console.log('updated user');
            }
        )
    }

    protected assignContact(contact, user) {
        user.contact = contact;
        this.userService.update(user).subscribe(
            (cont) => {
                console.log(cont)
            }
        );

    }


}
