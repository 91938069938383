import { Injectable } from '@angular/core';
import * as JsStore from 'jsstore';

@Injectable({
  providedIn: 'root'
})
export class IdbService {
  // this will make sure that we are using one connection
  // otherwise multiple instance will be created and thus multiple
  // worker and that may create some problems
  static idbCon = new JsStore.Instance(new Worker('./jsstore.worker.min.js'));
}
