


<app-calendar-elem
    [class.hidden]="isInSubRoute"
    [allArtists]="allArtists"
    [artist]="artist"
    [location]="company"
    [addEventObserver]="eventCreated"
    [removeEventObserver]="eventDeleted"
    [updateEventGroupObserver]="eventGroupUpdated"
    [updateEventObserver]="eventUpdated"
  ></app-calendar-elem>

<router-outlet (activate)="isInSubRoute = true" (deactivate)="isInSubRoute=false"></router-outlet>
