import { AbstractControl, FormGroup } from '@angular/forms';
import * as  Enums from './enums';
import { CurrencyEnum, EventTypeEnum, TravelTypeEnum } from './enums';
import * as moment from 'moment';
import { isMoment } from 'moment';
import { ContactModel } from './contact.model';
import { EventGroup } from './event-group';
import { TravelGroup } from './travel-group';
import { BaseModel } from './base.model';


export class EventModel extends BaseModel {
    id: number;
    start: moment.Moment;
    end: moment.Moment;
    type: Enums.EventTypeEnum;
    notes: string;
    created_at: moment.Moment;
    updated_at: moment.Moment;
    /**
     * @deprecated
     */
    artist: ContactModel;
    artist_id: number;
    cancelled: boolean;
    event_group_id?: number;
    event_group?: EventGroup;
    travel_group?: TravelGroup;


    fee: number;
    fee_currency: CurrencyEnum;

    use_name_as_title?: boolean;
    name?: string;
    travel_group_id?: number;
    travel_type?: TravelTypeEnum;

    audit_first?: any;
    audit_latest?: any;

    constructor() {
        super();
        this.created_at = moment();
        this.updated_at = moment();
        this.use_name_as_title = false;
        this.cancelled = false;
    }

    public createFromFormModel(formData: FormGroup) {

        this.updated_at = moment();

        if (formData.contains('id')) {
            this.id = formData.get('id').value;

        }

        // simple fields
        if (formData.contains('type')) {
            this.type = formData.get('type').value;
        }

        if (formData.contains('fee')) {
            this.fee = formData.get('fee').value;
        }
        if (formData.contains('fee_currency')) {
            this.fee_currency = formData.get('fee_currency').value;
        }

        if (formData.contains('notes')) {
            this.notes = formData.get('notes').value;
        }


        if (formData.contains('start')) {
            const startVal = formData.get('start').value;
            if (isMoment(startVal)) {
                this.start = moment(formData.get('start').value);
            } else {
                this.start = moment(startVal.day + '-' + startVal.month + '-' + startVal.year, 'D-M-YYYY');
                console.log(this.start)
            }
        }

        if (formData.contains('end')) {
            const endVal = formData.get('end').value;
            if (isMoment(endVal)) {
                this.end = moment(formData.get('end').value);
            } else {
                this.end = moment(endVal.day + '-' + endVal.month + '-' + endVal.year, 'D-M-YYYY');
            }
        }

        if (formData.contains('cancelled')) {
            this.cancelled = formData.get('cancelled').value;
        }


        if (formData.contains('event_group_id')) {
            this.event_group_id = formData.get('event_group_id').value;
        }

        if (formData.contains('name')) {
            this.name = formData.get('name').value;
        }

        if (formData.contains('travel_group_id')) {
            this.travel_group_id = formData.get('travel_group_id').value;
        }

        if (formData.contains('travel_type')) {
            this.travel_type = formData.get('travel_type').value;
        }

        if (formData.contains('use_name_as_title')) {
            this.use_name_as_title = formData.get('use_name_as_title').value || false;
        }

        if (formData.contains('artist_id')) {
            this.artist_id = formData.get('artist_id').value || false;
        }


    }

    public createFromDatabase(values: EventModel) {

        this.id = values.id;
        this.artist_id = values.artist_id;
        if (values.artist) {
            this.artist = values.artist;
        }
        this.start = BaseModel.parseDateFromDatabase(values.start);
        this.end = BaseModel.parseDateFromDatabase(values.end);
        this.type = values.type;
        this.fee = values.fee;
        this.fee_currency = values.fee_currency;
        this.notes = values.notes;
        this.created_at = BaseModel.parseDateFromDatabase(moment(values.created_at));
        this.updated_at = BaseModel.parseDateFromDatabase(moment(values.updated_at));
        this.event_group_id = values.event_group_id;
        if (values.event_group) {
            this.event_group = values.event_group
        }

        this.use_name_as_title = values.use_name_as_title;
        if (values.cancelled === true || values.cancelled === false) {
            this.cancelled = values.cancelled;
        } else {
            this.cancelled = false;
        }

        this.name = values.name;
        if (values.event_group !== null && values.event_group !== undefined) {
            const eg = new EventGroup();
            eg.createFromDatabase(values.event_group);
            this.event_group = eg;
        }
        if (values.travel_group !== null && values.travel_group !== undefined) {
            const eg = new TravelGroup();
            eg.createFromDatabase(values.travel_group);
            this.travel_group = eg;
        }
        this.travel_group_id = values.travel_group_id;
        this.travel_type = values.travel_type;

        if (values.audit_first) {
            this.audit_first = values.audit_first;
        }
        if (values.audit_latest) {
            this.audit_latest = values.audit_latest;
        }
    }


    public getDisplayName() {
        if (this.type === EventTypeEnum.travel) {
            return 'Reise';
        }

        if (this.type === EventTypeEnum.simpleEvent) {
            return this.name;
        }

        let name = '';


        if (this.use_name_as_title === true || !this.event_group) {
            return this.name;

        } else if (this.event_group.use_custom_title === true) {
            return this.event_group.custom_title;
        } else {


            if (this.event_group.is_guest_performance && this.event_group.guest_performance_name !== '') {
                name += this.event_group.guest_performance_name;
            } else if (this.event_group.location && this.event_group.location.city_for_calendar) {
                name += this.event_group.location.city_for_calendar;
            } else if (this.event_group.location && this.event_group.location.city) {
                name += this.event_group.location.city;
            } else {
                name += '(ohne Ort)'
            }


            name += ' - ';

            let work;
            if (!this.event_group || !this.event_group.work || !this.event_group.work.short_name) {
                work = '(ohne Werk)';

            } else {
                work = this.event_group.work.short_name;
            }

            if (this.type !== EventTypeEnum.rehearsal) {
                // premiere oder konzert
                work = work.toUpperCase();
            }
            name += work;
        }

        if (this.event_group.is_other_agency === true) {
            name += ' a.A.'
        }
        return name
    }
}

