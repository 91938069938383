import { Component } from '@angular/core';
import { AppUserService } from '../../../services/app-user.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
})
export class AddUserComponent {

  public static url = 'add';

  public sending =  false;

  public data: {
    first_name: string;
    last_name: string;
    email: string;
    contact_id: number;
    role: 'admin'|'artist'|'logistic' |'agent';
  } = {
    first_name : '',
    last_name : '',
    email : '',
    contact_id : null,
    role : 'artist',
  };

  public form: FormGroup;


  public success: string;
  public error: string;

  constructor(
    public userService: AppUserService,
    public formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      'first_name': new FormControl(this.data.first_name, [
        Validators.required,
      ]),
      'last_name': new FormControl(this.data.last_name, [
        Validators.required,
      ]),
      'email': new FormControl(this.data.email, [
        Validators.required,
        Validators.email
      ]),
    'contact_id': new FormControl(this.data.contact_id, ),
      'role': new FormControl(this.data.role, [
        Validators.required,
        ])

    });
  }

  public sendInvitation() {
    this.sending = true;
    this.success = '';
    this.error = '';

    this.userService.sendInvitation(this.form.getRawValue()).subscribe(
      (data) =>  {
        console.log('data', data);
        this.success = data.message;
        this.form.reset()
        this.sending = false;
        this.error = '';
      },
      (error) => {
        if (error.error.errors.message.message) {
          this.error = error.error.errors.message.message;
        }
        if (error.error.errors.message.validationError) {

          const validationErrors = error.error.errors.message.validationError;
          Object.keys(validationErrors).forEach( (key) => {
            console.log(validationErrors[key]);
            this.form.get(key).setErrors(validationErrors[key][0]);
            this.error += validationErrors[key][0];
          })

        }

        this.sending = false;
      }
    )

  }
}
