import {
  ApplicationRef,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { CompanyModel } from '../../models/company.model';
import { CompaniesService } from '../../services/companies.service';
import { AllCompaniesModalComponent } from '../../pages/modals/all-companies-modal/all-companies-modal.component';
import { CompanyTypeEnum } from '../../models/enums';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ModalComponent } from '../../pages/modals/modal/modal.component';
import { EditCompanyComponent } from '../../pages/companies/edit-company/edit-company.component';
import * as moment from 'moment';
import { OfflineModeService } from '../../services/offline/offline-mode.service';



@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html'
})
export class CompanySelectorComponent {


  @Input() public readOnly = false;
  public offline = false;
  @Input() public dirty = false;

  @Input()
  set companyId(companyId: number) {
    if (companyId !== null && companyId !== undefined) {
      this.isLoading = true;
      this.companyService.getCompany(companyId).subscribe(
        (company: CompanyModel) => {
          this._company = company;
          this.query = company.name;
          this.isLoading = false;
        },
        (e) => {
          console.error(e);
          this.isLoading = false;
        }
      )
    } else {
      console.log('company id is null')
      this.query = null;
      this._company = null;
      this.isLoading = false;
    }
  }

  @Input() public type: CompanyTypeEnum = null;
  @Input() public showDelete = true;
  @Input() public showEdit = true;
  @Input() public showSearch = true;
  @Input() public showAdd = true;
  @Input() public placeholder = 'Suchen';


  @ViewChild('companyModal') companyModal: ModalComponent;
  @ViewChild('companyEditor') companyEditor: EmbeddedViewRef<EditCompanyComponent>;
  @ViewChild('allCompaniesModal') public allCompaniesModal: ModalComponent;


  @Output() public onSubModalHide: EventEmitter<any> = new EventEmitter();
  @Output() public onSubModalShow: EventEmitter<any> = new EventEmitter();
  @Output() public companyChange = new EventEmitter<CompanyModel>();


  public hasSubModal = false;
  public showAllCompaniesModal = false;
  public _company: CompanyModel;


  public searchResults: Observable<CompanyModel[]>;
  public query: string;
  public queryObservable: BehaviorSubject<string> = new BehaviorSubject('');
  public timestamp = moment().format();
  public isLoading = false;


  constructor(private elRef: ElementRef,
              private applicationRef: ApplicationRef,
              private companyService: CompaniesService,
              private offlineModeService: OfflineModeService
   ) {

    this.offlineModeService.offlineStatusChange$.subscribe(
      (isOffline) => {
        this.offline = isOffline;
      }
    );
    this.offline = this.offlineModeService.offline.getValue();

    this.searchResults = this.queryObservable
      .pipe(
        distinctUntilChanged(),
        debounceTime(200),
        switchMap(
          (keyword) => this.companyService.searchCompany(keyword, this.type)
        )
      )
  }


  public subModalShow($event) {
    this.hasSubModal = true;
    this.onSubModalShow.emit($event)
  }

  public subModalHide($event) {
    this.hasSubModal = false;
    this.onSubModalHide.emit($event)
  }


  public ensureOnlyAllowedValue() {
    const elem = this.elRef.nativeElement.querySelector('#searchCompany');
    if (!this._company) {
      elem.value = '';
    } else {
      elem.value = this._company.name || '';
    }
  }

  public companySelected(selectedItem: { item: CompanyModel }) {
    this.selectCompany(selectedItem.item);
  }


  public selectCompany(company: CompanyModel) {
    this._company = company;
    this.query = company.name;
    this.companyChange.emit(this._company);
    if (this.allCompaniesModal) {
      this.showAllCompaniesModal = false;
      this.allCompaniesModal.hide()
    }
  }

  public editCompany() {
    this.companyModal.show();
  };

  public hideCompanyModal() {
    console.log('hide company modal');
    this.companyModal.hide();
  };

  public addCompany() {
    this.companyModal.show();
  };

  public findCompany() {
    this.showAllCompaniesModal = true;
    this.allCompaniesModal.show();
  };

  public deleteCompany() {
    this._company = null;
    this.query = '';
    this.companyChange.emit(null);
  }

}
