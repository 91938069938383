import { Pipe, PipeTransform } from '@angular/core';
import { CompanyTypeEnum } from '../models/enums';


@Pipe({
    name: 'companyType'
})
export class CompanyTypePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        switch (value) {
            case CompanyTypeEnum.location:
                return 'Veranstalter/Haus';
            case CompanyTypeEnum.hotel:
                return 'Hotel';
            default:
            case 'other':
                return 'Sonstige';
        }
    }

}
