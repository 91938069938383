import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';

@Pipe({
  name: 'sortFormArray',
  pure: false
})
export class SortFormArrayPipePipe implements PipeTransform {

  transform(array: Array<string>, args: string): Array<string> {
    if (array !== undefined) {
      return array.sort((a: any, b: any) => {

        const aValue = this.datePickerToMoment(a.controls[args].value);
        const bValue = this.datePickerToMoment(b.controls[args].value);

        if (aValue < bValue) {
          return -1;
        } else if (aValue > bValue) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return array;
  }


  private datePickerToMoment(value: { day: number, month: number, year: number }): Moment {
    const date: Moment = moment();
    date.year(value.year);
    date.month(value.month);
    date.date(value.day);
    date.hours(0);
    date.minutes(0);
    date.seconds(0);
    return date;

  }
}
