import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { RoleService } from '../services/role.service';
import { Role } from '../models/role.model';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'roleIdToRoleName'
})
export class RoleIdToRolenamePipe implements PipeTransform {

  constructor(
      private roleService: RoleService
  ) {}

  transform(value: any, args?: any): Observable<string> {
    if (value) {
      return this.roleService.get(value).pipe(map( (role: Role) => role.name));
    }
  }

}
