import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'role'
})
export class RolePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'admin' : return 'Admin';
      case 'new' : return 'Keine Rechte';
      case 'agent' : return 'Agent';
      case 'artist' : return 'Künstler';
      case 'logistic' : return 'Logistik';
      case 'none' : return 'Keine Rechte';
      default: return 'Unbekannt';
    }
  }

}
