import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, forkJoin, Observable, of, Subject, zip } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { BaseService } from './offline/base.service';
import { OfflineBaseService } from './offline-base.service';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class OfflineModeService {

  public offline =  new BehaviorSubject(null);
  public offlineStatusChange$:  Observable<boolean>;
  public lastUpdate = new BehaviorSubject(null);
  public offlineModeIsSupported = true;


  constructor(
    private http: HttpClient,
    private offlineBaseService: OfflineBaseService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private deviceService: DeviceDetectorService
  ) {
    if (this.deviceService.isDesktop() === false  &&  this.deviceService.browser === 'safari') {
      this.offlineModeIsSupported = false;
    }

    this.offline = new BehaviorSubject(this.localStorageService.retrieve('offline') === true);
    this.offlineStatusChange$ = this.offline.asObservable();

    this.lastUpdate.next(this.localStorageService.retrieve('lastOfflineDatabaseSync'));
    this.localStorageService.observe('lastOfflineDatabaseSync').subscribe(
      (val) => {
        this.lastUpdate.next(val)
      }
    )
  }


  public isOffline() {
    return this.offline.getValue();
  }

  public toggleOffline() {

    if (this.offline.getValue() === false) {
      // want to go offline => check if data is available
      console.log(this.lastUpdate.getValue())
      if (this.lastUpdate.getValue() === null ) {
       const gotoSync = confirm('Keine Offline Daten vorhanden. Wollen Sie jetzt Daten synchronisieren?')
       if (gotoSync) {
         this.router.navigateByUrl('/sync');
       }
       return;
      }
    }
    const next = !this.offline.getValue();
    this.localStorageService.store('offline', next);
    this.offline.next(next)
  }

  public checkServiceWorker(): Observable<boolean> {
    return this.send({action: 'check-alive'})
  }

  public dropDatabase(): Observable<boolean> {
    return this.offlineBaseService.dropDatabase();
  }

  public resetDatabase(): Observable<{message: string, numberOfImported: number}> {

    const status = new BehaviorSubject({ message: 'Lade Datenbank Schema', numberOfImported: 0} );

    let schema: { name: string, columns: any[] }[];

    let inserts = 0;

    this.http.get<{ name: string, columns: any[] }[]>('db-schema')
      .pipe(
        tap(() => status.next({message: 'Lösche alte Datenbank und erstelle neue Datenbank', numberOfImported: inserts} )),
        tap((schemaFromBackend) => {
          schema = schemaFromBackend;
        }),
        switchMap((schemaFromBackend: any) => this.send({
          action: 'reset-database',
          schema: schemaFromBackend,
        })),

        tap(() => status.next({message: 'Lösche alte Datenbank...', numberOfImported: inserts})),
        map((message) => {
          return schema.map((table: { name: string, columns: any }, index) => {
            return table.name;
          })
        }),
        // reset db connection
        tap( () => this.offlineBaseService.initJsStore()),
        tap( (tables: string[]) => { console.log('tables', tables)}),
        tap(() => status.next({message: 'Lade Daten von Server...', numberOfImported: inserts})),

        switchMap( (tables: string[]) => {
          return forkJoin(
            tables.map((table: string) => {
                return this.getCompleteTableData(table).pipe(
                  // tap((d) => console.log('got table data', table, d)),
                  tap((d: any[]) => {
                    inserts  = inserts + d.length
                  }),
                  map( (d: any[]) =>  this.bulkInsert(table, d)),
                  tap( (data) => {
                    status.next({message: 'Importiere Daten...', numberOfImported :  inserts })
                  })
                )
            })
          )
        }),
        tap( (response) => { console.log('response', response)}),

      )
      .subscribe(
        (tables: string[]) => {
          this.localStorageService.store('lastOfflineDatabaseSync',  moment());
          status.next({message: 'done', numberOfImported :  inserts });
          status.complete();
          status.unsubscribe();

        },
        (err) => {
          status.error('Fehler: Konnte Schemma nicht aktualisieren');
          console.error('could not restore db');
          status.unsubscribe();
        },
        () => {
          console.log('finished');
          status.unsubscribe();
        }
      );
    return status.asObservable();
  }

  private fetchPage(page: number = 0, table: string): Observable<any[]> {
    return this.http.get<any[]>('db-table-data', { params: { 'table': table, 'page': page.toString() } })
  }

  private getCompleteTableData(tableName: string, page: number = 0): Observable<any[]> {
    let currentPage = page;

    return this.fetchPage(currentPage, tableName)
      .pipe(
        mergeMap((items) => {
          const items$ = of(items);
          currentPage++;
          const next$ = items.length > 0 ? this.getCompleteTableData(tableName, currentPage) : of([]);
          return concat(items$, next$);
        })
      );

  }

  private bulkInsert(table: string, data: any[]): Observable<any[]> {
    return this.send({action: 'bulk-insert', data : data, table: table})
  }

  private send(message: { action: string, data?: any, schema?: any, table?: string,  request?: any }): Observable<any> {
    const subject: Subject<HttpResponse<any>> = new Subject();
    const messageChannel = new MessageChannel();



    setTimeout((number) => {
      if (!navigator.serviceWorker.controller) {
        subject.error('Service worker not found');
        return;
      }
      // Handler for recieving message reply from service worker
      messageChannel.port1.onmessage = function (event) {
        if (event.data.error) {
          console.log('service worker responded error', event);
          subject.error(event.data.error);
        } else {
          // console.log('service worker responded success', event);
          subject.next(event.data);
        }
      };

      // Send message to service worker along with port for reply
      navigator.serviceWorker.controller.postMessage(message, [ messageChannel.port2 ]);
    }, 100);

    return subject.asObservable();
  }
}
