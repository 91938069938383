import { Injectable, Injector } from '@angular/core';
import { EventModel } from '../models/event.model';
import * as moment from 'moment';
import { Moment } from 'moment';

import { EventGroupService } from './event-group.service';
import { TravelGroupService } from './travel-group.service';
import { ContactModel } from '../models/contact.model';
import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BaseModel } from '../models/base.model';
import { CompanyModel } from '../models/company.model';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OfflineEventService } from './offline/offline-event.service';
import { OfflineModeService } from './offline/offline-mode.service';

@Injectable()
export class EventService {

    private event_groupService: EventGroupService;
    private travel_groupService: TravelGroupService;

    constructor(private http: HttpClient,
                private offlineEventService: OfflineEventService,
                private offlineModeService: OfflineModeService,
                injector: Injector) {

        setTimeout(() => {
            this.event_groupService = injector.get(EventGroupService);
            this.travel_groupService = injector.get(TravelGroupService);
        });
    }

    public allEventsOnlyFirstEventOfEventGroup(
        monthRange: {
            start: Moment,
            end: Moment
        }): Observable<EventModel[]> {

        return this.http.get('events',
            {
                params:
                    {
                        start: monthRange.start.format('X'),
                        end: monthRange.end.format('X'),
                        ignore_cancelled_events: 'true',
                        only_first_event_of_group: 'true'
                    }
            })
            .pipe(
                map((value: { data: any }) => {
                    return value.data.map(this.createFromDatabase)
                }))
    }

    public allEventsOfArtist(artist: ContactModel, monthRange: { start: Moment, end: Moment }): Observable<EventModel[]> {
        return this.http.get('artist/' + artist.id + '/events',
            {
                headers: { 'also-from-idb': 'true' },
                params:
                    {
                        start: monthRange.start.format('X'),
                        end: monthRange.end.format('X')
                    }
            })
            .pipe(
                map((value: { data: any }) => {
                        return value.data.map(this.createFromDatabase)
                    }
                ),
                catchError(error => {
                    if (error.status === 0 || error.status === 504 && this.offlineModeService.isOffline()) {
                        return this.offlineEventService.allEventsOfArtist(artist, monthRange)
                    } else {
                        return throwError(error)
                    }
                })
            )
    }

    public allEventsOfLocation(location: CompanyModel, monthRange: { start: Moment, end: Moment }): Observable<EventModel[]> {
        return this.http.get('location/' + location.id + '/events',
            {
                params:
                    {
                        start: monthRange.start.format('X'),
                        end: monthRange.end.format('X')
                    }
            })
            .pipe(map((value: { data: any }) => {
                return value.data.map(this.createFromDatabase)
            }))
    }

    public update(event: EventModel): Observable<EventModel> {
        const myData = this.parseForDatabase(event);
        if (myData.start > myData.end) {
            myData.end = myData.start
        }
        return this.http.put('events/' + event.id, myData)
            .pipe(map(
                (response: { data: any }) => {
                    const data = response.data.event;
                    return this.createFromDatabase(data);
                }));
    }

    public create(eventData: EventModel): Observable<EventModel> {

        return this.http.post('events', this.parseForDatabase(eventData))
            .pipe(map(
                (response: { data: any }) => {
                    const data = response.data.event;
                    return this.createFromDatabase(data);
                }));
    }

    public get(eventId: number): Observable<EventModel> {
        return this.http
            .get('events/' + eventId)
            .pipe(
                map((res: { data: any }) => {
                    return this.createFromDatabase(res.data.event);
                }));
    }

    public deleteEvent(event: EventModel) {
        return this.http.delete('events/' + event.id);
    }

    public deleteEventById(id: number) {
        return this.http.delete('events/' + id);
    }

    private parseForDatabase(input: any): EventModel {

        let event = { ...input }
        event.start = BaseModel.parseDateForDatabase(event.start);
        event.end = BaseModel.parseDateForDatabase(event.end);
        event.updated_at = BaseModel.parseDateForDatabase(event.updated_at);
        event.created_at = BaseModel.parseDateForDatabase(event.created_at);
        event = this.removeCircularReferences(event);
        return event;
    }

    private removeCircularReferences(v) {
        const cache = new Map();
        return JSON.parse(JSON.stringify(v, function (key, value) {
            if (typeof value === 'object' && value !== null) {
                if (cache.get(value)) {
                    // Circular reference found, discard key
                    return;
                }
                // Store value in our map
                cache.set(value, true);
            }
            return value;
        }));
    };

    private createFromDatabase(data: any): EventModel {
        const model = new EventModel();
        model.createFromDatabase(data);
        return model;
    }

    public rescheduleToOtherDay(event: EventModel, scheduleTo: moment.Moment): Observable<EventModel> {
        const duration = moment.duration(scheduleTo.diff(moment(event.start)));
        event.start.add(duration.asDays(), 'days');
        event.end.add(duration.asDays(), 'days');
        return this.update(event);
    }
}
