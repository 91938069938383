import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CompanyModel } from '../../../models/company.model';
import { CompanyTypeEnum } from '../../../models/enums';
import { CompaniesService } from '../../../services/companies.service';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class EditCompanyComponent implements OnInit {

  public isCreating = false;

  public companyForm: FormGroup;

  public _company: CompanyModel;
  @Input() set company(comp: CompanyModel) {
    this.show(comp)

  }

  @Output() public companyChanged: EventEmitter<CompanyModel> = new EventEmitter();
  @Output() public companyDeleted: EventEmitter<any> = new EventEmitter();
  @Output() public hide: EventEmitter<any> = new EventEmitter();

  public companyTypes = [
    CompanyTypeEnum.hotel,
    CompanyTypeEnum.location,
    CompanyTypeEnum.other,
  ];


  constructor(private companyService: CompaniesService,
              private fb: FormBuilder,
              private cdr: ChangeDetectorRef
  ) {

    this.resetForm();
  }

  ngOnInit() {

  }


  public show(company: CompanyModel = null): void {
    if (company !== null) {
      this.resetForm();
      this._company = company;
      this.companyForm.patchValue(
        {
          id: company.id
        });
      this.setFormValues(company);
    } else {
      // show for add new
      this.resetForm();
      this._company = null;
    }
  }


  private setFormValues(company: CompanyModel) {
    this.companyForm.patchValue({
      name: company.name,
      type: company.type,
      street: company.street ? company.street : null,
      zip: company.zip ? company.zip : null,
      city: company.city ? company.city : null,
      city_for_calendar: company.city_for_calendar ? company.city_for_calendar : null,
      country: company.country ? company.country : null,
      email: company.email ? company.email : null,
      phone: company.phone ? company.phone : null,
      fax: company.fax ? company.fax : null,
      phone2: company.phone2 ? company.phone2 : null,
      website: company.website ? company.website : null,
      internal_comment: company.internal_comment ? company.internal_comment : null,
    });
    this.companyForm.markAsPristine({ onlySelf: false });
    this.companyForm.markAsUntouched({ onlySelf: false });
  }

  private resetForm() {
    this.companyForm = this.fb.group({
      id: [ null, [] ],
      name: [ null, [ Validators.required, Validators.minLength(2) ] ],
      type: [ CompanyTypeEnum.location ],
      street: [],
      zip: [],
      city: [ null, Validators.required ],
      city_for_calendar: [ null, [] ],
      country: [],
      email: [ null, [] ],
      phone: [],
      fax: [],
      phone2: [],
      website: [],
      internal_comment: []
    });
    this.companyForm.markAsPristine({ onlySelf: false });
    this.companyForm.markAsUntouched({ onlySelf: false });
  }

  public deleteCompany() {

    this.companyService.delete(this.companyForm.value.id).subscribe(
      (res) => {
        this.hide.next(true);
        this.companyDeleted.emit('deleted');

      },
      (err) => {
        alert(err.error.error);
      }
    );

  }

  public updateOrCreate(hideAfterSave) {
    this._company = this.companyForm.value;
    if (!this.companyForm.valid) {
      return;
    }
    if (this.companyForm.value.id !== null && this.isCreating === false) {
      this.companyService.update(this.companyForm.value.id, this.companyForm.value).subscribe(
        (company: CompanyModel) => {
          this.companyChanged.emit(company);
          this.companyForm.patchValue(this._company)
          this.companyForm.markAsPristine({ onlySelf: false });
          this.companyForm.markAsUntouched({ onlySelf: false });
          if (hideAfterSave === true) {
            this.hide.next(true);
          }
        },
        (err) => {
          alert(err)
        }
      );


    } else if (this.isCreating === false) {
      this.isCreating = true;

      this.companyService.create(this.companyForm.value).subscribe(
        (company) => {
          this.companyForm.patchValue({ 'id': company.id });
          this.companyChanged.emit(this.companyForm.value);
          this.companyForm.markAsPristine({ onlySelf: false });
          this.companyForm.markAsUntouched({ onlySelf: false });
          this.isCreating = false;
          if (hideAfterSave === true) {
            this.hide.next(true);
          }
        },
        (err) => {
          this.isCreating = false;
          console.log(err);
          alert('Eine Firma mit diesem Namen existiert bereits: ' + err.error.errors.message)
        }
      );
    }
  }

}
