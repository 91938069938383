<div class="row-fluid justify-content-center mt-5">


    <div class="container">
        <form class="form-horizontal justify-content-center align-middle" role="form"  (submit)="login()">
            <div class="row">
                <div class="col-sm-12 col-md-6 push-md-3">
                    <h2>Bitte einloggen</h2>
                    <hr>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 push-md-3">
                    <div class="form-group">

                        <div class="input-group mb-2 mr-sm-2 mb-sm-0">
                            <div class="input-group-addon" style="width: 2.6rem"><i class="fa fa-at"></i></div>
                            <input type="text" name="email" class="form-control" id="email"
                                   [(ngModel)]="email"
                                   placeholder="email@example.com" required autofocus autocomplete="false">
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6  push-md-3">
                    <div class="form-group">

                        <div class="input-group mb-2 mr-sm-2 mb-sm-0">
                            <div class="input-group-addon" style="width: 2.6rem"><i class="fa fa-key"></i></div>
                            <input type="password" name="password" class="form-control" id="password"
                                   [(ngModel)]="password"
                                   placeholder="Password" required autocomplete="false">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row-fluid" *ngIf="errorMessage">
                <div class="col-sm-12 col-md-6  push-md-3 alert alert-danger">



                  <strong>Fehler</strong><br>
                  {{ errorMessage }}



                </div>
            </div>

            <div class="row pt-2">
                <div class="col-sm-12 col-md-6   push-md-3">
                    <button type="submit" class="btn btn-success" [disabled]="password == '' || email == '' || loading ">

                        <i  class="fa fa-sign-in"></i>


                        Login</button>
                    <a class="btn btn-link" routerLink="/passwort-vergessen">Passwort vergessen?</a>
                </div>
            </div>
        </form>
    </div>
</div>
