<div class="container-fluid">
  <div class="row-fluid pt-4 row justify-content-between">
    <div class="col-3">
      <h2>Firmen</h2>
    </div>
    <div class="col-auto mr-3">
      <div class="form-inline">

        <!--
       <div class="form-check form-check-inline">
           <label class="form-check-label">
               <input class="form-check-input" type="checkbox" id="onlyNewContacts" [(ngModel)]="onlyNewContacts" (change)="updateData()"> Nur neue Kontakte
           </label>
       </div>

      <input class="form-control ml-1" id="searchContact"  autofocus (focus)="selectedRow=0" placeholder="Name suchen..." [(ngModel)]="filterQuery"/> -->
        <button class="form-control btn btn-primary" (click)="addCompany()">+ Neue Firma</button>
      </div>
    </div>
  </div>

  <div class="row  pt-4">
    <div class="col-12">
        <table #dataTable datatable [dtOptions]="dtOptions" class="table table-striped row-border hover table-responsive">
            <thead>

            <tr>
                <th>
                    ID
                </th>
                <th>
                    Name
                </th>
              <th>
                    Typ
                </th>
                <th>
                    Tel
                </th>
                <th>
                    E-Mail
                </th>

                <th>
                    Fax
                </th>
                <th>
                    Stadt
                </th>
                <th>
                    Straße
                </th>
                <th>
                    PLZ
                </th>
                <th>
                    Land
                </th>
                <th>
                    Aktionen
                </th>



            </tr>

            </thead>


        </table>

    </div>

  </div>

</div>

<ng-template #modalFooter></ng-template>
<app-modal title="Firma"
           [modalContent]="mT"
           [modalFooter]="modalFooter"
           (onShow)="subModalShow($event)"
           (onHide)="subModalHide($event)">
</app-modal>
<ng-template #mT>
  <app-edit-company
    *ngIf="_company"
    (hide)="hideCompanyModal()"
    [company]="_company"
    (companyChanged)="refreshPage()"
    (companyDeleted)="refreshPage()">

  </app-edit-company>
</ng-template>
