import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SelectManagedContactComponent } from './atoms/select-managed-contacts/select-managed-contacts';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { LocalStorageService, Ng2Webstorage } from 'ngx-webstorage';
import {
    BsDatepickerModule,
    BsDropdownModule,
    CollapseModule,
    ModalModule,
    TabsModule,
    TimepickerModule,
    TooltipModule,
    TypeaheadModule
} from 'ngx-bootstrap';
import { LoginComponent } from './pages/register/login/login.component';
import { AppRoutingModule } from './app-routing/app-routing.module';

import { ContactsComponent } from './pages/contacts/contacts.component';
import { ContactsService } from './services/contacts.service';
import { ContactSearchPipe } from './pipes/contact-search.pipe';
import { CompanySearchPipe } from './pipes/company-search.pipe';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CompaniesService } from './services/companies.service';
import { ContactTypePipe } from './pipes/contact-type.pipe';
import { UserManagerComponent } from './pages/user-manager/user-manager.component';
import { EmailNotVerifiedComponent } from './pages/register/email-not-verified/email-not-verified.component';
import { AppUserService } from './services/app-user.service';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { RolePipe } from './pipes/role.pipe';
import { ContactIdToContactPipe } from './pipes/contact-id-to-contact.pipe';
import { ForgotPasswordComponent } from './pages/register/forgot-password/forgot-password.component';
import { ContextMenuModule } from 'ngx-contextmenu';
import { EventGroupService } from './services/event-group.service';
import { CompanySelectorComponent } from './atoms/company-selector/company-selector.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { CompanyTypePipe } from './pipes/company-type.pipe';
import { EventEditorComponent } from './atoms/event/event-editor/event-editor.component';
import { EventTypePipe } from './pipes/event-type.pipe';
import { NgbDateMomentParserFormatter } from './ngb-date-moment-parser-formatter.service';
import { EventGroupStatePipe } from './pipes/event-group-state.pipe';
import { EventService } from './services/event.service';
import { EventListComponent } from './atoms/event/event-list/event-list.component';
import { EventGroupComponent } from './atoms/event/event-group/event-group.component';
import { TruncateModule } from 'ng2-truncate';
import { ContactSelectorComponent } from './atoms/contact-selector/contact-selector.component';
import { HotkeyModule } from 'angular2-hotkeys';
import { HotkeyCommandService } from './services/hotkey-command.service';
import { DndModule } from 'ng2-dnd';
import { OrderByPipe } from './pipes/order-by.pipe';
import { CostTypePipe } from './pipes/cost-type.pipe';
import { SelectWorkRoleComponent } from './atoms/select-work-role/select-work-role.component';
import { WorkModalComponent } from './pages/modals/work-modal/work-modal.component';
import { WorkService } from './services/work.service';
import { RoleService } from './services/role.service';
import { RoleListComponent } from './pages/modals/work-modal/role-list/role-list.component';
import { LangPipe } from './pipes/lang.pipe';
import { SimpleEventModalComponent } from './pages/modals/simple-event-modal/simple-event-modal.component';
import { TravelGroupService } from './services/travel-group.service';
import { TravelTypePipe } from './pipes/travel-type.pipe';
import { TravelGroupComponent } from './atoms/event/travel-group/travel-group.component';
import { FileDatabaseService } from './services/file-database.service';
import { FileDetailsComponent } from './atoms/filedetails/file-details.component';
import { ContractStatePipe } from './pipes/contract-state.pipe';
import { DROPZONE_CONFIG, DropzoneConfigInterface, DropzoneModule } from 'ngx-dropzone-wrapper';
import { AuthService } from './services/auth/auth.service';
import { DataTablesModule } from 'angular-datatables';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { TokenInterceptor } from './services/auth/token.interceptor';
import { environment } from '../environments/environment';
import { AddUserComponent } from './pages/user-manager/add-user/add-user.component';
import { DlDateTimePickerDateModule } from 'angular-bootstrap-datetimepicker';
import { AuditsComponent } from './pages/audits/audits.component';
import { CalendarElemComponent } from './atoms/calendar-elem/calendar-elem.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { CalenderConfigService } from './services/calender-config.service';
import { SyncComponent } from './pages/sync/sync.component';
import { ReadOnlyModalComponent } from './pages/modals/read-only-modal/read-only-modal.component';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { ContactNotesComponent } from './pages/contacts/edit-contact/contact-notes/contact-notes.component';
import { ContactNotesService } from './services/contact-notes.service';
import { EventDisplayNamePipe } from './pipes/event-display-name.pipe';
import { MomentIsSamePipe } from './pipes/moment-is-same.pipe';
import { MomentModule } from 'angular2-moment';
import { ModalComponent } from './pages/modals/modal/modal.component';
import { DeleteContactBtnComponent } from './atoms/delete-contact-btn/delete-contact-btn.component';
import { EditContactComponent } from './pages/contacts/edit-contact/edit-contact.component';
import { EditCompanyComponent } from './pages/companies/edit-company/edit-company.component';
import { EventGroupPageComponent } from './pages/events-group/event-group-page.component';
import { GetFormValuePipe } from './pipes/get-form-value.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AutosizeModule } from 'ngx-autosize';
import { EventGroupTableComponent } from './pages/event-group-table/event-group-table.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { OfflineModeService } from './services/offline/offline-mode.service';
import { OfflineEventGroupService } from './services/offline/offline-event-group.service';
import { OfflineContactService } from './services/offline/offline-contact.service';
import { OfflineBaseService } from './services/offline/offline-base.service';
import { OfflineTestComponent } from './pages/offline-test/offline-test.component';
import { OfflineTravelGroupService } from './services/offline/offline-travel-group.service';
import { OfflineEventService } from './services/offline/offline-event.service';
import { TableCountService } from './services/table-count.service';
import { CreateAccountComponent } from './pages/register/create-account/create-account.component';
import { SetPasswordComponent } from './pages/register/set-password/set-password.component';
import { EventListEditorComponent } from './atoms/event/event-list-editor/event-list-editor.component';
import { SortFormArrayPipePipe } from './pipes/sort-form-array-pipe.pipe';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { WordTemplatesComponent } from './pages/word-templates/word-templates.component';
import { WordTemplatesService } from './services/word-templates.service';
import { EditWordTemplateComponent } from './pages/word-templates/edit-word-template/edit-word-template.component';
import { RoleIdToRolenamePipe } from './pipes/role-id-to-rolename.pipe';
import { EndpointInterceptor } from './services/auth/endpoint.interceptor';


export function jwtOptionsFactory(storage: LocalStorageService) {
    return {
        tokenGetter: () => {
            return storage.retrieve('token')
        }
    }
}

export function dropZoneConfig(storage: LocalStorageService): DropzoneConfigInterface {
    const token = storage.retrieve('token');

    let endpoint = 'https://' + window.location.host + '/api/';
    if (window.location.host === 'localhost:4200') {
        endpoint = 'https://artist-calendar-backend.test/api/';
    }
    return {
        // Change this to your upload POST address:
        url: endpoint + 'attachments/dropzone',
        autoProcessQueue: true,
        createImageThumbnails: false,
        headers: { 'Authorization': 'Bearer ' + token }
    }
}

registerLocaleData(localeDe, 'de');

@NgModule({
    declarations: [
        AppComponent,
        SelectManagedContactComponent,
        ContactsComponent,
        ContactSearchPipe,
        CompanySearchPipe,
        ContactTypePipe,
        UserManagerComponent,
        EmailNotVerifiedComponent,
        NotFoundComponent,
        RolePipe,
        ContactIdToContactPipe,
        RoleIdToRolenamePipe,
        CalendarComponent,
        ForgotPasswordComponent,
        CompanySelectorComponent,
        CompaniesComponent,
        CompanyTypePipe,
        EventEditorComponent,
        EventTypePipe,
        EventGroupStatePipe,
        EventListComponent,
        EventGroupComponent,
        ContactSelectorComponent,
        OrderByPipe,
        CostTypePipe,
        EventDisplayNamePipe,
        MomentIsSamePipe,
        SelectWorkRoleComponent,
        WorkModalComponent,
        RoleListComponent,
        LangPipe,
        SimpleEventModalComponent,
        TravelTypePipe,
        TravelGroupComponent,
        FileDetailsComponent,
        ContractStatePipe,
        LoginComponent,
        AddUserComponent,
        AuditsComponent,
        CalendarElemComponent,
        SyncComponent,
        ReadOnlyModalComponent,
        ContactNotesComponent,
        ModalComponent,
        DeleteContactBtnComponent,
        EditContactComponent,
        EditCompanyComponent,
        EventGroupPageComponent,
        GetFormValuePipe,
        EventGroupTableComponent,
        OfflineTestComponent,
        CreateAccountComponent,
        ForgotPasswordComponent,
        SetPasswordComponent,
        EventListEditorComponent,
        SortFormArrayPipePipe,
        WordTemplatesComponent,
        EditWordTemplateComponent

    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        Ng2Webstorage,
        FormsModule,
        HttpClientModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [ LocalStorageService ]
            }
        }),
        ReactiveFormsModule,
        BrowserAnimationsModule,
        TypeaheadModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        CollapseModule.forRoot(),
        NgbModule.forRoot(),
        ModalModule.forRoot(),
        DataTablesModule.forRoot(),
        TruncateModule,
        HotkeyModule.forRoot(),
        TabsModule.forRoot(),
        DndModule.forRoot(),
        Nl2BrPipeModule,
        ContextMenuModule.forRoot({
            useBootstrap4: true,
        }),
        DropzoneModule,
        AuthService,
        DlDateTimePickerDateModule,
        UiSwitchModule,
        MomentModule,
        DragDropModule,
        AutosizeModule,
        ServiceWorkerModule.register('sw-master.js', { enabled: environment.production }),
        // ServiceWorkerModule.register('sw-sync.js) // can be used to load only the sync service worker
        DeviceDetectorModule.forRoot(),


    ],
    providers: [
        ContactsService,
        CompaniesService,
        AppUserService,
        EventGroupService,
        EventService,
        HotkeyCommandService,
        WorkService,
        RoleService,
        TravelGroupService,
        FileDatabaseService,
        CalenderConfigService,
        ContactNotesService,
        ContactTypePipe,
        OfflineBaseService,
        OfflineModeService,
        OfflineEventGroupService,
        OfflineEventService,
        OfflineContactService,
        OfflineTravelGroupService,
        TableCountService,
        WordTemplatesService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EndpointInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: NgbDateParserFormatter,
            useClass: NgbDateMomentParserFormatter
        },
        {
            provide: DROPZONE_CONFIG,
            useFactory: dropZoneConfig,
            deps: [ LocalStorageService ]
        },
        { provide: LOCALE_ID, useValue: 'de' }
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule {
}
