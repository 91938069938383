<div class="container">
    <div class="row-fluid pt-4 row justify-content-between">
        <div class="col-6">
            <h2>Login Verwaltung</h2>
        </div>

        <div class="col-6 text-right">
            <a routerLink="/add" class="btn btn-primary">Neuen User anlegen</a>
        </div>

    </div>
</div>


<div class="container" *ngIf="openInvitations.length > 0">
    <div class="row-fluid ">

        <h3>Offene Einladungen</h3>
        <table class="table table-striped">
            <thead>

            <tr>
                <th>
                    Name
                </th>
                <th>
                    E-Mail
                </th>

                <th>
                    Rolle
                </th>
                <th>
                    Einladung gültig bis
                </th>
                <th>
                    Erneut versenden
                </th>

            </tr>

            </thead>
            <tbody>
            <tr class="contactTableRow" *ngFor="let item of openInvitations; let i=index;">

                <td>{{ item.first_name }} {{item.last_name}}</td>
                <td>{{ item.email }} </td>
                <td>{{ item.role|role}}</td>
                <td>
                    {{ item.valid_till|amTimeAgo}}

                </td>

                <td>
                    <button class="btn btn-primary" (click)="resendInvitation(item.id)">Einladung erneut senden</button>
                    <button class="btn btn-danger" (click)="deleteInvitation(item.id)">Einladung zurückziehen</button>
                </td>
            </tr>
            </tbody>

        </table>

    </div>

</div>

<div class="container">
    <div class="row-fluid">

        <h3>User</h3>
        <table class="table table-striped">
            <thead>

            <tr>
                <th>
                    Name
                </th>
                <th>
                    E-Mail
                </th>

                <th>
                    Rolle
                </th>
                <th>
                    Bearbeiten
                </th>
                <th>
                    Kalenderzugriff
                </th>
                <th>
                    Kalender Abo
                </th>
            </tr>

            </thead>
            <tbody>
            <tr class="contactTableRow" *ngFor="let item of allUsers; let i=index;" [attr.userid]="item.id">

                <td>{{ item.first_name }} {{item.last_name}}</td>
                <td>{{ item.email }} </td>
                <td>{{ item.role|role}}</td>
                <td>

          <span *ngIf="getCurrentUserId() === item.id">
            Ihr Account
          </span>
                    <div ngbDropdown class="" [placement]="'top-right'">
                        <button class="btn btn-outline-primary" [id]="'allUserAssignDropdown' + i" ngbDropdownToggle>
                            Rolle
                            zuweisen
                        </button>
                        <div class="dropdown-menu">
                            <button class="dropdown-item" (click)="assignRole('none', item)"
                                    [disabled]="item.role === 'new'">Keine
                                Rechte
                            </button>
                            <button class="dropdown-item" (click)="assignRole('artist', item)"
                                    [disabled]="item.role === 'artist'">
                                Künstler
                            </button>
                            <button class="dropdown-item" (click)="assignRole('agent', item)"
                                    [disabled]="item.role === 'agent'">
                                Agent
                            </button>
                            <button class="dropdown-item" (click)="assignRole('logistic', item)"
                                    [disabled]="item.role === 'logistic'">
                                Logistic
                            </button>
                            <div class="dropdown-divider"></div>
                            <button class="dropdown-item btn-danger btn" (click)="assignRole('admin', item)"
                                    [disabled]="item.role === 'admin'">Admin
                            </button>
                        </div>
                    </div>

                </td>
                <td>
                    <app-select-managed-contacts
                            (artistSelected)="assignContact($event, item)"
                            [selectedArtist]="item?.contact"></app-select-managed-contacts>




                </td>
                <td>
                    <button class="btn btn-success btn-sm" *ngIf="item.ical_link" (click)="copy('https://kalender.hilbert.de' + item.ical_link)">Link kopieren</button>

                </td>
                <td>
                    <button *ngIf="item.id !== getCurrentUserId()" class="btn btn-danger" (click)="deleteUser(item.id)">User Löschen</button>
                </td>
            </tr>
            </tbody>

        </table>

    </div>

</div>
