<div bsModal #modal="bs-modal" class="modal" [class.hasSubModal]="hasSubModal" tabindex="-1" role="dialog"
     aria-hidden="true"
     [config]="{backdrop: 'static'}">

    <form [formGroup]="eventForm" >
    <div class="modal-dialog">
        <div class="modal-content" *ngIf="modal.isShown">


            <div class="modal-header">
                <h4 class="modal-title" *ngIf="eventForm.get('id').value !== null">Termin Bearbeiten</h4>
                <h4 class="modal-title" *ngIf="eventForm.get('id').value === null">Neuer Einfacher Termin</h4>


                <button type="button" class="close pull-right" (click)="modal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-12">

                            <input type="hidden" formControlName="id">

                            <div class="row form-group has-feedback"
                                 [class.has-danger]="(eventForm.get('start').invalid && ( eventForm.get('start').touched ||  eventForm.get('start').dirty))">

                                <input type="hidden" formControlName="use_name_as_title"/>

                                <label class="col-2 col-form-label-sm" >
                                    Von
                                </label>

                                <div class="col-5">
                                    <div class="input-group">
                                        <div class="input-group-addon" (click)="startElem.toggle()" *ngIf="!readOnly && !offline">
                                            <i class="fa fa-calendar"></i>
                                        </div>


                                        <input type="text"
                                               class="form-control"
                                               #startElem="ngbDatepicker"
                                               ngbDatepicker
                                               [class.disabled]="readOnly || offline"
                                               formControlName="start"
                                        >


                                    </div>

                                    <span class="form-control-feedback has-danger"
                                          *ngIf="eventForm.get('start').invalid && (eventForm.get('start').touched  )">
                                      Pflichtfeld
                                    </span>
                                </div>
                                <div class="col-5">
                                    <div class="form-inline">
                                    </div>

                                </div>
                            </div>

                        <div class="row form-group has-feedback"
                             [class.has-danger]="(eventForm.get('end').invalid && ( eventForm.get('end').touched ||  eventForm.get('end').dirty))">

                            <label class="col-2 col-form-label-sm" >
                                Bis
                            </label>

                            <div class="col-5">
                                <div class="input-group">
                                    <div class="input-group-addon" (click)="endElem.toggle()" *ngIf="!readOnly && !offline">
                                        <i class="fa fa-calendar"></i>
                                    </div>



                                  <input type="text"
                                         class="form-control"
                                         #endElem="ngbDatepicker"
                                         ngbDatepicker
                                         [class.disabled]="readOnly || offline"
                                         formControlName="end"
                                  >





                                </div>

                                <span class="form-control-feedback has-danger"
                                      *ngIf="eventForm.get('end').invalid && (eventForm.get('end').touched  )">
                                      Pflichtfeld
                                    </span>
                            </div>
                            <div class="col-5">
                                <div class="form-inline">


                                </div>

                            </div>
                        </div>


                            <div class="row form-group has-feedback"
                                 [class.has-danger]="(eventForm.get('name').invalid && ( eventForm.get('name').touched ||  eventForm.get('name').dirty))">

                                <label class="col-2 col-form-label-sm">
                                    Titel
                                </label>

                                <div class="col-10">
                                    <input class="form-control form-control-sm"
                                           placeholder="Titel"
                                           formControlName="name"
                                           [disabled]="readOnly || offline"
                                    >

                                    <span class="form-control-feedback has-danger"
                                          *ngIf="eventForm.get('name').invalid && (eventForm.get('name').touched  )">
                                        Pflichtfeld
                                    </span>
                                </div>


                            </div>


                            <div class="row form-group has-feedback">
                                <label class="col-2 col-form-label-sm" for="notes">
                                    Notizen
                                </label>
                                <div class="col-10">
                                    <textarea formControlName="notes"
                                              [disabled]="readOnly || offline"
                                              autosize
                                              [minRows]="5"
                                              id="notes"
                                              class="form-control">{{event?.notes}}</textarea>

                                </div>
                            </div>


                    </div>
                </div>

                <div class="row">
                    <div class="col-12">

                        <span class="text-muted" *ngIf="_event">
                          Erstellt von {{ _event.audit_first?.user.first_name}}  {{ _event.audit_first?.user.last_name}} am  {{ _event.created_at|date:'medium'}}   <br>
                          Bearbeitet von {{ _event.audit_latest?.user.first_name}}  {{ _event.audit_latest?.user.last_name}} am {{ _event.updated_at|date:'medium'}}
                        </span>
                    </div>
                </div>




            </div>


            <div class="modal-footer" *ngIf="!readOnly && !offline">

                    <div class="col-6">
                        <button type="button" class="btn btn-danger btn-sm pull-left" (click)="deleteEvent()" *ngIf="eventForm.get('id').value !== null" [disabled]="isDeleting">Termin löschen</button>

                    </div>

                    <div class="col-6 ">
                        <button type="button" class="btn btn-success btn-sm pull-right" (click)="save()" [disabled]="eventForm.get('name').value === ''">Termin speichern</button>
                    </div>

            </div>
        </div>
    </div>
    </form>
</div>
