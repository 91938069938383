import { Injectable } from '@angular/core';

import { TravelGroup } from '../models/travel-group';
import { EventGroup } from '../models/event-group';
import { ContactModel } from '../models/contact.model';
import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class TravelGroupService {

    private tableName = 'travelgroups';


    constructor(private http: HttpClient) {
    }

    public get(travel_groupId: number): Observable<TravelGroup> {
        return this.http.get(this.tableName + '/' + travel_groupId)
            .pipe(map((value: {data: any}, index: number) => {
              return this.createFromDatabase(value.data.travelGroup)
            }))
    }


    public create(artist: ContactModel, data): Observable<TravelGroup> {

        const travel_group = this.prepareForDatabase({ ...data });
        return this.http.post('artist/' + artist.id + '/' + this.tableName + '/', travel_group)
            .pipe(map((value: any, index: number) => {
                return value.map(this.createFromDatabase)
            }))
    }

    public update(travel_group: TravelGroup): Observable<TravelGroup> {
        return this.http
            .put(this.tableName + '/' + travel_group.id, travel_group)
            .pipe(map((value: any, index: number) => {
                return this.createFromDatabase(value.data.travelGroup)
            }))
    }

    public deleteTravelGroup(travel_group: TravelGroup) {
        return this.http
            .delete(this.tableName + '/' + travel_group.id)
            .pipe(map((value: any, index: number) => {
                return value
            }))
    }


    private createFromDatabase(data: any): TravelGroup {
        const model = new TravelGroup();
        model.createFromDatabase(data);
        return model;
    }


    private prepareForDatabase(data) {
        const eventsArray = {};
        if (data.events) {
            data.events.forEach(
                (eventKey) => {
                    eventsArray[ eventKey ] = eventKey
                }
            );
            data.events = eventsArray;
        }

        const filesArray = {};
        if (data.files) {
            data.files.forEach(
                (fileKey) => {
                    filesArray[ fileKey ] = fileKey
                }
            );
            data.files = filesArray;
        }
        return data;
    }


}
