import * as moment from 'moment';
import { BaseModel } from './base.model';
import { ContactModel } from './contact.model';

export class UserModel {
    id: number;
    created_at: moment.Moment;
    updated_at: moment.Moment;
    first_name: string;
    last_name: string;
    email: string;
    role: 'admin' | 'artist' | 'logistic' | 'agent';
    contact_id?: number;
    ical_link?: string;


    contact: ContactModel | null;

    public createFromDatabase(data: any) {
        this.id = data.id;
        this.created_at = BaseModel.parseDateFromDatabase(data.created_at);
        this.updated_at = BaseModel.parseDateFromDatabase(data.updated_at);
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        if (data.contact_id) {

            this.contact_id = data.contact_id;
        }
        this.email = data.email;
        this.role = data.role;
        if (data.contact) {
            const cont = new ContactModel();
            cont.createFromDatabase(data.contact);
            this.contact = cont
        } else {
            this.contact = null;
        }

        if (data.ical_link) {
            this.ical_link = data.ical_link;
        }


    }
}
