import { Pipe, PipeTransform } from '@angular/core';
import { EventTypeEnum } from '../models/enums';

@Pipe({
  name: 'eventType'
})
export class EventTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case EventTypeEnum.rehearsal : return 'Rehearsal';
      case EventTypeEnum.performanceOrConcert : return 'Performance/Concert';
      case EventTypeEnum.premiere : return 'Premiere';
      case EventTypeEnum.simpleEvent : return 'Termin';
      case EventTypeEnum.travel : return 'Reise';
      case EventTypeEnum.performance : return 'Performance';
      case EventTypeEnum.concert : return 'Concert';
      default: return 'Unknown'
    }
  }

}
