import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'contractState'
})
export class ContractStatePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        switch (value) {
            case 'contract_requested' :
                return 'Vertrag angefordert';
            case     'contract_received' :
                return 'Vertrag erhalten';
            case         'contract_sent_to_artist' :
                return 'Vertrag an Künslter gesendet';
            case         'contract_signed_by_artist' :
                return 'Vertrag von Künstler unterzeichent';
            case         'contract_sent_to_location' :
                return 'Vertrag an Veranstalter gesendet';
            case         'contract_signed_by_location':
                return 'Vertrag von Veranstalter unterzeichnet';
            case         'contract_invoice_sent':
                return 'Rechnung gesendet';
        }
    }

}
