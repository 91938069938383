

<nav class="navbar navbar-toggleable-md navbar-inverse  fixed-top" [class.bg-danger]="offline" [class.bg-primary]="!offline" >
  <button class="navbar-toggler navbar-toggler-right" type="button" (click)="navIsCollapsed = !navIsCollapsed"
          aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand text-white" routerLink="/">{{ name }}
    <span

      style="font-size: 11px;
    color: white !important;
    display: block;
    position: absolute;
    top: 38px;"
      *ngIf="showArtistName && currentArtist && auth.isLoggedIn() && (user?.role === 'admin' || user?.role === 'agent' || user?.role === 'logistic') " class="text-muted small">{{currentArtist.getName()}}</span>

  </a>



  <div class="collapse navbar-collapse"
       [collapse]="navIsCollapsed"
       id="mainNav">


    <div class="navbar-nav mr-auto"
         *ngIf="auth.isLoggedIn()">

      <div class="nav-item" *ngIf="offlineModeService.offlineModeIsSupported">
        <button class="btn px-1" *ngIf="user?.role === 'admin'" [class.btn-danger]="offline" [class.btn-primary]="!offline"
                (click)="toggleOffline()">
          <ng-container *ngIf="!offline">
            <i class="fa fa-toggle-on"></i> Online
          </ng-container>
          <ng-container class="navbar-nav" *ngIf="offline">
            <i class="fa fa-toggle-off"></i> Offline

            <span class="small"
                  *ngIf="user?.role === 'admin' && offline && lastUpdate">Letztes Update: {{lastUpdate|amTimeAgo}}</span>
            <span class="small"
                  *ngIf="user?.role === 'admin' && offline && !(lastUpdate)">Keine Offline Daten vorhanden</span>

          </ng-container>
        </button>
      </div>
    </div>



    <ul class="navbar-nav  ml-auto ">
      <li class="nav-item"
          *ngIf="auth.isLoggedIn() && (user?.role === 'admin' || user?.role === 'agent' || user?.role === 'logistic') && !offline">
        <a class="btn btn-primary px-1" [routerLink]="['/contacts']">Kontakte</a>
      </li>

      <li class="nav-item"
          *ngIf="auth.isLoggedIn() && (user?.role === 'admin' || user?.role === 'agent'|| user?.role === 'logistic') && !offline">
        <a class="btn btn-primary px-1" [routerLink]="['/companies']">Firmen</a>
      </li>
      <li role="nav-item"
          *ngIf="auth.isLoggedIn() && (user?.role === 'admin' || user?.role === 'agent'|| user?.role === 'logistic') && !offline">
        <a class="btn btn-primary px-1" [routerLink]="['/calendar']" [queryParams]="{allArtists : 'true'}">Gesamtkalender</a>
      </li>


      <li class="nav-item">
        <ul class="navbar-nav">
          <li role="menuitem" *ngIf="!auth.isLoggedIn()">
            <a class="btn btn-outline-secondary" href="#" [routerLink]="['/login']">Login</a>
          </li>
        </ul>


        <div class="dropdown" dropdown *ngIf="auth.isLoggedIn()">

          <button dropdownToggle type="button" class="btn px-1 dropdown-toggle" [class.btn-danger]="offline" [class.btn-primary]="!offline">
            {{ user?.email }} <span class="caret"></span>
          </button>

          <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">

            <li role="menuitem" *ngIf="user?.role === 'admin'">
              <a class="dropdown-item" [routerLink]="['/user-manager']">Login-Verwaltung</a>
            </li>

            <li role="menuitem" *ngIf="user?.role === 'admin'">
              <a class="dropdown-item" [routerLink]="['/sync']">Offline Synchronisierung</a>
            </li>

            <li role="menuitem" *ngIf="user?.role === 'admin'">
              <a class="dropdown-item" [routerLink]="['/word-templates']">Briefvorlagen</a>
            </li>


            <li role="menuitem" *ngIf="user?.role === 'admin'">
              <a class="dropdown-item" [routerLink]="['/audits']">Audits</a>
            </li>

            <li role="menuitem"><a class="dropdown-item" (click)="logout()">Logout</a></li>

          </ul>
        </div>
      </li>

      <li class="nav-item form-inline" *ngIf="auth.isLoggedIn() && (user?.role === 'admin' || user?.role === 'agent' || user?.role === 'logistic') && !offline">
        <div style="min-width: 180px" class="text-right">

        <app-company-selector class="d-inline-flex"
                              [showDelete]="false"
                              [showEdit]="false"
                              [showSearch]="false"
                              [showAdd]="false"
                              [type]="2"
                              [placeholder]="'Hauskalender wählen'"
                              (companyChange)="selectLocation($event)"></app-company-selector>

        </div>
      </li>

      <li class="nav-item" *ngIf="auth.isLoggedIn() && (user?.role === 'admin' || user?.role === 'agent' || user?.role === 'logistic') ">
        <div style="min-width: 200px">
          <app-select-managed-contacts
            class="d-inline-flex"
            (artistSelected)="selectArtist($event)"
            [selectedArtist]="currentArtist"></app-select-managed-contacts>

        </div>


      </li>

      <li class="nav-item" *ngIf="auth.isLoggedIn() && (user?.role === 'admin' || user?.role === 'agent' || user?.role === 'logistic') ">
         <span class="btn px-1" [class.btn-danger]="offline" [class.btn-primary]="!offline" (click)="selectArtist(currentArtist)">
           <i class="fa fa-calendar"></i>
         </span>
      </li>
      <li class="nav-item" *ngIf="auth.isLoggedIn() && (user?.role === 'admin' || user?.role === 'agent' || user?.role === 'logistic') ">
         <span class="btn px-1" [class.btn-danger]="offline" [class.btn-primary]="!offline" routerLink="/tabelle">
           <i class="fa fa-table"></i>
         </span>
      </li>

    </ul>


  </div>
</nav>


<main style="padding-top:54px">
  <router-outlet></router-outlet>
</main>


<div
  (mouseover)="toggleConnectionIndicatorPosition()"
  (click)="toggleConnectionIndicatorPosition()"
  class="alert hidden-sm-down"
  [class.fixed-right]="connectionIndicatorRight"
  [class.fixed-left]="!connectionIndicatorRight"
  style="position: fixed;
    bottom: 0;
    margin: 0;
    padding: 2px 5px;
    opacity: 1;
    font-size: 12px;
    color: white;" [class.bg-danger]="!isConnected" [class.bg-success]="isConnected">
  {{status}}
</div>
