import { Pipe, PipeTransform } from '@angular/core';
import { TravelTypeEnum } from '../models/enums';

@Pipe({
    name: 'travel_type'
})
export class TravelTypePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        switch (value) {
            case TravelTypeEnum.hotel  :
                return 'Hotel';
            case TravelTypeEnum.outbound :
                return 'Hinreise';
            case TravelTypeEnum.inbound :
                return 'Rückreise';
            default:
                return 'Unbekannt'
        }
    }

}
