<ng-template #modalTemplate>


  <div bsModal #modal="bs-modal" class="modal"

       [class.hasSubModal]="hasSubModal"
       tabindex="-1"
       role="dialog"
       aria-hidden="true"
       [config]="{
        backdrop: 'static',
        show: false,
        keyboard: !hasSubModal,
        ignoreBackdropClick: true,
        animated: false
       }
">


    <div class="modal-dialog "
         [class.modal-lg]="!smallModal"
         [class.modal-sm]="smallModal"
    >
      <div class="modal-content" >
        <div class="modal-header">
          <h4 class="modal-title">{{title}}</h4>


          <button type="button" class="close pull-right" (click)="modal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0" >

          <ng-container *ngIf="isShown" [ngTemplateOutlet]="modalContent" ></ng-container>

        </div>
        <div class="modal-footer">

          <ng-container [ngTemplateOutlet]="modalFooter" ></ng-container>

          <button *ngIf="!modalFooter" type="submit" class="btn btn-primary" (click)="hide()">Schließen</button>

        </div>
      </div>


    </div>
  </div>

</ng-template>
