<div class="row">
  <div class="col-12">
    <hr/>
    <h4>Rollen</h4>
  </div>
</div>

<div class="row mt-2">
  <div class="col-12">
    <table class="table-condensed">
      <thead>
      <tr>
        <td>Rolle</td>
        <td>Größe</td>
        <td>Fachpartie</td>
        <td>Stimme</td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let role of work.roles">

        <td><input type="text" [(ngModel)]="role.name" (change)="updateRole(role)" class="form-control "></td>
        <td><input type="text" [(ngModel)]="role.size" (change)="updateRole(role)" class="form-control"></td>
        <td><input type="checkbox" [(ngModel)]="role.fachpartie" (change)="updateRole(role)" class="form-control"></td>
        <td><select class="form-control-sm" [(ngModel)]="role.voice" (change)="updateRole(role)">
          <option *ngFor="let voiceEntry of voiceList" [ngValue]="voiceEntry">{{voiceEntry}}</option>
        </select>
        </td>
        <td></td>
      </tr>
      </tbody>
    </table>
  </div>


</div>


<div class="row">
  <div class="col-12">
    <hr/>
    <p>Neue Rolle:</p>
  </div>
</div>


<div class="row">


  <div class="col-12">
    <table class="table-condensed">
      <thead>
      <tr>
        <td>Rolle</td>
        <td>Größe</td>
        <td>Fachpartie</td>
        <td>Stimme</td>
      </tr>
      </thead>
      <tbody>
      <tr>

        <td>
          <input type="text" #nameField [(ngModel)]="name" (submit)="sizeField.focus()" required class="form-control">
        </td>
        <td><input type="text" #sizeField [(ngModel)]="size" (submit)="fachpartieFiled.focus()" class="form-control">
        </td>
        <td><input type="checkbox" #fachpartieFiled [(ngModel)]="fachpartie" (submit)="voiceField.focus()"
                   class="form-control"></td>
        <td> <span class="input-group-addon">
                <select #voiceField class="form-control-sm" [(ngModel)]="voice" (submit)="addRole()" required>
                    <option *ngFor="let voiceEntry of voiceList" [ngValue]="voiceEntry">{{voiceEntry}}</option>
                </select>
            </span></td>
        <td>
          <button type="button" class="btn btn-outline-primary" (click)="addRole()"
                  [disabled]="name =='' || voice == ''">Hinzufügen
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>


</div>
