import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';

import { EventTypePipe } from '../../pipes/event-type.pipe';
import { ContactModel } from '../../models/contact.model';

import { LocalStorageService } from 'ngx-webstorage';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactsService } from '../../services/contacts.service';
import { map } from 'rxjs/operators';
import { CompanyModel } from '../../models/company.model';
import { CompaniesService } from '../../services/companies.service';
import { EventModel } from '../../models/event.model';
import { EventGroup } from '../../models/event-group';
import { AuthService } from '../../services/auth/auth.service';
import { UserRoleArtist } from '../../models/enums';


@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  providers: [ EventTypePipe ]
})
export class CalendarComponent implements OnInit {

  public static url = 'calendar';
  public isInSubRoute = false;

  public artist: ContactModel = null;
  public allArtists: boolean;
  public mode: string;
  public company: CompanyModel;

  @Output() public eventUpdated = new EventEmitter<EventModel>();
  @Output() public eventDeleted = new EventEmitter<number>();
  @Output() public eventCreated = new EventEmitter<EventModel>();
  @Output() public eventGroupUpdated = new EventEmitter<EventGroup>();
  @Output() public eventGroupCreated = new EventEmitter<EventGroup>();
  @Output() public eventGroupDeleted = new EventEmitter<number>();

  @Output() public stopLoadingAndRefresh = new EventEmitter<void>();

  constructor(
      private contactService: ContactsService,
      private companyService: CompaniesService,
      private router: Router,
      private auth: AuthService,
      private cdr: ChangeDetectorRef,
      private localStorage: LocalStorageService,
      private activatedRoute: ActivatedRoute,
  ) {

  }

  ngOnInit() {

    this.activatedRoute.queryParams.pipe(
        map((params) => {

          if (params[ 'artist' ]) {
            this.allArtists = false;
            if (this.artist && this.artist.id === +params[ 'artist' ]) {
              this.isInSubRoute = false;
              return;
            }
            this.artist = null;
            this.company = null;
            this.stopLoadingAndRefresh.next();
            this.contactService.get(+params[ 'artist' ]).subscribe(
                (arist: ContactModel) => this.artist = arist
            );
            this.isInSubRoute = false;
            return ('artist')
          } else if (params[ 'location' ]) {
            this.artist = null;
            this.company = null;
            this.allArtists = false;
            this.stopLoadingAndRefresh.next();
            this.companyService.getCompany(+params[ 'location' ]).subscribe(
                (comp: CompanyModel) => {
                  this.company = comp;
                }
            );
            this.isInSubRoute = false;
            return ('location');
          } else if (params[ 'allArtists' ]) {

            this.artist = null;
            this.company = null;
            this.stopLoadingAndRefresh.next();
            this.allArtists = true;
            this.isInSubRoute = false;
            return ('allArtists');
          } else if (this.auth.getUser().role === UserRoleArtist) {
            const userId = this.auth.getUser().contact_id;
            this.allArtists = false;
            if (this.artist && this.artist.id === userId) {
              this.isInSubRoute = false;
              return;
            }
            this.artist = null;
            this.company = null;
            this.stopLoadingAndRefresh.next();
            this.contactService.get(userId).subscribe(
                (arist: ContactModel) => this.artist = arist
            );
            this.isInSubRoute = false;
            return ('user')
          }

        })
    ).subscribe((mode: string) => {
          this.mode = mode
        },
        (error) => {
          this.router.navigateByUrl('select-artist');
        }
    )
  }
}
