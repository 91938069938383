import { Injectable } from '@angular/core';
import { IdbService } from './idb.service';
import { fromPromise } from 'rxjs/internal-compatibility';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class OfflineBaseService {

  private databaseName = 'ArtistCalendar';

  constructor() {
    // turn on jsstore log status - help you to debug
    // turn it off in production or when you dont need
    this.connection.setLogStatus(false);
    this.initJsStore();
  }

  public initJsStore() {
    this.connection.isDbExist(this.databaseName).then(isExist => {
      if (isExist) {
        this.connection.openDb(this.databaseName).then(
          () => {},
          () => console.error('could not connect to idb')
        );
      } else {
        console.error('database has not been created yet');

       // this.connection.createDb(this.databaseName);
      }
    }).catch(err => {
      // this will be fired when indexedDB is not supported.
      console.error(err.message);
      // alert(err.message);
    });
  }

  get connection() {
    return IdbService.idbCon;
  }

  public bulkInsert(table: string, data: []): Observable<any> {
    return fromPromise(this.connection.insert({
      into: table,
      values: data,
      return: false,
      skipDataCheck: false,
    }));
  }


  public dropDatabase(): Observable<boolean> {
    return fromPromise(this.connection.isDbExist(this.databaseName))
      .pipe(
        tap((exists) => console.log(exists)),
        switchMap((exists: boolean) => {
          if (exists === true) {
            return fromPromise(this.connection.dropDb()).pipe(
              tap(() => console.log('db cleared'))
            )
          }
          return of(true)

        })
      );
  }

  public count(tableName: string): Observable<number> {

    return fromPromise(this.connection.count({
      from: tableName,
    }));

  }


}
