import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { EventModel } from '../../models/event.model';
import { EventListComponent } from '../../atoms/event/event-list/event-list.component';
import { EventGroupComponent } from '../../atoms/event/event-group/event-group.component';
import { EventGroup } from '../../models/event-group';
import { ContactModel } from '../../models/contact.model';
import { EventService } from '../../services/event.service';
import { AuthService } from '../../services/auth/auth.service';
import { ContactsService } from '../../services/contacts.service';
import { EventGroupService } from '../../services/event-group.service';
import { ActivatedRoute, Router } from '@angular/router';
import { delay, switchMap, take, tap } from 'rxjs/operators';
import { CalendarComponent } from '../calendar/calendar.component';
import { TravelGroupService } from '../../services/travel-group.service';
import { TravelGroup } from '../../models/travel-group';
import { Subject } from 'rxjs';
import { EventListEditorComponent } from '../../atoms/event/event-list-editor/event-list-editor.component';


@Component({
    selector: 'app-event-group-page',
    templateUrl: './event-group-page.component.html'
})
export class EventGroupPageComponent implements OnInit, OnDestroy {

    public static urlTravel = 'travel-group';
    public static urlEvent = 'event-group';


    @Input() public defaultDate: moment.Moment;

    @Input() public event: EventModel;

    @ViewChild(EventListEditorComponent) public eventList: EventListEditorComponent;
    @ViewChild(EventGroupComponent) public event_groupViewChild: EventGroupComponent;

    @Output() public eventGroupCreated = new EventEmitter<EventGroup>();
    @Output() public eventGroupUpdated = new EventEmitter<EventGroup>();
    @Output() public eventGroupDeleted = new EventEmitter<number>();

    @Output() public travelGroupCreated = new EventEmitter<TravelGroup>();
    @Output() public travelGroupUpdated = new EventEmitter<TravelGroup>();
    @Output() public travelGroupDeleted = new EventEmitter<number>();

    @Output() public eventCreated = new EventEmitter<EventModel>();
    @Output() public eventUpdated = new EventEmitter<EventModel>();
    @Output() public eventDeleted = new EventEmitter<number>();

    @Output() public onModalShow = new EventEmitter<void>();
    @Output() public onModalHide = new EventEmitter<void>();


    public numberOfSubModals = 0;
    public artist: ContactModel;
    public keyPressed: boolean;
    public event_group: EventGroup = null;
    public travel_group: TravelGroup = null;


    public groupIsDirty: Subject<boolean> = new Subject();

    public requestDirtyCheck: Subject<void> = new Subject();


    constructor(private eventService: EventService,
                private authService: AuthService,
                private contactsService: ContactsService,
                private route: ActivatedRoute,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private eventGroupService: EventGroupService,
                private travelGroupService: TravelGroupService,
                private calenderComponent: CalendarComponent
    ) {


    }

    ngOnInit() {

        if (window.getSelection) {
            window.getSelection().removeAllRanges()
        }


        this.onModalShow.subscribe(() => {
            this.numberOfSubModals++;
        });
        // the delay prevents the esc key action
        this.onModalHide.pipe(delay(250)).subscribe(() => {
            this.numberOfSubModals--;
        });

        this.eventCreated.subscribe(
            (event: EventModel) => {
                // if (event.event_group) {
                //     this.event_group.events.push(event);
                // }
                // if (event.travel_group) {
                //     this.travel_group.events.push(event);
                // }
                this.calenderComponent.eventCreated.next(event);
            }
        );
        this.eventUpdated.subscribe(
            (event: EventModel) => {

               // if (event.event_group) {
               //     const index = this.event_group.events.findIndex((groupEvent: EventModel) => {
               //         return groupEvent.id === event.id
               //     });
               //     this.event_group.events[ index ] = event;
               // }
               // if (event.travel_group) {
               //     const index = this.travel_group.events.findIndex((groupEvent: EventModel) => {
               //         return groupEvent.id === event.id
               //     });
               //     this.travel_group.events[ index ] = event;
               // }

                this.calenderComponent.eventUpdated.next(event);
            }
        );
        this.eventDeleted.subscribe(
            (eventId: number) => {

                if (this.event_group) {
                    const index = this.event_group.events.findIndex((groupEvent: EventModel) => {
                        return groupEvent.id === eventId;
                    });
                    this.event_group.events.slice(index, 1);
                }

                if (this.travel_group) {
                    const index = this.travel_group.events.findIndex((groupEvent: EventModel) => {
                        return groupEvent.id === eventId;
                    });
                    this.travel_group.events.slice(index, 1);
                }

                this.calenderComponent.eventDeleted.next(eventId);
            }
        );
        this.eventGroupCreated.subscribe(
            (eventGroup: EventGroup) => {
                this.calenderComponent.eventGroupCreated.next(eventGroup);
            }
        );
        this.eventGroupUpdated.subscribe(
            (eventGroup: EventGroup) => {
                this.calenderComponent.eventGroupUpdated.next(eventGroup);
            }
        );
        this.eventGroupDeleted.subscribe(
            (id: number) => {
                this.calenderComponent.eventGroupDeleted.next(id);
                this.returnToCalendar(false)
            }
        );

        this.travelGroupDeleted.subscribe(
            (id: number) => {
                this.calenderComponent.eventGroupDeleted.next(id);
                this.returnToCalendar(false)
            }
        );


        this.route.params.pipe(
            switchMap((params) => {
                if (params.hasOwnProperty('event_group_id')) {
                    return this.eventGroupService.get(+params[ 'event_group_id' ])
                }
                return this.travelGroupService.get(+params[ 'travel_group_id' ])

            }),
            tap(
                (group: EventGroup | TravelGroup) => {
                    if (group instanceof EventGroup) {
                        this.event_group = group;
                    }
                    if (group instanceof TravelGroup) {
                        this.travel_group = group;
                    }
                }
            ),
            switchMap(
                (group: EventGroup | TravelGroup) => this.contactsService.get(group.artist_id)
            )
        )
            .subscribe(
                (artist: ContactModel) => {
                    this.artist = artist;
                }
            );

    }

    @HostListener('document:keydown', [ '$event' ]) onKeydownHandler(event: KeyboardEvent) {
        if (this.numberOfSubModals === 0 && event.key === 'Escape') {

            this.returnToCalendar();
            this.keyPressed = false;
        } else {
            this.keyPressed = true;
        }
    }

    public returnToCalendar(checkIfDirty = true) {
        if (!checkIfDirty) {
            window.history.back();
            return;
        }

        const sub = this.groupIsDirty.asObservable()
            .pipe(
                take(1)
            )
            .subscribe(
                (isDirty: boolean) => {
                    console.log('isDirty', isDirty);
                    if (isDirty || this.keyPressed) {
                        const doit = confirm('Wollen Sie das Engagement schließen? Nicht gespeicherte Änderungen gehen verloren.');
                        if (!doit) {
                            return;
                        }
                    }
                    window.history.back();
                },
                () => {
                },
                () => {
                    sub.unsubscribe();
                }
            );
        this.requestDirtyCheck.next();

    }

    ngOnDestroy() {
        this.event = null;
        this.event_group = null;
    }




    public onEventGroupEdited(event_group: EventGroup, closeAfterSave: boolean = false) {

        this.eventList.saveAll();

        this.eventGroupService.update(event_group).subscribe(
            (newGrp) => {
                console.log('Event Group after Update', newGrp);
                this.event_group = newGrp;
                this.eventGroupUpdated.emit(this.event_group);
                if (closeAfterSave === true) {
                    this.returnToCalendar(false)
                }
            }
        )
    }

    public onTravelGroupEdited(travel_group: TravelGroup, closeAfterSave: boolean = false) {
        this.eventList.saveAll();

        this.travelGroupService.update(travel_group).subscribe(
            (newGrp) => {
                console.log('Travel Group after Update', newGrp);
                this.travel_group = newGrp;
                this.travelGroupUpdated.emit(this.travel_group);
                if (closeAfterSave === true) {
                    this.returnToCalendar(false)
                }
            }
        )
    }

    public onEventGroupDeleted(id: number) {
        this.eventGroupService.deleteEventGroup(id).subscribe(
            (event_group: EventGroup) => {
                this.event_group.events.forEach(
                    (event: EventModel) => {
                        this.eventDeleted.emit(event.id);
                    });
                this.returnToCalendar(false);
            }
        )
    }


}
