import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { LoginComponent } from '../../pages/register/login/login.component';
import { catchError, flatMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { OfflineModeService } from '../offline/offline-mode.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private inj: Injector,
        private router: Router,
        private offlineMode: OfflineModeService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const authService = this.inj.get(AuthService);

        // Get the auth header from the service.
        const token = authService.getToken();

        // Clone the request to add the new header.
        const authReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });

        // when in offline mode, do not even try to load from server!
        if (req.headers.get('also-from-idb') === 'true' && this.offlineMode.isOffline()) {
            throw { status: 0, error: 'App is offline' };
        }

        if (this.offlineMode.isOffline()) {
            console.warn('trying to get content from api in offline mode')
        }
        return next.handle(authReq)
            .pipe(
                catchError((error, caught) => {

                    if (error.status === 400) {
                        // logout users, redirect to login page
                        authService.logout();
                        this.router.navigate([ '/' + LoginComponent.url ]);
                        return throwError(error)
                    }

                    if (error.status === 401 && !req.url.endsWith('/login') && !req.url.endsWith('/set-password')) {
                        return authService.refreshToken()
                            .pipe(
                                flatMap(t => {
                                    const authRequestWithRefreshedToken = req.clone(
                                        {
                                            headers: req.headers.set('Authorization', 'Bearer ' + t)
                                        });
                                    return next.handle(authRequestWithRefreshedToken);
                                }));
                    }

                    // return all others errors
                    console.log('other error');
                    return throwError(error)
                })) as any;
    }

}
