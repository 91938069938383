import { Injectable } from '@angular/core';

import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TableCountService {

  constructor(
    private http: HttpClient) {
  }


  public getCount(table): Observable<number> {
    return this.http.get<number>('count/' + table)
  }

}
