import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { EventModel } from '../../../models/event.model';
import { EventService } from '../../../services/event.service';
import { ContactsService } from '../../../services/contacts.service';
import { ContactModel } from '../../../models/contact.model';
import { AuthService } from '../../../services/auth/auth.service';
import { UserModel } from '../../../models/user.model';

@Component({
  selector: 'app-read-only-modal',
  templateUrl: './read-only-modal.component.html'
})
export class ReadOnlyModalComponent implements OnInit {

  @ViewChild('modal') public modal: ModalDirective;

  @Output('onHide') public onHide = new EventEmitter<any>();
  @Output('onShow') public onShow = new EventEmitter<any>();

  public event: EventModel;
  public artist: ContactModel;
  public currentUser: UserModel;

  constructor(
    private eventService: EventService,
    private contactService: ContactsService,
    private authService: AuthService
  ) { }

  ngOnInit() {

    this.currentUser = this.authService.getUser();

    this.modal.onShow.subscribe(
      ($event) => {
        this.onShow.emit($event);
      }
    );
    this.modal.onHide.subscribe(
      ($event) => {
        this.event = null;
        this.onHide.emit($event);
      }
    );
  }

  // called from modals
  public openEvent(event: EventModel) {
    this.eventService.get(event.id).subscribe(
      (event: EventModel) => {
        this.event = event;
        this.contactService.get(this.event.artist_id).subscribe(
          (artist: ContactModel) => {
            this.artist = artist;
          }
        );

        this.modal.show()
      }
    )

  }

}
