import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectWorkRoleComponent } from '../../../atoms/select-work-role/select-work-role.component';
import { ContactModel } from '../../../models/contact.model';
import { CompanyTypeEnum, ContactTypeEnum, LangEnum } from '../../../models/enums';
import { Role } from '../../../models/role.model';
import { Work } from '../../../models/work.model';
import { ContactsService } from '../../../services/contacts.service';
import { CompaniesService } from '../../../services/companies.service';
import { RoleService } from '../../../services/role.service';
import { WorkService } from '../../../services/work.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { debounceTime } from 'rxjs/operators';
import { CompanyModel } from '../../../models/company.model';
import { environment } from '../../../../environments/environment';
import { saveAs } from 'file-saver';
import { isArray } from 'util';
import { FileModel } from '../../../models/file.model';
import { WordTemplate } from '../../../models/word-template.model';
import { WordTemplatesService } from '../../../services/word-templates.service';

@Component({
    selector: 'app-edit-contact',
    templateUrl: './edit-contact.component.html'
})
export class EditContactComponent implements OnDestroy, OnInit {

    public isCreating = false;
    public form: FormGroup;
    public hasSubModal = false;
    public _contact: ContactModel;

    public wordTemplates: WordTemplate[] = [];

    @ViewChild('workRoleComp') public workRoleComp: SelectWorkRoleComponent;

    @Output() public hide = new EventEmitter<any>();
    @Output() public contactCreated = new EventEmitter<ContactModel>();
    @Output() public contactUpdated = new EventEmitter<ContactModel>();
    @Output() public contactDeleted = new EventEmitter();

    @Input() set contact(contact: ContactModel) {
        if (contact === null) {
            this.resetForm()
        } else {
            this._contact = contact;
            this.setFormValues(contact);
        }

    };

    public contactTypes = [
        ContactTypeEnum.artist,
        ContactTypeEnum.location,
        ContactTypeEnum.agent,
        ContactTypeEnum.hotel,
        ContactTypeEnum.director,
        ContactTypeEnum.conductor,
        ContactTypeEnum.other,
    ];

    public tabs = [
        { active: true },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
    ];

    public languages = [
        LangEnum.de,
        LangEnum.en,
        LangEnum.fr,
        LangEnum.it,
    ];


    public newRole: Role;
    public newWork: Work;

    public profile: string;
    public profileEn: string;
    public profileFr: string;

    public profileCopied: boolean;
    public profileCopiedEn: boolean;
    public profileCopiedFr: boolean;

    constructor(private contactsService: ContactsService,
                private companyService: CompaniesService,
                private roleService: RoleService,
                private workService: WorkService,
                private fb: FormBuilder,
                private wordTemplateService: WordTemplatesService,
                private http: HttpClient
    ) {

        this.hide.subscribe(
            (hiding) => {
               this.reset()
            }
        );

        this.form = this.fb.group({
            id: [ null ],
            salutation: [ null ],
            first_name: [ null, [ Validators.required, Validators.minLength(2) ] ],
            last_name: [ null, [ Validators.required, Validators.minLength(2) ] ],

            first_name_pass: [ null ],
            last_name_pass: [ null ],

            street: [ null ],
            zip: [ null ],
            city: [ null ],
            country: [ null ],
            email: [ null ],
            type: [ ContactTypeEnum.other, [ Validators.required ] ],
            company_id: [ null ],
            department: [ null ],
            function: [ null ],
            is_new_contact: [ true ],
            mobile: [ null ],
            mobile2: [ null ],
            mobile3: [ null ],
            birthday: [ null ],
            phone: [ null ],
            phone3: [ null ],
            fax: [ null ],
            phone2: [ null ],
            lang: [ LangEnum.de ],
            website: [ null ],
            internal_comment: [ null ],
            roles: [ [] ],
            eye_color: null,
            shoe_size: null,
            height: null,
            weight: null,
            hair_color: null,
            managed_artist: null,
            has_calendar: null,
            artist_name: [ null ],
            artist_name_first_name: [ null ],
            artist_name_last_name: [ null ],
            place_of_birth: null,
            nationality: null,
            martial_status: null,
            fiscal_address: null,
            bank_name: null,
            bank_address: null,
            bank_account_holder: null,
            bank_account_number: null,
            bank_code: null,
            bank_iban: null,
            bank_bic: null,
            passport_number: null,
            passport_authority: null,
            passport_issue_date: null,
            passport_expirey_date: null,
            vat_number: null,
            finanzamt: null,
            tax_number: null,
            health_insurance_name: null,
            health_insurance_type: null,
            health_insurance_number: null,
            social_security_number_de: null,
            conges_spectacles: null,
            codice_fiscale: null,
            social_security_number_us: null,
            enpals: null,
            mothers_name: null,
            fathers_name: null,
            attachments: null,
        });

        this.form.valueChanges
            .pipe(
                debounceTime(200)
            ).subscribe(
            (values) => {
                this.createProfileText();
                this.ensureOnlyArtistsCanBeManagedArtists()

            }
        );

    }

    ngOnInit() {
        this.wordTemplateService.index().subscribe(
            (templates: WordTemplate[]) => {
                this.wordTemplates = templates;
            }
        )
    }

    ngOnDestroy() {
        this.resetForm();
    }


    public ensureOnlyArtistsCanBeManagedArtists() {
        if (this.form.get('type').value !== ContactTypeEnum.artist
            && this.form.get('type').value !== ContactTypeEnum.conductor
            && this.form.get('type').value !== ContactTypeEnum.director
        ) {
            this.form.get('managed_artist').setValue(false);
        }
    }


    private setFormValues(contact: ContactModel) {
        console.log('set form values', contact);
        this.form.setValue({
            id: contact.id ? contact.id : null,
            salutation: contact.salutation ? contact.salutation : '',
            first_name: contact.first_name ? contact.first_name : '',
            first_name_pass: contact.first_name_pass ? contact.first_name_pass : '',
            last_name: contact.last_name ? contact.last_name : '',
            last_name_pass: contact.last_name_pass ? contact.last_name_pass : '',
            company_id: contact.company_id ? contact.company_id : null,
            department: contact.department ? contact.department : null,
            function: contact.function ? contact.function : null,
            street: contact.street ? contact.street : null,
            zip: contact.zip ? contact.zip : null,
            city: contact.city ? contact.city : null,
            country: contact.country ? contact.country : null,
            email: contact.email ? contact.email : null,
            phone: contact.phone ? contact.phone : null,
            phone2: contact.phone2 ? contact.phone2 : null,
            phone3: contact.phone3 ? contact.phone3 : null,
            birthday: contact.birthday ? contact.birthday : null,
            fax: contact.fax ? contact.fax : null,
            mobile: contact.mobile ? contact.mobile : null,
            mobile2: contact.mobile2 ? contact.mobile2 : null,
            mobile3: contact.mobile3 ? contact.mobile3 : null,
            type: contact.type ? contact.type : ContactTypeEnum.other,
            lang: contact.lang ? contact.lang : null,
            website: contact.website ? contact.website : null,
            internal_comment: contact.internal_comment ? contact.internal_comment : null,
            is_new_contact: contact.is_new_contact ? contact.is_new_contact : false,
            roles: contact.roles ? contact.roles : [],
            eye_color: contact.eye_color ? contact.eye_color : null,
            shoe_size: contact.shoe_size ? contact.shoe_size : null,
            height: contact.height ? contact.height : null,
            weight: contact.weight ? contact.weight : null,
            hair_color: contact.hair_color ? contact.hair_color : null,
            managed_artist: contact.managed_artist ? contact.managed_artist : false,
            has_calendar: contact.has_calendar ? contact.has_calendar : false,
            artist_name: contact.artist_name ? contact.artist_name : null,
            artist_name_first_name: contact.artist_name_first_name ? contact.artist_name_first_name : null,
            artist_name_last_name: contact.artist_name_last_name ? contact.artist_name_last_name : null,
            place_of_birth: contact.place_of_birth ? contact.place_of_birth : null,
            nationality: contact.nationality ? contact.nationality : null,
            martial_status: contact.martial_status ? contact.martial_status : null,
            fiscal_address: contact.fiscal_address ? contact.fiscal_address : null,
            bank_name: contact.bank_name ? contact.bank_name : null,
            bank_address: contact.bank_address ? contact.bank_address : null,
            bank_account_holder: contact.bank_account_holder ? contact.bank_account_holder : null,
            bank_account_number: contact.bank_account_number ? contact.bank_account_number : null,
            bank_code: contact.bank_code ? contact.bank_code : null,
            bank_iban: contact.bank_iban ? contact.bank_iban : null,
            bank_bic: contact.bank_bic ? contact.bank_bic : null,
            passport_number: contact.passport_number ? contact.passport_number : null,
            passport_authority: contact.passport_authority ? contact.passport_authority : null,
            passport_issue_date: contact.passport_issue_date ? contact.passport_issue_date : null,
            passport_expirey_date: contact.passport_expirey_date ? contact.passport_expirey_date : null,
            vat_number: contact.vat_number ? contact.vat_number : null,
            finanzamt: contact.finanzamt ? contact.finanzamt : null,
            tax_number: contact.tax_number ? contact.tax_number : null,
            health_insurance_name: contact.health_insurance_name ? contact.health_insurance_name : null,
            health_insurance_type: contact.health_insurance_type ? contact.health_insurance_type : null,
            health_insurance_number: contact.health_insurance_number ? contact.health_insurance_number : null,
            social_security_number_de: contact.social_security_number_de ? contact.social_security_number_de : null,
            conges_spectacles: contact.conges_spectacles ? contact.conges_spectacles : null,
            codice_fiscale: contact.codice_fiscale ? contact.codice_fiscale : null,
            social_security_number_us: contact.social_security_number_us ? contact.social_security_number_us : null,
            enpals: contact.enpals ? contact.enpals : null,
            mothers_name: contact.mothers_name ? contact.mothers_name : null,
            fathers_name: contact.fathers_name ? contact.fathers_name : null,
            attachments: contact.attachments ? contact.attachments : [],


        }, { onlySelf: false, emitEvent: true });

    }

    public setCompany(company: CompanyModel | null) {
        if (company && company.id) {
            this.form.get('company_id').setValue(company.id);
            if (company.type === CompanyTypeEnum.location) {
                this.form.get('type').setValue(ContactTypeEnum.location);
            } else if (company.type === CompanyTypeEnum.hotel) {
                this.form.get('type').setValue(ContactTypeEnum.hotel);
            }
        } else {
            this.form.get('company_id').setValue(null);
        }

        this.form.get('company_id').markAsTouched();
        this.updateOrCreate(false);
    }

    public onContactDeleted() {
        this.contactDeleted.emit('deleted');
    }

    public updateOrCreate(hideAfterSave) {
        if (!this.form.valid) {
            return;
        }

        console.log('update or create', hideAfterSave);
        if (this.form.value.id !== null && this.isCreating === false) {
            console.log('Starting request', this.form.value.id, this.form.value);
            this.contactsService.update(this.form.value.id, this.form.value)
                .subscribe(
                    (contact: ContactModel) => {
                        console.log('did update contact', contact);
                        this.form.markAsUntouched({ onlySelf: false });
                        this.form.markAsPristine({ onlySelf: false });
                        this._contact = contact;
                        this.contactUpdated.emit(contact);
                        if (hideAfterSave === true) {
                            this.hide.next(true)
                        }
                    }
                );

        } else if (this.isCreating === false) {
            console.log('create');
            this.isCreating = true;
            this.contactsService.create(this.form.value).subscribe(
                (contact: ContactModel) => {
                    this._contact = contact;
                    this.form.patchValue({ 'id': contact.id });
                    this.form.markAsUntouched({ onlySelf: false });
                    this.form.markAsPristine({ onlySelf: false });
                    this.contactCreated.emit(contact);
                    this.isCreating = false;
                    if (hideAfterSave === true) {
                        this.hide.next(true)
                    }
                }
            );
        }
    }

    public reset() {
        this.resetForm();
        this._contact = null;
        this.tabs = [
            { active: true },
            { active: false },
            { active: false },
            { active: false },
            { active: false },
            { active: false },
        ];
    }

    private resetForm() {
        this.form.reset({
            id: null,
            first_name: null,
            first_name_pass: null,
            last_name: null,
            last_name_pass: null,
            street: null,
            zip: null,
            city: null,
            country: null,
            department: null,
            function: null,
            email: null,
            type: ContactTypeEnum.other,
            company_id: null,
            is_new_contact: true,
            mobile: null,
            mobile2: null,
            mobile3: null,
            birthday: null,
            phone: null,
            phone3: null,
            fax: null,
            phone2: null,
            lang: LangEnum.de,
            website: null,
            internal_comment: null,
            roles: [],
            eye_color: null,
            shoe_size: null,
            height: null,
            weight: null,
            hair_color: null,
            managed_artist: false,
            artist_name: null,
            artist_name_first_name: null,
            artist_name_last_name: null,
            place_of_birth: null,
            nationality: null,
            martial_status: null,
            fiscal_address: null,
            bank_name: null,
            bank_address: null,
            bank_account_holder: null,
            bank_account_number: null,
            bank_code: null,
            bank_iban: null,
            bank_bic: null,
            passport_number: null,
            passport_authority: null,
            passport_issue_date: null,
            passport_expirey_date: null,
            vat_number: null,
            finanzamt: null,
            tax_number: null,
            health_insurance_name: null,
            health_insurance_type: null,
            health_insurance_number: null,
            social_security_number_de: null,
            conges_spectacles: null,
            codice_fiscale: null,
            social_security_number_us: null,
            enpals: null,
            mothers_name: null,
            fathers_name: null,
            attachments: [],
        }, { onlySelf: false, emitEvent: false });
    }


    /**
     * Work / Role related methods
     */

    public setNewRole(role: Role) {
        this.newRole = role;
    }

    public addRole() {
        if (!this.newRole.id) {
            return;
        }
        const currentRoles = <Role[]>this.form.get('roles').value;

        if (!currentRoles.map((role: Role) => role.id).includes(this.newRole.id)) {
            currentRoles.push(this.newRole);
            this.form.markAsTouched()
            this.form.patchValue({ roles: currentRoles }, { onlySelf: false, emitEvent: true });
            this.workRoleComp.deleteWorkAndRole();
            this.updateOrCreate(false);

        } else {
            alert('Rolle bereits im Repertoire vorhanden');
        }
        this.newRole = null;
        this.newWork = null;

    }


    public tabSelected(tab, selected) {
        tab.active = selected;
    }

    public goToFirstTab() {
        this.tabs[ 0 ].active = true;
    }

    public removeRole(role: Role) {

        const currentRoles = <Role[]>this.form.get('roles').value;
        const index = currentRoles.indexOf(role)
        currentRoles.splice(index, 1);
        this.form.patchValue({ roles: currentRoles }, { onlySelf: false, emitEvent: true });


    }

    public downloadDocument(wordTemplate: WordTemplate) {
        this.http.get('contacts/' + this.form.get('id').value + '/word-template/' + wordTemplate.id,
            { headers: new HttpHeaders().append('Content-Type', 'application/octet-stream'), responseType: 'blob', observe: 'body' }
        ).subscribe(
            (data) => {
                saveAs(data, wordTemplate.name + '.docx');
            }
        );
    }

    public downloadPersonalBogen() {
        this.http.get('contacts/' + this.form.get('id').value + '/personalbogen',
            { headers: new HttpHeaders().append('Content-Type', 'application/octet-stream'), responseType: 'blob', observe: 'body' }
        ).subscribe(
            (data) => {
                saveAs(data, 'Personalbogen.docx');
            }
        );
    }

    public generateWordFile(file) {
        this.http.get('contacts/' + this.form.get('id').value + '/word/' + file[ 1 ].uuid,
            { headers: new HttpHeaders().append('Content-Type', 'application/octet-stream'), responseType: 'blob', observe: 'body' }
        ).subscribe(
            (data) => {
                saveAs(data, file[ 1 ].filename);
            },
            error => {
                alert('Datei konnte nicht verarbeitet werden. Bitte laden Sie nur .doc oder .docx Dateien hoch')
            }
        );
    }

    public copyProfileDe() {
        this.copyToClipboard(this.profile);
        this.profileCopied = true;
    }

    public copyProfileEn() {
        this.copyToClipboard(this.profileEn);
        this.profileCopiedEn = true;
    }

    public copyProfileFr() {
        this.copyToClipboard(this.profileFr);
        this.profileCopiedFr = true;
    }

    private copyToClipboard(text) {
        if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
            const textarea = document.createElement('textarea');
            textarea.textContent = text;
            textarea.style.position = 'fixed';  // Prevent scrolling to bottom of page in MS Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {

                document.execCommand('copy');  // Security exception may be thrown by some browsers.

            } catch (ex) {
                console.warn('Copy to clipboard failed.', ex);
                alert('Ihr Browswer unterstützt die Kopierren Funktion nicht. Bitte verwenden Sie Chrome oder Firefox')
            } finally {
                document.body.removeChild(textarea);
            }
        } else {
            alert('Ihr Browswer unterstützt die Kopierren Funktion nicht. Bitte verwenden Sie Chrome oder Firefox')
        }
    }

    private createProfileText() {
        this.profileCopied = false;
        this.profile =

            'Nachname (wie im Pass): ' + (this.val('last_name') || '') + '\n' +
            'Vorname (wie im Pass): ' + (this.val('first_name') || '') + '\n' +
            'Künstlername: ' + (this.val('artist_name_first_name') || '') + ' ' + (this.val('artist_name_last_name') || '') + '\n' +
            'Geburtsort: ' + (this.val('place_of_birth') || '') + '\n' +
            'Nationalität: ' + (this.val('nationality') || '') + '\n' +
            'Steueradresse: ' + (this.val('fiscal_address') || '') + '\n' +
            'Name der Bank: ' + (this.val('bank_name') || '') + '\n' +
            'Bank Filiale / Adresse: ' + (this.val('bank_address') || '') + '\n' +
            'Kontoinhaber: ' + (this.val('bank_account_holder') || '') + '\n' +
            'Kontonummer: ' + (this.val('bank_account_number') || '') + '\n' +
            'BLZ: ' + (this.val('bank_code') || '') + '\n' +
            'IBAN: ' + (this.val('bank_iban') || '') + '\n' +
            'SWIFT/BIC: ' + (this.val('bank_bic') || '') + '\n' +
            'Passnummer: ' + (this.val('passport_number') || '') + '\n' +
            'Pass:  Ausstellende Behörde: ' + (this.val('passport_authority') || '') + '\n' +
            'Pass: Ausstellungsdatum: ' + (this.val('passport_issue_date') || '') + '\n' +
            'Pass gültig bis:: ' + (this.val('passport_expirey_date') || '') + '\n' +
            'Umsatzsteuer-Identifikationsnummer: ' + (this.val('vat_number') || '') + '\n' +
            'A1 Formular : bitte benachrichtigen, ob gewünscht\n' +
            'Umsatssteuerbefreiung: bitte benachrichtigen, ob gewünscht';

        this.profileEn =

            'Surname (as stated in passport): ' + (this.val('last_name') || '') + '\n' +
            'Forename (as stated in passport): ' + (this.val('first_name') || '') + '\n' +
            'Artist name: ' + (this.val('artist_name_first_name') || '') + ' ' + (this.val('artist_name_last_name') || '') + '\n' +
            'Place of birth: ' + (this.val('place_of_birth') || '') + '\n' +
            'Nationality: ' + (this.val('nationality') || '') + '\n' +
            'Fiscal address: ' + (this.val('fiscal_address') || '') + '\n' +
            'Name of the bank: ' + (this.val('bank_name') || '') + '\n' +
            'Bank branch / address: ' + (this.val('bank_address') || '') + '\n' +
            'Account owner: ' + (this.val('bank_account_holder') || '') + '\n' +
            'Account number: ' + (this.val('bank_account_number') || '') + '\n' +
            'Bank Code: ' + (this.val('bank_code') || '') + '\n' +
            'IBAN: ' + (this.val('bank_iban') || '') + '\n' +
            'SWIFT/BIC: ' + (this.val('bank_bic') || '') + '\n' +
            'Passport number: ' + (this.val('passport_number') || '') + '\n' +
            'Passport issuing authority: ' + (this.val('passport_authority') || '') + '\n' +
            'Date of issue: ' + (this.val('passport_issue_date') || '') + '\n' +
            'Date of expiry: ' + (this.val('passport_expirey_date') || '') + '\n' +
            'VAT identification number: ' + (this.val('vat_number') || '') + '\n' +

            'A1 form: please advice if needed'


        this.profileFr =

            'Nom de famille (voir passeport): ' + (this.val('last_name') || '') + '\n' +
            'Prénom (voir passeport): ' + (this.val('first_name') || '') + '\n' +
            'Nom d’Artist: ' + (this.val('artist_name_first_name') || '') + ' ' + (this.val('artist_name_last_name') || '') + '\n' +
            'Lieu de Naissance: ' + (this.val('place_of_birth') || '') + '\n' +
            'Nationalité: ' + (this.val('nationality') || '') + '\n' +

            'Adresse fiscale: ' + (this.val('fiscal_address') || '') + '\n' +
            'Nom de la banqu: ' + (this.val('bank_name') || '') + '\n' +
            'Succursale bancaire / adresse' + (this.val('bank_address') || '') + '\n' +
            'Titulaire du compte' + (this.val('bank_account_holder') || '') + '\n' +
            'N° de compte' + (this.val('bank_account_number') || '') + '\n' +
            'Code Banque' + (this.val('bank_code') || '') + '\n' +
            'IBAN' + (this.val('bank_iban') || '') + '\n' +
            'SWIFT/BIC' + (this.val('bank_bic') || '') + '\n' +
            'N° du passeport: ' + (this.val('passport_number') || '') + '\n' +
            'Autorité émettrice du passeport:: ' + (this.val('passport_authority') || '') + '\n' +
            'Date d\'émission: ' + (this.val('passport_issue_date') || '') + '\n' +
            'Date d\'expiration: ' + (this.val('passport_expirey_date') || '') + '\n' +
            'N° VAT intracommunautair: ' + (this.val('vat_number') || '') + '\n' +
            'Nom caisse assurance maladie: ' + (this.val('health_insurance_name') || '') + '\n' +


            'formulaire A1: merci de votre notification, s\'il est nécessaire';
    }


    private val(type: string) {
        return this.form.get(type).value
    }

    public uploadFinished(file: any, group = 'attachments') {
        if (!this.form.get('id').value) {
            throw new Error('can not upload file without an user id ')
        }

        let fileValue = this.form.get('attachments').value;
        if (!isArray(fileValue)) {
            fileValue = [];
        }


        file[ 1 ].group = group;
        console.log('file', file);

        fileValue.push(file[ 1 ]);
        this.form.get('attachments').setValue(fileValue);
        this.updateOrCreate(false);

    }

    public removeFile(file: FileModel) {
        const fileValue = this.form.get('attachments').value;
        const idx = fileValue.indexOf(file);
        fileValue.splice(idx, 1);
        this.form.get('attachments').setValue(fileValue);
    }

}
