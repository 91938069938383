import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-set-password',
    templateUrl: './set-password.component.html'
})
export class SetPasswordComponent implements OnInit {

    public static url = 'passwort-vergessen/:token/:email';

    public token: string;
    public email: string;
    public password = '';
    public password_confirmation = '';
    public errorMessage = null;
    public disabled = false;
    public loading = false;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private auth: AuthService) {
    }


    ngOnInit() {

        this.activatedRoute.params.subscribe(
            (params) => {
                if (params[ 'token' ]) {
                    this.token = params[ 'token' ];
                }
                if (params[ 'email' ]) {
                    this.email = params[ 'email' ];
                }
            }
        )
    }

    public savePassword() {
        // this.loading = true;
        this.errorMessage = null;

        this.auth.setPassword(
            this.email,
            this.password,
            this.password_confirmation,
            this.token
        )
            .pipe(
                switchMap(
                    (result: any) => this.auth.login(this.email, this.password)
                )
            )
            .subscribe(
                (result: boolean) => {
                    if (result === true) {

                        setTimeout(() => {
                            this.router.navigate([ '/' ]).then(
                                () => console.log('navigated'),
                                (err) => console.error(err)
                            );
                        }, 100)

                    } else {
                        this.errorMessage = 'Fehler beim Passwort setzen';
                        this.loading = false;
                    }
                },
                (error) => {

                    console.error(error);
                    this.errorMessage = error.error.message;
                    this.loading = false;
                })



    }

}
