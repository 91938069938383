import { EventModel } from './event.model';
import { FileModel } from './file.model';

export class TravelGroup {
    id: number;
    created_at: Date;
    updated_at: Date;
    artist_id?: number; // ID of contact
    notes_int?: string;
    notes_pub?: string;
    events: EventModel[];
    attachments?: FileModel[];

    audit_first?: any;
    audit_latest?: any;

    constructor() {
    }

    public createFromDatabase(data: any) {
        this.id = data.id;
        this.created_at = data.created_at;
        this.updated_at = data.updated_at;
        this.notes_int = data.notes_int;
        this.notes_pub = data.notes_pub;
        this.artist_id = data.artist_id;


        if (data.events) {
            this.events = [];
            data.events.forEach((ev) => {
                const evnt = new EventModel();
                evnt.createFromDatabase(ev);
                this.events.push(evnt)
            });
        } else {
            this.events = [];
        }


        if (data.attachments) {
            this.attachments = data.attachments;
        }

        if (data.audit_first) {
            this.audit_first = data.audit_first;
        }
        if (data.audit_latest) {
            this.audit_latest = data.audit_latest;
        }


    }
}

