import { ApplicationRef, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { CompanyModalComponent } from '../modals/company-modal/company-modal.component';
import { Subscription } from 'rxjs';
import { HotkeyCommandService } from '../../services/hotkey-command.service';
import { CompanyModel } from '../../models/company.model';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../services/auth/auth.service';

import { LocalStorageService } from 'ngx-webstorage';
import { DataTableDirective } from 'angular-datatables';
import { ModalComponent } from '../modals/modal/modal.component';
import CellMetaSettings = DataTables.CellMetaSettings;

@Component({
  selector: 'app-all-companies',
  templateUrl: './companies.component.html'
})
export class CompaniesComponent implements OnInit, OnDestroy {

  public static url = 'companies';

  public companies;
  public rowsOnPage = 10;

  public selectedRow = 0;
  public hasSubModal = false;
  public _company: any;


  @ViewChild(DataTableDirective)
  dataTable: DataTableDirective;

  dtOptions: any = {};

  @ViewChild(ModalComponent) companyModal: ModalComponent;

  @Output() companySelected: EventEmitter<CompanyModel> = new EventEmitter();
  @Output() public onSubModalHide: EventEmitter<any>  = new EventEmitter();
  @Output() public onSubModalShow: EventEmitter<any> = new EventEmitter();

  private hotkeySubscription: Subscription;
  /**
   * What should be the default Action
   * either "select" or "edit"
   */
  @Input() public defaultAction = 'edit';
  @Input() public showSelect = false;
  @Input() public useHotKeys = true;


  constructor(private applicationRef: ApplicationRef,
              private auth: AuthService,
              private cdr: ChangeDetectorRef,
              private http: HttpClient,
              private hotkeyCommandService: HotkeyCommandService,
              private localStorage: LocalStorageService
  ) {
    this.onSubModalHide = new EventEmitter();
    this.onSubModalShow = new EventEmitter();
    this.companySelected = new EventEmitter();

  }


  ngOnInit() {
    const search = this.localStorage.retrieve('companies-search') || '';


    this.dtOptions = {
      search: {
        search: search,

      },
      pagingType: 'full_numbers',
      pageLength: this.rowsOnPage,
      serverSide: true,
      processing: true,
      responsive: false,
      language: {
        'sEmptyTable': 'Keine Daten in der Tabelle vorhanden',
        'sInfo': '_START_ bis _END_ von _TOTAL_ Einträgen',
        'sInfoEmpty': '0 bis 0 von 0 Einträgen',
        'sInfoFiltered': '(gefiltert von _MAX_ Einträgen)',
        'sInfoPostFix': '',
        'sInfoThousands': '.',
        'sLengthMenu': '_MENU_ Einträge anzeigen',
        'sLoadingRecords': 'Wird geladen...',
        'sProcessing': 'Bitte warten...',
        'sSearch': 'Suchen',
        'sZeroRecords': 'Keine Einträge vorhanden.',
        'oPaginate': {
          'sFirst': 'Erste',
          'sPrevious': 'Zurück',
          'sNext': 'Nächste',
          'sLast': 'Letzte'
        },
        'oAria': {
          'sSortAscending': ': aktivieren, um Spalte aufsteigend zu sortieren',
          'sSortDescending': ': aktivieren, um Spalte absteigend zu sortieren'
        },
        select: {
          rows: {
            _: '%d Zeilen ausgewählt',
            0: 'Zum Auswählen auf eine Zeile klicken',
            1: '1 Zeile ausgewählt'
          }
        }
      },
      ajax: (request, drawCallback, settings, test) => {
        const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
        this.http.post('dt/companies', request, { headers: headers }).subscribe(
          (data: any) => {
            this.activateHotkeys();
            this.localStorage.store('companies-search', (request.search.value) || null);
            drawCallback(data);
          },
          (err) => {
            console.error('error', err)
          }
        )
      },
      createdRow: (row, data, dataIndex) => {

        $(row).addClass('row-' + dataIndex);
        row.addEventListener('dblclick', () => {
          this.onDoubleClick(data);
        });
        row.addEventListener('click', () => {
          this.selectedRow = dataIndex;
          $('.table-active').removeClass('table-active');
          $(row).addClass('table-active');
        });

        $(row).on('click', '.dt-button', ($event) => {
          const action = $($event.currentTarget).data('elem-action');
          switch (action) {
            case 'select' :
              this.select(data);
              break;
            case 'edit' :
              this.edit(data);
              break;

          }
        });

      },
      columns: [
        { data: 'id', searchable: 'true', visible: false },
        { data: 'name', searchable: 'true' },
        {
          data: 'type',
          searchable: 'true',
          render: (data: any, type: any, row: any, meta: CellMetaSettings) => {
            switch (data) {
              case '1':
                return 'Hotel';
              case '2' :
                return 'Veranstalter / Haus';
              default :
                return 'Sonstige';
            }
          }
        },
        {
          data: 'phone', searchable: 'true'
        },

        { data: 'email', searchable: 'true' },
        { data: 'fax', searchable: 'true' },
        { data: 'city', searchable: 'true' },
        { data: 'street', searchable: 'true' },
        { data: 'zip', searchable: 'true' },
        { data: 'country', searchable: 'true' },
        {
          data: null,
          searchable: false,
          orderable: false,
          name: 'Aktion',
          render: (data: any, type: any, row: any, meta: CellMetaSettings) => {


            let elem = `<div class="btn-group-sm">`
            if (this.showSelect) {
              elem += '<button class="btn btn-sm btn-primary dt-button" type="button" data-elem-id="' +
                data.id + '" data-elem-action="select" >Auswählen</button>';
            }
            elem += '<button class="btn btn-sm btn-outline-primary dt-button" type="button"  data-elem-id="' +
              data.id + '" data-elem-action="edit" ><i class="fa fa-edit"></i></button>';

            elem += `</div>`;

            return elem;


          },
          createdCell: (cell: Node, cellData: any, rowData: any, row: number, col: number) => {

          }
        },

      ]
    };
  }

  ngOnDestroy() {
    this.deactivateHotkeys()
  }

  activateHotkeys() {
    if (this.useHotKeys  && !this.hotkeySubscription) {
      this.hotkeySubscription = this.hotkeyCommandService.hotKeyEvents.subscribe(
        (command) => this.handleHotkey(command)
      )
    }

  }

  deactivateHotkeys() {
    if (this.hotkeySubscription) {
      this.hotkeySubscription.unsubscribe()
    }
  }

  public subModalShow($event) {
    this.hasSubModal = true;
    this.onSubModalShow.emit($event);
    this.deactivateHotkeys();
  }

  public subModalHide($event) {
    this.hasSubModal = false;
    this.onSubModalHide.emit($event);
    this.activateHotkeys();
  }

  public editSelectedRow() {
    this.dataTable.dtInstance.then(
      (dtInstance) => {
        const company = dtInstance.data()[ this.selectedRow ];
        this.edit(company);
      }
    )

  }


  public selectSelectedRow() {
    this.dataTable.dtInstance.then(
      (dtInstance) => {
        const company = dtInstance.data()[ this.selectedRow ];
        this.select(company);
      }
    )
  }


  public edit(company: CompanyModel) {
    this.companyModal.show();
    this._company = company;
  }

  public select(company: CompanyModel) {
    this.companySelected.next(company);
  }

  public addCompany() {
    this._company = { id:  null};
    this.companyModal.show();
  }

  public refreshPage() {
    this.dataTable.dtInstance.then(
      (inst: DataTables.Api) => {
        inst.ajax.reload(null, false)
      }
    );
  }

  public onDoubleClick(company: CompanyModel) {
    if (this.defaultAction === 'edit') {
      this.edit(company);
    }
    if (this.defaultAction === 'select') {
      this.selectSelectedRow();
    }
  }

  public hideCompanyModal() {
    console.log('hide company modal')
    this.companyModal.hide()
  }


  private handleHotkey(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        this.selectPrevRow();

        break;
      case 'ArrowDown':
        event.preventDefault();
        this.selectNextRow();
        break;
      case 'Enter':
        if (this.defaultAction === 'edit') {
          this.editSelectedRow();
        } else if (this.defaultAction === 'select') {
          this.selectSelectedRow();
        }
        break;
    }
  }


  protected selectPrevRow() {
    if (this.selectedRow > 0) {
      this.selectedRow--;
    } else {
      this.selectedRow = this.rowsOnPage;
    }
    this.updateSelectedRow()
  }

  private selectNextRow() {
    if (this.selectedRow + 1 < this.rowsOnPage) {
      this.selectedRow++;
      this.updateSelectedRow()
    } else {
      this.selectedRow = 0;
    }
  }


  private updateSelectedRow() {
    $('.table-active').removeClass('table-active');
    $('.row-' + this.selectedRow).addClass('table-active');
  }

}
