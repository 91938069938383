import { Injectable, Injector } from '@angular/core';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { OfflineBaseService } from './offline-base.service';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map, switchMap, tap } from 'rxjs/operators';
import { TravelGroup } from '../../models/travel-group';
import { OfflineEventService } from './offline-event.service';

@Injectable()
export class OfflineTravelGroupService extends  OfflineBaseService {

  private offlineEventsService: OfflineEventService;

  constructor(
    private $injector: Injector
  ) {
    super();
    setTimeout(() => this.offlineEventsService = $injector.get(OfflineEventService));
  }

  get(id: number, includeEvents = true): Observable<{ data: TravelGroup }> {
    return fromPromise(this.connection.select({
      from: 'travel_groups',
      where: {
        id: id
      }
    })).pipe(
      map((value: any) => {
        return value.map(this.createFromIdb)
      }),
      switchMap((travelGroup: TravelGroup) => {
        if (!includeEvents) {
          return of(travelGroup);
        }
        return this.offlineEventsService.getEventsOfTravelGroup(travelGroup)
          .pipe(
            map((events) => {
              travelGroup.events = events;
              return travelGroup
            })
          )
      }),
      map((value: TravelGroup) => {
        return { data: value[ 0 ] }
      }),
      tap((event) => console.log('return travel group from OfflineTravelgroupService', event))
    );

  }



  private createFromIdb(data: any): TravelGroup {
    const model = new TravelGroup();


    data.updated_at = moment(data.updated_at);
    data.created_at = moment(data.created_at);
    model.createFromDatabase(data);
    return model;
  }


}
