import { Injectable } from '@angular/core';
import { ContactsService } from './contacts.service';
import { UserModel } from '../models/user.model';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Moment } from 'moment';


@Injectable()
export class AppUserService {


    private tableNameUsers = 'users';


    constructor(private contactsService: ContactsService,
                private authHttp: HttpClient) {
    }


    public allUsers(): Observable<UserModel[]> {
        return this.authHttp.get(this.tableNameUsers)
            .pipe(
                map((res: { data: any }) => {
                    return res.data.users.data.map((elem) => this.createFromDatabase(elem))
                }));
    }

    public openInvitations(): Observable<{
        id: number,
        email: string,
        first_name: string,
        last_name: string,
        role: string,
        valid_till: Moment
    }[]> {
        return this.authHttp.get(this.tableNameUsers + '/invitations')
            .pipe(
                map((data: { data: { invitations: any[] } }) => {
                    return data.data.invitations
                }));
    }

    public resendInviation(id): Observable<{
        id: number,
        email: string,
        first_name: string,
        last_name: string,
        role: string,
        valid_till: Moment
    }[]> {
        return this.authHttp.post(this.tableNameUsers + '/invitations/' + id, {})
            .pipe(
                map((data: { data: { invitations: any[] } }) => {
                    return data.data.invitations
                }));
    }

    public deleteInvitation(id): Observable<{
        id: number,
        email: string,
        first_name: string,
        last_name: string,
        role: string,
        valid_till: Moment
    }[]> {
        return this.authHttp.delete(this.tableNameUsers + '/invitations/' + id, {})
            .pipe(
                map((data: { data: { invitations: any[] } }) => {
                    return data.data.invitations
                }));
    }

    public getMe(): Observable<UserModel> {
        return this.authHttp.get('user')
            .pipe(map((res: UserModel) => {
                return this.createFromDatabase(res)
            }));
    }

    public getUser(id: number): Observable<UserModel> {
        return this.authHttp.get(this.tableNameUsers + '/' + id)
            .pipe(map((res: { data: any }) => {
                // todo: this should really be only one user! :p
                return this.createFromDatabase(res.data.user)
            }));
    }

    public deleteUser(id: number): Observable<{}> {
        return this.authHttp.delete(this.tableNameUsers + '/' + id)
            .pipe(map((res: { data: any }) => {
                // todo: this should really be only one user! :p
                return {}
            }));
    }

    public update(user: UserModel): Observable<any> {
        return this.authHttp.put(this.tableNameUsers + '/' + user.id, user)
            .pipe(map((res: { data: any }) => {
                return this.createFromDatabase(res.data.user)
            }))

    }


    public sendInvitation(data: {
        first_name: string;
        last_name: string;
        email: string;
        contact_id: number;
        role: 'admin' | 'artist' | 'logistic' | 'agent';
    }) {
        return this.authHttp.post( this.tableNameUsers, data)
            .pipe(
                map((res: { data: any }) => {
                    return (res.data)
                }))
    }


    private createFromDatabase(data: any): UserModel {
        const model = new UserModel();
        model.createFromDatabase(data);
        console.log('model', data, model);
        return model;
    }


}

