import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import * as moment from 'moment';
import { Moment } from 'moment';
import 'moment/locale/de';
import { extendMoment } from 'moment-range';
import { ContactModel } from '../../models/contact.model';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { EventService } from '../../services/event.service';
import { EventModel } from '../../models/event.model';
import { EventTypePipe } from '../../pipes/event-type.pipe';
import { HotkeyCommandService } from '../../services/hotkey-command.service';
import { Observable, of, Subject, Subscription } from 'rxjs';
import {
  EventGroupStateEnum,
  EventTypeEnum,
  TravelTypeEnum,
  UserRoleAdmin,
  UserRoleAgent,
  UserRoleArtist,
  UserRoleLogistic,
  UserRoleNone
} from '../../models/enums';
import { AuthService } from '../../services/auth/auth.service';
import { UserModel } from '../../models/user.model';
import { EventGroup } from '../../models/event-group';
import { CalenderConfig } from '../../models/calender-config';
import { CalenderConfigService } from '../../services/calender-config.service';
import { CompanyModel } from '../../models/company.model';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleEventModalComponent } from '../../pages/modals/simple-event-modal/simple-event-modal.component';
import { ReadOnlyModalComponent } from '../../pages/modals/read-only-modal/read-only-modal.component';
import { EventGroupPageComponent } from '../../pages/events-group/event-group-page.component';
import { EventEditorComponent } from '../event/event-editor/event-editor.component';
import { ModalComponent } from '../../pages/modals/modal/modal.component';
import { takeUntil } from 'rxjs/operators';
import { OfflineModeService } from '../../services/offline/offline-mode.service';


const momentRange = extendMoment(moment);

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-calendar-elem',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './calendar-elem.component.html',
  providers: [ EventTypePipe ]
})
export class CalendarElemComponent implements OnInit, OnDestroy {

  @ViewChild(EventEditorComponent) eventEditor: EventEditorComponent;
  @ViewChild(ModalComponent) eventEditorModal: ModalComponent;
  @ViewChild(SimpleEventModalComponent) simpleEventModal: SimpleEventModalComponent;
  @ViewChild(ReadOnlyModalComponent) readOnlyModal: ReadOnlyModalComponent;
  @ViewChild(ModalComponent) settingModal: ModalComponent;
  @ViewChild(ContextMenuComponent) contextMenu: ContextMenuComponent;


  public _artist: ContactModel;
  public _location: CompanyModel;

  public startDate: moment.Moment = moment();
  public monthRange: any;
  public selectedDay: moment.Moment = moment();
  public monthRangeForView: any;

  public eventList: EventModel[] = [];
  public filteredEvents: EventModel[][];

  public contextMenuActions = [];

  public dragAndDropAllowed = false;
  public currentUser: UserModel = null;
  public loading = 0;
  public eventInEditor: EventModel;

  private stopLoading = new Subject();
  private hotkeySubscription: Subscription;
  private offline: boolean;

  private _allArtists: boolean;
  @Input()  set allArtists (allArtists: boolean) {
    this.createNewLoadingStopper();
    this._artist = null;
    this.eventList = [];
    this.filteredEvents = [];
    this.monthRangeForView = [];
    this.setStartDate();
    this.refreshCalendar();
    this._allArtists = allArtists;
  }
  get allArtists() {
    return this._allArtists;
  }

  public config: CalenderConfig;

  @Input() set artist(artist: ContactModel) {
    this.createNewLoadingStopper();

    this._artist = null;
    this.eventList = [];
    this.filteredEvents = [];
    this.monthRangeForView = [];
    this._artist = artist;
    this.setStartDate();
    this.refreshCalendar();

  }

  @Input() set location(location: CompanyModel) {
    this.createNewLoadingStopper();
    this._location = location;
    this.eventList = [];
    this.filteredEvents = [];
    this.monthRangeForView = [];
    this.setStartDate();
    this.refreshCalendar();
  }

  @Input() monthsInView: number;


  @HostListener('window:resize', [ '$event' ])
  onResize() {
    this.setMonthsInViewByWindowWidth();
  }

  @Input() set updateEventObserver(obs: Observable<EventModel>) {
    obs.subscribe(
      (event: EventModel) => {
        this.updateEvent(event)
      }
    )
  };


  private createNewLoadingStopper() {
    this.stopLoading.next();
    this.stopLoading.complete();
    this.stopLoading = new Subject();

    this.stopLoading.asObservable().subscribe(
      (loadingStopped) => {
        console.log('Calendar loading was aborted');
      }
    )

  }
  public updateEvent(event: EventModel) {
    const idx = this.eventList.findIndex((ev) => {
      return ev.id === event.id
    });
    if (idx > -1) {
      this.eventList[ idx ] = event;
      this.refreshCalendar();
    }
  }

  @Input() set updateEventGroupObserver(obs: Observable<EventGroup>) {
      obs.subscribe(eventGroup => this.updateEventGroup(eventGroup))
  }

  public updateEventGroup(eventGroup: EventGroup) {
    eventGroup.events.map((event) => {
      const idx = this.eventList.findIndex((ev) => {
        return ev.id === event.id
      });

      if (idx > -1) {
        if (!event.event_group) {
          event.event_group = eventGroup
        }
        this.eventList[ idx ] = event;
      }
    });
    this.refreshCalendar();
  }

  @Input() set addEventObserver(obs: Observable<EventModel>) {
    obs.subscribe((event: EventModel) => this.addEvent(event))
  }
  public addEvent(event: EventModel, openEventGroup = false) {
    this.eventList.push(event);
    this.refreshCalendar();
    if (openEventGroup && event.event_group_id) {
      this.openEvent(event, null);
    }
  }

  @Input() set removeEventObserver(obs: Observable<number>) {
    obs.subscribe((number) => this.deleteEvent(number))
  }
  public deleteEvent(id: number) {
    const idx = this.eventList.findIndex((ev) => {
      return ev.id === id;
    });
    if (idx > -1) {
      this.eventList.splice(idx, 1);
      this.refreshCalendar();
    }
  }



  constructor(private localStorage: LocalStorageService,
              private auth: AuthService,
              private eventService: EventService,
              private contextMenuService: ContextMenuService,
              private calenderConfigService: CalenderConfigService,
              private cdr: ChangeDetectorRef,
              private eventTypePipe: EventTypePipe,
              private route: ActivatedRoute,
              private router: Router,
              private offlineModeService: OfflineModeService,
              private hotKeyCommandService: HotkeyCommandService) {
    this.selectedDay = moment(this.localStorage.retrieve('selectedDay'));

    if (!this.selectedDay.isValid()) {
      this.selectedDay = moment();
    }

    this.currentUser = this.localStorage.retrieve('user');
    this.localStorage.observe('user')
      .subscribe((user) => {
        this.currentUser = user;
      });

    this.offlineModeService.offlineStatusChange$.subscribe(
        (changed) => {
          this.offline = changed;

          if (this.eventList.length > 0) {
            this.eventList = [];
            this.filteredEvents = [];
            this.cdr.markForCheck();
            this.refreshCalendar();
            this.setStartDate();
            this.cdr.markForCheck();
          } else if (this._allArtists === true || this._artist || this._location)  {
            this.refreshCalendar();
            this.setStartDate();
            this.cdr.markForCheck();
          }
        }
      );

    this.offline = this.offlineModeService.offline.getValue();
  }



  ngOnInit() {
    this.calenderConfigService.getConfig().subscribe(
      (config: CalenderConfig) => {
        this.config = config;
        this.setMonthsInViewByWindowWidth();
        this.setStartDate();
        this.activateHotkeys();
      }
    );



  }

  ngOnDestroy() {
    this.deactivateHotkeys()
  }


  private setMonthsInViewByWindowWidth() {
    const oldVal = this.monthsInView;
    let val;
    if (window.innerWidth > 768) {
      val = 6;
    }
    if (window.innerWidth <= 768) {
      val = 3;
    }
    if (window.innerWidth <= 576) {
      val = 1;
    }
    if (oldVal !== val) {
      this.switchMonthsInView(val);
    }
  }



  private selectNextMonth() {
    this.setSelectedDay(this.selectedDay.add(1, 'month'));
  }

  private selectPrevMonth() {
    this.setSelectedDay(this.selectedDay.add(-1, 'month'));

  }

  private selectNextWeek() {
    this.setSelectedDay(this.selectedDay.add(1, 'week'));
  }

  private selectPrevWeek() {
    this.setSelectedDay(this.selectedDay.add(-1, 'week'));
  }

  private selectNextDay() {
    this.setSelectedDay(this.selectedDay.add(1, 'day'));
  }

  private selectPrevDay() {
    this.setSelectedDay(this.selectedDay.add(-1, 'day'));
  }

  private selectNextMonthRangePeriod() {
    this.setStartDate(this.startDate.add(this.monthsInView, 'month'));
    this.refreshCalendar();
    this.setSelectedDay(this.selectedDay.add(this.monthsInView, 'month'));
  }

  private selectPrevMonthRangePeriod() {
    this.setStartDate(this.startDate.add(-this.monthsInView, 'month'));
    this.refreshCalendar();
    this.setSelectedDay(this.selectedDay.add(-this.monthsInView, 'month'));
  }

  private selectNextYear() {
    this.setStartDate(this.startDate.add(1, 'year'));
    this.refreshCalendar();
    this.setSelectedDay(this.selectedDay.add(1, 'year'));
  }

  private selectPrevYear() {
    this.setStartDate(this.startDate.add(-1, 'year'));
    this.refreshCalendar();
    this.setSelectedDay(this.selectedDay.add(-1, 'year'));
  }

  private selectToday() {
    this.setStartDate(moment().startOf('month'));
    this.refreshCalendar();
    this.setSelectedDay(moment().startOf('day'));
  }

  public switchMonthsInView(months: number) {
    this.monthsInView = months;
    this.setStartDate(this.startDate)
    this.refreshCalendar()
  }

  private checkSelectionInView() {

    if (!this.monthRange.contains(moment(this.selectedDay))) {

      if (this.selectedDay < this.monthRange.start) {
        const desiredStartDate = moment(this.selectedDay).startOf('month');
        this.setStartDate(desiredStartDate);
      } else {
        const desiredStartDate = moment(this.selectedDay).startOf('month').subtract(this.monthsInView - 1, 'month');
        this.setStartDate(desiredStartDate);

      }
      this.refreshCalendar();
    }
    this.cdr.markForCheck();
  }

  private setSelectedDay(day: Moment) {

    this.selectedDay = moment(day);
    this.checkSelectionInView();

    this.localStorage.store('selectedDay', day);
  }

  private setStartDate(startDate: any = null) {

    if (startDate === null) {
      startDate = this.localStorage.retrieve('startDate');
      if (startDate === null) {
        startDate = moment();
      }
    } else {
      this.localStorage.store('startDate', startDate);
    }

    if (!this.monthsInView) {
      this.setMonthsInViewByWindowWidth();
    }
    let start = moment(startDate).clone();

    this.startDate = start.clone();
    this.localStorage.store('startDate', start);

    start = start.startOf('month');
    let end = start.clone();

    switch (this.monthsInView) {
      case 1:
        end = end.add(10, 'days').endOf('month');
        this.monthRange = momentRange.range(start.clone(), end.clone());
        break;

      case 6:
      case 5:
      case 4:
      case 3:
      case 2:
      default:
        end = end.add(this.monthsInView - 1, 'months').endOf('month');
        this.monthRange = momentRange.range(start.clone(), end.clone());
        break;
    }
    this.cdr.markForCheck();
    this.addEventsForNewStartDate(start.clone());
  }

  private refreshCalendar() {
    if (!this.monthRange) {
      this.setStartDate();
    }
    // stop detection changes
    this.cdr.detach();

    this.parseEvents();

    const monthIterator: any = Array.from(this.monthRange.by('month'));
    const monthRangeForView = [];
    for (const month of monthIterator) {
      const monthForView = {
        name: month.tz('Europe/Berlin').format('MMM YY'),
        days: []
      };
      const dayIterator: any = Array.from(this.createDayRange(month).by('day'));
      for (const day of dayIterator) {
        const dayForView = {
          day: day.tz('Europe/Berlin'),
          sunday: day.tz('Europe/Berlin').isoWeekday() === 7,
          display: day.tz('Europe/Berlin').format('D dd'),
          events: [],
        };
        if (this.filteredEvents[ day.tz('Europe/Berlin').format('YYYYMMDD') ]) {
          dayForView.events = this.filteredEvents[ day.tz('Europe/Berlin').format('YYYYMMDD') ];
        }
        monthForView.days.push(dayForView);
      }
      monthRangeForView.push(monthForView)
    }
    this.monthRangeForView = monthRangeForView;

    // restart detection changes
    this.cdr.reattach();
    this.cdr.detectChanges();
  }

  private createDayRange(month): any {
    return momentRange.range(moment(month).startOf('month'), moment(month).endOf('month'));
  }

  private addEventsForNewStartDate(startDate: Moment) {

    const range = {
      start: moment(startDate).subtract(1, 'month').startOf('month').clone(),
      end: moment(startDate).add(this.monthsInView, 'months').endOf('month').clone()
    };

    this.loading++;
    this.cdr.markForCheck();
    this.getEventsFromService(range).
      pipe(
        takeUntil(this.stopLoading),
    ).
      subscribe(
      (events: EventModel[]) => {
        let changesMade = false;
        events.forEach((event: EventModel) => {
          if (this.eventList && !this.eventList.find((eventInList: EventModel) => eventInList.id === event.id)) {
            this.eventList.push(event);
            changesMade = true;
          }
        });
        if (changesMade) {
          this.refreshCalendar();
        }
      },
      (err) => {
        console.error('could not load events', err);

      },
      () => {
        this.loading--;
        this.cdr.markForCheck();
      }
    );
  }


  private getEventsFromService(range: { start: Moment, end: Moment }): Observable<EventModel[]> {
    if (this.allArtists === true) {
      return this.eventService.allEventsOnlyFirstEventOfEventGroup(range);
    }
    if (this._location) {
      return this.eventService.allEventsOfLocation(this._location, range);
    }
    if (!this._artist) {
      return of([]);
    }
    return this.eventService.allEventsOfArtist(this._artist, range);
  }

  private parseEvents() {
    // clone the all event Obj
    if (!this.eventList) {
      return
    }
    const parsedEvents: EventModel[] = [ ...this.eventList ];
    // calc once use always might be faster than recalc always
    // filter wont work while using iterators
    this.filteredEvents = [];

    parsedEvents.forEach(
      (event: EventModel) => {
        const start = moment.tz(event.start, 'UTC').tz('Europe/Berlin');
        const end = moment.tz(event.end, 'UTC').tz('Europe/Berlin');


        const dateString = moment(start).tz('Europe/Berlin').startOf('day').format('YYYYMMDD');

        if (!this.filteredEvents[ dateString ]) {
          this.filteredEvents[ dateString ] = [];
        }
        if (moment(start.tz('Europe/Berlin'))
            .tz('Europe/Berlin').format('YYYYMMDD') ===
          moment(end.tz('Europe/Berlin')).tz('Europe/Berlin').format('YYYYMMDD')) {
          this.filteredEvents[ dateString ].push(event);
        } else {
          // lasts several days!

          const dayIterator = moment(start).tz('Europe/Berlin');
          for (const i = dayIterator.tz('Europe/Berlin'); i <= moment(end.tz('Europe/Berlin')).tz('Europe/Berlin'); i.add('1', 'day')) {

            if (!this.filteredEvents[ i.format('YYYYMMDD') ]) {
              this.filteredEvents[ i.format('YYYYMMDD') ] = [];
            }

            this.filteredEvents[ i.format('YYYYMMDD') ].push(event);
          }
        }


      });


  }



  public createEvent(day: moment.Moment, mode: 'activity' | 'travel' | 'simple' = 'activity') {
    if (!this.currentUser || this.currentUser.role === UserRoleArtist) {
      return;
    }
    if (mode === 'simple') {
      this.simpleEventModal.createEvent(day, this._artist.id);
      return;
    }

    if (this.currentUser.role === UserRoleLogistic) {
      return;
    }
    this.createFullEvent(day, this._artist.id, mode)



  }

  public createFullEvent(day: moment.Moment, artist_id, mode: 'travel' | 'activity') {
    const newEvent = new EventModel();
    newEvent.id = null;
    newEvent.start = { ...day };
    newEvent.end = { ...day };
    newEvent.artist_id = artist_id;
    newEvent.type = mode === 'activity' ? EventTypeEnum.performanceOrConcert : EventTypeEnum.travel;
    newEvent.notes = null;
    this.eventEditorModal.show();
    this.eventInEditor = newEvent;

  }


  public openEvent(event: EventModel, clickEvent: MouseEvent | null) {
    this.dragAndDropAllowed  = false;

    if (clickEvent !== null) {
      // stop all other handlers - prevents creating a new event
      clickEvent.stopImmediatePropagation();
      clickEvent.stopPropagation();
    }
    if (this.monthsInView === 1 || this.auth.getUser().role === 'artist') {
      this.readOnlyModal.openEvent(event);
      return;
    }

    if (event.type === EventTypeEnum.simpleEvent) {
      this.eventService.get(event.id).subscribe(
          (evt: EventModel) => {
            this.simpleEventModal.openEvent(evt);
          },
          (err) => {
            alert('Konnte Termin nicht laden')
          }
      )

      return;
    }

    if (event.type === EventTypeEnum.travel) {
      this.router.navigate([ EventGroupPageComponent.urlTravel + '/' + event.travel_group_id ], { relativeTo: this.route });
      return;
    }

    this.router.navigate([ EventGroupPageComponent.urlEvent + '/' + event.event_group_id ], { relativeTo: this.route });

  }

  public onContextMenu($event: MouseEvent, day: any): void {


    this.selectedDay = day.day;

    this.contextMenuActions = [];
    this.cdr.detectChanges();

    if (!this.allArtists && !this._location) {


      if (this.currentUser.role === UserRoleArtist) {
        this.contextMenuActions = [
          {
            html: (item) => day.day.format('dddd DD.MM.YYYY') + ':',
            click: (item) => false,
            visible: (item) => true,
            enabled: (item) => false,
            strikeThrough: (item) => false,
          }
        ];
      } else if (this.currentUser.role === UserRoleLogistic) {
        this.contextMenuActions = [
          {
            html: (item) => day.day.format('dddd DD.MM.YYYY') + ':',
            click: (item) => false,
            visible: (item) => true,
            enabled: (item) => false,
            strikeThrough: (item) => false,
          },
          {
            html: (item) => 'Neuer Termin ...',
            click: (item) => this.createEvent(day.day, 'simple'),
            enabled: (item) => true,
            visible: (item) => true,
            strikeThrough: (item) => false,
          },
          {
            html: (item) => 'Neue Reise ...',
            click: (item) => this.createEvent(day.day, 'travel'),
            enabled: (item) => true,
            visible: (item) => true,
            strikeThrough: (item) => false,
          }
        ];
      } else if (!this.offline && (this.currentUser.role === UserRoleAdmin || this.currentUser.role === UserRoleAgent)) {


        this.contextMenuActions = [
          {
            html: (item) => day.day.format('dddd DD.MM.YYYY') + ':',
            click: (item) => false,
            visible: (item) => true,
            enabled: (item) => false,
            strikeThrough: (item) => false,
          },
          {
            html: (item) => 'Neues Engagement ...',
            click: (item) => this.createEvent(day.day),
            enabled: (item) => true,
            visible: (item) => true,
            strikeThrough: (item) => false,
          },
          {
            html: (item) => 'Neuer Termin ...',
            click: (item) => this.createEvent(day.day, 'simple'),
            enabled: (item) => true,
            visible: (item) => true,
            strikeThrough: (item) => false,
          },
          {
            html: (item) => 'Neue Reise ...',
            click: (item) => this.createEvent(day.day, 'travel'),
            enabled: (item) => true,
            visible: (item) => true,
            strikeThrough: (item) => false,
          }
        ];


      }

      if (day.events[ 0 ]) {
        this.contextMenuActions.push({
          divider: true,
          visible: true
        });
      }
    }

    day.events.forEach(
      (event: EventModel) => {
        let html = this.eventTypePipe.transform(event.type) + ' "';
        html += this.getNameForEvent(event);
        html += '"';
        if (this.allArtists) {
          html += ' (' + event.artist.first_name + ' ' + event.artist.last_name + ')';
        }


        this.contextMenuActions.push(
          {
            html: (item) => html,
            click: (item) => this.openEvent(event, null),
            enabled: (item) => true,
            strikeThrough: (item) => {
              return event.cancelled || (event.event_group && event.event_group.state === EventGroupStateEnum.cancelled)
            },
            visible: (item) => true,
          },
        );
      }
    );


    if (this.contextMenuActions.length === 0 ) {
      this.contextMenuActions.push(
        {
          html: (item) => 'Keine Optionen verfügbar',
          click: (item) => {},
          enabled: (item) => false,
          strikeThrough: (item) => false ,
          visible: (item) => true,
        },
      );
    }

    this.cdr.detectChanges();
    this.contextMenuService.show.next({
      // Optional - if unspecified, all context menu components will open
      contextMenu: this.contextMenu,
      event: $event,
      item: day,
    });
    this.cdr.markForCheck();
    $event.preventDefault();
    $event.stopPropagation();

  }

  private getNameForEvent(event: EventModel): string {

    if (event.type === EventTypeEnum.travel) {
      if (event.travel_type && event.travel_type === TravelTypeEnum.hotel) {
        return 'Hotel';
      }
      if (event.travel_type && (event.travel_type === TravelTypeEnum.outbound )) {
        return 'Hinreise';
      }
      if (event.travel_type && (event.travel_type === TravelTypeEnum.inbound )) {
        return 'Rückreise';
      }
    }
    return event.getDisplayName();

  }

  private handleEnter() {
    if (this.getNumberOfEventsOnDay(this.selectedDay) > 0) {
      this.openEvent(this.getFirstEventOnSelectedDay(), null);
      return;
    }
    // return this.onContextMenu(null, this.selectedDay);
  }

  private getFirstEventOnSelectedDay(): EventModel {
    return this.filteredEvents[ this.selectedDay.format('YYYYMMDD') ][ 0 ]
  }


  private handleHotKey(event: KeyboardEvent) {
    console.log('handle hotkey');
    switch (event.key) {
      case 'PageUp' :
        this.selectNextYear();
        break;
      case 'PageDown':
        this.selectPrevYear();
        break;
      case 'ArrowRight':
        event.shiftKey ? this.selectNextMonthRangePeriod() : this.selectNextMonth();
        break;
      case 'ArrowLeft':
        event.shiftKey ? this.selectPrevMonthRangePeriod() : this.selectPrevMonth();
        break;
      case 'ArrowUp':
        event.shiftKey ? this.selectPrevWeek() : this.selectPrevDay();
        break;
      case 'ArrowDown':
        event.shiftKey ? this.selectNextWeek() : this.selectNextDay();
        break;
      case 'h':
        this.selectToday();
        break;
      case 'Home':
        this.setSelectedDay(this.selectedDay.startOf('month'));
        break;
      case 'End':
        this.setSelectedDay(this.selectedDay.endOf('month'));
        break;
      case 'Enter':
        this.handleEnter();
        break;
      case 'Alt':
        if (!this.allArtists && this.currentUser.role !== (UserRoleArtist || UserRoleNone)) {
          this.dragAndDropAllowed = !this.dragAndDropAllowed;
          console.log(this.dragAndDropAllowed);
          this.cdr.markForCheck();
        }
        break;
    }

  }


  activateHotkeys() {
    console.log('shoudl add hotkeys')
    if (!this.hotkeySubscription || this.hotkeySubscription.closed) {
      console.log('will add hotkeys')
      this.hotkeySubscription = this.hotKeyCommandService.hotKeyEvents.subscribe(
        (command) => this.handleHotKey(command)
      )
    }

  }

  deactivateHotkeys() {
    if (this.hotkeySubscription) {
      this.hotkeySubscription.unsubscribe()
    }

  }


  updateConfig() {
    this.calenderConfigService.update(this.config).subscribe(
      (conf: CalenderConfig) => {
        this.config = conf;
        this.eventList = [];
        this.filteredEvents = [];
        this.monthRangeForView = [];
        this.addEventsForNewStartDate(this.monthRange.start);
        this.settingModal.hide()
      }
    )
  }


  onEventDrop(data: {item: { data: {eventToTransform: EventModel, draggedDate: moment.Moment}}}, to: moment.Moment) {
    if (!this.dragAndDropAllowed) {
      return;
    }

    const from = data.item.data.draggedDate;

    if (from.isSame(to, 'day')) {
      return;
    }
    const doAction = confirm('Möchten Sie diesen Termin wirklich verschieben?');
    if (doAction === true) {
      const eventModel = data.item.data.eventToTransform;

      if (moment(eventModel.end).isSame(moment(eventModel.start), 'day')) {
        // start and end on same day => reschedule to other Day
        const clonedModel: EventModel = <EventModel>{ ...eventModel };
        this.eventService.rescheduleToOtherDay(clonedModel, to).subscribe(
          (ev: EventModel) => {
            this.updateEvent(ev)
          }
        );

      } else if (from.isSame(eventModel.start, 'day')) {
        // dragging the start date
        eventModel.start = to;
        this.eventService.update(eventModel).subscribe(
          (ev: EventModel) => this.updateEvent(ev)
        );


      } else if (from.isSame(eventModel.end, 'day')) {
        // dragging the end date
        eventModel.end = to;
        this.eventService.update(eventModel).subscribe(
          (ev: EventModel) => this.updateEvent(ev)
        );
      }

    }
  }


  private getNumberOfEventsOnDay(day: moment.Moment): number {
    const dayString = moment(day).format('YYYYMMDD');
    if (this.filteredEvents[ dayString ]) {
      return this.filteredEvents[ dayString ].length;
    } else {
      return 0;
    }
  }


}
