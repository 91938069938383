import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ContactNoteModel } from '../models/contact-note.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable()
export class ContactNotesService {

    private tableName = 'contact-notes';

  constructor(
                private httpClient: HttpClient) {
    }


  public update(data: ContactNoteModel): Observable<any> {
        data = this.parseForDatabase(data);
        return this.httpClient.put(this.tableName + '/' + data.id, data)
            .pipe(map((ret: {data: any}) => {
                return this.createFromDatabase(ret.data.note);
            }))
    }

    public create(data): Observable<ContactNoteModel> {
        data = this.parseForDatabase(data);
        return this.httpClient.post(this.tableName, data)
            .pipe(map((ret: {data: any}) => {
                return this.createFromDatabase(ret.data.note);
            }))
    }

    public autocomplete(keyword) {
      let params = new HttpParams();
      if (keyword) {
        params = params.set('search', keyword)
      }
      return this.httpClient.get(this.tableName , { params: params })
        .pipe(map((res: {data: any}) => {
          return res.data.contactNotes.map((elem) => this.createFromDatabase(elem))
        }));
    }


    public remove(id: number) {
        return this.httpClient.delete(this.tableName + '/' + id);
    }

    private createFromDatabase(data: any): ContactNoteModel {
        const model = new ContactNoteModel();
        model.createFromDatabase(data);
        return model;
    }

    private parseForDatabase({ ...data }: ContactNoteModel | any) {
        return data;
    }

}
