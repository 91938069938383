import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CalenderConfig } from '../models/calender-config';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { OfflineModeService } from './offline/offline-mode.service';

@Injectable()
export class CalenderConfigService {

    constructor(
        private offlineModeService: OfflineModeService,
        private http: HttpClient) {
    }


    public getConfig(): Observable<CalenderConfig> {
        return this.http.get('calender-config', {
            headers: { 'also-from-idb': 'true' },
        })
            .pipe(
                map(
                    (res: { data: { config: CalenderConfig } }) => {
                        return res.data.config
                    }
                ),
                catchError((error: any) => {
                    console.error(error);
                    if (error.code === 0 || error.error === 'App is offline') {
                        // simple offline response
                        return of({
                            all: true,
                            contacts: []
                        })
                    }
                    throwError(error);
                })
            );
    }

    public update(config: CalenderConfig): Observable<CalenderConfig> {
        return this.http.put('calender-config', config)
            .pipe(map((res: { data: { config: CalenderConfig } }) => {
                    return res.data.config
                }
            ));
    }
}
