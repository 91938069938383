import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { CalendarComponent } from '../../pages/calendar/calendar.component';

@Injectable()
export class RedirectToCalendarIfAuthenticatedGuard implements CanActivate {

  constructor(
      public authService: AuthService,
      private router: Router
  ) {
  }


  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): boolean {
    console.log('RedirectToCalendarIfAuthenticatedGuard');
    if ( this.authService.isLoggedIn() === false) {
      console.log('not logged in')
      return true
    } else {
        console.log('logged in');
        this.router.navigateByUrl(CalendarComponent.url);
      return false;
    }
  }
}
