<div class="input-group input-group-sm" *ngIf="isLoading">
  <i class="fa fa-spin fa-spinner"></i>
</div>
<div class="input-group input-group-sm" *ngIf="!isLoading">

    <input [placeholder]="placeholder"
           [disabled]="readOnly || offline"
           class="form-control has-feedback"
           [class.ng-dirty]="dirty"
           id="searchCompany"
           type="text"
           [(ngModel)]="query"
           (ngModelChange)="queryObservable.next($event)"
           (typeaheadOnSelect)="companySelected($event)"
           (focusout)="ensureOnlyAllowedValue()"
           typeaheadOptionField="name"
           [typeaheadMinLength]="0"
           [typeaheadOptionsLimit]="100"
           [typeahead]="searchResults"
           [autocomplete]="'i-' + timestamp"
           [id]="'i-' + timestamp"
           [name]="'i-' + timestamp"

    >


    <span class="input-group-btn" *ngIf="_company && !readOnly && !offline && showEdit">
        <button class="btn btn-sm  btn-outline-primary"
                type="button"
                [disabled]="readOnly || offline"
                (click)="editCompany()"><i class="fa fa-edit"></i>
        </button>
    </span>
    <span class="input-group-btn" *ngIf="showAdd && !offline">
        <button class="btn btn-sm btn-outline-primary"
                [disabled]="readOnly || offline"
                type="button"
                (click)="addCompany()">
            <i class="fa fa-plus"></i>
        </button>
    </span>

    <span class="input-group-btn" *ngIf="showSearch && !offline">
        <button class="btn btn-sm btn-outline-primary"
                type="button"
                [disabled]="readOnly || offline"
                (click)="findCompany()">
            <i class="fa fa-search"></i>
        </button>
    </span>
    <span class="input-group-btn" *ngIf="showDelete && _company !== null && !offline">
        <button
                class="btn btn-sm btn-outline-danger"
                type="button"
                [disabled]="readOnly || offline"
                (click)="deleteCompany()">
            <i class="fa fa-trash"></i>
        </button>
    </span>
</div>




<app-modal   #companyModal
             [modalContent]="companyModalContent"
             *ngIf="showAdd||showEdit"
             (onShow)="subModalShow($event)"
             (onHide)="subModalHide($event)"
             title="Firma"
></app-modal>
<ng-template #companyModalContent>
  <app-edit-company
    [company]="_company"
    (hide)="hideCompanyModal()"
    (companyChanged)="selectCompany($event)">
  </app-edit-company>
</ng-template>



<app-modal #allCompaniesModal
           [modalContent]="allCompaniesContent"
           *ngIf="showSearch"
           (onShow)="subModalShow($event)"
           (onHide)="subModalHide($event)"
           title="Alle Firmen/Locations">
</app-modal>

<ng-template #allCompaniesContent>
  <app-all-companies
    [useHotKeys]="false"
    [defaultAction]="'select'"
    (onSubModalHide)="allCompaniesModal.hasSubModal = false"
    (onSubModalShow)="allCompaniesModal.hasSubModal = true"
    (companySelected)="selectCompany($event)"></app-all-companies>
</ng-template>
