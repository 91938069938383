import { ApplicationRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ContactsService } from '../../services/contacts.service';
import { ContactModel } from '../../models/contact.model';
import { Subscription } from 'rxjs';
import { HotkeyCommandService } from '../../services/hotkey-command.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth/auth.service';
import { DataTableDirective } from 'angular-datatables';
import { ContactTypePipe } from '../../pipes/contact-type.pipe';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'ngx-webstorage';
import { ModalComponent } from '../modals/modal/modal.component';
import CellMetaSettings = DataTables.CellMetaSettings;
import { EditContactComponent } from './edit-contact/edit-contact.component';


@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  providers: [ ContactTypePipe ]
})
export class ContactsComponent implements OnInit, OnDestroy {

  public static url = 'contacts';

  public selectedRow = 0;
  public _contact: ContactModel|{id: null} = null;

  @ViewChild(DataTableDirective)
  dataTable: DataTableDirective;

  @ViewChild(ModalComponent) contactModal: ModalComponent;
  @ViewChild(EditContactComponent) editContactComponent: EditContactComponent;


  @Output() public contactSelected = new EventEmitter<ContactModel>();
  @Input() public showSelectButton = false;

  @Output() public onSubModalHide = new EventEmitter<any>();
  @Output() public onSubModalShow = new EventEmitter<any>();


  @Input() public useHotKeys = true;

  private hotkeySubscription: Subscription;


  dtOptions: DataTables.Settings | any = {};


  constructor(private elRef: ElementRef,
              private contactsService: ContactsService,
              private applicationRef: ApplicationRef,
              private hotkeyCommandService: HotkeyCommandService,
              private localStorage: LocalStorageService,
              private auth: AuthService,
              protected contactPipe: ContactTypePipe,
              private http: HttpClient) {
  }

  ngOnInit() {

    const search = this.localStorage.retrieve('contacts-search') || '';

    this.dtOptions = {
      search: {
        search: search,

      },
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      responsive: false,
      language: {
        'sEmptyTable': 'Keine Daten in der Tabelle vorhanden',
        'sInfo': '_START_ bis _END_ von _TOTAL_ Einträgen',
        'sInfoEmpty': '0 bis 0 von 0 Einträgen',
        'sInfoFiltered': '(gefiltert von _MAX_ Einträgen)',
        'sInfoPostFix': '',
        'sInfoThousands': '.',
        'sLengthMenu': '_MENU_ Einträge anzeigen',
        'sLoadingRecords': 'Wird geladen...',
        'sProcessing': 'Bitte warten...',
        'sSearch': 'Suchen',
        'sZeroRecords': 'Keine Einträge vorhanden.',
        'oPaginate': {
          'sFirst': 'Erste',
          'sPrevious': 'Zurück',
          'sNext': 'Nächste',
          'sLast': 'Letzte'
        },
        'oAria': {
          'sSortAscending': ': aktivieren, um Spalte aufsteigend zu sortieren',
          'sSortDescending': ': aktivieren, um Spalte absteigend zu sortieren'
        },
        select: {
          rows: {
            _: '%d Zeilen ausgewählt',
            0: 'Zum Auswählen auf eine Zeile klicken',
            1: '1 Zeile ausgewählt'
          }
        }
      },
      ajax: (request, drawCallback, settings, test) => {
        const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
        this.http.post('dt/contacts', request, { headers: headers }).subscribe(
            (data: any) => {
              this.localStorage.store('contacts-search', (request.search.value) || null);
              drawCallback(data);
            },
            (err) => {
              console.error('error', err)
            }
        )

      },

      rowCallback: (row: Node, data, index: number) => {

        row.addEventListener('dblclick', () => {
          this.onDoubleClick(data);
          console.log('event double click')
        });
        row.addEventListener('click', () => {
          this.selectedRow = index;
          console.log('event click')
        });


        $(row).on('click', '.dt-button', ($event) => {
          const id: number = $($event.currentTarget).data('elem-id');
          const action: string = $($event.currentTarget).data('elem-action');
          switch (action) {
            case 'copy' :
              this.copyContact(id);
              break;
            case 'select' :
              this.selectContactById(id);
              break;
            case 'edit' :
              this.edit(id);
              break;
            case 'toggleNew' :
              this.toggleContactIsNewContact(id);
              break;
          }
        });

      },
      columns: [
        { data: 'id', visible: false, type: 'num' },
        { data: 'is_new_contact', visible: false },
        { data: 'last_name', searchable: 'true' },
        { data: 'first_name', searchable: 'true' },
        {
          data: 'artist_name_last_name', searchable: 'true', render: (data, type, row, meta) => {
            if (row.artist_name_first_name && row.artist_name_last_name) {
              return row.artist_name_first_name + ' ' + row.artist_name_last_name;
            }
            return data
          }
        },
        //  { data: 'city', searchable: "true", visible: false},
        {
          name: 'company.name',
          data: 'company.name',
          defaultContent: '',
          orderable: 'false',
          searchable: 'true'
        },
        {
          data: 'department',
          searchable: 'true'
        },
        { data: 'function', searchable: 'true' },
        {
          data: 'phone', searchable: 'true',
          render: (data: any, type: any, row: any, meta: CellMetaSettings) => {
            if (data !== '' && data !== null) {
              return '<a class="btn btn-sm btn-outline-success" href="tel:' + data + '"><i class="fa fa-phone"></i> ' + data + '</button>'
            }
            return data

          }
        },
        {
          data: 'mobile', searchable: 'true',
          render: (data: any, type: any, row: any, meta: CellMetaSettings) => {
            if (data !== '' && data !== null) {
              return '<a class="btn btn-sm btn-outline-success" href="tel:' +
                  data + '"><i class="fa fa-phone"></i> ' + data + '</button>'
            }
            return data
          }
        },
        {
          data: 'email', searchable: 'true',
          render: (data: any, type: any, row: any, meta: CellMetaSettings) => {
            if (data !== '' && data !== null) {
              return '<a class="btn btn-sm btn-outline-success" href="mailto:' +
                  data + '"><i class="fa fa-email"></i> ' + data + '</button>'
            }
            return data
          }
        },
        {
          data: 'type',
          searchable: 'true',
          render: (data: any, type: any, row: any, meta: CellMetaSettings) => {
            return this.contactPipe.transform(parseInt(data, 10))
          }
        },
        {
          data: null,
          searchable: false,
          orderable: false,
          name: 'Aktion',
          render: (data: any, type: any, row: any, meta: CellMetaSettings) => {

            let elem = `<div class="btn-group-sm btn-group-justified btn-group">`
            if (this.showSelectButton) {
              elem += '<button class="btn btn-sm btn-outline-primary dt-button" type="button" data-elem-id="' +
                  row.id + '" data-elem-action="select" ><i class="fa fa-check"></i></button>';
            }

            if (!this.showSelectButton) {
              elem += '<button class="btn btn-sm btn-outline-primary dt-button" type="button"  data-elem-id="' +
                  row.id + '" data-elem-action="edit" ><i class="fa fa-edit"></i></button>';
            }

            elem += '<button class="btn btn-sm btn-outline-primary dt-button" type="button"  data-elem-id="' +
                row.id + '" data-elem-action="copy" ><i class="fa fa-copy"></i></button>';
            if (row.is_new_contact) {
              elem += ' <button *ngIf="row.id && row.is_new_contact" class="btn btn-sm btn-outline-success ' +
                  'dt-button" type="button"  data-elem-id="' + row.id + '" data-elem-action="toggleNew" >neu</button>';
            }
            if (!row.is_new_contact) {
              elem += '<button *ngIf="row.id && !row.is_new_contact" class="btn btn-sm btn-outline-danger ' +
                  'dt-button" type="button"  data-elem-id="' + row.id + '" data-elem-action="toggleNew" >Alt</button>';
            }
            elem += `</div>`;

            return elem;


          },
          createdCell: (cell: Node, cellData: any, rowData: any, row: number, col: number) => {

          }
        },
      ],
      initComplete: () => {
        setTimeout(() => {
          $('app-contacts input[type=search]').focus();
        }, 300)


      },

    };

    this.activateHotkeys();


  }

  activateHotkeys() {

    if (this.useHotKeys && !this.hotkeySubscription) {
      this.hotkeySubscription = this.hotkeyCommandService.hotKeyEvents.subscribe(
          (command) => this.handleHotkey(command)
      )
    }

  }

  deactivateHotkeys() {
    if (this.hotkeySubscription) {
      this.hotkeySubscription.unsubscribe()
    }

  }


  ngOnDestroy() {
    this.deactivateHotkeys()
  }


  private handleHotkey(event: KeyboardEvent) {
    console.log('all contacts received hotkey', event.key);


    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        this.selectPrevRow();
        break;
      case 'ArrowDown':
        event.preventDefault();
        this.selectNextRow();
        break;
      case 'Enter':
        if (this.showSelectButton) {
          this.selectContactOnSelectedRow();
        } else {
          this.editSelectedRow();
        }
        break;
      case 'PageDown':
        event.preventDefault();
        this.nextPage();
        break;
      case 'PageUp':
        event.preventDefault();
        this.prevPage();
        break;
    }
  }


  public newContact() {
    this._contact = { id: null };
    this.contactModal.show();
  }

  protected edit(contactId: number) {
    this.contactModal.show();
    this.contactsService.get(contactId).subscribe(
        (contct: ContactModel) => {
          this._contact = contct;
        }
    );
  }

  protected copyContact(contactId) {
    this.contactModal.show();
    this.contactsService.get(contactId).subscribe(
        (contct: ContactModel) => {
          contct.id = null;
          contct.is_new_contact = true;
          this._contact = contct;
        }
    );
  }

  public editSelectedRow() {
    const contactId = this.elRef.nativeElement.querySelector('tr.table-active').getAttribute('userid');
    console.log('edit contact', contactId);
    this.edit(contactId);
  }

  public selectContactOnSelectedRow() {
    const data = this.elRef.nativeElement.querySelector('tr.table-active').getAttribute('item');
    const contact = new ContactModel()
    contact.createFromDatabase(data);
    this.selectContact(contact);
  }


  protected selectContact(contact: ContactModel) {
    this.contactSelected.emit(contact);
  }

  protected selectContactById(contactId: number) {
    this.contactsService.get(contactId).subscribe(
        (cont: ContactModel) => {
          this.contactSelected.emit(cont);
        }
    )
  }

  protected onDoubleClick(contact: ContactModel) {
    if (this.showSelectButton) {
      this.selectContact(contact);
    } else {
      this.edit(contact.id);
    }
  }

  protected selectPrevRow() {
    if (this.selectedRow > 0) {
      this.selectedRow--;
    } else {
      this.prevPage();
    }
  }


  public subModalShow() {
    this.onSubModalShow.emit(true);
    this.deactivateHotkeys()
  }

  public subModalHide() {
    this.onSubModalHide.emit(true);
    this.activateHotkeys()
  }


  public refreshPage() {
    this.dataTable.dtInstance.then(
        (inst: DataTables.Api) => {
          inst.ajax.reload(null, false)
        }
    );
  }

  private selectNextRow() {
    this.dataTable.dtInstance.then(
        (inst: DataTables.Api) => {
          if (this.selectedRow + 1 < inst.page.info().recordsDisplay && this.selectedRow + 1 < inst.page.info().recordsTotal) {
            this.selectedRow++;
          } else {
            this.nextPage();
          }
        }
    )

  }


  protected toggleContactIsNewContact(contactId: number) {

    this.contactsService.toggleNewContact(contactId).subscribe(
        (con: ContactModel) => {
          console.log('updated contact', con.is_new_contact)
          this.refreshPage()
        }
    );

  }

  private nextPage() {
    // total pages
    this.dataTable.dtInstance.then(
        (inst: DataTables.Api) => {
          if (inst.page.info().recordsDisplay === inst.page.info().length) {
            inst.settings().page(inst.settings().page() + 1)
            this.selectedRow = 0
          }
        }
    );

  }

  private prevPage() {

    // total pages
    this.dataTable.dtInstance.then(
        (inst: DataTables.Api) => {
          if (inst.page() > 1) {
            inst.settings().page(inst.settings().page() - 1)
            this.selectedRow = inst.page.info().recordsDisplay
          }
        }
    );

  }


}
