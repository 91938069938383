import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { CompanyModel } from '../models/company.model';
import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../environments/environment';
import { CompanyTypeEnum } from '../models/enums';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CompaniesService {


    constructor(private http: HttpClient,
                private localStorage: LocalStorageService) {
    }


    public searchCompany(search: string, companyType: CompanyTypeEnum = null): Observable<CompanyModel[]> {

        const args = { search: search };
        if (companyType !== null) {
            args[ 'company_type' ] = companyType;
        }
        return this.http.post('companies/search', args)
            .pipe(
              map((res: {data: any}) => {
                return res.data.companies.map((elem) => this.createFromDatabase(elem))
            }));
    }


    public getCompany(id: number): Observable<CompanyModel> {
        return this.http.get( 'companies/' + id).pipe(map(
            (response: {data: any}) => {
                return this.createFromDatabase(response.data.company);
            }
        ));
    }

    public update(companyId: number, data): Observable<CompanyModel> {

        return this.http.put( 'companies/' + companyId, data).pipe(map(
            (response: {data: any}) => {
                return this.createFromDatabase(response.data.company);
            }
        ));
    }


    public delete(companyId: number): Observable<CompanyModel> {

        return this.http.delete('companies/' + companyId).pipe(map(
            (response: {data: any}) => {
                return response.data;
            }
        ));
    }

    public create(data): Observable<CompanyModel> {
        return this.http.post('companies', data).pipe(map(
            (response: {data: any}) => {
                return this.createFromDatabase(response.data.company);
            }
        ));
    }


    private createFromDatabase(data: any): CompanyModel {
        const model = new CompanyModel();
        model.createFromDatabase(data);
        return model;
    }

}
