import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OfflineBaseService } from './offline-base.service';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map, tap } from 'rxjs/operators';
import { ContactModel } from '../../models/contact.model';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class OfflineContactService {

  constructor(
    private baseOfflineService: OfflineBaseService
  ) {}

  get(id: number): Observable<ContactModel> {

    return fromPromise(this.baseOfflineService.connection.select({
      from: 'contacts',
      where: {
        id: id
      }
    })).pipe(
      map((value: any) => {
        return value.map(this.createFromIdb)[0]
      }),
    );

  }

  public searchContacts(params: HttpParams
  ): Observable<ContactModel[]> {

    const where: any = {};
    if (params.has('managed_artist')) {
      where.managed_artist = '1';
    }
    if (params.has('with_calendar_only')) {
      where.has_calendar = '1';
    }
    if (params.has('search')) {

    }
    if (params.has('_company')) {
      where.company_id = parseInt(params.get('_company'), 10);
    }
    if (params.has('contact_type')) {
      where.type = params.get('contact_type');
    }

    const query: any = {
      from: 'contacts',
      where: where
    };
    if (params.has('limit')) {
      query.limit = parseInt(params.get('limit'), 10);

    }
    console.log('searchContacts: sending query to idb', where, JSON.stringify(where));
    return fromPromise(this.baseOfflineService.connection.select(query))
      .pipe(
        map((res: any[]) => {
            return res.map((elem) => this.createFromIdb(elem))
          }
        )
      );
  }



  private createFromIdb(data: any): ContactModel {
    data.updated_at = ContactModel.parseDateFromDatabaseUnixTimestamp(data.updated_at);
    data.created_at = ContactModel.parseDateFromDatabaseUnixTimestamp(data.created_at);
    data.type = data.type === '1';
    data.managed_artist = data.managed_artist === '1';
    data.is_new_contact = data.is_new_contact === '1';
    data.has_calendar = data.has_calendar === '1';
    data.lang = parseInt(data.lang, 10)
    const model = new ContactModel();
    model.createFromDatabase(data);
    return model;
  }


}
