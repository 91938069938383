

<nav class="navbar  navbar-inverse bg-inverse py-0" *ngIf="_artist || allArtists || _location" [class.location-cal]="_location">
    <div class="row">
      <form class="form-inline col-3 pl-0 pl-sm-1">
                <span class="btn-group">
                    <button (click)="selectPrevYear()" class="btn btn-sm small btn-outline-secondary">-12</button>
                    <button (click)="selectPrevMonthRangePeriod()" class="btn btn-sm btn-outline-secondary">-{{ monthsInView}}</button>
                    <button (click)="selectToday()" class="btn btn-sm btn-outline-secondary ">H<span
                      class="hidden-sm-down">eute</span></button>
                  <button (click)="settingModal.show()" *ngIf="allArtists" class="btn btn-sm btn-outline-secondary "><i
                      class="fa fa-cog"></i></button>

                  <span  *ngIf="loading > 0 " class="btn btn-sm btn-outline-secondary "><i
                    class="fa fa-spin fa-spinner"></i></span>
                </span>
        </form>

        <span class="navbar-text col-6 col-lg-3 text-center text-white ">
            <p class=" mb-0 text-white" *ngIf="monthsInView > 1">
                {{ monthRange?.start|amDateFormat:'MMMM YYYY'}} - {{ monthRange?.end|amDateFormat:'MMMM YYYY'}}
            </p>
            <p class=" mb-0 text-white" *ngIf="monthsInView === 1">
                {{ monthRange?.start|amDateFormat:'M/YYYY'}}
            </p>
        </span>


      <span class="navbar-text col-3 text-center text-white d-none d-lg-block">
        <p (click)="switchMonthsInView(3)" class="btn btn-sm btn-outline-secondary d-inline-block">3 Monate</p>
        <p (click)="switchMonthsInView(6)" class="btn btn-sm btn-outline-secondary d-inline-block ">6 Monate</p>
      </span>

      <form class="form-inline col-3 justify-content-end text-right pr-0 pr-sm-1">
                <span class="btn-group">
                    <button (click)="selectNextMonthRangePeriod()" class="btn btn-sm btn-outline-secondary ">+{{ monthsInView}}</button>
                    <button (click)="selectNextYear()" class="btn btn-sm btn-outline-secondary">+12</button>
                </span>
        </form>
    </div>
</nav>

<div class="container-fluid noselect p-0" *ngIf="_artist || allArtists || _location" cdkDropListGroup >

      <div class="d-flex flex-row align-items-stretch" >
        <div class="d-flex month-col flex-column align-items-stretch justify-content-between" style="overflow:hidden;flex-basis: 100px;
    flex-shrink: 1;
    flex-grow: 1;" *ngFor="let month of monthRangeForView;let last = last" [class.last]="last">
            <div class=" month-head py-0 hidden-xs-down text-center pt-1" [class.location-cal]="_location">

                {{ month.name }}

            </div>

            <div class=" py-0  day-row d-flex flex-row"
                 cdkDropList
                 (cdkDropListDropped)="onEventDrop($event, day.day)"

                 *ngFor="let day of month.days"
                 (dblclick)="onContextMenu($event, day)"


                 (contextmenu)="onContextMenu($event, day)"
                 (click)="setSelectedDay(day.day)"




                 [class.active]="day.day.isSame(selectedDay, 'day')"
                 [class.sunday]="day.sunday">

                    <div class="col-auto day p-0  mr-0 ml-0" style="max-width: 35px; padding-left:2px!important;padding-right:2px!important;">{{ day.display}} </div>




                      <span class="event float-left align-self-stretch"
                                *ngFor="let evnt of day.events"
                            cdkDrag
                            [cdkDragDisabled]="!(dragAndDropAllowed && ( day.day.isSame(evnt.start,'day') || day.day.isSame(evnt.end,'day') )) "
                            [cdkDragData]="{ eventToTransform: evnt, draggedDate: day.day}"
                            [class.dragable]="dragAndDropAllowed  && ( day.day.isSame(evnt.start,'day')  || day.day.isSame(evnt.end,'day')  )  "

                            (dblclick)="openEvent(evnt,$event)"
                            (tap)="openEvent(evnt, null)"
                            [class.request]="evnt.event_group?.state === 1 && !evnt.cancelled"
                            [class.confirmed]="evnt.event_group?.state === 2 && !evnt.cancelled"
                            [class.contract]="evnt.event_group?.state === 3 && !evnt.cancelled"
                            [class.invisible-for-artist]="evnt.event_group?.visible_for_artist === false"
                            [class.cancelled]="evnt.event_group?.state === 4 || evnt.cancelled"
                            [class.newprod]="evnt.event_group?.is_new_production"
                            [class.pull-right]="evnt.type === 4 "


                            >

                                <ng-container  *ngIf="allArtists">
                                      {{ evnt|event_getDisplayName }}
                                </ng-container>
                                <ng-container [ngSwitch]="evnt.type" *ngIf="!allArtists">
                                    <!-- simple event -->
                                    <ng-container *ngSwitchCase="5">{{ evnt.name }}</ng-container >

                                    <!-- travel event -->
                                    <ng-container *ngSwitchCase="4">
                                        <span class="fa fa-plane fa-2x hidden-md-up" *ngIf="evnt.travel_type !==3"></span>
                                        <span class="fa fa-plane hidden-sm-down" *ngIf="evnt.travel_type !==3"></span>
                                        <span class="fa fa-home fa-2x hidden-md-up" *ngIf="evnt.travel_type ===3"></span>
                                        <span class="fa fa-home hidden-sm-down" *ngIf="evnt.travel_type ===3"></span>

                                    </ng-container >

                                    <!-- other events -->
                                    <ng-container *ngSwitchDefault >

                                      <span class="eventName ">
                                        {{ evnt|event_getDisplayName}}
                                      </span>

                                    </ng-container >

                                </ng-container>



                            </span>



            </div>

            <div  class=" py-0 day-row d-flex flex-row" *ngFor="let i of ' '.repeat(31 - month.days.length).split('')" ></div>

        </div>
      </div>

      <context-menu #contextMenu>
        <ng-template *ngFor="let action of contextMenuActions"
                     contextMenuItem
                     [passive]="true"
                     let-item
                     [visible]="action.visible"
                     [enabled]="action.enabled"
                     [divider]="action.divider"

        >
          <span  [style.text-decoration]="(action.strikeThrough()|| false ) ? 'line-through': 'none' " (click)="action.click($event)">{{ action.html(event) }}</span>
        </ng-template>
      </context-menu>
</div >

<div class="container-fluid">
    <div class="row"  *ngIf="!_artist && !allArtists && !_location">
        <div class="col-6 push-3 mt-5 text-center">
            <h2>Bitte wählen Sie einen Künstler</h2>
            <p><small>Über die Suche/Auswahl oben rechts</small></p>
        </div>


    </div>
</div>



<app-modal
  #eventEditorModal
  [modalContent]="eET"
  [modalFooter]="modalFooter"
  (onShow)="deactivateHotkeys()"
  (onHide)="activateHotkeys()"
>

</app-modal>
<ng-template #modalFooter></ng-template>
<ng-template #eET>
  <app-event-editor
    [event]="eventInEditor"
    [defaultDate]="selectedDay"
    (hide)="eventEditorModal.hide()"
    (eventCreated)="addEvent($event, true)"
    (eventUpdated)="updateEvent($event)"
    (eventDeleted)="deleteEvent($event)"
  ></app-event-editor>
</ng-template>



<app-simple-event-modal
        #simpleEventModal
        [(defaultDate)]="selectedDay"
        (onShow)="deactivateHotkeys()"
        (onHide)="activateHotkeys()"
        (eventUpdated)="updateEvent($event)"
        (eventDeleted)="deleteEvent($event)"
        (eventCreated)="addEvent($event)"
></app-simple-event-modal>



<app-read-only-modal
  #readOnlyModal
  (onShow)="deactivateHotkeys()"
  (onHide)="activateHotkeys()"
></app-read-only-modal>




<app-modal
    #settingModal
    [modalContent]="selectArtists"
    [modalFooter]="modalFooterSelectArtists"
    (onShow)="deactivateHotkeys()"
    (onHide)="activateHotkeys()"
>

</app-modal>
<ng-template #modalFooterSelectArtists></ng-template>
<ng-template #selectArtists>
    <div class="modal-header">
        <div class="modal-title">
            <h4 class="pb-0 mb-0">
                Ansicht konfigurieren
            </h4>
        </div>

        <button type="button" class=" pull-right btn btn-outline-primary" (click)="updateConfig();settingModal.hide()">
          Schließen
        </button>
      </div>
      <div class="modal-body" *ngIf="config">

        <div class="row">
            <div class="col-8">
                <h5>Alle Kalender</h5>
            </div>
            <div class="col-4">
                <ui-switch [(ngModel)]="config.all"></ui-switch>
            </div>
        </div>

        <div class="row" *ngFor="let contact of config.contacts, let index = index">
            <div class="col-8">
                <p>{{contact.first_name}} {{contact.last_name}}</p>
            </div>
            <div class="col-4"  >
                <ui-switch *ngIf="config.all == true" disabled checked ></ui-switch>
                <ui-switch *ngIf="config.all == false"  [(ngModel)]="config.contacts[index].checked" ></ui-switch>
            </div>
        </div>

    </div>
</ng-template>


