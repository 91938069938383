import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TravelGroupService } from '../../../services/travel-group.service';
import { TravelGroup } from '../../../models/travel-group';
import { FileModel } from '../../../models/file.model';
import { UserRoleArtist } from '../../../models/enums';
import { AuthService } from '../../../services/auth/auth.service';
import { debounceTime, map } from 'rxjs/operators';
import { isArray } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { OfflineModeService } from '../../../services/offline/offline-mode.service';


@Component({
  selector: 'app-travel-group',
  templateUrl: './travel-group.component.html'
})
export class TravelGroupComponent implements OnInit {


  public readOnly = false;
  public offline = false;
  public _travel_group: TravelGroup;

  @Input('travel_group')
  set travel_group(travel_group: TravelGroup) {
    this.travel_groupService.get(travel_group.id).subscribe(
      (tg: TravelGroup) => {
        this.form.get('artist_id').setValue(tg.artist_id);
        this.loadGroup(tg);
        this.travel_groupLoaded = true;
      }
    )
  }

  @Output() public onTravelGroupEdited = new EventEmitter<TravelGroup>();
  @Output() public onTravelGroupSaveAndClose = new EventEmitter<TravelGroup>();
  @Output() public isDirty = new EventEmitter<boolean>();
  @Input() public requestDirtyCheck: Observable<void>;

  public form: FormGroup;
  public submitted = false;


  public travel_groupLoaded = false;

  constructor(private travel_groupService: TravelGroupService,
              private fb: FormBuilder,
              private auth: AuthService,
              private cdr: ChangeDetectorRef,
              private offlineModeService: OfflineModeService
  ) {


    if (this.auth.getUser().role === UserRoleArtist) {
      console.log('user is artist => read onyl!');
      this.readOnly = true;
    }

    this.form = this.fb.group({
      id: [ { value: null, disabled: this.readOnly }, [] ],
      artist_id: [ { value: null, disabled: this.readOnly }, [ Validators.required ] ], // will be setted in ngOnInit
      notes_int: [ { value: null, disabled: this.readOnly }, [] ],
      notes_pub: [ { value: null, disabled: this.readOnly }, [] ],
      events: [ { value: null, disabled: this.readOnly }, [] ],
      attachments: [ { value: null, disabled: this.readOnly }, [] ]
    }, { updateOn: 'blur' });

    this.offlineModeService.offlineStatusChange$.subscribe(
      (isOffline) => {
        this.offline = isOffline;
        if (this.form && this.readOnly === false && isOffline === true) {
          this.form.disable()
        }
        if (this.form && this.readOnly === false && isOffline === false) {
          this.form.enable();
        }
      }
    );
    this.offline = this.offlineModeService.offline.getValue();

    if (this.offline) {
      this.form.disable();
    }

    this.form.statusChanges
      .pipe(
        debounceTime(200),
        map((event) => this.form.dirty),
      )
      .subscribe(
        () => {
          this.isDirty.emit(this.form.dirty);
        }
      );

  }


  ngOnInit(): void {
    this.requestDirtyCheck.subscribe(
      () => {
        this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        this.isDirty.emit(this.form.dirty);
      }
    );

  }

  public save(closeAfterSave = false) {
    this.submitted = true;
    if (closeAfterSave) {
      this.onTravelGroupSaveAndClose.emit(this.form.value);
    } else {
      this.onTravelGroupEdited.emit(this.form.value);
    }
  }

  private loadGroup(group: TravelGroup) {
    this.resetForm();
    this._travel_group = group;
    this.submitted = false;

    this.form.get('id').setValue(group.id);
    this.form.get('artist_id').setValue(group.artist_id);

    if (group.notes_int) {
      this.form.get('notes_int').setValue(group.notes_int);
    }

    if (group.notes_pub) {
      this.form.get('notes_pub').setValue(group.notes_pub);
    }

    if (group.events) {
      this.form.get('events').setValue(group.events);
    }
    if (group.attachments) {
      this.form.get('attachments').setValue(group.attachments);
    }

    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.cdr.detectChanges();
  }

  private resetForm() {
    this.form.clearValidators();
    this.form.clearAsyncValidators();

    this.form.reset({
      id: null,
      artist_id: null,
      notes_int: null,
      notes_pub: null,
      events: null,
      attachments: [],
    }, { onlySelf: false, emitEvent: false });
    this.form.markAsPristine();
    this.form.markAsUntouched();

  }

  public uploadFinished(file: any) {
    if (!this.form.get('id').value) {
      throw new Error('can not upload file without an travel group')
    }

    let fileValue = this.form.get('attachments').value;
    if (!isArray(fileValue)) {
      fileValue = [];
    }
    fileValue.push(file[ 1 ]);
    this.form.get('attachments').setValue(fileValue);
    this.save()
  }


  public removeFile(file: FileModel) {
    const fileValue = this.form.get('attachments').value;
    // fileValue.push(fileId);
    const idx = fileValue.indexOf(file.uuid);
    fileValue.splice(idx, 1);
    this.form.get('attachments').setValue(fileValue);
    this.save()

  }

}
