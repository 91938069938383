import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { EventEditorComponent } from './event-editor/event-editor.component';
import * as moment from 'moment';
import { Moment } from 'moment';
import { EventModel } from '../../../models/event.model';
import { EventService } from '../../../services/event.service';
import { AuthService } from '../../../services/auth/auth.service';
import { EventListComponent } from './event-list/event-list.component';
import { EventGroupComponent } from './event-group/event-group.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventTypeEnum, UserRoleArtist } from '../../../models/enums';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { OfflineModeService } from '../../../services/offline/offline-mode.service';
import { AutosizeDirective } from 'ngx-autosize';


@Component({
  selector: 'app-simple-event-modal',
  templateUrl: './simple-event-modal.component.html'
})
export class SimpleEventModalComponent implements OnInit {

  @Input() public defaultDate: moment.Moment;

  @Input() public event: EventModel;

  @ViewChild('modal') public modal: ModalDirective;
  @Output() public onHide = new EventEmitter<any>();
  @Output() public onShow = new EventEmitter<any>();
  @Output() public eventUpdated = new EventEmitter<EventModel>();
  @Output() public eventDeleted = new EventEmitter<number>();
  @Output() public eventCreated = new EventEmitter<EventModel>();


  // is a submodal opened
  public hasSubModal = false;
  public readOnly = false;
  public offline = false;

  // prevent double creating
  public isCreating = false;
  public isDeleting = false;

  public eventForm: FormGroup;
  public _event: EventModel;
  public start: Moment = null;
  public end: Moment = null;


  constructor(private eventService: EventService,
              private fb: FormBuilder,
              private auth: AuthService,
              private offlineModeService: OfflineModeService
  ) {
    this.offline = this.offlineModeService.offline.getValue();

    if (this.auth.getUser().role === UserRoleArtist) {
      console.log('user is artist => read onyl!');
      this.readOnly = true;
    }

    this.eventForm = this.fb.group({
      id: [ null, [] ],
      artist_id: [ null, [ Validators.required ] ],
      type: [ EventTypeEnum.simpleEvent, { updateOn: 'change', validators: [ Validators.required ] } ],
      start: [ this.formatForDatepicker(moment()), [ Validators.required ] ],
      end: [ this.formatForDatepicker(moment()), [ Validators.required ] ],
      notes: [ null, [] ],
      name: [ null, [ Validators.required ] ],
      use_name_as_title: [ true ],
      cancelled: [ false, [] ],
    }, { updateOn: 'blur' });

    if (this.offline) {
      this.eventForm.disable();
    }

    this.offlineModeService.offlineStatusChange$.subscribe(
      (isOffline) => {
        this.offline = isOffline;
        if (this.eventForm && this.readOnly === false && isOffline === true) {
          this.eventForm.disable()
        }
        if (this.eventForm && this.readOnly === false && isOffline === false) {
          this.eventForm.enable();
        }
      }
    );

    this.eventForm.valueChanges
      .pipe(
        debounceTime(100),
        // strip of the notes
        map((values) => {
          return { end: values.end, start: values.start, type: values.type };
        }),
        distinctUntilChanged()
      )

      .subscribe(
        (values) => {

          if (this.datePickerToMoment(values.start) > this.datePickerToMoment(values.end)) {
            this.eventForm.get('end').setValue(this.eventForm.get('start').value);
          }
        }
      );

  }

  public datePickerToMoment(value: { day: number, month: number, year: number }): Moment {
    const date: Moment = moment();
    date.year(value.year);
    date.month(value.month);
    date.date(value.day);
    date.hours(0);
    date.minutes(0);
    date.seconds(0);
    return date;

  }

  ngOnInit() {

    this.modal.onShow.subscribe(
      ($event) => {
        this.isDeleting = false;
        this.onShow.emit($event);
      }
    );
    this.modal.onHide.subscribe(
      ($event) => {

        if (this.eventForm.touched === true) {
          const doAction = confirm('Möchten Sie die Änderungen speichern?');
          if (doAction === true) {
            this.save();
          }
        }

        this.event = null;
        this.onHide.emit($event);
      }
    );
  }

  // called from modals
  public openEvent(event: EventModel) {
    this.loadEvent(event);
    this.modal.show()
  }


  private loadEvent(event: EventModel) {

    this._event = event;
    this.eventForm.reset({
      id: event.id,
      type: event.type,
      cancelled: event.cancelled,
      notes: event.notes,
      artist_id: event.artist_id,
      use_name_as_title: true,
      start: this.formatForDatepicker(event.start),
      end: this.formatForDatepicker(event.end),
      name: event.name
    }, { onlySelf: true, emitEvent: false });

  }


  public formatForDatepicker(value: Moment) {
    return {
      day: parseInt(value.format('DD'), 10),
      month: parseInt(value.format('M'), 10),
      year: parseInt(value.format('YYYY'), 10),
    }
  }


  public createEvent(day: moment.Moment, artist_id: number) {
    this.defaultDate = day;
    this.resetForm(artist_id);
    this.modal.show();
  }

  public deleteEvent() {
    const doAction = confirm('Möchten Sie diesen Termin wirklich löschen?');
    if (doAction === true) {
      this.isDeleting = true;
      this.eventService.deleteEvent(this.eventForm.value).subscribe(
        (result) => {
          console.log('event deleted', this.eventForm.value.id);
          this.eventDeleted.emit(this.eventForm.value.id);
          this.event = null;
          this.modal.hide();
        }
      );

    }
  }

  // called from button
  public save() {

    // speichere in db
    if (this.eventForm.invalid) {
      console.log(this.eventForm.value);
      return;
    }
    const event = new EventModel();
    event.createFromFormModel(this.eventForm);

    if (this.eventForm.get('id').value) {
      console.log('simple event gets updated', event);
      this.eventService.update(event).subscribe(
        (ev: EventModel) => {
          this.eventUpdated.emit(ev)
          this.loadEvent(ev);
          this.modal.hide();
        }
      );


    } else {
      // Prevent double creating
      if (this.isCreating === true) {
        return;
      }
      this.isCreating = true;
      console.log('simple event gets created', event);
      // lade das event neu:
      this.eventService.create(event).subscribe(
        (eventModel: EventModel) => {
          this.event = eventModel;
          this.isCreating = false;
          this.eventCreated.emit(eventModel);
          this.loadEvent(eventModel);
          this.modal.hide();
        }
      );
    }
  }

  public markDirty(fieldName: string, event) {
    this.eventForm.get(fieldName).markAsDirty();
  }


  private resetForm(artist_id) {
    this.eventForm.clearValidators();
    this.eventForm.clearAsyncValidators();
    this.eventForm.reset({
      id: null,
      type: EventTypeEnum.simpleEvent,
      start: this.formatForDatepicker(this.defaultDate),
      end: this.formatForDatepicker(this.defaultDate),
      notes: null,
      artist_id: artist_id,
      cancelled: false,
      name: null,
      use_name_as_title: true
    }, { onlySelf: false, emitEvent: false });
  }
}
