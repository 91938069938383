import { Injectable, Injector } from '@angular/core';
import { EventGroup } from '../models/event-group';


import { EventService } from './event.service';
import * as moment from 'moment';

import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BaseModel } from '../models/base.model';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TravelGroupService } from './travel-group.service';
import { OfflineEventGroupService } from './offline/offline-event-group.service';
import { OfflineModeService } from './offline/offline-mode.service';

@Injectable()
export class EventGroupService {

    private tableName = 'eventgroups';
    private eventService: EventService;

    private allEventGroupsObservable: Observable<EventGroup[]>;


    constructor(private http: HttpClient,
                private offlineModeService: OfflineModeService,
                private offlineEventGroupService: OfflineEventGroupService,
                injector: Injector) {

      setTimeout(() => {
        this.eventService = injector.get(EventService);
      });
    }

    public all(): Observable<EventGroup[]> {
        this.allEventGroupsObservable = this.http.get( this.tableName)
          .pipe(map(
            (response: any) => {
                return response.map(group => this.createFromDatabase(group)
                )
            }
        ));
        return this.allEventGroupsObservable
    }


    public get(id: number): Observable<EventGroup> {
        return this.http.get( this.tableName + '/' + id,
          {headers: {'also-from-idb' : 'true'}})
            .pipe(
              // tap((res) => {console.log('got event group from api', res)}),
              map((response: any) => this.createFromDatabase(response.data.eventGroup)),

              catchError(error => {
                if (error.status === 0 || error.status === 504 && this.offlineModeService.isOffline()) {
                  return this.offlineEventGroupService.get(id);
                }
                return throwError(error)

              })
            )

    }


    public update(event_group: EventGroup): Observable<EventGroup> {
        return this.http
            .put( this.tableName + '/' + event_group.id, this.parseForDatabase(event_group))
            .pipe(map((response: any) => this.createFromDatabase(response.data.eventGroup)));
    }

    public deleteEventGroup(event_group_id: number) {

          return this.http.delete( this.tableName + '/' + event_group_id)


    }


    private createFromDatabase(data: any): EventGroup {
        const model = new EventGroup();
        model.createFromDatabase(data);
        return model;
    }

    private parseForDatabase(eventGroup: any): any {

        let elem = { ...eventGroup };
        Object.keys(elem).forEach((key) => {
            if (elem[ key ] && moment.isMoment(elem[ key ])) {
                elem[ key ] = BaseModel.parseDateForDatabase(elem[ key ]);
                if (elem[ key ] === false) {
                    elem[ key ] = null;
                }
            }
        });

      // remove circular structures
        elem = this.removeCircularReferences(elem);
        return elem;
    }

    private removeCircularReferences(v) {
        const cache = new Map();
        return JSON.parse(JSON.stringify(v, function (key, value) {
          if (typeof value === 'object' && value !== null) {
            if (cache.get(value)) {
              // Circular reference found, discard key
              return;
            }
            // Store value in our map
            cache.set(value, true);
        }
        return value;
      }));
    };
}
