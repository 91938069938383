import {
    AfterViewInit,
    ApplicationRef,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Work } from '../../models/work.model';
import { Role } from '../../models/role.model';
import { AuthService } from '../../services/auth/auth.service';
import { WorkService } from '../../services/work.service';
import { RoleService } from '../../services/role.service';
import { WorkModalComponent } from '../../pages/modals/work-modal/work-modal.component';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { OfflineModeService } from '../../services/offline/offline-mode.service';


@Component({
    changeDetection: ChangeDetectionStrategy.Default,
    selector: 'app-select-work-role',
    templateUrl: './select-work-role.component.html',
})
export class SelectWorkRoleComponent implements OnInit, AfterViewInit {

    @Input() public disabled = false;
    public offline = false;

    @Input()
    set workKey(workId: number) {
        if (workId !== null) {
            this.isLoadingWork = true;
            this.workService.get(workId).subscribe(
                (work: Work) => {
                    this.work = work;
                    this.queryWork = work.short_name
                    console.log('set queryWork', work)
                    this.isLoadingWork = false;
                },
                () => {
                    this.isLoadingWork = false
                }
            )
        }
    }

    @Input()
    set roleKey(roleId: number) {
        if (roleId !== null) {
            this.isLoadingRole = true;
            this.roleService.get(roleId).subscribe(
                (role: Role) => {
                    this.role = role;
                    this.work = role.work;
                    this.queryRole = role.name;
                    this.queryWork = role.work.short_name;
                    this.isLoadingRole = false
                },
                () => {
                    this.isLoadingRole = false
                }
            )
        }

    }


    @Output('workSelected') public workSelected = new EventEmitter<Work>();
    @Output('roleSelected') public roleSelected = new EventEmitter<Role>();
    @Output('onSubModalHide') public onSubModalHide = new EventEmitter<any>();
    @Output('onSubModalShow') public onSubModalShow = new EventEmitter<any>();


    @ViewChild('workModal') workModal: WorkModalComponent;
    @ViewChild('workModalView') workModalView;

    public work: Work;
    public role: Role;

    public filteredRoles: Role[] = [];
    public hasSubModal = false;


    public searchResultsWork: Observable<Work[]>;
    public queryWork: string;

    public searchResultsRole: Observable<Role[]>;
    public queryRole: string;

    public isLoadingWork = false;
    public isLoadingRole = false;

    constructor(private auth: AuthService,
                private workService: WorkService,
                private roleService: RoleService,
                private applicationRef: ApplicationRef,
                private offlineModeService: OfflineModeService
    ) {

        this.offlineModeService.offlineStatusChange$.subscribe(
            (isOffline) => {
                this.offline = isOffline;
            }
        );
        this.offline = this.offlineModeService.offline.getValue();
        this.searchResultsWork = new Observable((observer: any) => {
            // Runs on every search
            observer.next(this.queryWork);

        }).pipe(
            switchMap((keyword: string) => {
                console.log('search work', keyword);
                return this.workService.searchWork(keyword)
            }));

        this.searchResultsRole = new Observable((observer: any) => {
            // Runs on every search
            observer.next(this.queryRole);

        }).pipe(switchMap((keyword: string) => {
            console.log('search role', keyword);
            return this.roleService.searchRole(keyword, this.work)
        }));


    }


    // needed to no nest bootstrap modals
    ngAfterViewInit() {
        this.getRootViewContainerRef().createEmbeddedView(this.workModalView);

    }

    // needed to no nest bootstrap modals
    getRootViewContainerRef(): ViewContainerRef {
        const appInstance = this.applicationRef.components[ 0 ].instance;
        if (!appInstance.viewContainerRef) {
            const appName = this.applicationRef.componentTypes[ 0 ].name;
            throw new Error(`Missing 'applicationRef' declaration in ${appName} constructor`);
        }
        return appInstance.viewContainerRef;
    }

    ngOnInit() {


    }

    public onWorkSelected(work: Work) {
        this.workSelected.emit(work);
        this.work = work;
        this.filteredRoles = [];
        this.role = null;

    }

    public onWorkEdited(work: Work) {
        this.workSelected.emit(work)
    }


    public onRoleSelected(role: Role) {
        console.log('onRoleSelected', role)
        this.roleSelected.emit(role)
    }


    public subModalShow($event) {
        this.hasSubModal = true;
        this.onSubModalShow.emit($event)
    }

    public subModalHide($event) {
        this.hasSubModal = false;
        this.onSubModalHide.emit($event)
    }


    public editWork() {
        this.workModal.show(this.work.id);
    }

    public addWork() {
        this.workModal.show(null)
    }

    public deleteWorkAndRole() {
        this.work =
            this.role =
                this.queryWork =
                    this.queryRole = null;
        this.workSelected.next(null);
        this.roleSelected.next(null);
    }


}
