import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class EndpointInterceptor implements HttpInterceptor {


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.url.startsWith('http')) {

            let endpoint = 'https://' + window.location.host + '/api/';
            if (window.location.host === 'localhost:4200') {
                endpoint = 'https://artist-calendar-backend.test/api/';
            }

            req = req.clone({
                url: endpoint + req.url,
            });
        }
        return next.handle(req);
    }

}
