import { Injectable } from '@angular/core';

import { AuthHttp } from 'angular2-jwt';
import { HttpClient } from '@angular/common/http';
import { FileModel } from '../models/file.model';
import { environment } from '../../environments/environment';

@Injectable()
export class FileDatabaseService {

    constructor(private http: HttpClient) {
    }



  public deleteFile(file: FileModel) {
      return this.http.delete('attachments/' + file.uuid)
  }




}
