export enum EventTypeEnum {
    rehearsal = 1,
    performanceOrConcert = 2,
    premiere = 3,
    travel = 4,
    simpleEvent = 5,
    performance = 6,
    concert = 7,
}

export enum EventGroupStateEnum {
    request = 1,
    confirmed = 2,
    contract = 3,
    cancelled = 4

}

export const UserRoleAdmin = 'admin';
export const UserRoleArtist = 'artist';
export const UserRoleAgent = 'agent';
export const UserRoleLogistic = 'logistic';
export const UserRoleNone = 'none';

export enum CompanyTypeEnum {
    hotel = 1,
    location = 2,
    other = 3
}


export enum LangEnum {
    de = '1',
    en = '2',
    fr = '3',
    it = '4',
    ru = '5'
}


export enum ContactTypeEnum {
    artist = 1,
    location = 2,
    hotel = 3,
    agent = 4,
    logistic = 5,
    other = 6,
    director = 7,
    conductor = 8,
}

export enum CurrencyEnum {
    eur = '€',
    usd = '$',
    jpy = '¥',
    gbp = '£',
    aud = 'AU$',
    chf = 'CHF',
    cad = 'CAD',
    cny = 'CNY',
    // could add more please use ISO 4217 codes
}

export enum CostTypeEnum {
    limit = 1,
    fix = 2,
    payedByEventOrganizer = 3,
    willNotBePayed = 4,
    seeNotes = 5
}


export enum SyncStateEnum {
  new = 1,
  creating_backup = 2,
  created_backup = 3,
  error = 3,

}

export enum TravelTypeEnum {
    outbound = 1,
    inbound = 2,
    hotel = 3,
}
