import { Injector, NgModule } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { UserModel } from '../../models/user.model';
import { CommonModule } from '@angular/common';

import { environment } from '../../../environments/environment';
import { AppUserService } from '../app-user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { OfflineModeService } from '../offline/offline-mode.service';


@NgModule({
    imports: [
        CommonModule
    ],
    exports: [],
    declarations: []
})
export class AuthService {

    constructor(private localStorage: LocalStorageService,
                private httpClient: HttpClient,
                private userService: AppUserService,
                private inj: Injector,
                private offlineModeService: OfflineModeService,
                private jwtHelperService: JwtHelperService) {
    }

    getUser(): UserModel {
        return this.localStorage.retrieve('user');
    }

    getToken(): string {
        return this.localStorage.retrieve('token');
    }

    isLoggedIn(): boolean {
        return this.localStorage.retrieve('token') !== null;
    }

    loginWithToken(email: string, token: string): Observable<boolean> {
        this.storeToken(token);
        return this.userService.getMe()
            .pipe(
                map((user: UserModel) => {
                    this.localStorage.store('user', user);
                    return true;
                })
            )
    }

    login(email: string, password: string): Observable<boolean> {

        return this.httpClient
            .post('login',
                { email: email, password: password })
            .pipe(
                catchError((err) => {
                    console.log(err);
                    return of(false)
                }),
                map((response: any) => {
                        // login successful if there's a jwt token in the response
                        const token = response && response.token;
                        if (token) {
                            // store  jwt token in local storage to keep user logged in between page refreshes
                            this.storeToken(token);
                            this.userService.getMe().subscribe(
                                (user: UserModel) => {
                                    this.localStorage.store('user', user);
                                }
                            );
                            return true;
                        } else {
                            // return false to indicate failed login
                            console.log('token invalid');
                            return false
                        }
                    }
                )
            );

    }

    public refreshTokenIfNeeded() {
        const tokenExpiration = this.jwtHelperService.getTokenExpirationDate(this.getToken());
        if (moment(tokenExpiration) > moment('-5 minutes')) {
            console.log('refreshToken');
            this.refreshToken();
        }
    }

    private storeToken(token: string): void {
        this.localStorage.store('token', token);
        this.localStorage.store('refresh_token', token);
    }

    private getRefreshToken(): string {
        return this.localStorage.retrieve('refresh_token');
    }


    public refreshToken() {

        const refreshAuth = this.getRefreshToken(); // get refresh token from storage
        return this.httpClient.post('refresh_token', null, {
            headers: new HttpHeaders().set('refreshAuthorization', refreshAuth),
        })
            .pipe(
                map((refreshResponse: any) => {
                    const token = refreshResponse && refreshResponse.token;
                    if (token) {
                        // store  jwt token in local storage to keep user logged in between page refreshes
                        this.storeToken(token);
                    }
                    // add token to storage
                    return token; // return the new authorization token
                }),
                catchError(error => {
                    if (error.status === 401) {
                        // logout users, redirect to login page
                        this.logout(true);

                        return Observable.throw(error);
                    }
                })
            )
            ;

    }

    public logout(redirect: boolean = false) {
        this.clearCache().subscribe(
            (cleared) => {
                if (cleared) {
                    this.localStorage.clear();
                    if (redirect === true) {
                        const router: Router = this.inj.get(Router);
                        router.navigate([ '/login' ])
                    }
                } else {
                    alert('Konnte Daten nicht löschen. Bitte Seite neu Laden und erneut versuchen. Notfalls Browser Cache löschen')
                }
            },
            (err) => {
                console.error(err);
                alert('Konnte Daten nicht löschen. Bitte Seite neu Laden und erneut versuchen. Notfalls Browser Cache löschen')
            }
        );
    }


    public checkInvitationCode(code: string) {
        return this.httpClient.get('check-invitation-code/' + code)
    }

    public createAccountWithCode(email: string, password: string, code: string) {
        return this.httpClient.post('use-invitation/' + code,
            {
                email: email,
                password: password,
                code: code,
            })
    }

    public requestNewPasswort(email: string) {
        return this.httpClient.post('forgot-password',
            {
                email: email
            })
    }
    public setPassword(
        email: string,
        password: string,
        password_confirmation: string,
        token: string,
    ): Observable<any> {
        return this.httpClient.post('set-password',
            {
                email: email,
                password: password,
                password_confirmation: password_confirmation,
                token: token,
            })
            .pipe(
                tap((en) => console.log(en))
            )
    }

    private clearCache(): Observable<boolean> {
        return this.offlineModeService.dropDatabase()
            .pipe(
                map((cleared) => {
                    if (cleared) {
                        this.localStorage.clear();
                        return true;
                    }
                    return false;
                })
            )

    }

}
