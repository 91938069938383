import { Injectable, Injector } from '@angular/core';

import { Role } from '../models/role.model';
import { WorkService } from './work.service';

import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../environments/environment';
import { Work } from '../models/work.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class RoleService {
    private workService: WorkService;

    constructor(private injector: Injector,
                private http: HttpClient) {
        setTimeout(() => this.workService = injector.get(WorkService));
    }


    public all(): Observable<Role[]> {
        return this.http.get('roles')
            .pipe(map(
                (res: {data: any}) => {
                    return res.data.roles.map(this.createFromDatabase);
                }
            ));
    }

    public searchRole(search: string, work: Work): Observable<Role[]> {
        const args = { search: search };
        if (work !== null) {
            args[ 'work_id' ] = work.id;
        }
        return this.http.post('roles/search', args)
            .pipe(map(
                (res: {data: any}) => {
                    return res.data.roles.map(this.createFromDatabase);
                }));
    }

    public get(roleId): Observable<Role> {
        return this.http.get('roles/' + roleId)
            .pipe(map((res: {data: any}) => this.createFromDatabase(res.data.role)));
    }

    public create(data: any): Observable<Role> {
        return this.http.post('roles', data)
            .pipe(map((res: {data: any}) => this.createFromDatabase(res.data.role)));
    }

    public update(role) {
        return this.http.put('roles/' + role.id, role)
            .pipe(map((res: {data: any}) => this.createFromDatabase(res.data.role)));
    }

    public deleteRole(role: Role): Observable<any> {
        return this.http.delete('roles/' + role.id)
    }

    private createFromDatabase(data: any): Role {
        const model = new Role();
        model.createFromDatabase(data);
        return model;
    }
}
