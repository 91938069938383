import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EventGroupService } from '../../../services/event-group.service';
import { AuthService } from '../../../services/auth/auth.service';
import {
  CompanyTypeEnum,
  ContactTypeEnum,
  CostTypeEnum,
  CurrencyEnum,
  EventGroupStateEnum,
  UserRoleArtist,
  UserRoleLogistic
} from '../../../models/enums';
import { ContactModel } from '../../../models/contact.model';
import { CompanyModel } from '../../../models/company.model';
import { Work } from '../../../models/work.model';
import { Role } from '../../../models/role.model';

import { FileModel } from '../../../models/file.model';
import { EventService } from '../../../services/event.service';
import { EventGroup } from '../../../models/event-group';
import { isArray } from 'util';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { OfflineModeService } from '../../../services/offline/offline-mode.service';
import { AbstractControl } from '@angular/forms/src/model';
import { ValidationErrors } from '@angular/forms/src/directives/validators';


@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'app-event-group',
  templateUrl: './event-group.component.html'
})
export class EventGroupComponent implements OnInit {


  @Input('event_group')
  set event_group(event_group: EventGroup) {
    this.loadGroup(event_group);
    this.form.get('artist_id').setValue(event_group.artist_id, { emit: false });
    this._event_groupLoaded = true;
    this._event_group = event_group;
  }

  @Output() public onEventGroupEdited = new EventEmitter<EventGroup>();
  @Output() public onEventGroupSaveAndClose = new EventEmitter<EventGroup>();
  @Output() public onEventGroupDeleted = new EventEmitter<number>();
  @Output() public onModalHide = new EventEmitter<any>();
  @Output() public onModalShow = new EventEmitter<any>();
  @Output() public isDirty = new EventEmitter<boolean>();
  @Input() public requestDirtyCheck: Observable<void>;

  public form: FormGroup;
  public _event_group: EventGroup;
  public submitted = false;

  public companyTypeFilterForCompanySelector = CompanyTypeEnum.location;
  public contactTypeFilterForSelectContactComponent = ContactTypeEnum.location;


  public filterContactsToCompany: number = null;

  public isSaving = false;

  public event_groupStates = [
    EventGroupStateEnum.request,
    EventGroupStateEnum.confirmed,
    EventGroupStateEnum.contract,
    EventGroupStateEnum.cancelled,
  ];

  public currencies = [
    CurrencyEnum.eur,
    CurrencyEnum.chf,
    CurrencyEnum.usd,
    CurrencyEnum.jpy,
    CurrencyEnum.gbp,
    CurrencyEnum.aud,
    CurrencyEnum.cad,
    CurrencyEnum.cny,
  ];
  public costTypes = [
    CostTypeEnum.limit,
    CostTypeEnum.fix,
    CostTypeEnum.payedByEventOrganizer,
    CostTypeEnum.willNotBePayed,
    CostTypeEnum.seeNotes,
  ];

  public readOnly: boolean;
  public offline = false;

  public _event_groupLoaded = false;

  public contractFields =
      [
        'contract_requested',
        'contract_received',
        'contract_sent_to_artist',
        'contract_signed_by_artist',
        'contract_sent_to_location',
        'contract_signed_by_location',
        'contract_invoice_sent',
      ];

  constructor(private event_groupService: EventGroupService,
              private eventService: EventService,
              private authService: AuthService,
              private fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              private offlineModeService: OfflineModeService
  ) {


    if (this.authService.getUser().role === (UserRoleArtist || UserRoleLogistic)) {
      this.readOnly = true;
    } else {
      this.readOnly = false;
    }
    this.filterContactsToCompany = null;
    this.form = this.fb.group({
      id: [ null, [] ],
      artist_id: [ null, [ Validators.required ] ], // will be setted in ngOnInit
      location_id: [ null, [ Validators.required ] ],
      location_contact_id: [ null, [ Validators.required ] ],
      location_contact_for_artist_id: [ null, [] ],
      work_id: [ null, [] ],
      role_id: [ null, [] ],
      director_id: [ null, [] ],
      conductor_id: [ null, [] ],
      custom_title: [ null, [] ],
      use_custom_title: [ false, { updateOn: 'change' } ],
      is_other_agency: [ false, { updateOn: 'change' } ],
      is_new_production: [ false, { updateOn: 'change' } ],
      is_guest_performance: [ false, { updateOn: 'change' } ],
      guest_performance_name: [ '', [] ],
      notes_int: [ null, [] ],
      notes_pub: [ null, [] ],
      events: [ null, [] ],
      state: [ EventGroupStateEnum.request, { updateOn: 'change', validators: [ Validators.required ] } ],
      fee: [ null, { updateOn: 'change', validators: [ Validators.pattern('^[0-9,]*$') ] } ],
      fee_different_for_events: [ false, { updateOn: 'change' } ],
      currency: [ CurrencyEnum.eur, { updateOn: 'change' } ],
      all_in: [ true, { updateOn: 'change' } ],
      visible_for_artist: [ false, { updateOn: 'change' } ],
      travel_cost: [ null, [ Validators.pattern('^[0-9,]*$') ] ],

      rehearsal_fee: [ null, [ Validators.pattern('^[0-9,]*$') ] ],
      rehearsal_fee_annotation: [ null, { updateOn: 'change' } ],
      rehearsal_fee_currency: [ CurrencyEnum.eur, { updateOn: 'change' } ],

      travel_cost_type: [ CostTypeEnum.limit, { updateOn: 'change' } ],
      travel_cost_currency: [ CurrencyEnum.eur, { updateOn: 'change' } ],
      hotel_cost: [ null, [ Validators.pattern('^[0-9,]*$') ] ],
      hotel_cost_type: [ CostTypeEnum.limit, { updateOn: 'change' } ],
      hotel_cost_currency: [ CurrencyEnum.eur, { updateOn: 'change' } ],
      attachments: [ [] ],

      contract_requested: [ false, { updateOn: 'change', validators: [ Validators.required ] } ],
      contract_received: [ false, { updateOn: 'change', validators: [ Validators.required ] } ],
      contract_sent_to_artist: [ false, { updateOn: 'change', validators: [ Validators.required ] } ],
      contract_signed_by_artist: [ false, { updateOn: 'change', validators: [ Validators.required ] } ],
      contract_sent_to_location: [ false, { updateOn: 'change', validators: [ Validators.required ] } ],
      contract_signed_by_location: [ false, { updateOn: 'change', validators: [ Validators.required ] } ],
      contract_invoice_sent: [ false, { updateOn: 'change', validators: [ Validators.required ] } ],

      contract_requested_time: [ false, [] ],
      contract_received_time: [ false, [] ],
      contract_sent_to_artist_time: [ false, [] ],
      contract_signed_by_artist_time: [ false, [] ],
      contract_sent_to_location_time: [ false, [] ],
      contract_signed_by_location_time: [ false, [] ],
      contract_invoice_sent_time: [ false, [] ],
      contract_number: [ null, [] ],
    }, { updateOn: 'blur' });


    this.offlineModeService.offlineStatusChange$.subscribe(
        (isOffline) => {
          this.offline = isOffline;
          if (this.form && this.readOnly === false && isOffline === true) {
            this.form.disable({onlySelf: false})
          }
          if (this.form && this.readOnly === false && isOffline === false) {
            this.form.enable({onlySelf: false});
          }
        }
    );
    this.offline = this.offlineModeService.offline.getValue();
    if (this.offline) {
      this.form.disable();
    }

    this.contractFields.forEach((fieldName) => {
      this.form.get(fieldName).valueChanges.subscribe(
          (value) => {
            this.form.get(fieldName + '_time').setValue(value ? moment() : null);
          }
      );
    });

    this.form.get('all_in').valueChanges.subscribe(
        (all_in: boolean) => {
          if (all_in === true) {
            this.form.get('travel_cost').setValue(null);
            this.form.get('travel_cost').markAsDirty();
            this.form.get('hotel_cost').setValue(null);
            this.form.get('hotel_cost').markAsDirty();
          }
        }
    );


    this.form.get('use_custom_title').valueChanges.subscribe(
        (use_title: boolean) => {
          if (use_title === true) {
            this.form.get('custom_title').setValidators(
                [
                    Validators.required,
                  (control: FormControl)  => {
                    const isWhitespace = (control.value || '').trim().length === 0;
                    const isValid = !isWhitespace;
                    return isValid ? null : { 'whitespace': true };
                  }
                ]);

            this.form.get('custom_title').markAsTouched();
          } else {
            this.form.get('custom_title').setValidators([]);
            this.form.get('custom_title').markAsTouched();
          }
          this.form.get('custom_title').updateValueAndValidity();
        }
    );

    this.form.statusChanges
        .pipe(
            debounceTime(500),
            map((event) => this.form.dirty),
            distinctUntilChanged(),
        )
        .subscribe(
            () => {
              console.log('emit status chaange');
              this.isDirty.emit(this.form.dirty);
            }
        );

  }

  ngOnInit() {
    this.filterContactsToCompany = null;

    this.requestDirtyCheck.subscribe(
        () => {
          this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
          this.isDirty.emit(this.form.dirty);
        }
    );

  }


  public setLocation(company: CompanyModel) {

    if (company && company.id) {
      this.form.get('location_id').setValue(company.id);
      this.form.get('location_id').markAsDirty();
      this.filterContactsToCompany = company.id;
    } else {
      this.form.get('location_id').setValue(null);
      this.form.get('location_id').markAsDirty();
      this.filterContactsToCompany = null;
    }

  }

  public setLocationContact(contact: ContactModel) {
    if (contact && contact.id) {
      this.form.get('location_contact_id').setValue(contact.id, { emit: true });
      this.form.get('location_contact_id').markAsDirty();
      if (this.form.get('location_id').value === null) {
        this.form.get('location_id').setValue(contact.company_id);
        this.filterContactsToCompany = contact.company_id;
      }


    } else {
      this.form.get('location_contact_id').markAsDirty();
      this.form.get('location_contact_id').setValue(null, { emit: null != this.form.get('location_contact_id').value });
    }
  }

  public setLocationContactForArtist(contact: ContactModel) {
    this.form.get('location_contact_for_artist_id').markAsDirty();
    if (contact && contact.id) {
      this.form.get('location_contact_for_artist_id').setValue(contact.id, { emit: true });
      if (this.form.get('location_id').value === null) {
        this.form.get('location_id').setValue(contact.company_id);
        this.form.get('location_id').markAsDirty();
        this.filterContactsToCompany = contact.company_id;
      }
    } else {
      this.form.markAsDirty();
      this.form.get('location_contact_for_artist_id')
          .setValue(null, { emit: null != this.form.get('location_contact_for_artist_id').value });
    }
  }

  public setDirectorContact(contact: ContactModel) {
    this.form.get('director_id').markAsDirty();
    if (contact && contact.id) {
      this.form.get('director_id').setValue(contact.id, { emit: contact.id !== this.form.get('director_id').value });
    } else {
      this.form.get('director_id').setValue(null, { emit: null != this.form.get('director_id').value });
    }
  }


  public setConductorContact(contact: ContactModel) {
    this.form.get('conductor_id').markAsDirty();
    if (contact && contact.id) {
      this.form.get('conductor_id').setValue(contact.id, { emit: contact.id !== this.form.get('conductor_id').value });
    } else {
      this.form.get('conductor_id').setValue(null, { emit: null != this.form.get('conductor_id').value });
    }
  }

  public setWork(work: Work) {
    this.form.get('work_id').markAsDirty();
    if (work && work.id) {
      this.form.get('work_id').setValue(work.id, { emit: work.id !== this.form.get('work_id').value });
    } else {
      this.form.get('work_id').setValue(null, { emit: null != this.form.get('work_id').value });
    }
  }

  public setRole(role: Role) {
    console.log(role);
    this.form.get('role_id').markAsDirty();
    if (role && role.id) {
      this.form.get('role_id').setValue(role.id, { emit: role.id !== this.form.get('role_id').value });
    } else {
      this.form.get('role_id').setValue(null, { emit: null != this.form.get('role_id').value });
    }
  }

  public toggleAllIn() {
    this.form.get('all_in').setValue(!this.form.get('all_in').value);
    this.form.markAsDirty();
  }


  public save(closeModal = false) {
    console.log(this.form.value);
    if (this.offline) {
      return;
    }
    this.submitted = true;
    this.isSaving = true;
    this.readOnly = true;

    if (closeModal === true) {
      this.onEventGroupSaveAndClose.emit(this.form.value);
    } else {
      this.onEventGroupEdited.emit(this.form.value);
    }
  }

  public uploadFinished(file: any, visibleToArtist = true) {
    if (!this.form.get('id').value) {
      throw new Error('can not upload file without an event group')
    }

    let fileValue = this.form.get('attachments').value;
    if (!isArray(fileValue)) {
      fileValue = [];
    }
    if (visibleToArtist === false) {
      file[ 1 ].group = 'private'
    }

    fileValue.push(file[ 1 ]);
    // remove circulars with json
    this.form.get('attachments').setValue(JSON.parse(JSON.stringify(fileValue)));

    this.save()

  }

  public removeFile(file: FileModel) {
    const fileValue = this.form.get('attachments').value;
    const idx = fileValue.indexOf(file);
    fileValue.splice(idx, 1);
    this.form.get('attachments').setValue(fileValue);
  }

  public deleteGroup() {
    if (this.form.get('state').value !== EventGroupStateEnum.cancelled) {
      return;
    }
    const doAction = confirm('Möchten Sie wirklich alle Termine löschen? Dieser Vorgang kann nicht rückgängig gemacht werden!');
    if (doAction === false) {
      return;
    }
    this.onEventGroupDeleted.emit(this.form.get('id').value);

  }


  public loadGroup(group: EventGroup) {

    console.log('load grouo', group.fee_different_for_events, group)
    this.cdr.detach();
    this.resetForm();

    this.submitted = false;
    this.isSaving = false;

    this.form.get('id').setValue(group.id, { emit: false });
    this.form.get('artist_id').setValue(group.artist_id, { emit: false });


    this.filterContactsToCompany = group.location_id;
    this.form.get('location_id').setValue(group.location_id, { emit: false });


    if (group.state) {
      this.form.get('state').setValue(group.state, { emit: false });
    }


    this.form.get('location_contact_id').setValue(group.location_contact_id, { emit: false });
    this.form.get('location_contact_for_artist_id').setValue(group.location_contact_for_artist_id, { emit: false });
    this.form.get('custom_title').setValue(group.custom_title, { emit: false });
    this.form.get('use_custom_title').setValue(group.use_custom_title, { emit: false });
    this.form.get('is_other_agency').setValue(group.is_other_agency, { emit: false });
    this.form.get('is_new_production').setValue(group.is_new_production, { emit: false });

    this.form.get('is_guest_performance').setValue(group.is_guest_performance, { emit: false });
    this.form.get('guest_performance_name').setValue(group.guest_performance_name, { emit: false });


    this.form.get('contract_requested').setValue(group.contract_requested, { emit: false });
    this.form.get('contract_received').setValue(group.contract_received, { emit: false });
    this.form.get('contract_sent_to_artist').setValue(group.contract_sent_to_artist, { emit: false });
    this.form.get('contract_signed_by_artist').setValue(group.contract_signed_by_artist, { emit: false });
    this.form.get('contract_sent_to_location').setValue(group.contract_sent_to_location, { emit: false });
    this.form.get('contract_signed_by_location').setValue(group.contract_signed_by_location, { emit: false });
    this.form.get('contract_invoice_sent').setValue(group.contract_invoice_sent, { emit: false });
    this.form.get('contract_number').setValue(group.contract_number, { emit: false });


    this.form.get('contract_requested_time').setValue(group.contract_requested_time, { emit: false });
    this.form.get('contract_received_time').setValue(group.contract_received_time, { emit: false });
    this.form.get('contract_sent_to_artist_time').setValue(group.contract_sent_to_artist_time, { emit: false });
    this.form.get('contract_signed_by_artist_time').setValue(group.contract_signed_by_artist_time, { emit: false });
    this.form.get('contract_sent_to_location_time').setValue(group.contract_sent_to_location_time, { emit: false });
    this.form.get('contract_signed_by_location_time').setValue(group.contract_signed_by_location_time, { emit: false });
    this.form.get('contract_invoice_sent_time').setValue(group.contract_invoice_sent_time, { emit: false });

    if (group.work_id) {
      this.form.get('work_id').setValue(group.work_id, { emit: false });
    }


    if (group.role_id) {
      this.form.get('role_id').setValue(group.role_id, { emit: false });
    }


    if (group.director_id) {
      this.form.get('director_id').setValue(group.director_id, { emit: false });
    }
    if (group.conductor_id) {
      this.form.get('conductor_id').setValue(group.conductor_id, { emit: false });
    }

    if (group.notes_int) {
      this.form.get('notes_int').setValue(group.notes_int, { emit: false });
    }

    if (group.notes_pub) {
      this.form.get('notes_pub').setValue(group.notes_pub, { emit: false });
    }

    if (group.events) {
      this.form.get('events').setValue(group.events, { emit: false });
    }

    if (group.fee) {
      this.form.get('fee').setValue(group.fee, { emit: false });
    }
    if (group.fee_different_for_events) {
      this.form.get('fee_different_for_events').setValue(group.fee_different_for_events, { emit: false });
    }

    if (group.rehearsal_fee) {
      this.form.get('rehearsal_fee').setValue(group.rehearsal_fee, { emit: false });
    }
    if (group.rehearsal_fee_annotation) {
      this.form.get('rehearsal_fee_annotation').setValue(group.rehearsal_fee_annotation, { emit: false });
    }
    if (group.rehearsal_fee_currency) {
      this.form.get('rehearsal_fee_currency').setValue(group.rehearsal_fee_currency, { emit: false });
    }
    if (group.currency) {
      this.form.get('currency').setValue(group.currency, { emit: false });
    }

    this.form.get('all_in').setValue(group.all_in, { emit: false });
    this.form.get('visible_for_artist').setValue(group.visible_for_artist, { emit: false });

    if (group.travel_cost) {
      this.form.get('travel_cost').setValue(group.travel_cost, { emit: false });
    }
    if (group.travel_cost_type) {
      this.form.get('travel_cost_type').setValue(group.travel_cost_type, { emit: false });
    }

    if (group.travel_cost_currency) {
      this.form.get('travel_cost_currency').setValue(group.travel_cost_currency, { emit: false });
    }
    if (group.hotel_cost) {
      this.form.get('hotel_cost').setValue(group.hotel_cost, { emit: false });
    }
    if (group.hotel_cost_type) {
      this.form.get('hotel_cost_type').setValue(group.hotel_cost_type, { emit: false });
    }
    if (group.attachments) {
      this.form.get('attachments').setValue(group.attachments, { emit: false });
    }

    this.form.markAsPristine();
    this.cdr.reattach();
  }


  private resetForm() {

    if (this.authService.getUser().role === (UserRoleArtist || UserRoleLogistic)) {
      this.readOnly = true;
    } else {
      this.readOnly = false;
    }


    this.filterContactsToCompany = null;
    this.form.clearValidators();
    this.form.clearAsyncValidators();

    this.form.reset({
      id: null,
      artist_id: null,
      custom_title: null,
      is_new_production: false,
      is_guest_performance: false,
      guest_performance_name: '',
      is_other_agency: false,
      use_custom_title: false,
      location_id: null,
      location_contact_id: null,
      location_contact_for_artist_id: null,
      work_id: null,
      role_id: null,
      director_id: null,
      conductor_id: null,
      notes_int: null,
      notes_pub: null,
      events: null,
      state: EventGroupStateEnum.request,
      fee: null,
      fee_different_for_events: false,
      currency: CurrencyEnum.eur,
      all_in: true,
      visible_for_artist: false,
      travel_cost: null,
      travel_cost_type: CostTypeEnum.limit,
      travel_cost_currency: CurrencyEnum.eur,
      hotel_cost: null,
      hotel_cost_currency: CurrencyEnum.eur,

      rehearsal_fee: null,
      rehearsal_fee_annotation: null,
      rehearsal_fee_currency: CurrencyEnum.eur,

      hotel_cost_type: CostTypeEnum.limit,
      contract_requested: null,
      contract_received: null,
      contract_sent_to_artist: null,
      contract_signed_by_artist: null,
      contract_sent_to_location: null,
      contract_signed_by_location: null,
      contract_invoice_sent: null,
      contract_number: null,
      files: []
    }, { onlySelf: false, emitEvent: false });

  }

}
