import { ContactTypeEnum } from './enums';
import { BaseModel } from './base.model';

export class ContactModel extends BaseModel {
  id: number;
  created_at: Date;
  updated_at: Date;
  salutation?: string;
  first_name: string;
  first_name_pass: string;
  last_name: string;
  last_name_pass: string;
  email: string;
  is_new_contact: boolean;
  type: ContactTypeEnum;
  mobile?: string;
  mobile2?: string;
  mobile3?: string;
  phone?: string;
  birthday?: Date;
  fax?: string;
  company_id: number;
  street?: string;
  department?: string;
  function?: string;
  country?: string;
  city?: string;
  zip?: string;
  phone2?: string;
  phone3?: string;
  lang?: string;
  website?: string;
  internal_comment?: string;
  roles: string[];
  eye_color: string;
  shoe_size: string;
  height: string;
  weight: string;
  hair_color: string;
  managed_artist: boolean;
  has_calendar: boolean;


  /** @deprecated */
  artist_name?: string;
  artist_name_first_name: string;
  artist_name_last_name: string;

  place_of_birth?: string;
  nationality?: string;
  martial_status?: string;
  fiscal_address?: string; // textarea
  bank_name?: string;
  bank_address?: string;
  bank_account_holder?: string;
  bank_account_number?: string;
  bank_code?: string;
  bank_iban?: string;
  bank_bic?: string;
  passport_number?: string;
  passport_authority?: string;
  passport_issue_date?: string;
  passport_expirey_date?: string;
  vat_number?: string;
  finanzamt?: string;
  tax_number?: string;
  health_insurance_name?: string;
  health_insurance_type?: [ null | 'private' | 'public' ];
  health_insurance_number?: string;
  social_security_number_de?: string;
  conges_spectacles?: string;
  codice_fiscale?: string;
  social_security_number_us?: string;
  enpals?: string;
  mothers_name?: string;
  fathers_name?: string;
  attachments?: string[];
  correspondence?: string[];


  audit_first?: any;
  audit_latest?: any;

  public createFromDatabase(data: any) {
    this.id = data.id;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.salutation = data.salutation;
    this.first_name = data.first_name;
    this.first_name_pass = data.first_name_pass;
    this.last_name_pass = data.last_name_pass;
    this.last_name = data.last_name;
    this.email = data.email;
    this.is_new_contact = data.is_new_contact;
    this.type = data.type;
    this.department = data.department;
    this.function = data.function;
    this.mobile = data.mobile;
    this.mobile2 = data.mobile2;
    this.mobile3 = data.mobile3;
    this.phone = data.phone;
    this.phone2 = data.phone2;
    this.phone3 = data.phone3;
    this.birthday = data.birthday;
    this.fax = data.fax;
    this.company_id = data.company_id;
    this.street = data.street;
    this.country = data.country;
    this.city = data.city;
    this.zip = data.zip;

    this.lang = data.lang;
    this.website = data.website;
    this.internal_comment = data.internal_comment;

    this.eye_color = data.eye_color;
    this.shoe_size = data.shoe_size;
    this.height = data.height;
    this.weight = data.weight;
    this.hair_color = data.hair_color;
    this.managed_artist = data.managed_artist;
    this.has_calendar = data.has_calendar;

    this.artist_name = data.artist_name;
    this.artist_name_first_name = data.artist_name_first_name;
    this.artist_name_last_name = data.artist_name_last_name;

    this.place_of_birth = data.place_of_birth;
    this.nationality = data.nationality;
    this.martial_status = data.martial_status;
    this.fiscal_address = data.fiscal_address;
    this.bank_name = data.bank_name;
    this.bank_address = data.bank_address;
    this.bank_account_holder = data.bank_account_holder;
    this.bank_account_number = data.bank_account_number;
    this.bank_code = data.bank_code;
    this.bank_iban = data.bank_iban;
    this.bank_bic = data.bank_bic;
    this.passport_number = data.passport_number;
    this.passport_authority = data.passport_authority;
    this.passport_issue_date = data.passport_issue_date;
    this.passport_expirey_date = data.passport_expirey_date;
    this.vat_number = data.vat_number;
    this.finanzamt = data.finanzamt;
    this.tax_number = data.tax_number;
    this.health_insurance_name = data.health_insurance_name;
    this.health_insurance_type = data.health_insurance_type;
    this.health_insurance_number = data.health_insurance_number;
    this.social_security_number_de = data.social_security_number_de;
    this.conges_spectacles = data.conges_spectacles;
    this.codice_fiscale = data.codice_fiscale;
    this.social_security_number_us = data.social_security_number_us;
    this.enpals = data.enpals;
    this.mothers_name = data.mothers_name;
    this.fathers_name = data.fathers_name;


    if (data.audit_first) {
      this.audit_first = data.audit_first;
    }
    if (data.audit_latest) {
      this.audit_latest = data.audit_latest;
    }
    if (data.roles) {
      this.roles = data.roles;
    } else {
      this.roles = [];
    }
    if (data.attachments) {
      this.attachments = data.attachments;
    } else {
      this.attachments = [];
    }

    if (data.correspondence) {
      this.correspondence = data.correspondence;
    } else {
      this.correspondence = [];
    }
  }


  //  do not remove used in contact-selector typahead!
  public getName() {
    let displayName = '';
    if (this.artist_name_last_name !== null) {
      displayName = this.artist_name_last_name + ', ' + this.artist_name_first_name;
    } else {
      displayName += this.last_name + ', ' + this.first_name;
    }
    return displayName
  }
}
