<form [formGroup]="eventForm"   class="bg-faded p-4"  action="#" (submit)="submit($event)">



    <div class="row" >
        <div class="col-12">
            <p *ngIf="eventForm.get('id').value === null"><strong>
                Termin hinzufügen
            </strong></p>
            <p *ngIf="eventForm.get('id').value !== null"><strong>
                Termin bearbeiten
            </strong></p>
        </div>
    </div>



    <input type="hidden" formControlName="id">





    <div class="row" *ngIf="eventForm.get('type').value !== 4">
        <label class="col-2 col-form-label-sm" for="eventType">
            Art
        </label>
        <div class="col-5">
            <div class="form-group">
                <select class="form-control custom-select" id="eventType" formControlName="type">
                    <option *ngFor="let type of eventTypes" [ngValue]="type">{{type|eventType}}</option>
                </select>
            </div>
        </div>
        <div class="col-5">
            <button type="button" *ngIf="eventForm.get('cancelled').value !== true" class="btn btn-sm btn-outline-primary"   (click)="toggleCancelEvent()">Findet statt</button>
            <button type="button" *ngIf="eventForm.get('cancelled').value === true"  class="btn btn-sm btn-danger"   (click)="toggleCancelEvent()">Abgesagt</button>
        </div>
    </div>


    <div class="row" *ngIf="eventForm.get('type').value === 4">
      <label class="col-2 col-form-label-sm" for="travel_type">
        Art
      </label>
      <div class="col-5">
        <div class="form-group">
          <select class="form-control custom-select" id="travel_type"  formControlName="travel_type">
            <option *ngFor="let type of travel_types" [ngValue]="type">{{type|travel_type}}</option>
          </select>
        </div>
      </div>
    </div>


    <div class="row form-group has-feedback"
             [class.has-danger]="
                 (eventForm.get('start').invalid && ( eventForm.get('start').touched ||  eventForm.get('start').dirty || formIsSubmitted))">

        <label class="col-2 col-form-label-sm" *ngIf="eventForm.get('type').value === 1 || eventForm.get('type').value === 4">
            Von - Bis
        </label>
        <label class="col-2 col-form-label-sm" *ngIf="eventForm.get('type').value !== 1 && eventForm.get('type').value !== 4">
            Am
        </label>

        <div class="col-5">
                <div class="input-group">
                    <div class="input-group-addon" (click)="startElem.toggle()" *ngIf="!readOnly && !offline">
                        <i class="fa fa-calendar"></i>
                    </div>


                    <input type="text"
                           class="form-control"
                           #startElem="ngbDatepicker"
                           ngbDatepicker
                           formControlName="start"
                    >

                </div>

                <span class="form-control-feedback has-danger" *ngIf="eventForm.get('start').invalid && (eventForm.get('start').touched || formIsSubmitted )">
                    Pflichtfeld
                </span>
        </div>

        <div class="col-5"  *ngIf="eventForm.get('type').value === 1 || eventForm.get('type').value === 4">
            <div class="input-group">
                <div class="input-group-addon" (click)="endElem.toggle()" *ngIf="!readOnly && !offline">
                    <i class="fa fa-calendar"></i>
                </div>
                <input type="text"
                       class="form-control"
                       #endElem="ngbDatepicker"
                       ngbDatepicker
                       formControlName="end"
                >


            </div>
            <span class="form-control-feedback has-danger" *ngIf="eventForm.get('end').invalid && ( eventForm.get('end').touched || formIsSubmitted )">
                Pflichtfeld
            </span>
        </div>


    </div>







    <div class="row" >
        <div class="col-12">

            <button *ngIf="eventForm.get('id').value === null"
                    type="submit" class="btn btn-sm "
                    [disabled]="!eventForm.valid || isSaving || readOnly || offline"
                    [ngClass]=" eventForm.pristine ?  'btn-default' : 'btn-success'"
                    >Neuen Termin speichern <i class="fa fa-spin fa-spinner" *ngIf="isSaving"></i></button>

            <button *ngIf="eventForm.get('id').value !== null"
                    type="submit" class="btn btn-sm"
                    [ngClass]=" eventForm.pristine ?  'btn-default' : 'btn-success'"
                    [disabled]="!eventForm.valid || eventForm.pristine || isSaving || readOnly || offline"
            >Speichern <i class="fa fa-spin fa-spinner" *ngIf="isSaving"></i></button>

          <button type="button" class="btn btn-outline-primary btn-sm" (click)="hide.next()">Abbrechen</button>
        </div>
    </div>



</form>
