<div class="container-fluid" *ngIf="template">
    <div class="row pt-4 row justify-content-between">
        <div class="col-12">
            <h2 *ngIf="template.id" >Briefvorlage bearbeiten</h2>
            <h2 *ngIf="!template.id" >Briefvorlage erstellen</h2>
        </div>
    </div>

    <div class="row  pt-4" *ngIf="template">


      <div class="col-12 col-md-6 offset-md-3" >


          <div class="form-group row">
            <label class="control-label col-sm-2" for="name">Vorlagen Name:</label>
            <div class="col-sm-10">
              <input class="form-control" type="text" id="name"  [(ngModel)]="template.name"/>
            </div>
          </div>


          <div class="form-group row" *ngIf="template.id">
            <label class="control-label col-sm-2">Aktuelle  Datei</label>
            <div class="col-sm-10">
              <ng-container *ngFor="let file of template.attachments">
                <app-file-details class="pb-1"
                                  [readOnly]="true"
                                  [file]="file"
                ></app-file-details>
              </ng-container>



            </div>
          </div>

          <div class="form-group row">
              <div class="col-12 text-right">
                  <p>
                      Sie können <a href="./vorlage.docx" class="btn btn-sm btn-primary" target="_blank" >hier </a> die Beispielvorlage herunterladen. Editieren Sie Datei anschließend können Sie die Datei als Vorlage hier hochladen
                  </p>
              </div>

          </div>


          <div class="form-group row">
            <label class="control-label col-sm-2" >Vorlage hochladen</label>
            <div class="col-sm-10">
              <dropzone
                      #dropZone
                      [useDropzoneClass]="true"
                      (success)="uploadFinished($event)"
              ></dropzone>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-offset-2 col-sm-10">
             Zum Speichern Datei hochladen
            </div>
          </div>



      </div>
    </div>

</div>
