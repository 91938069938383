import { ApplicationRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { HotkeyCommandService } from '../../../../services/hotkey-command.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { UserModel } from '../../../../models/user.model';
import { ContactNoteModel } from '../../../../models/contact-note.model';
import { ContactNotesService } from '../../../../services/contact-notes.service';

@Component({
    selector: 'app-contact-notes',
    templateUrl: './contact-notes.component.html'
})
export class ContactNotesComponent implements OnInit {

    public sortBy = 'id';
    public selectedRow = 0;
    public _contact_id = null;

    @ViewChild(DataTableDirective)
    dataTable: DataTableDirective;

    @Input() set contact_id(id: number) {
        const reset = this._contact_id !== id;
        this._contact_id = id;
        if (this.contactNote) {
            this.contactNote.contact_id = id;
        }

        if (reset) {
            this.refreshPage();
        }
    };


    public contactNote: ContactNoteModel;


    dtOptions: DataTables.Settings | any = {};


    constructor(private elRef: ElementRef,
                private contactNotesService: ContactNotesService,
                private applicationRef: ApplicationRef,
                private hotkeyCommandService: HotkeyCommandService,
                private auth: AuthService,
                private http: HttpClient) {
    }

    ngOnInit() {

        this.resetForm();

        this.dtOptions = {
            pagingType: 'full_numbers',
            order: [ 0, 'desc' ],
            pageLength: 10,
            serverSide: true,
            processing: true,
            responsive: false,
            /// order: [0, 'desc'],
            language: {
                'sEmptyTable': 'Keine Daten in der Tabelle vorhanden',
                'sInfo': '_START_ bis _END_ von _TOTAL_ Einträgen',
                'sInfoEmpty': '0 bis 0 von 0 Einträgen',
                'sInfoFiltered': '(gefiltert von _MAX_ Einträgen)',
                'sInfoPostFix': '',
                'sInfoThousands': '.',
                'sLengthMenu': '_MENU_ Einträge anzeigen',
                'sLoadingRecords': 'Wird geladen...',
                'sProcessing': 'Bitte warten...',
                'sSearch': 'Suchen',
                'sZeroRecords': 'Keine Einträge vorhanden.',
                'oPaginate': {
                    'sFirst': 'Erste',
                    'sPrevious': 'Zurück',
                    'sNext': 'Nächste',
                    'sLast': 'Letzte'
                },
                'oAria': {
                    'sSortAscending': ': aktivieren, um Spalte aufsteigend zu sortieren',
                    'sSortDescending': ': aktivieren, um Spalte absteigend zu sortieren'
                },
                select: {
                    rows: {
                        _: '%d Zeilen ausgewählt',
                        0: 'Zum Auswählen auf eine Zeile klicken',
                        1: '1 Zeile ausgewählt'
                    }
                }
            },
            ajax: (request, drawCallback) => {
                const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
                this.http.post('dt/contact-notes/' + this._contact_id, request, { headers: headers }).subscribe(
                    (data) => {
                        console.log('returned data', data);
                        drawCallback(data);
                    },
                    (err) => {
                        console.error('error', err)
                    }
                )

            },

            rowCallback: (row: Node, data, index: number) => {

                row.addEventListener('dblclick', () => {
                    // this.onDoubleClick(data);
                    console.log('event double click')
                });
                row.addEventListener('click', () => {
                    this.selectedRow = index;
                    console.log('event click')
                });


                $(row).on('click', '.dt-button', ($event) => {
                    const id = $($event.currentTarget).data('elem-id');
                    const action = $($event.currentTarget).data('elem-action');
                    switch (action) {
                        case 'edit' :
                            // this.editNoteWithId(id);
                            break;
                        case 'delete' :
                            this.delete(id);
                            break;
                    }
                });

            },
            columns: [
                { data: 'id', visible: false, type: 'num' },
                {
                    name: 'user',
                    data: 'user',
                    defaultContent: '',
                    searchable: false,
                    orderable: false,
                    render: (data: UserModel) => {
                        return data.first_name.slice(0, 1).toLocaleUpperCase() + data.last_name.slice(0, 1).toUpperCase();
                    },
                },
                { data: 'keywords', searchable: 'true' },
                {
                    data: 'text', searchable: 'true',
                    render: (data: string) => {
                        if (data === null) {
                            return '';
                        }
                        return data.replace(/(\r\n|\n\r|\r|\n)/g, '<br>');
                    },
                },
                { data: 'created_at', searchable: 'false' },
                { data: 'updated_at', searchable: 'false', visible: false },

                {
                    data: null,
                    searchable: false,
                    orderable: false,
                    name: 'Aktion',
                    render: (data: any, type: any, row: any) => {
                        let elem = `<div class="btn-group-sm btn-group-justified btn-group">`;
                        elem += '<button *ngIf="row.id && !row.is_new_contact" class="btn btn-sm btn-outline-danger dt-button"' +
                            ' type="button"  data-elem-id="' + row.id + '" data-elem-action="delete"><i class="fa fa-trash"></i></button>';
                        elem += `</div>`;

                        return elem;


                    }
                },
            ]
        };

    }


    save() {
        if (this.contactNote.keywords.trim() === '' &&
            this.contactNote.text.trim() === '') {
            return
        }
        this.contactNotesService.create(this.contactNote).subscribe(
            () => {
                this.resetForm();
                this.refreshPage();
            },
            (err) => {
                console.error(err);
                alert(err)
            }
        )
    }

    resetForm() {
        this.contactNote = new ContactNoteModel();
        this.contactNote.contact_id = this._contact_id;
        this.contactNote.user_id = this.auth.getUser().id;
        this.contactNote.keywords = '';
        this.contactNote.text = '';
    }

    // needed to no nest bootstrap modals
    /*getRootViewContainerRef(): ViewContainerRef {
        const appInstance = this.applicationRef.components[ 0 ].instance;
        if (!appInstance.viewContainerRef) {
            const appName = this.applicationRef.componentTypes[ 0 ].name;
            throw new Error(`Missing 'applicationRef' declaration in ${appName} constructor`);
        }
        return appInstance.viewContainerRef;
    }*/


    protected delete(id: number) {

        if (confirm('Wollen Sie diese Notiz wirklich löschen?')) {
            this.contactNotesService.remove(id).subscribe(
                () => this.refreshPage(),
                () => alert('Fehler: Konnte die Notiz nicht löschen.')
            )
        }

    }


    public refreshPage(forcedReload?: boolean) {
        if (this.dataTable) {
            this.dataTable.dtInstance.then(
                (inst: DataTables.Api) => {
                    inst.ajax.reload(() => {
                    }, false)
                }
            );
        }

    }

}
