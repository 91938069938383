<div class="container-fluid pt-2" >
  <div class="row" *ngIf="event_group">
    <div class="col-8">
      <h4 class="pb-0 mb-0" *ngIf="event_group">
        <span *ngIf="event_group?.use_custom_title">
            {{event_group.custom_title}}
        </span>
        <span *ngIf="!event_group?.use_custom_title">
          {{ event_group?.location?.city_for_calendar || event_group?.location?.city }}
          - {{ event_group?.work?.short_name }}
        </span>
      </h4>
      <h4 class="pb-0 mb-0" *ngIf="!event_group">Neuer Termin</h4>

      <p class="mb-0">{{artist?.first_name}} {{ artist?.last_name}}</p>

    </div>
    <div class="col-4 text-right">
      <button type="button" class=" pull-right btn btn-outline-primary" (click)="returnToCalendar()">
        Schließen
      </button>
    </div>
  </div>

  <div class="row" *ngIf="travel_group">
    <div class="col-8">
      <h4>Reise</h4>
      <p class="mb-0">{{artist?.first_name}} {{ artist?.last_name}}</p>
    </div>
    <div class="col-4 text-right">
      <button type="button" class=" pull-right btn btn-outline-primary" (click)="returnToCalendar()">
        Schließen
      </button>
    </div>
  </div>



  <div class="row">
    <div class="modal-body">
      <app-event-list-editor
              [event_group]="event_group"
              [travel_group]="travel_group"
              [defaultDate]="defaultDate"
              (eventDeleted)="eventDeleted.emit($event)"
              (eventUpdated)="eventUpdated.emit($event)"
              (eventCreated)="eventCreated.emit($event)"
              (eventGroupDeleted)="eventGroupDeleted.next($event)"
              (travelGroupDeleted)="travelGroupDeleted.next($event)"
      ></app-event-list-editor>
    </div>
  </div>


  <div class="row pt-5">
    <div class="col-12">

      <app-event-group
        *ngIf="event_group"
        (isDirty)="groupIsDirty.next($event)"
        [requestDirtyCheck]="requestDirtyCheck.asObservable()"
        [event_group]="event_group"
        (onModalShow)="onModalShow.next($event)"
        (onModalHide)="onModalHide.next($event)"
        (onEventGroupDeleted)="onEventGroupDeleted($event)"
        (onEventGroupEdited)="onEventGroupEdited($event)"
        (onEventGroupSaveAndClose)="onEventGroupEdited($event, true)"
      ></app-event-group>

      <app-travel-group
        *ngIf="travel_group"
        (isDirty)="groupIsDirty.next($event)"
        [requestDirtyCheck]="requestDirtyCheck.asObservable()"
        [travel_group]="travel_group"
        (onTravelGroupEdited)="onTravelGroupEdited($event)"
        (onTravelGroupSaveAndClose)="onTravelGroupEdited($event, true)"
      ></app-travel-group>

    </div>

  </div>
</div>
