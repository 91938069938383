import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { WordTemplatesService } from '../../../services/word-templates.service';
import { WordTemplate } from '../../../models/word-template.model';
import { WordTemplatesComponent } from '../word-templates.component';
import { of } from 'rxjs';


@Component({
    selector: 'app-edit-word-template',
    templateUrl: './edit-word-template.component.html',
})
export class EditWordTemplateComponent implements OnInit {

    public template: WordTemplate = null;


    constructor(private http: HttpClient,
                private wordTemplateService: WordTemplatesService,
                public router: Router,
                private route: ActivatedRoute) {
    }


    public uploadFinished(file) {
        this.template.attachments = JSON.parse(JSON.stringify([ file[ 1 ] ]));
        this.save(true);
    }

    public save(redirect = false) {

        if (this.template.id) {
            this.wordTemplateService.update(this.template).subscribe(
                () => {
                    if (redirect) {
                        this.router.navigateByUrl(WordTemplatesComponent.url);
                    }
                },
                (err) => {
                    alert('Bitte geben Sie einen Namen an');
                }
            );
        } else {
            // create

            this.wordTemplateService.create(this.template).subscribe(
                () => {
                    if (redirect) {
                        this.router.navigateByUrl(WordTemplatesComponent.url);
                    }
                },
                (err) => {
                    alert('Bitte geben Sie einen Namen an');
                }
            )
        }


    }

    public delete(id) {

    }


    ngOnInit() {

        this.route.params
            .pipe(
                switchMap((params) => {

                    if (params[ 'word_template_id' ] === 'new') {
                        return of(new WordTemplate());
                    }
                    return this.wordTemplateService.get(+params[ 'word_template_id' ])


                })
            )
            .subscribe((template: WordTemplate) => {
                    this.template = template;
                },
                (err) => {
                    console.error(err)
                })

    }


}
