import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventModel } from '../../../models/event.model';
import { CurrencyEnum, EventTypeEnum, TravelTypeEnum, UserRoleArtist } from '../../../models/enums';
import * as moment from 'moment';
import { Moment } from 'moment';
import { AuthService } from '../../../services/auth/auth.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { EventService } from '../../../services/event.service';
import { EventGroup } from '../../../models/event-group';
import { OfflineModeService } from '../../../services/offline/offline-mode.service';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'app-event-editor',
  templateUrl: './event-editor.component.html',
})
export class EventEditorComponent {

  @Input() defaultDate: moment.Moment = moment();

  @Output() eventCreated = new EventEmitter<EventModel>();
  @Output() eventUpdated = new EventEmitter<EventModel>();
  @Output() eventDeleted = new EventEmitter<number>();

  @Output() eventGroupCreated = new EventEmitter<EventGroup>();

  @Output() hide = new EventEmitter<any>();

  public formIsSubmitted = false;
  public isSaving = false;

  public editMode: 'create' | 'update';
  public index: number = null;

  public readOnly = false;
  public offline = false;

  public travel_types = [
    TravelTypeEnum.outbound,
    TravelTypeEnum.inbound,
    TravelTypeEnum.hotel
  ];

  public eventTypes = [
    EventTypeEnum.rehearsal,
    EventTypeEnum.premiere,
    EventTypeEnum.performance,
    EventTypeEnum.concert,
    // EventTypeEnum.performanceOrConcert,
  ];
  public eventForm: FormGroup;

  public currencies = [
    CurrencyEnum.eur,
    CurrencyEnum.chf,
    CurrencyEnum.usd,
    CurrencyEnum.jpy,
    CurrencyEnum.gbp,
    CurrencyEnum.aud,
    CurrencyEnum.cad,
    CurrencyEnum.cny,
  ];



  @Input('event')
  set event(event: EventModel) {
    this.editMode = 'update';
    this.eventForm.reset({
      id: event.id,
      type: event.type,
      travel_group: event.travel_group || null,
      travel_group_id: event.travel_group_id || null,
      travel_type: event.travel_type || null,
      cancelled: event.cancelled || false,
      notes: event.notes,
      fee: event.fee,
      fee_currency: event.fee_currency,
      event_group_id: event.event_group_id,
      artist_id: event.artist_id,
      name: event.name,
      use_name_as_title: event.use_name_as_title,
      startTime: { hour: 0, minute: 0, second: 0 },
      endTime: { hour: 23, minute: 59, second: 59 },
      start: this.formatForDatepicker(event.start),
      end: this.formatForDatepicker(event.end),
    }, { onlySelf: false, emitEvent: false });

    this.isSaving = false;
  };

  constructor(private fb: FormBuilder,
              private af: AuthService,
              private eventService: EventService,
              private offlineModeService: OfflineModeService
  ) {

    this.eventForm = this.fb.group({
      id: [ null, [] ],
      travel_group: [ null ],
      travel_group_id: [ null ],
      type: [ null, { updateOn: 'change', validators: [ Validators.required ] } ],
      travel_type: [ null, { updateOn: 'change', validators: [  ] } ],
      cancelled: [ false, [] ],
      notes: [ null, [] ],
      fee: [ null, [ Validators.pattern('^[0-9,]*$') ] ],
      fee_currency: [ null, { updateOn: 'change'}  ],
      event_group_id: [ null, [] ],
      artist_id: [ null, [] ],
      name: [ null, [] ],
      use_name_as_title: [ false, {updateOn: 'change'} ],
      startTime: [ null ],
      endTime: [ null ],
      start: [ this.formatForDatepicker(this.defaultDate), [ Validators.required ] ],
      end: [ this.formatForDatepicker(this.defaultDate), [ Validators.required ] ],
    }, { updateOn: 'blur' });

    this.offlineModeService.offlineStatusChange$.subscribe(
      (isOffline) => {
        this.offline = isOffline;
        if (this.eventForm && this.readOnly === false && isOffline === true) {
          this.eventForm.disable()
        }
        if (this.eventForm && this.readOnly === false && isOffline === false) {
          this.eventForm.enable();
        }
      }
    );
    this.offline = this.offlineModeService.offline.getValue();
    if (this.offline) {
      this.eventForm.disable();
    }

    if (this.af.getUser().role === UserRoleArtist) {
      this.readOnly = true;
      this.eventForm.disable();
    }
    this.resetForm();
    this.editMode = 'create';

    this.eventForm.valueChanges
      .pipe(
        debounceTime(100),
        // strip of the notes
        map((values) => {
          console.log(this.eventForm);
          return { end: values.end, start: values.start, type: values.type };
        }),
        distinctUntilChanged()
      )

      .subscribe(
        (values) => {
          console.log(values);
          if (values.end !== values.start &&
            (values.type !== EventTypeEnum.rehearsal) && (values.type !== EventTypeEnum.travel)
          ) {
            this.eventForm.get('end').setValue(this.eventForm.get('start').value);
            return;
          } else {
            if (this.datePickerToMoment(values.start) > this.datePickerToMoment(values.end)) {
              this.eventForm.get('end').setValue(this.eventForm.get('start').value);
            }
          }
        }
      );


  }

  public formatForDatepicker(value: Moment) {
    const date = moment(value);
    return {
      day: parseInt(date.format('DD'), 10),
      month: parseInt(date.format('M'), 10),
      year: parseInt(date.format('YYYY'), 10),
    }
  }

  public datePickerToMoment(value: { day: number, month: number, year: number }): Moment {
    const  date: Moment = moment();
    date.year(value.year);
    date.month(value.month - 1 );
    date.date(value.day);
    date.hours(0);
    date.minutes(0);
    date.seconds(0);
    return date;

  }


  public submit(e = null) {
    if (e) {
      e.preventDefault()
    }

    if (this.readOnly || this.offline) {
      console.log('read only/ offline');
      return;
    }
    this.formIsSubmitted = true;

    if (!this.eventForm.valid) {
      console.log('form not valid');
      console.log(this.eventForm.value);
      return;
    }
    const event = new EventModel();
    event.createFromFormModel(this.eventForm);
    this.onEventEdited(event);
    return false
  }


  public onEventEdited(event: EventModel) {
    console.log('onEventEdited', event);
    // speichere in db

    if (event.id != null) {
      this.editMode = 'update';
      this.eventService.update(event).subscribe(
        (ev: EventModel) => {

          console.log('event-editor: event updated');
          this.eventUpdated.emit(ev);
          this.event = ev;
          this.hide.next();
        }
      );


    } else {
      const isNewGroup = !event.event_group_id;
      this.eventService.create(event).subscribe(
        (eventModel: EventModel) => {
          this.event = eventModel;
          this.editMode = 'update';

          console.log('event-editor: event create');
          this.eventCreated.emit(eventModel);
          this.hide.next();
          if (isNewGroup) {
            console.log('created new event group!', event.event_group);
            this.eventGroupCreated.emit(event.event_group);
          }

        }
      );
    }

  }


  public loadForNew() {
    this.editMode = 'create';
    this.eventForm.get('type').setValue(EventTypeEnum.rehearsal);
    this.resetForm();
  }

  public loadForAddEvent(event_groupId: string) {
    this.resetForm();
    this.editMode = 'create';
    this.eventForm.get('id').setValue(null);
    this.eventForm.get('createdAt').setValue(moment());
    this.eventForm.get('type').setValue(EventTypeEnum.rehearsal);
    this.eventForm.get('cancelled').setValue(false);
  }

  public toggleCancelEvent() {
    this.eventForm.get('cancelled').setValue(!this.eventForm.get('cancelled').value);
    this.eventForm.get('cancelled').markAsDirty();
  }

  public markDirty(fieldName: string, event) {
    this.eventForm.get(fieldName).markAsDirty();
  }

  private resetForm() {
    this.formIsSubmitted = false;
    this.eventForm.clearValidators();
    this.eventForm.clearAsyncValidators();
    this.eventForm.reset({
      id: null,
      type: EventTypeEnum.rehearsal,
      start: this.formatForDatepicker(this.defaultDate),
      end: this.formatForDatepicker(this.defaultDate),
      startTime: { hour: 0, minute: 0, second: 0 },
      endTime: { hour: 23, minute: 59, second: 0 },
      notes: null,
      fee: null,
      fee_currency: CurrencyEnum.eur,
      cancelled: false,
      event_group_id: null,
      egState: null,
      egCity: null,
      egWork: null,
      egCustomTitle: null,
      egUseCustomTitle: null,
      name: null,
      use_name_as_title: false,
    }, { onlySelf: false, emitEvent: false });
  }
}
