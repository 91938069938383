import { Pipe, PipeTransform } from '@angular/core';
import { ContactTypeEnum } from '../models/enums';

@Pipe({
  name: 'contactType'
})
export class ContactTypePipe implements PipeTransform {

  public transform(value: any, args?: any): string {

    switch (value) {
      case ContactTypeEnum.artist :
        return 'Künstler';
      case ContactTypeEnum.location:
        return 'Veranstalter';
      case ContactTypeEnum.hotel:
        return 'Hotel';
      case ContactTypeEnum.agent:
        return 'Agent';
      case ContactTypeEnum.director:
            return 'Regiesseur';
      case ContactTypeEnum.conductor:
            return 'Dirigent';
      default:
      case 'other': return 'Sonstige';
    }
  }

}
