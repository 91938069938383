import { Component, OnInit } from '@angular/core';
import { CalendarComponent } from '../../calendar/calendar.component';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { AuthService } from '../../../services/auth/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {

  public static url = 'login';
  public email: string;
  public password: string;
  public errorMessage = null;
  public loading = false;

  constructor(private router: Router,
              private auth: AuthService,
              private localStorage: LocalStorageService) {
  }

  ngOnInit() {
    // go to modals page if logged in
    this.localStorage.observe('user').subscribe(
      (user) => {
        if (user !== null) {
          this.router.navigate([ CalendarComponent.url ])
        }
      }
    );
  }

  public login() {
    // this.loading = true;
    this.errorMessage = null;
    this.auth.login(this.email, this.password)
      .subscribe(
        (result) => {
          console.log('login result', result)
          if (result === true) {

                setTimeout(() => {
                  this.router.navigate([ '/' ]).then(
                    () => console.log('navigated'),
                    (err) => console.error(err)
                  );
                }, 100)

          } else {
            this.errorMessage = 'Ungültige Zugangsdaten';
            this.loading = false;
          }
        },
        (error) => {
          console.error(error);
          this.errorMessage = 'Ungültige Zugangsdaten';
          this.loading = false;
        },
        () => {
          this.loading = false;
        });


  }

}
