import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, PreloadAllModules, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { DataResolver } from './app.resolver';
import { CalendarComponent } from '../pages/calendar/calendar.component';
import { LoginComponent } from '../pages/register/login/login.component';
import { AuthenticatedOnlyOrRedirectGuard } from './guards/authenticated-only.guard';
import { NoAuthenticatedUsersGuard } from './guards/no-authenticated-users.guard';
import { ContactsComponent } from '../pages/contacts/contacts.component';
import { EmailNotVerifiedComponent } from '../pages/register/email-not-verified/email-not-verified.component';
import { AuthenticatedOnlyButEmailNotVerified } from './guards/authenticated-but-email-not-verfied-only.guard';
import { NotFoundComponent } from '../pages/not-found/not-found.component';
import { UserManagerComponent } from '../pages/user-manager/user-manager.component';
import { ForgotPasswordComponent } from '../pages/register/forgot-password/forgot-password.component';
import { RedirectToCalendarIfAuthenticatedGuard } from './guards/redirect-to-calendar-if-authenticated.guard';
import { CompaniesComponent } from '../pages/companies/companies.component';
import { AddUserComponent } from '../pages/user-manager/add-user/add-user.component';
import { AuditsComponent } from '../pages/audits/audits.component';
import { CalendarAllArtistsComponent } from '../pages/calendar/calendar-all-artists/calendar-all-artists.component';
import { SyncComponent } from '../pages/sync/sync.component';
import { EventGroupPageComponent } from '../pages/events-group/event-group-page.component';
import { EventGroupTableComponent } from '../pages/event-group-table/event-group-table.component';
import { OfflineTestComponent } from '../pages/offline-test/offline-test.component';
import { CreateAccountComponent } from '../pages/register/create-account/create-account.component';
import { SetPasswordComponent } from '../pages/register/set-password/set-password.component';
import { WordTemplatesComponent } from '../pages/word-templates/word-templates.component';
import { EditWordTemplateComponent } from '../pages/word-templates/edit-word-template/edit-word-template.component';

export class CustomReuseStrategy implements RouteReuseStrategy {

  private handlers: { [ key: string ]: DetachedRouteHandle } = {};
  constructor() {
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return true;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const path =  this.getIdentiefier(route)

    this.handlers[path] = handle;

    // cache pages for 10 seconds only
    setTimeout(() => {
      this.handlers[ path] = undefined;
    }, 10000);

  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path =  this.getIdentiefier(route)
    console.log('should attach path?', path, !!this.handlers[ path]);
    return !!this.handlers[ path];
  }


  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const path =  this.getIdentiefier(route)
    return this.handlers[ path ];
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  private  getIdentiefier(route: ActivatedRouteSnapshot) {
    let params = '?';
    route.queryParamMap.keys.forEach((key) => {
      params += '&' + key + '=' + route.queryParamMap.get(key)
    });
    return (route.url.join('/') || route.parent.url.join('/') ) + params;
  }

}


const ROUTES: Routes = [
  {
    path: LoginComponent.url,
    component: LoginComponent,
    canActivate: [ NoAuthenticatedUsersGuard, RedirectToCalendarIfAuthenticatedGuard ]
  },
  {
    path: ForgotPasswordComponent.url,
    component: ForgotPasswordComponent,
    canActivate: [ NoAuthenticatedUsersGuard, RedirectToCalendarIfAuthenticatedGuard ]
  },
  {
    path: SetPasswordComponent.url,
    component: SetPasswordComponent,
    canActivate: [ NoAuthenticatedUsersGuard, RedirectToCalendarIfAuthenticatedGuard ]
  },
  {
    path: CreateAccountComponent.url,
    component: CreateAccountComponent,
    canActivate: [ NoAuthenticatedUsersGuard, RedirectToCalendarIfAuthenticatedGuard ]
  },
  {
    path: 'offline',
    component: OfflineTestComponent,
    canActivate: [ AuthenticatedOnlyOrRedirectGuard ]
  },
  {
    path: ContactsComponent.url,
    component: ContactsComponent,
    canActivate: [ AuthenticatedOnlyOrRedirectGuard ]
  },
  {
    path: CompaniesComponent.url,
    component: CompaniesComponent,
    canActivate: [ AuthenticatedOnlyOrRedirectGuard ]
  },
  {
    path: EmailNotVerifiedComponent.url,
    component: EmailNotVerifiedComponent,
    canActivate: [ AuthenticatedOnlyButEmailNotVerified ]
  },
  {
    path: UserManagerComponent.url,
    component: UserManagerComponent,
    canActivate: [ AuthenticatedOnlyOrRedirectGuard ]
  },
  {
    path: AddUserComponent.url,
    component: AddUserComponent,
    canActivate: [ AuthenticatedOnlyOrRedirectGuard ]
  },
  {
    path: EventGroupTableComponent.url,
    component: EventGroupTableComponent,
    canActivate: [ AuthenticatedOnlyOrRedirectGuard ]
  },
  {
    path: CalendarComponent.url,
    component: CalendarComponent,
    canActivate: [ AuthenticatedOnlyOrRedirectGuard ],
    children : [
      {
        path: EventGroupPageComponent.urlEvent + '/:event_group_id',
        component: EventGroupPageComponent,
        canActivate: [ AuthenticatedOnlyOrRedirectGuard ],
      },
      {
        path: EventGroupPageComponent.urlTravel + '/:travel_group_id',
        component: EventGroupPageComponent,
        canActivate: [ AuthenticatedOnlyOrRedirectGuard ],
      },
    ]
  },
  {
    path: AuditsComponent.url,
    component: AuditsComponent,
    canActivate: [ AuthenticatedOnlyOrRedirectGuard ]
  },

  {
    path: WordTemplatesComponent.url,
    component: WordTemplatesComponent,
    canActivate: [ AuthenticatedOnlyOrRedirectGuard ]
  },
  {
    path: WordTemplatesComponent.url + '/:word_template_id',
    component: EditWordTemplateComponent,
    canActivate: [ AuthenticatedOnlyOrRedirectGuard ]
  },
  {
    path: SyncComponent.url,
    component: SyncComponent,
    canActivate: [ AuthenticatedOnlyOrRedirectGuard ]
  },
  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [ RedirectToCalendarIfAuthenticatedGuard, AuthenticatedOnlyOrRedirectGuard ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, { useHash: true, preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload'})
  ],
  exports: [
    RouterModule
  ],
  declarations: [],
  providers: [
    AuthenticatedOnlyOrRedirectGuard,
    NoAuthenticatedUsersGuard,
    AuthenticatedOnlyButEmailNotVerified,
    RedirectToCalendarIfAuthenticatedGuard,
  //  { provide: RouteReuseStrategy, useClass: CustomReuseStrategy }
  ]
})
export class AppRoutingModule {
}

