import { Pipe, PipeTransform } from '@angular/core';
import { EventGroupStateEnum } from '../models/enums';

@Pipe({
  name: 'event_groupState'
})
export class EventGroupStatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case EventGroupStateEnum.request  : return 'Anfrage';
      case EventGroupStateEnum.confirmed : return 'Bestätigt';
      case EventGroupStateEnum.cancelled  : return 'Abgesagt';
      case EventGroupStateEnum.contract  : return 'Vertrag';
      default: return 'Unbekannt'
    }
  }

}
