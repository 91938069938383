<div class="row-fluid justify-content-center mt-5">


  <div class="container">
    <form class="form-horizontal justify-content-center align-middle" role="form"  (submit)="savePassword()">
      <div class="row">
        <div class="col-sm-12 col-md-6 push-md-3">
          <h2>Neues Passwort setzen</h2>
          <hr>
        </div>
      </div>


      <div class="row" *ngIf="!disabled">

        <div class="col-sm-12 col-md-6  push-md-3">
          <div class="form-group">
            <label for="password" class="form-control-label">
              Passwort (mindestens 8 Stellen)
            </label>

            <div class="input-group mb-2 mr-sm-2 mb-sm-0">
              <div class="input-group-addon" style="width: 2.6rem"><i class="fa fa-key"></i></div>
              <input type="password" name="password" class="form-control" id="password"
                     minlength="8"
                     [(ngModel)]="password"
                     placeholder="Passwort" required autocomplete="new-password">
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!disabled">
        <div class="col-sm-12 col-md-6  push-md-3">
          <div class="form-group">

            <div class="input-group mb-2 mr-sm-2 mb-sm-0">
              <div class="input-group-addon" style="width: 2.6rem"><i class="fa fa-key"></i></div>
              <input type="password" name="password_repeat"
                     minlength="8"
                     class="form-control" id="password_repeat"
                     [(ngModel)]="password_confirmation"
                     placeholder="Passwort wiederholen" required autocomplete="password-repeat">
            </div>
          </div>
        </div>
      </div>

      <div class="row-fluid" *ngIf="errorMessage">
        <div class="col-sm-12 col-md-6  push-md-3 alert alert-danger">
          <strong>Fehler</strong><br>
          {{ errorMessage }}
        </div>
      </div>

      <div class="row pt-2" *ngIf="!disabled">
        <div class="col-sm-12 col-md-6   push-md-3">
          <button type="submit" class="btn btn-success" [disabled]="password == '' || password != password_confirmation || loading || password.length < 8 ">

            <i  class="fa fa-sign-in"></i>
            Passwort festlegen</button>
          <a class="btn btn-link" routerLink="/login">Zum Login?</a>
          <a class="btn btn-link" routerLink="/passwort-vergessen" >Passwort vergessen?</a>
        </div>
      </div>
    </form>
  </div>
</div>
