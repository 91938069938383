<div class="container-fluid">
  <div class="row-fluid pt-4 row justify-content-between">
    <div class="col-6">
      <h2>Neuen User anlegen</h2>
    </div>

  </div>
</div>

<div class="row-fluid" *ngIf="error">
  <div class="col-sm-12 col-md-6  push-md-3 alert alert-danger">
    <strong>Fehler</strong><br>
    {{ error }}

  </div>
</div>


<div class="row-fluid" *ngIf="success">
  <div class="col-sm-12 col-md-6  push-md-3 alert alert-success">
    <strong>OK</strong><br>
    {{ success }}

  </div>
</div>


<div class="container-fluid">

  <form [formGroup]="form" class="form form-horizontal"  (submit)="sendInvitation()">


    <div class="row">
      <div class="col-sm-12 col-md-6 push-md-3">
        <div class="form-group">
          <div class="input-group mb-2 mr-sm-2 mb-sm-0">
            <input type="text" name="first_name" class="form-control" id="first_name"
                   formControlName="first_name"
                   placeholder="Vorname" required autofocus autocomplete="false">
          </div>
          <div *ngIf="form.controls['first_name'].invalid && (form.controls['first_name'].dirty || form.controls['first_name'].touched)" class="alert alert-danger">
            <div *ngIf="form.controls['first_name'].errors.required">
              Pflichtfeld
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 push-md-3">
        <div class="form-group">
          <div class="input-group mb-2 mr-sm-2 mb-sm-0">
            <input type="text" name="last_name" class="form-control" id="last_name"
                   formControlName="last_name"
                   placeholder="Nachname" required  autocomplete="false">
          </div>

          <div *ngIf="form.controls['last_name'].invalid && (form.controls['last_name'].dirty || form.controls['last_name'].touched)" class="alert alert-danger">
            <div *ngIf="form.controls['last_name'].errors.required">
              Pflichtfeld
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-sm-12 col-md-6 push-md-3">
        <div class="form-group">
          <div class="input-group mb-2 mr-sm-2 mb-sm-0">
            <input type="text" name="email" class="form-control" id="email"
                   formControlName="email"
                   placeholder="email@example.com" required email autocomplete="false">
          </div>
          <div *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)" class="alert alert-danger">
            <div *ngIf="form.controls['email'].errors.required">
              Pflichtfeld
            </div>
            <div *ngIf="form.controls['email'].errors.email">
              keine gültige email adresse
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 push-md-3">
        <div class="form-group">
          <div class="input-group mb-2 mr-sm-2 mb-sm-0">
            <select id="role" class="form-control" formControlName="role">
              <option value="artist">Künstler</option>
              <option value="logistic">Logistik</option>
              <option value="agent">Agent</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <div *ngIf="form.controls['role'].invalid && (form.controls['role'].dirty || form.controls['role'].touched)" class="alert alert-danger">

            <div *ngIf="form.controls['role'].errors.required">
              Pflichtfeld
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-sm-12 col-md-6 push-md-3">
        <div class="form-group">

          Optional:
            <app-select-managed-contacts
                    (artistSelected)="form.controls['contact_id'].setValue($event.id)"
                    ></app-select-managed-contacts>



        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-sm-12 col-md-6 push-md-3">
        <div class="form-group">
          <button class="btn btn-primary" type="submit" [disabled]="form.invalid">Einladung senden</button>
        </div>
      </div>
    </div>


  </form>
</div>
