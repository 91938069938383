import { Work } from './work.model';

export class Role {
    id: number;
    name: string;
    voice: string;
    work: Work;
    size: string;
    fachpartie: boolean;

    public createFromDatabase(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.voice = data.voice;
        this.work = data.work;
        this.size = data.size;
        this.fachpartie = data.fachpartie;
        return this;
    }
}
