import { Component, OnInit } from '@angular/core';
import { CalendarComponent } from '../../calendar/calendar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { AuthService } from '../../../services/auth/auth.service';
import { AppUserService } from '../../../services/app-user.service';
import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
})
export class CreateAccountComponent implements OnInit {

  public static url = 'create-account/:code';
  public code: string;
  public email: string;
  public password_repeat = '';
  public password = '';
  public errorMessage = null;
  public disabled = false;
  public loading = false;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private auth: AuthService,
              private userService: AppUserService,
              private localStorage: LocalStorageService) {
  }

  ngOnInit() {
    // go to modals page if logged in
    this.localStorage.observe('user').subscribe(
      (user) => {
        if (user !== null) {
          this.router.navigate([ CalendarComponent.url ])
        }
      }
    );

    this.activatedRoute.params.subscribe(
        (params) => {
            if (params['code']) {
                this.code = params['code'];
                this.auth.checkInvitationCode(this.code).subscribe(
                    (response: any) => {
                        this.email =  response.data.email;
                        this.disabled = false;
                        this.errorMessage = null;
                    },

                    (res) => {
                        this.disabled = true;
                        console.log(res);
                        this.errorMessage = res.error.errors.message.status},
                )
            }
        }
    )

  }

  public savePassword() {
    // this.loading = true;
    this.errorMessage = null;

      this.auth.createAccountWithCode(
          this.email,
          this.password,
          this.code
      )
          .pipe(
              switchMap(
                  (result: any) => this.auth.login(this.email, this.password)
              )
          )
          .subscribe(
              (result: boolean) => {
                  if (result === true) {

                      setTimeout(() => {
                          this.router.navigate([ '/' ]).then(
                              () => console.log('navigated'),
                              (err) => console.error(err)
                          );
                      }, 100)

                  } else {
                      this.errorMessage = 'Fehler beim Passwort setzen';
                      this.loading = false;
                  }

              },
              (error) => {
                  console.error(error);
                  this.errorMessage = 'Fehler beim Passwort setzen';
                  this.loading = false;
              },
              () => {
                  this.loading = false;
              });


  }

}
