import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DataTableDirective } from 'angular-datatables';
import { WordTemplatesService } from '../../services/word-templates.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-word-templates',
  templateUrl: './word-templates.component.html',
})
export class WordTemplatesComponent implements OnInit {

  public static url = 'word-templates';


  @ViewChild(DataTableDirective)
  dataTable: DataTableDirective;
  dtOptions: DataTables.Settings | any = {};

  constructor(private http: HttpClient,
              private router: Router,
              private wordTemplateService: WordTemplatesService) {
  }


  public edit(id) {
    this.router.navigateByUrl('/' + WordTemplatesComponent.url + '/' + id)
  }




  public delete(id) {

    if (confirm('Wollen Sie diese Vorlage wirklich löschen?')) {
      this.wordTemplateService.delete(id).subscribe(
          () => {
            this.refreshPage();
            alert('Vorlage gelöscht')
          }


      )
    }

  }


  ngOnInit() {
    this.dtOptions = {
      order: [ [ 0, 'desc' ] ],
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      responsive: false,
      search: false,
      searching: false,
      language: {
        'sEmptyTable': 'Keine Daten in der Tabelle vorhanden',
        'sInfo': '_START_ bis _END_ von _TOTAL_ Einträgen',
        'sInfoEmpty': '0 bis 0 von 0 Einträgen',
        'sInfoFiltered': '(gefiltert von _MAX_ Einträgen)',
        'sInfoPostFix': '',
        'sInfoThousands': '.',
        'sLengthMenu': '_MENU_ Einträge anzeigen',
        'sLoadingRecords': 'Wird geladen...',
        'sProcessing': 'Bitte warten...',
        'sSearch': 'Suchen',
        'sZeroRecords': 'Keine Einträge vorhanden.',
        'oPaginate': {
          'sFirst': 'Erste',
          'sPrevious': 'Zurück',
          'sNext': 'Nächste',
          'sLast': 'Letzte'
        },
        'oAria': {
          'sSortAscending': ': aktivieren, um Spalte aufsteigend zu sortieren',
          'sSortDescending': ': aktivieren, um Spalte absteigend zu sortieren'
        },
        select: {
          rows: {
            _: '%d Zeilen ausgewählt',
            0: 'Zum Auswählen auf eine Zeile klicken',
            1: '1 Zeile ausgewählt'
          }
        }
      },
      ajax: (request, drawCallback, settings, test) => {
        const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
        this.http.post('dt/word-templates', request, { headers: headers }).subscribe(
            (data) => {
              console.log('returned data', data);
              drawCallback(data);
            },
            (err) => {
              console.error('error', err)
            }
        )
      },


      rowCallback: (row: Node, data, index: number) => {

        $(row).on('click', '.dt-button', ($event) => {
          const id: number = $($event.currentTarget).data('elem-id');
          const action: string = $($event.currentTarget).data('elem-action');
          switch (action) {
            case 'edit' :
              this.edit(id);
              break;
            case 'delete' :
              this.delete(id);
              break;
          }
        });

      },
      columns: [
        { data: 'id', visible: true, type: 'num', orderable: true },
        { data: 'name', visible: true },
        {
          data: null,
          searchable: false,
          orderable: false,
          name: 'Aktion',
          render: (data: any, type: any, row: any, meta: any) => {

            let elem = `<div class="btn-group-sm btn-group-justified btn-group">`;
            elem += '<button class="btn btn-sm btn-outline-primary dt-button" type="button"  data-elem-id="' +
                row.id + '" data-elem-action="edit" ><i class="fa fa-edit"></i></button>';


            elem += '<button *ngIf="row.id && !row.is_new_contact" class="btn btn-sm btn-outline-danger ' +
                'dt-button" type="button"  data-elem-id="' + row.id + '" data-elem-action="delete" ><i class="fa fa-trash"></i></button>';

            elem += `</div>`;

            return elem;


          },
          createdCell: (cell: Node, cellData: any, rowData: any, row: number, col: number) => {

          }
        },
      ],
    };
  }

  public refreshPage() {
    this.dataTable.dtInstance.then(
        (inst: DataTables.Api) => {
          inst.ajax.reload(null, false)
        }
    );
  }

}
