import { Pipe, PipeTransform } from '@angular/core';
import { EventModel } from '../models/event.model';

@Pipe({
  name: 'event_getDisplayName'
})
export class EventDisplayNamePipe implements PipeTransform {


  transform(value: EventModel, args?: any): any {
    return value.getDisplayName()
  }


}
