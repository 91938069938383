<div class="input-group input-group-sm">
  <select name="contact" class="form-control form-control-sm has-feedback" id="select-contact"
          [ngModel]="selectedArtist?.id"
          (ngModelChange)="contactSelected($event)"
  >
    <option [ngValue]="null">Kalender wählen</option>
    <option [ngValue]="cont.id" *ngFor="let cont of allContacts">{{cont.displayName}}</option>
  </select>

  <button
          *ngIf="showDeselectBtn"
          class="btn btn-sm btn-outline-danger" (click)="deselected()" ><i class="fa fa-times pointer" ></i></button>

</div>







