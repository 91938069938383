import { Injectable, Injector } from '@angular/core';
import { ContactModel } from '../../models/contact.model';
import { Moment } from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { OfflineBaseService } from './offline-base.service';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map, switchMap, tap } from 'rxjs/operators';
import { EventModel } from '../../models/event.model';
import { OfflineEventGroupService } from './offline-event-group.service';
import { EventGroup } from '../../models/event-group';
import { TravelGroup } from '../../models/travel-group';
import { OfflineTravelGroupService } from './offline-travel-group.service';

@Injectable()
export class OfflineEventService {
  private offlineEventGroupService: OfflineEventGroupService;
  private offlineTravelGroupService: OfflineTravelGroupService;
  constructor(
    private $injector: Injector,
    private base: OfflineBaseService
  ) {
    setTimeout(() => this.offlineEventGroupService = $injector.get(OfflineEventGroupService));
    setTimeout(() => this.offlineTravelGroupService = $injector.get(OfflineTravelGroupService));
  }

  allEventsOfArtist(artist: ContactModel, monthRange: { start: Moment, end: Moment }): Observable<EventModel[]> {

    const query = {
      from: 'events',
      where: {
        artist_id: artist.id,
        start: {
          '<': monthRange.end.unix() + 100
        }
        ,
        end: {
          '>': monthRange.start.unix() - 100
        }
      }
    };
    return fromPromise(
      this.base.connection.select(query)
    ).pipe(
      map((events: any[]) => events.sort((a: EventModel, b: EventModel) => {
         if (a.start < b.start) {
           return -1;
         }
         if (a.start > b.start) {
           return 1;
         }
         return 0;
      })),
      switchMap((group) => this.mapToEvent(group, true)),
    );
  }

  public getEventsOfEventGroup(eventGroup: EventGroup): Observable<EventModel[]> {
    return fromPromise(this.base.connection.select({
        from: 'events',
        where: {
          event_group_id: eventGroup.id
        }
      })
    ).pipe(
      switchMap((group) => this.mapToEvent(group, false)),
    )
  }

  public getEventsOfTravelGroup(travelGroup: TravelGroup): Observable<EventModel[]> {
    return fromPromise(this.base.connection.select({
        from: 'events',
        where: {
          travel_group_id: travelGroup.id
        }
      })
    ).pipe(
      switchMap((group) => this.mapToEvent(group, false)),
      switchMap((group) => this.mapToEvent(group, false)),
    )
  }


  private mapToEvent(input: any, includeGroups: boolean = false) {
    return of(input).pipe(
      switchMap((rawEventModels: any[]) =>
        // get all event groups and return them as array
        forkJoin(
          rawEventModels.map((item: any) => {
            if (includeGroups && item.event_group_id > 0) {
              return this.offlineEventGroupService
                .get(item.event_group_id, false)
                .pipe(
                  map((data: EventGroup) => {
                    return data ? data : {};
                  })
                )
            }
            if (includeGroups && item.travel_group_id > 0) {
              return this.offlineTravelGroupService
                .get(item.travel_group_id, false)
                .pipe(
                  map((data: { data: TravelGroup }) => {
                    return data ? data : {};
                  })
                )
            }
            return of({})
          })
        )
          .pipe(
            map((eventGroupsAndTravelGroups) => {
              // now we have all event_groups in results and all events in rawEventModels
              // merge them
              rawEventModels.forEach(
                (event: EventModel, index) => {

                  if (event.event_group_id && event.event_group_id > 0) {
                    rawEventModels[ index ].event_group = eventGroupsAndTravelGroups
                      .find((event_group: any) => event_group.id === event.event_group_id);
                  } else if (event.travel_group_id && event.travel_group_id > 0) {

                    rawEventModels[ index ].travel_group = eventGroupsAndTravelGroups
                      .find((event_group: any) => event_group.id === event.travel_group_id);
                  }
                }
              );
              return rawEventModels;
            })
          )
      ),
      map((value: any[]) => {
        return value.map(this.createFromIdb)
      }),
    )

  }

  private createFromIdb(data: any): EventModel {
    const model = new EventModel();

    data.updated_at = EventModel.parseDateFromDatabaseUnixTimestamp(data.updated_at);
    data.created_at = EventModel.parseDateFromDatabaseUnixTimestamp(data.created_at);
    data.start = EventModel.parseDateFromDatabaseUnixTimestamp(data.start);
    data.end = EventModel.parseDateFromDatabaseUnixTimestamp(data.end);
    data.type = parseInt(data.type.toString(), 10);
    data.cancelled = data.cancelled.toString() === '1';
    data.use_name_as_title = data.use_name_as_title.toString() === '1';

    model.createFromDatabase(data);
    return model;
  }


}
