import { Pipe, PipeTransform } from '@angular/core';
import { ContactsService } from '../services/contacts.service';
import { Observable } from 'rxjs';
import { ContactModel } from '../models/contact.model';

@Pipe({
  name: 'contactIdToContact'
})
export class ContactIdToContactPipe implements PipeTransform {

  constructor(
      private contactsService: ContactsService
  ) {}

  transform(value: any, args?: any): Observable<ContactModel> {
    if (value) {
      return this.contactsService.get(value)
    }
  }

}
