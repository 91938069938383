import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileModel } from '../../models/file.model';
import { SafeUrl } from '@angular/platform-browser';
import { FileDatabaseService } from '../../services/file-database.service';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
    selector: 'app-file-details',
    templateUrl: './file-details.component.html'
})
export class FileDetailsComponent {


    @Input() public readOnly = false;

    @Input() public file: FileModel;
    @Output() public fileDeleted: EventEmitter<FileModel> = new EventEmitter();

    public localFileUrl: SafeUrl = null;
    public serverUrl: SafeUrl = null;
    public isLoading = false;


    constructor(private fileService: FileDatabaseService,
                private storage: LocalStorageService) {

    }

    public deleteFile() {
        const doAction = confirm('Möchten Sie diese Datei wirklich löschen?');
        if (doAction === true) {
            // todo delete this file
            this.fileService.deleteFile(this.file).subscribe(
                () => this.fileDeleted.emit(this.file)
            )

        }
    }

    public downloadFile() {
        window.open(this.file.url + '?token=' + this.storage.retrieve('token'), '_blank')
    }


}
