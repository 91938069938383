import { Component, OnInit } from '@angular/core';
import { OfflineContactService } from '../../services/offline/offline-contact.service';
import { OfflineEventService } from '../../services/offline/offline-event.service';
import { OfflineEventGroupService } from '../../services/offline/offline-event-group.service';
import * as moment from 'moment';
import { EventService } from '../../services/event.service';
import { EventGroup } from '../../models/event-group';
import { EventGroupService } from '../../services/event-group.service';
import { ContactsService } from '../../services/contacts.service';

@Component({
  selector: 'app-offline-test',
  templateUrl: './offline-test.component.html',
  styleUrls: ['./offline-test.component.scss']
})
export class OfflineTestComponent implements OnInit {

  public contact_id = 2679;
  public event_id = 67022;
  public event_group_id = 20869;
  public events_contact_id = 2679;

  constructor(
    private offlineCOntactService: OfflineContactService,
    private offlineEventsService: OfflineEventService,
    private eventService: EventService,
    private contactService: ContactsService,
    private eventGroupService: EventGroupService,
    private offlineEventGroupsService: OfflineEventGroupService

  ) { }

  ngOnInit() {
  }


  getContact() {
    this.offlineCOntactService.get(this.contact_id).subscribe(
      (res) => {
        console.log('res', res)
      },
      (res) => {
        console.error('error', res)
      },
      () => {
        console.log('final')
      }
    )
  }

  getContactOnline() {
    this.contactService.get(this.contact_id).subscribe(
      (res) => {
        console.log('res', res)
      },
      (res) => {
        console.error('error', res)
      },
      () => {
        console.log('final')
      }
    )
  }

  getEventGroup() {
    this.offlineEventGroupsService.get(this.event_group_id).subscribe(
      (res) => {
        console.log('res', res)
      },
      (res) => {
        console.error('error', res)
      },
      () => {
        console.log('final')
      }
    )
  }
  getEventGroupOnline() {
    this.eventGroupService.get(this.contact_id).subscribe(
      (res) => {
        console.log('res', res)
      },
      (res) => {
        console.error('error', res)
      },
      () => {
        console.log('final')
      }
    )
  }


  getEvent() {
    this.offlineCOntactService.get(this.events_contact_id).subscribe(
      (cont) => {
        this.offlineEventsService.allEventsOfArtist(cont, {start: moment(0), end: moment(154905997583)}).subscribe(
          (res) => {
            console.log('res', res)
          },
          (res) => {
            console.error('error', res)
          },
          () => {
            console.log('final')
          }
        )
      }
    )

  }


  getEventOnline() {
    this.contactService.get(this.events_contact_id).subscribe(
      (cont) => {
        this.eventService.allEventsOfArtist(cont, {start: moment(0), end: moment(154905997583)}).subscribe(
          (res) => {
            console.log('res', res)
          },
          (res) => {
            console.error('error', res)
          },
          () => {
            console.log('final')
          }
        )
      }
    )

  }

}
