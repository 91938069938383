import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'app-email-not-verified',
  templateUrl: './email-not-verified.component.html',
})
export class EmailNotVerifiedComponent implements OnInit {

  public static url = 'please-confirm-email';
  public errorMessage: string;
  public successMessage: string;

  constructor(
      private af: AuthService,
      private router: Router
  ) {

  }

  ngOnInit() {}

  logout() {
    this.af.logout()
  }

  resendMail() {
    this.errorMessage = '';
  }

  didVerify() {
    this.errorMessage = '';
  }
}
