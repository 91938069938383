import { Injectable } from '@angular/core';
import { ContactModel } from '../models/contact.model';
import { ContactTypeEnum } from '../models/enums';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMapTo } from 'rxjs/operators';
import { OfflineContactService } from './offline/offline-contact.service';
import { OfflineModeService } from './offline/offline-mode.service';


@Injectable()
export class ContactsService {

    private tableName = 'contacts';

  constructor(private httpClient: HttpClient,
              private offlineModeService: OfflineModeService,
              private offlineContactsService: OfflineContactService) {
    }

    public searchContacts(search: string,
                          onlyManagedArtists: boolean,
                          companyId: number = null,
                          contactTypeFilter: ContactTypeEnum = null,
                          limit: number = null,
                          onlyWithCalendar = false
                          ): Observable<ContactModel[]> {

        let params = new HttpParams();
        if (onlyManagedArtists) {
            params = params.append('managed_artist', 'true')
        }
        if (onlyWithCalendar) {
            params = params.append('with_calendar_only', 'true')
        }
        if (search) {
            params = params.set('search', search)
        }
        if (limit) {
          params = params.set('limit', limit.toString())
        }

        if (companyId !== null) {
            params = params.set('company', companyId.toString())
        }
        if (contactTypeFilter !== null) {
            params = params.set('contact_type', contactTypeFilter.toString())
        }

        return this.httpClient.get( this.tableName,
          {
            headers: {'also-from-idb' : 'true'},
            params: params
          })
            .pipe(
              map((res: {data: any}) => {
                return res.data.contacts.map((elem) => this.createFromDatabase(elem))
                }
              ),
              catchError(error => {
                if (error.status === 0 || error.status === 504 && this.offlineModeService.isOffline()) {
                  return this.offlineContactsService.searchContacts(params)
                } else {
                  return throwError(error)
                }
              }),
            );
    }

    public get(contactId: number): Observable<ContactModel> {
        return this.httpClient.get( this.tableName + '/' + contactId
      , {headers: {'also-from-idb' : 'true'}})
          .pipe(
            map((res: { data: any }) => {
              const data = res.data.contact;
              return this.createFromDatabase(data)
            }),
            catchError(error => {
              if (error.status === 0 || error.status === 504) {
                return this.offlineContactsService.get(contactId);
              } else {
                console.error(error);
                throw error;
              }
            })
          );
    }

    public update(contactId: number, data): Observable<any> {
        data = this.parseForDatabase(data);
        return this.httpClient.put( this.tableName + '/' + contactId, data)
            .pipe(map((ret: {data: any}) => {
                return this.createFromDatabase(ret.data.contact);
            }))

    }

    public toggleNewContact(contactId: number): Observable<any> {

      return this.get(contactId).pipe(map(
        (contact: ContactModel) => {
          contact.is_new_contact = !contact.is_new_contact;
          return this.update(contactId, contact).subscribe(res => {
            return res
          })
        }
      ))
    }

    public create(data): Observable<ContactModel> {
        data = this.parseForDatabase(data);
        return this.httpClient.post( this.tableName, data)
            .pipe(map((ret: {data: any}) => {
                return this.createFromDatabase(ret.data.contact);
            }))
    }


    public search(term): Observable<ContactModel> {
        return this.httpClient.get( this.tableName, {
            params: {
                orderByChild: 'last_name',
                equalTo: term
            }
        })
            .pipe(map((data) => this.createFromDatabase(data)));
    }

    public remove(contactId: number) {
        return this.httpClient.delete( this.tableName + '/' + contactId);
    }

    private createFromDatabase(data: any): ContactModel {
        const model = new ContactModel();
        model.createFromDatabase(data);
        return model;
    }

    private parseForDatabase({ ...data }: ContactModel | any) {

        return data;
    }

}
