<div bsModal #modal="bs-modal" class="modal modal-lg" tabindex="-1" role="dialog"
     aria-hidden="true"
     [config]="{backdrop: 'static'}">


    <div class="modal-dialog">
        <div class="modal-content">


            <div class="modal-header">

                <h4 *ngIf="work" class="modal-title pull-left">{{work?.composer }}: {{work?.name}}</h4>
                <h4 *ngIf="!work" class="modal-title pull-left">Neues Werk</h4>

                <button type="button" class="close pull-right" (click)="modal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">

                    <div class="col-12">
                        <form autocomplete="off" [formGroup]="form" >


                            <div class="form-group row"
                                 [class.has-danger]="form.get('name').invalid">
                                <label class="form-control-label  col-4" for="name">Name/ Oper</label>
                                <div class="col-8">
                                    <input type="text"
                                           class="form-control  "
                                           formControlName="name"
                                           placeholder="Name"
                                           [class.form-control-danger]="form.get('name').invalid"
                                           id="name">

                                </div>

                                <div class="col-12">
                                    <div class="form-control-feedback"
                                         *ngIf="form.get('name').hasError('required')">
                                        Name ist ein Pflichtfeld
                                    </div>
                                </div>
                            </div>


                            <div class="form-group row"
                                 [class.has-danger]="form.get('short_name').invalid">
                                <label class="form-control-label  col-4" for="name">Kurzname
                                </label>
                                <div class="col-8">
                                    <input type="text"
                                           class="form-control  "
                                           formControlName="short_name"
                                           placeholder="Kurzname"
                                           [class.form-control-danger]="form.get('short_name').invalid"
                                           id="shortName">

                                </div>

                                <div class="col-12">
                                    <div class="form-control-feedback"
                                         *ngIf="form.get('short_name').hasError('required')">
                                        Kurzname ist ein Pflichtfeld
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row"
                                 [class.has-danger]="form.get('composer').invalid">
                                <label class="form-control-label  col-4" for="name">Komponist</label>
                                <div class="col-8">
                                    <input type="text"
                                           class="form-control  "
                                           formControlName="composer"
                                           placeholder="Komponist"
                                           [class.form-control-danger]="form.get('composer').invalid"
                                           id="composer">

                                </div>

                                <div class="col-12">
                                    <div class="form-control-feedback"
                                         *ngIf="form.get('composer').hasError('required')">
                                        Komponist ist ein Pflichtfeld
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row"
                                 [class.has-danger]="form.get('composer_short_name').invalid">
                                <label class="form-control-label  col-4" for="name">Komponist Kurzname</label>
                                <div class="col-8">
                                    <input type="text"
                                           class="form-control  "
                                           formControlName="composer_short_name"
                                           placeholder="Komponist"
                                           [class.form-control-danger]="form.get('composer_short_name').invalid"
                                           id="composerShortName">

                                </div>

                                <div class="col-12">
                                    <div class="form-control-feedback"
                                         *ngIf="form.get('composer_short_name').hasError('required')">
                                        Komponist Kurzname ist ein Pflichtfeld
                                    </div>
                                </div>
                            </div>

                          <div class="form-group row"
                               [class.has-danger]="form.get('category').invalid">
                            <label class="form-control-label  col-4" for="name">Kategorie</label>
                            <div class="col-8">
                              <select
                                     class="form-control"
                                     formControlName="category"
                                     [class.form-control-danger]="form.get('category').invalid"
                                     id="category">
                                <option *ngFor="let cat of categories" [ngValue]="cat">{{cat}}</option>
                              </select>

                            </div>

                            <div class="col-12">
                              <div class="form-control-feedback"
                                   *ngIf="form.get('composer_short_name').hasError('required')">
                                Komponist Kurzname ist ein Pflichtfeld
                              </div>
                            </div>
                          </div>


                        </form>
                    </div>
                </div>

                <div class="row" *ngIf="work?.id">
                    <div class="col-12">
                        <app-role-list
                                [work]="work">
                        </app-role-list>


                    </div>
                </div>
                <div class="modal-footer">

                        <span class="alert-danger alert" *ngIf="form.get('id').value === null">
                            Noch nicht gespeichert
                        </span>
                    <button type="button" (click)="updateOrCreate(false)" class="btn btn-primary">Speichern</button>
                    <button type="button" (click)="updateOrCreate(true)" class="btn btn-primary">Speichern & Schließen</button>
                </div>
            </div>

        </div>
    </div>

</div>
