import { Role } from './role.model';

export class Work {
    id: number;
    name: string;
    short_name: string;
    composer: string;
    composer_short_name: string;
    category: string;
    roles: Role[];

    public createFromDatabase(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.short_name = data.short_name;
        this.composer = data.composer;
        this.category = data.category;
        this.composer_short_name = data.composer_short_name;

        if (data.roles) {
            this.roles = data.roles;
        } else {
            this.roles = [];
        }
        return this;
    }
}
