import { Injectable, Injector } from '@angular/core';
import { RoleService } from './role.service';
import { Role } from '../models/role.model';
import { Work } from '../models/work.model';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class WorkService {
    private roleService: RoleService;

    constructor(private http: HttpClient,
                injector: Injector) {
        setTimeout(() => this.roleService = injector.get(RoleService));
    }

    public all(): Observable<Work[]> {
        return this.http.get('works')
          .pipe(
            map((res: {data: any}) => {
                return res.data.works.map((work) => this.createFromDatabase(work))
            })
          );
    }

    public searchWork(search: string): Observable<Work[]> {
        const args = { search: search };
        return this.http.post('works/search', args)
          .pipe(
            map((res: {data: any}) => {
              return res.data.works.map((work) => this.createFromDatabase(work));
            })
          )
            ;
    }


    public get(workId): Observable<Work> {
        return this.http.get('works/' + workId)
            .pipe(map((res: {data: any}) => this.createFromDatabase(res.data.work)));
    }

    public create(data: any): Observable<Work> {
        return this.http.post('works', data)
            .pipe(map((value: {data: any}) => this.createFromDatabase(value.data.work)));
    }

    public update(workId: string, data): Observable<Work> {
        return this.http.put('works/' + workId, data)
            .pipe(map((work: {data: any}) => this.createFromDatabase(work.data.work)));
    }

    public addRole(workId: string, role: Role): Observable<Work> {
        return this.http.post( 'works/' + workId + '/roles/', role)
            .pipe(map((work: {data: any}) => this.createFromDatabase(work.data.work)));
    }

    public removeRole(workId: string, roleId: string) {
        return this.http.delete('works/' + workId + '/roles/' + roleId)
            .pipe(map((work: {data: any}) => this.createFromDatabase(work.data.work)));
    }

    private createFromDatabase(data: any): Work {
        const model = new Work();
        model.createFromDatabase(data);
        return model;
    }

}
