<form autocomplete="off" [formGroup]="form">

    <div class="modal-body">


        <tabset>
            <tab heading='Daten'
                 [active]="tabs[0].active"
                 (select)="tabSelected(tabs[0], true)"
                 (deselect)="tabSelected(tabs[0], false)">
                <div class="container-fluid">
                    <div class="row mt-4">

                        <div class="col-6">

                            <div class="form-group row"
                                 [class.has-danger]="form.get('salutation').invalid">
                                <label class="form-control-label col-form-label-sm col-2" for="country">Anrede</label>

                                <div class="col-10">
                                    <input type="text"
                                           class="form-control  form-control-sm"
                                           formControlName="salutation"
                                           placeholder="Anrede"
                                           [class.form-control-danger]="form.get('salutation').invalid"
                                           id="salutation">
                                </div>
                            </div>

                            <div class="form-group row"
                                 [class.has-danger]="form.get('first_name').invalid || form.get('last_name').invalid">
                                <label class="form-control-label col-form-label-sm col-2" for="first_name">Name
                                    (Brief)</label>
                                <div class="col-5">
                                    <input type="text"
                                           class="form-control form-control-sm "
                                           formControlName="first_name"
                                           placeholder="Vorname"
                                           [class.form-control-danger]="form.get('first_name').invalid"
                                           id="first_name">

                                </div>
                                <div class="col-5">
                                    <input type="text"
                                           class="form-control  form-control-sm"
                                           formControlName="last_name"
                                           placeholder="Nachname"
                                           [class.form-control-danger]="form.get('last_name').invalid"
                                           id="last_name">
                                </div>
                                <div class="col-12">
                                    <div class="form-control-feedback"
                                         *ngIf="form.get('first_name').hasError('required')">
                                        Vorname ist ein Pflichtfeld
                                    </div>
                                    <div class="form-control-feedback"
                                         *ngIf="form.get('first_name').hasError('minlength')">
                                        Mindestens 2 Zeichen.
                                    </div>

                                    <div class="form-control-feedback"
                                         *ngIf="form.get('last_name').hasError('required')">
                                        Nachname ist ein Pflichtfeld
                                    </div>
                                    <div class="form-control-feedback"
                                         *ngIf="form.get('last_name').hasError('minlength')">
                                        Mindestens 2 Zeichen.
                                    </div>
                                </div>
                            </div>


                            <div class="form-group row"
                                 [class.has-danger]="form.get('company_id').invalid"
                            >
                                <label class="form-control-label col-form-label-sm col-2"><strong>Firma</strong></label>

                                <input type="hidden" formControlName="company_id">

                                <div class="col-10">
                                    <app-company-selector
                                            (onSubModalShow)="hasSubModal=true"
                                            (onSubModalHide)="hasSubModal=false"
                                            [companyId]="form.get('company_id').value"
                                            (companyChange)="setCompany($event)"></app-company-selector>
                                </div>
                            </div>


                            <div class="form-group row"
                                 [class.has-danger]="form.get('department').invalid">
                                <label class="form-control-label col-form-label-sm col-2"
                                       for="country">Abteilung</label>
                                <div class="col-10">
                                    <input type="text"
                                           class="form-control  form-control-sm"
                                           formControlName="department"
                                           placeholder="Abteilung"
                                           [class.form-control-danger]="form.get('department').invalid"
                                           id="department">
                                </div>
                            </div>


                            <div class="form-group row"
                                 [class.has-danger]="form.get('function').invalid">
                                <label class="form-control-label col-form-label-sm col-2" for="country">Function</label>
                                <div class="col-10">
                                    <input type="text"
                                           class="form-control  form-control-sm"
                                           formControlName="function"
                                           placeholder="Funktion"
                                           [class.form-control-danger]="form.get('function').invalid"
                                           id="function">
                                </div>
                            </div>


                            <div class="form-group row"
                                 [class.has-danger]="form.get('street').invalid">
                                <label class="form-control-label col-form-label-sm col-2" for="street">Adresse</label>


                                <div class="col-10">
                                    <input type="text"
                                           class="form-control  form-control-sm"
                                           formControlName="street"
                                           placeholder="Adresse"
                                           [class.form-control-danger]="form.get('street').invalid"
                                           id="street">
                                </div>
                            </div>

                            <div class="form-group row"
                                 [class.has-danger]="form.get('city').invalid">
                                <label class="form-control-label col-form-label-sm col-2" for="city">PLZ/ Stadt</label>
                                <div class="col-3">
                                    <input type="text"
                                           class="form-control form-control-sm "
                                           formControlName="zip"
                                           placeholder="PLZ"
                                           [class.form-control-danger]="form.get('zip').invalid"
                                           id="zip">

                                </div>
                                <div class="col-7">
                                    <input type="text"
                                           class="form-control  form-control-sm"
                                           formControlName="city"
                                           placeholder="Stadt"
                                           [class.form-control-danger]="form.get('city').invalid"
                                           id="city">
                                </div>

                            </div>

                            <div class="form-group row"
                                 [class.has-danger]="form.get('country').invalid">
                                <label class="form-control-label col-form-label-sm col-2" for="country">Land</label>


                                <div class="col-10">
                                    <input type="text"
                                           class="form-control  form-control-sm"
                                           formControlName="country"
                                           placeholder="Land"
                                           [class.form-control-danger]="form.get('country').invalid"
                                           id="country">
                                </div>
                            </div>


                            <div class="form-group row"
                                 [class.has-danger]="form.get('email').invalid">
                                <label class="form-control-label col-form-label-sm col-2" for="email">E-Mail</label>


                                <div class="col-10">
                                    <div class="input-group input-group-sm">
                                        <input type="email"
                                               class="form-control  form-control-sm"
                                               formControlName="email"
                                               placeholder="E-Mail"
                                               [class.form-control-danger]="form.get('email').invalid"
                                               id="email">

                                        <span class="input-group-btn">
                                                <a *ngIf="form.get('email').value !== '' && form.get('email').valid"
                                                   href="mailto:{{form.get('email').value}}"
                                                   class="btn btn-sm btn-outline-success"><i class="fa fa-at"></i></a>
                                            </span>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group row"
                                 [class.has-danger]="form.get('phone').invalid ">
                                <label class="form-control-label col-form-label-sm col-2" for="phone">Telefon</label>

                                <div class="col-10">
                                    <div class="input-group input-group-sm">

                                        <input type="tel"
                                               class="form-control  form-control-sm"
                                               formControlName="phone"
                                               placeholder="Telefon"
                                               [class.form-control-danger]="form.get('phone').invalid"
                                               id="phone">

                                        <span class="input-group-btn">
                                                <a *ngIf="form.get('phone').value !== ''"
                                                   href="tel:{{form.get('phone').value}}"
                                                   class="btn btn-sm btn-outline-success"><i
                                                        class="fa fa-phone"></i></a>
                                            </span>
                                    </div>

                                </div>
                            </div>

                            <div class="form-group row"
                                 [class.has-danger]="form.get('phone2').invalid">
                                <label class="form-control-label col-form-label-sm col-2" for="phone2">Telefon
                                    2</label>

                                <div class="col-10">
                                    <div class="input-group input-group-sm">
                                        <input type="text"
                                               class="form-control  form-control-sm"
                                               formControlName="phone2"
                                               placeholder="Telefon 2"
                                               [class.form-control-danger]="form.get('phone2').invalid"
                                               id="phone2">

                                        <span class="input-group-btn">
                                                <a *ngIf="form.get('phone2').value !== ''"
                                                   href="tel:{{form.get('phone2').value}}"
                                                   class="btn btn-sm btn-outline-success"><i
                                                        class="fa fa-phone"></i></a>
                                            </span>

                                    </div>
                                </div>
                            </div>

                            <div class="form-group row"
                                 [class.has-danger]="form.get('phone3').invalid">
                                <label class="form-control-label col-form-label-sm col-2" for="phone3">Telefon
                                    3</label>

                                <div class="col-10">
                                    <div class="input-group input-group-sm">
                                        <input type="text"
                                               class="form-control  form-control-sm"
                                               formControlName="phone3"
                                               placeholder="Telefon 3"
                                               [class.form-control-danger]="form.get('phone3').invalid"
                                               id="phone3">

                                        <span class="input-group-btn">
                                                <a *ngIf="form.get('phone3').value !== ''"
                                                   href="tel:{{form.get('phone3').value}}"
                                                   class="btn btn-sm btn-outline-success"><i
                                                        class="fa fa-phone"></i></a>
                                            </span>

                                    </div>
                                </div>
                            </div>


                            <div class="form-group row"
                                 [class.has-danger]="form.get('fax').invalid">
                                <label class="form-control-label col-form-label-sm col-2" for="fax">Fax</label>

                                <div class="col-10">
                                    <input type="tel"
                                           class="form-control  form-control-sm"
                                           formControlName="fax"
                                           placeholder="Fax"
                                           [class.form-control-danger]="form.get('fax').invalid"
                                           id="fax">
                                </div>
                            </div>

                            <div class="form-group row"
                                 [class.has-danger]="form.get('mobile').invalid">
                                <label class="form-control-label col-form-label-sm col-2" for="mobile">Handy</label>

                                <div class="col-10">
                                    <div class="input-group input-group-sm">
                                        <input type="tel"
                                               class="form-control  form-control-sm"
                                               formControlName="mobile"
                                               placeholder="Handy-Nr."
                                               [class.form-control-danger]="form.get('mobile').invalid"
                                               id="mobile">
                                        <span class="input-group-btn">
                                                <a *ngIf="form.get('mobile').value !== ''"
                                                   href="tel:{{form.get('mobile').value}}"
                                                   class="btn btn-sm btn-outline-success"><i
                                                        class="fa fa-phone"></i></a>
                                            </span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row"
                                 [class.has-danger]="form.get('mobile2').invalid">
                                <label class="form-control-label col-form-label-sm col-2" for="mobile2">Handy 2</label>

                                <div class="col-10">
                                    <div class="input-group input-group-sm">
                                        <input type="tel"
                                               class="form-control  form-control-sm"
                                               formControlName="mobile2"
                                               placeholder="Handy-Nr. 2"
                                               [class.form-control-danger]="form.get('mobile2').invalid"
                                               id="mobile2">
                                        <span class="input-group-btn">
                                                      <a *ngIf="form.get('mobile2').value !== ''"
                                                         href="tel:{{form.get('mobile2').value}}"
                                                         class="btn btn-sm btn-outline-success"><i
                                                              class="fa fa-phone"></i></a>
                                                    </span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row"
                                 [class.has-danger]="form.get('mobile3').invalid">
                                <label class="form-control-label col-form-label-sm col-2" for="mobile3">Handy 3</label>

                                <div class="col-10">
                                    <div class="input-group input-group-sm">
                                        <input type="tel"
                                               class="form-control  form-control-sm"
                                               formControlName="mobile3"
                                               placeholder="Handy-Nr. 3"
                                               [class.form-control-danger]="form.get('mobile3').invalid"
                                               id="mobile3">
                                        <span class="input-group-btn">
                                                      <a *ngIf="form.get('mobile3').value !== ''"
                                                         href="tel:{{form.get('mobile3').value}}"
                                                         class="btn btn-sm btn-outline-success"><i
                                                              class="fa fa-phone"></i></a>
                                                    </span>
                                    </div>
                                </div>
                            </div>


                        </div>


                        <div class="col-6">

                            <div class="form-group row"
                                 [class.has-danger]="form.get('type').invalid">
                                <label class="form-control-label col-form-label-sm col-2"
                                       for="type">Kategorie</label>
                                <div class="col-10">
                                    <select class="form-control form-control-sm"
                                            formControlName="type"
                                            [class.form-control-danger]="form.get('type').invalid"
                                            id="type">
                                        <option *ngFor="let contactType of contactTypes" [ngValue]="contactType">
                                            {{contactType|contactType}}
                                        </option>

                                    </select>
                                </div>
                            </div>


                            <div class="form-group row"
                                 *ngIf="form.get('type').value === 1 || form.get('type').value === 7 || form.get('type').value === 8">
                                <label class="form-control-label col-form-label-sm col-2"
                                       for="type">Künstler unter Vertrag?</label>
                                <div class="col-10">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input"
                                                   id="managed_artist"
                                                   [class.form-control-danger]="form.get('managed_artist').invalid"
                                                   type="checkbox"
                                                   formControlName="managed_artist"
                                            >

                                        </label>
                                    </div>

                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="form-control-label col-form-label-sm col-2"
                                       for="type">Kalender anzeigen?</label>
                                <div class="col-10">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input"
                                                   id="has_calendar"
                                                   [class.form-control-danger]="form.get('has_calendar').invalid"
                                                   type="checkbox"
                                                   formControlName="has_calendar"
                                            >

                                        </label>
                                    </div>

                                </div>
                            </div>


                            <div class="form-group row"
                                 [class.has-danger]="form.get('website').invalid">
                                <label class="form-control-label col-form-label-sm col-2" for="website">Website</label>

                                <div class="col-10">
                                    <input type="text"
                                           class="form-control  form-control-sm"
                                           formControlName="website"
                                           placeholder="Website"
                                           [class.form-control-danger]="form.get('website').invalid"
                                           id="website">
                                </div>
                            </div>

                            <div class="form-group row"
                                 [class.has-danger]="form.get('lang').invalid">
                                <label class="form-control-label col-form-label-sm col-2" for="lang">Sprache</label>

                                <div class="col-10">
                                    <select formControlName="lang" id="lang" class="form-control-sm">
                                        <option *ngFor="let l of languages" [ngValue]="l"
                                                [selected]="l == form.get('lang').value">{{ l|language}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group row"
                                 [class.has-danger]="form.get('is_new_contact').invalid">
                                <label class="form-control-label col-form-label-sm col-2" for="is_new_contact">Neuer
                                    Kontakt?</label>

                                <div class="col-10">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input"
                                                   id="is_new_contact"
                                                   [class.form-control-danger]="form.get('is_new_contact').invalid"
                                                   type="checkbox"
                                                   formControlName="is_new_contact"
                                            >

                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row"
                                 [class.has-danger]="form.get('birthday').invalid">
                                <label class="form-control-label col-form-label-sm col-2"
                                       for="birthday">Geburtsdatum</label>

                                <div class="col-10">
                                    <input type="text"
                                           class="form-control  form-control-sm"
                                           formControlName="birthday"
                                           placeholder="Geburtsdatum (TT.MM.JJJJ)"
                                           [class.form-control-danger]="form.get('birthday').invalid"
                                           id="birthday">
                                </div>
                            </div>


                            <div class="form-group"
                                 [class.has-danger]="form.get('internal_comment').invalid">
                                <label class="form-control-label " for="website">Kommentar</label>


                                <textarea formControlName="internal_comment"
                                          autosize
                                          class="form-control  form-control-sm"
                                          placeholder="Interner Kommentar"
                                          [class.form-control-danger]="form.get('website').invalid"
                                          id="internal_comment"></textarea>

                            </div>

                        </div>
                    </div>
                </div>
            </tab>
            <tab heading="Repertoire"
                 *ngIf="form.get('type').value === 1 || form.get('type').value === 7 || form.get('type').value === 8"
                 [active]="tabs[1].active" (select)="tabSelected(tabs[1], true)"
                 [disabled]="!form || form.get('id').value === null"
                 (deselect)="tabSelected(tabs[1], false)">


                <div class="container-fluid pt-4">

                    <div class="row">
                        <div class="col-10">
                            <app-select-work-role
                                #workRoleComp
                                (roleSelected)="setNewRole($event)"
                                (workSelected)="newWork = $event"
                                [workKey]="newWork?.id"
                                [roleKey]="newRole?.id"
                            ></app-select-work-role>
                        </div>
                        <div class="col-2">
                            <button type="button" class="btn btn-sm btn-primary" (click)="addRole()"
                                    [disabled]="!newRole?.id || form.get('roles').value?.includes(newRole.id)"
                                    [class.disabled]="!newRole?.id || form.get('roles').value?.includes(newRole.id)"
                            >Hinzufügen
                            </button>
                        </div>

                    </div>

                    <div class="row pt-2">
                        <div class="col-12">
                            <hr>
                            <table class="table table-striped ">
                                <thead>
                                <tr>
                                    <th>Komponist</th>
                                    <th>Werk</th>
                                    <th>Rolle</th>
                                    <th>Fachpartie?</th>
                                    <th>Stimme</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let role of form.get('roles').value ">

                                    <td>{{role.work?.composer}}</td>
                                    <td>
                                        {{role.work?.name}}
                                        ({{role.work?.short_name}} )
                                    </td>
                                    <td>{{role.name}}</td>
                                    <td><span [class.hidden]="!role.fachpartie">Ja</span> <span
                                            [class.hidden]="role.fachpartie">Nein</span>
                                    </td>
                                    <td>{{role.voice}}</td>
                                    <td>
                                        <button class="btn btn-outline-danger" type="button" (click)="removeRole(role)">
                                            Entfernen
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


            </tab>
            <tab heading="Künstlerdaten"
                 *ngIf="form.get('type').value === 1 || form.get('type').value === 7 || form.get('type').value === 8"
                 [active]="tabs[2].active" (select)="tabSelected(tabs[2], true)"
                 [disabled]="!form || form.get('id').value === null"
                 (deselect)="tabSelected(tabs[2], false)">


                <div class="container-fluid pt-4">
                    <div class="row">
                        <div class="col-6">
                            <div class="btn-group btn-group-sm">
                                <button type="button" class="btn btn-sm"
                                        [class.btn-success]="profileCopied"
                                        [class.btn-primary]="!profileCopied"
                                        (click)="copyProfileDe()"><i class="fa fa-copy"></i> Steckbrief (DE)
                                </button>

                                <button type="button" class="btn btn-sm"
                                        [class.btn-success]="profileCopiedEn"
                                        [class.btn-primary]="!profileCopiedEn"
                                        (click)="copyProfileEn()"><i class="fa fa-copy"></i> Steckbrief (EN)
                                </button>

                                <button type="button" class="btn btn-sm"
                                        [class.btn-success]="profileCopiedFr"
                                        [class.btn-primary]="!profileCopiedFr"
                                        (click)="copyProfileFr()"><i class="fa fa-copy"></i> Steckbrief (FR)
                                </button>

                            </div>

                        </div>

                        <div class="col-6">
                            <div class="btn-group btn-group-sm pull-right">
                                <button type="button" class="btn btn-sm btn-primary pull-right"
                                        (click)="downloadPersonalBogen()"><i class="fa fa-file-word-o"></i>
                                    Personalbogen
                                </button>
                            </div>

                        </div>

                    </div>


                    <div class="row mt-4">
                        <div class="col-6">

                            <div class="form-group row"
                                 [class.has-danger]="form.get('first_name_pass').invalid || form.get('last_name_pass').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="first_name2">Name
                                    (Pass)</label>
                                <div class="col-4">
                                    <input type="text"
                                           class="form-control form-control-sm "
                                           formControlName="first_name_pass"
                                           placeholder="Vorname (Pass)"
                                           [class.form-control-danger]="form.get('first_name_pass').invalid"
                                           id="first_name2">

                                </div>
                                <div class="col-5">
                                    <input type="text"
                                           class="form-control  form-control-sm"
                                           formControlName="last_name_pass"
                                           placeholder="Nachname (Pass)"
                                           [class.form-control-danger]="form.get('last_name_pass').invalid"
                                           id="last_name2">
                                </div>
                            </div>


                            <div class="form-group row"
                                 [class.has-danger]="form.get('artist_name_first_name').invalid || form.get('artist_name_last_name').invalid">
                                <label class="form-control-label col-form-label-sm col-3"
                                       for="first_name2">Künstlername</label>
                                <div class="col-4">
                                    <input type="text"
                                           class="form-control form-control-sm "
                                           formControlName="artist_name_first_name"
                                           placeholder="Künstlername (Vorname)"
                                           [class.form-control-danger]="form.get('artist_name_first_name').invalid"
                                           id="artist_name_first_name">

                                </div>
                                <div class="col-5">
                                    <input type="text"
                                           class="form-control  form-control-sm"
                                           formControlName="artist_name_last_name"
                                           placeholder="Künstlername (Nachname)"
                                           [class.form-control-danger]="form.get('artist_name_last_name').invalid"
                                           id="artist_name_last_name">
                                </div>
                            </div>


                            <div class="form-group row"
                                 [class.has-danger]="form.get('artist_name').invalid">
                                <label class="form-control-label col-form-label-sm col-3 " for="artist_name"
                                       style="text-decoration: line-through">Künstlername (veraltet)</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="artist_name"
                                           disabled
                                           id="artist_name"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('place_of_birth').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="place_of_birth">Geburtsort</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="place_of_birth"
                                           [class.form-control-danger]="form.get('place_of_birth').invalid"
                                           id="place_of_birth"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('nationality').invalid">
                                <label class="form-control-label col-form-label-sm col-3"
                                       for="nationality">Nationalität</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="nationality"
                                           [class.form-control-danger]="form.get('nationality').invalid"
                                           id="nationality"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('martial_status').invalid">
                                <label class="form-control-label col-form-label-sm col-3"
                                       for="martial_status">Familienstand</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="martial_status"
                                           [class.form-control-danger]="form.get('martial_status').invalid"
                                           id="martial_status"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('fiscal_address').invalid">
                                <label class="form-control-label col-form-label-sm col-3"
                                       for="fiscal_address">Steueradresse</label>
                                <div class="col-9">
                                              <textarea autosize class="form-control form-control-sm"
                                                        formControlName="fiscal_address"
                                                        [class.form-control-danger]="form.get('fiscal_address').invalid"
                                                        id="fiscal_address"></textarea>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('bank_name').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="bank_name">Name der
                                    Bank</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="bank_name"
                                           [class.form-control-danger]="form.get('bank_name').invalid"
                                           id="bank_name"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('bank_address').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="bank_address">Bank
                                    Filiale /
                                    Adresse</label>
                                <div class="col-9">
                                              <textarea autosize class="form-control form-control-sm"
                                                        formControlName="bank_address"
                                                        [class.form-control-danger]="form.get('bank_address').invalid"
                                                        id="bank_address"></textarea>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('bank_account_holder').invalid">
                                <label class="form-control-label col-form-label-sm col-3"
                                       for="bank_account_holder">Kontoinhaber</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="bank_account_holder"
                                           [class.form-control-danger]="form.get('bank_account_holder').invalid"
                                           id="bank_account_holder"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('bank_account_number').invalid">
                                <label class="form-control-label col-form-label-sm col-3"
                                       for="bank_account_number">Kontonummer</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="bank_account_number"
                                           [class.form-control-danger]="form.get('bank_account_number').invalid"
                                           id="bank_account_number"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('bank_code').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="bank_code">BLZ</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="bank_code"
                                           [class.form-control-danger]="form.get('bank_code').invalid"
                                           id="bank_code"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('bank_iban').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="bank_iban">IBAN</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="bank_iban"
                                           [class.form-control-danger]="form.get('bank_iban').invalid"
                                           id="bank_iban"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('bank_bic').invalid">
                                <label class="form-control-label col-form-label-sm col-3"
                                       for="bank_bic">SWIFT/BIC </label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="bank_bic"
                                           [class.form-control-danger]="form.get('bank_bic').invalid"
                                           id="bank_bic"/>
                                </div>
                            </div>


                            <div class="form-group row"
                                 [class.has-danger]="form.get('passport_number').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="passport_number">Pass
                                    Nummer</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="passport_number"
                                           [class.form-control-danger]="form.get('passport_number').invalid"
                                           id="passport_number"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('passport_authority').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="passport_authority">Pass
                                    Ausstellende Behörde: </label>
                                <div class="col-9">
                                              <textarea autosize class="form-control form-control-sm"
                                                        formControlName="passport_authority"
                                                        [class.form-control-danger]="form.get('passport_authority').invalid"
                                                        id="passport_authority"></textarea>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('passport_issue_date').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="passport_issue_date">Pass:
                                    Ausstellungsdatum</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="passport_issue_date"
                                           [class.form-control-danger]="form.get('passport_issue_date').invalid"
                                           id="passport_issue_date"/>
                                </div>
                            </div>


                        </div>

                        <div class="col-6">


                            <div class="form-group row"
                                 [class.has-danger]="form.get('passport_expirey_date').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="passport_expirey_date">Pass
                                    gültig
                                    bis:</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="passport_expirey_date"
                                           [class.form-control-danger]="form.get('passport_expirey_date').invalid"
                                           id="passport_expirey_date"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('vat_number').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="vat_number">Umsatzsteuer-Identifikationsnummer</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="vat_number"
                                           [class.form-control-danger]="form.get('vat_number').invalid"
                                           id="vat_number"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('finanzamt').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="finanzamt">Finanzamt
                                    (DE)</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="finanzamt"
                                           [class.form-control-danger]="form.get('finanzamt').invalid"
                                           id="finanzamt"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('tax_number').invalid">
                                <label class="form-control-label col-form-label-sm col-3"
                                       for="tax_number">Steuernummer</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="tax_number"
                                           [class.form-control-danger]="form.get('tax_number').invalid"
                                           id="tax_number"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('health_insurance_name').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="health_insurance_name">Krankenkasse
                                    Name</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="health_insurance_name"
                                           [class.form-control-danger]="form.get('health_insurance_name').invalid"
                                           id="health_insurance_name"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('health_insurance_type').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="health_insurance_type">Name
                                    Krankenkasse (Priv/ges)</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="health_insurance_type"
                                           [class.form-control-danger]="form.get('health_insurance_type').invalid"
                                           id="health_insurance_type"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('health_insurance_number').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="health_insurance_number">Krankenversicherungsnummer</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="health_insurance_number"
                                           [class.form-control-danger]="form.get('health_insurance_number').invalid"
                                           id="health_insurance_number"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('social_security_number_de').invalid">
                                <label class="form-control-label col-form-label-sm col-3"
                                       for="social_security_number_de">Sozialversicherungsnummer
                                    (DE)</label>
                                <div class="col-9">
                                              <textarea autosize class="form-control form-control-sm"
                                                        formControlName="social_security_number_de"
                                                        [class.form-control-danger]="form.get('social_security_number_de').invalid"
                                                        id="social_security_number_de"></textarea>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('conges_spectacles').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="conges_spectacles">N°
                                    Congés
                                    Spectacles</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="conges_spectacles"
                                           [class.form-control-danger]="form.get('conges_spectacles').invalid"
                                           id="conges_spectacles"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('codice_fiscale').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="codice_fiscale">Codice
                                    Fiscale</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="codice_fiscale"
                                           [class.form-control-danger]="form.get('codice_fiscale').invalid"
                                           id="codice_fiscale"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('social_security_number_us').invalid">
                                <label class="form-control-label col-form-label-sm col-3"
                                       for="social_security_number_us">Social
                                    Security Number (USA)</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="social_security_number_us"
                                           [class.form-control-danger]="form.get('social_security_number_us').invalid"
                                           id="social_security_number_us"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('enpals').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="enpals">Enpals (attention
                                    : only if
                                    artist cannot provide an A1) </label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="enpals"
                                           [class.form-control-danger]="form.get('enpals').invalid"
                                           id="enpals"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('mothers_name').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="mothers_name">Name der
                                    Mutter</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="mothers_name"
                                           [class.form-control-danger]="form.get('mothers_name').invalid"
                                           id="mothers_name"/>
                                </div>
                            </div>
                            <div class="form-group row"
                                 [class.has-danger]="form.get('fathers_name').invalid">
                                <label class="form-control-label col-form-label-sm col-3" for="fathers_name">Name des
                                    Vaters</label>
                                <div class="col-9">
                                    <input class="form-control form-control-sm"
                                           formControlName="fathers_name"
                                           [class.form-control-danger]="form.get('fathers_name').invalid"
                                           id="fathers_name"/>
                                </div>
                            </div>


                        </div>
                    </div>


                </div>


            </tab>

            <tab heading="Dateien"
                 [active]="tabs[3].active" (select)="tabSelected(tabs[3], true)"
                 [disabled]="!form || form.get('id').value === null"
                 (deselect)="tabSelected(tabs[3], false)"
            >
                <div class="container-fluid pt-4">
                    <div class="row">
                        <div class="col-12 bg-faded pt-4">

                            <h2>Dateien</h2>
                            <ng-container *ngFor="let file of form.get('attachments').value">
                                <app-file-details

                                        class="pb-1"
                                        *ngIf="file.group !== 'correspondence'"
                                        [file]="file"
                                        (fileDeleted)="removeFile($event)"
                                ></app-file-details>
                            </ng-container>


                            <div class="row pt-3">

                                <div class="col-12">
                                    <strong>Datei hochladen</strong>

                                </div>
                                <div class="col-12">


                                    <!-- [disabled]="!readOnly"
                                    (addedfile)="console.log($event)"
                                    -->

                                    <dropzone
                                        #dropZone
                                        [useDropzoneClass]="true"
                                        (success)="uploadFinished($event)"
                                    ></dropzone>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </tab>

            <tab heading="Gesprächsnotizen"
                 [disabled]="!form || form.get('id').value === null"
                 (select)="tabSelected(tabs[4], true)" (deselect)="tabSelected(tabs[4], false)">

                <app-contact-notes class="pt-4"
                                   [contact_id]="_contact?.id"></app-contact-notes>

            </tab>

            <tab heading="Korrespondenz"
                 [disabled]="!form || form.get('id').value === null"
                 [active]="tabs[5].active" (select)="tabSelected(tabs[5], true)"
                 (deselect)="tabSelected(tabs[5], false)"
            >
                <div class="container-fluid pt-4">
                    <div class="row">

                        <h2>Brief erstellen</h2>
                        <div class="col-12 bg-faded ">

                            <div class="row">
                                <div class="col-6 p-3">
                                    <div class="btn-group btn-group-sm pull-right">
                                        <button type="button" class="btn btn-sm btn-primary pull-right"
                                                *ngFor="let wordTemplate of wordTemplates"
                                                (click)="downloadDocument(wordTemplate)"><i
                                                class="fa fa-file-word-o"></i> {{ wordTemplate.name}}
                                        </button>


                                    </div>
                                </div>
                                <div class="col-6 p-3">
                                    <a href="./vorlage.docx" target="_blank"
                                       class="btn btn-sm btn-primary pull-right"><i class="fa fa-file-word-o"></i>
                                        Vorlage für Direkt-Upload</a>
                                    <dropzone
                                            [message]="'Direkt Upload: Word Vorlage hochladen um Datei zu erstellen'"
                                            [useDropzoneClass]="true"
                                            (success)="generateWordFile($event)"
                                    ></dropzone>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div class="row mt-2">
                        <h2>Korrespondenz</h2>
                        <div class="col-12 bg-faded">
                            <ng-container *ngFor="let file of form.get('attachments').value">
                                <app-file-details

                                        class="pb-1"
                                        *ngIf="file.group === 'correspondence'"
                                        [file]="file"
                                        (fileDeleted)="removeFile($event)"
                                ></app-file-details>
                            </ng-container>


                            <div class="row pt-3">

                                <div class="col-12">
                                    <strong>Korrespondenz hochladen</strong>

                                </div>
                                <div class="col-12">


                                    <!-- [disabled]="!readOnly"
                                    (addedfile)="console.log($event)"
                                    -->

                                    <dropzone
                                        #dropZone
                                        [message]="'Korrespondenz hier hochladen'"
                                        [useDropzoneClass]="true"
                                        (success)="uploadFinished($event,'correspondence')"
                                    ></dropzone>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </tab>
        </tabset>


    </div>
    <div class="modal-footer">

        <div class="col-6">
            <p>
        <span class="text-muted" *ngIf="_contact?.audit_first && _contact?.audit_latest">
              ID: {{form.get('id').value }} <br>
              Erstellt von {{ _contact?.audit_first?.user?.first_name}}  {{ _contact?.audit_first?.user?.last_name}}
            am  {{ _contact.created_at|date:'medium'}}   <br>
              Bearbeitet von {{ _contact?.audit_latest?.user?.first_name}}  {{ _contact?.audit_latest?.user?.last_name}}
            am {{ _contact.updated_at|date:'medium'}}
        </span>
            </p>

        </div>

        <div class="col-6  text-right">
            <app-delete-contact-btn
                    (onDelete)="onContactDeleted()"
                    [contactId]="form.get('id').value"
                    *ngIf="form.get('is_new_contact').value==false"
            ></app-delete-contact-btn>


            <span class="alert-danger alert mr-2" *ngIf="form.get('id').value === null">
                            Noch nicht gespeichert.
                </span>
            <button type="button" (click)="updateOrCreate(false)" class="btn btn-primary">Speichern</button>
            <button type="button" (click)="updateOrCreate(true)" class="btn btn-primary">Speichern & Schließen</button>
        </div>
    </div>


</form>
