import {
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CompanyModalComponent } from './company-modal/company-modal.component';
import { AllCompaniesModalComponent } from './all-companies-modal/all-companies-modal.component';
import { ContactsService } from '../../services/contacts.service';
import { ContactModel } from '../../models/contact.model';
import { ContactTypeEnum } from '../../models/enums';
import { AllContactsModalComponent } from '../../pages/modals/all-contacts-modal/all-contacts-modal.component';

import { distinctUntilChanged } from 'rxjs/observable/add';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ModalComponent } from '../../pages/modals/modal/modal.component';
import { OfflineModeService } from '../../services/offline/offline-mode.service';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'app-contact-selector',
  templateUrl: './contact-selector.component.html'
})
export class ContactSelectorComponent implements OnInit {


  @Input() set selectedContactId(contactId: number) {
    if (contactId == null || contactId === undefined) {
      return;
    }
    if (this.selectedContact && this.selectedContact.id && contactId === this.selectedContact.id) {
      console.log('id not changed');
      return
    }
    this.isLoading = true;
    this.contactService.get(contactId).subscribe(
      (contact: ContactModel) => {
        this.selectedContact = contact;
        this.query = contact.first_name + ' ' + contact.last_name;
        this.isLoading = false;
        this.cdr.markForCheck();
      },
      () => {
        this.isLoading = false;
      }
    )

  }

  @Input() public contactTypeFilter: ContactTypeEnum = null;
  @Input() public onlyManagedArtists = false;
  @Input() public readOnly: boolean;
  @Input() public dirty: boolean;
  public offline = false;

  @Input() set filterToCompanyId(companyId: number) {
    this._filterToCompanyId = companyId;
  }

  @Output() public onSubModalHide: EventEmitter<any>;
  @Output() public onSubModalShow: EventEmitter<any>;
  @Output() public contactChange = new EventEmitter<ContactModel>();


  @ViewChild('contactModal') contactModal: ModalComponent;
  @ViewChild('allContactsModal') public allContactsModal: ModalComponent;


  public hasSubModal = false;
  public _contact: ContactModel| any;
  public isLoading = false;
  private _filterToCompanyId = null;


  public showAllContactsModal = false;

  public selectedContact: ContactModel;
  public searchResults: Observable<ContactModel[]>;
  public query = '';

  private lastKeyword: string;

  constructor(private elRef: ElementRef,
              private applicationRef: ApplicationRef,
              private contactService: ContactsService,
              private cdr: ChangeDetectorRef,
              private offlineModeService: OfflineModeService
  ) {
    this.offlineModeService.offlineStatusChange$.subscribe(
      (isOffline) => {
        this.offline = isOffline;
      }
    );
    this.offline = this.offlineModeService.offline.getValue();
    this.onSubModalHide = new EventEmitter();
    this.onSubModalShow = new EventEmitter();


    this.searchResults = new Observable((observer: any) => {
      // Runs on every search
      if (this.query !== this.lastKeyword || this.query === '') {
        observer.next(this.query);
        this.lastKeyword = this.query
      }


    }).pipe(
      switchMap((keyword: string) => {

        this.lastKeyword = keyword;
        return this.contactService.searchContacts(
          keyword,
          this.onlyManagedArtists,
          this._filterToCompanyId,
          this.contactTypeFilter,
        )


      })
    )


  }

  ngOnInit() {

  }


  public subModalShow($event) {
    this.hasSubModal = true;
    this.onSubModalShow.emit($event)
  }

  public subModalHide($event) {
    this.hasSubModal = false;
    this.onSubModalHide.emit($event)
  }

  public search(contact: ContactModel) {
    return JSON.stringify(contact)
  }

  public ensureOnlyAllowedValue() {
    const elem = this.elRef.nativeElement.querySelector('#searchContact');

    if (!this.selectedContact) {
      elem.value = '';
    } else {
      elem.value = this.selectedContact.first_name + ' ' + this.selectedContact.last_name || '';
    }
  }

  public contactSelected(selectedItem: { item: ContactModel }) {
    console.log('contactSelected', selectedItem);
    this.selectContact(selectedItem.item);
  }

  public selectContact(contact: ContactModel) {
    this.query = contact.first_name + ' ' + contact.last_name;
    this.selectedContact = contact;
    this.contactChange.emit(this.selectedContact);
    this.allContactsModal.hide()
  }


  public editContact() {
    this.contactModal.show();
    this._contact = this.selectedContact
  };

  public addContact() {
    let companyId = this.selectedContact && this.selectedContact.company_id ? this.selectedContact.company_id : null;
    if (companyId === null) {
      if (this._filterToCompanyId !== null) {
        companyId = this._filterToCompanyId;
      }
    }
    this.contactModal.show();
    this._contact = {
      company_id: companyId,
      type: this.contactTypeFilter
    };
  };

  public findContact() {
    this.showAllContactsModal = true;
    this.allContactsModal.show();
  };

  public deleteContact() {
    this.selectedContact = null;
    this.query = '';
    this.contactChange.emit(null);
  }
}
