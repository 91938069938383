import { Pipe, PipeTransform } from '@angular/core';
import { CostTypeEnum } from '../models/enums';

@Pipe({
  name: 'costType'
})
export class CostTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case CostTypeEnum.fix :
        return 'Fix';
      case CostTypeEnum.limit:
        return 'Limit';
      case CostTypeEnum.payedByEventOrganizer:
        return 'bezahlt Veranstalter';
      case CostTypeEnum.willNotBePayed:
        return 'wird nicht übernommen';
      case CostTypeEnum.seeNotes:
        return 'Siehe Notizen';
      default:
        return 'Sonstige';
    }
  }

}
