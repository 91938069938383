<div class="container-fluid">
    <div class="row pt-4 row justify-content-between">
        <div class="col-12">
            <h2>Termine</h2>
        </div>
    </div>

    <div class="row  pt-4">
        <div class="col-12">


            <form class="input-group" (ngSubmit)="searchIt(query)">
                <input type="text" class="form-control" [(ngModel)]="query" [ngModelOptions]="{standalone:true}" />
                <button type="submit" class="button btn-primary btn">Suche</button>
            </form>
        </div>
        <div class="col-12">

            <table #dataTable id="eventgroup-table" datatable [dtOptions]="dtOptions"
                   class="table table-striped row-border hover">
                <thead>
                <tr>
                    <th *ngFor="let col of columnDef" class="px-1">

                        <ng-container *ngIf="col.filter?.type === 'select'">
                            <span style="box-sizing: border-box ">
                            <select class="form-control form-control-sm" [name]="col.data"
                                    (change)="filter(col.data, $event.target.value)">
                                <option [value]="null">Alle</option>
                                <option *ngFor="let elem of col.filter.filter_values"
                                        [value]="elem.value">{{elem.name}}</option>
                            </select>
                                </span>
                        </ng-container>


                        <ng-container *ngIf="col.filter?.type == 'contact'">


                            <app-select-managed-contacts
                                    [showDeselectBtn]="true"
                                    [selectedArtist]="_filteredToArtist"
                                    (artistSelected)="filterToArtist($event)"
                            ></app-select-managed-contacts>

                        </ng-container>


                        <ng-container *ngIf="col.filter?.type == 'name'">
                            <input type="search" placeholder="Name" class="form-control form-control-sm" [(ngModel)]="_filteredToName" (change)="filterName()" />
                        </ng-container>



                        <ng-container *ngIf="col.filter?.type == 'work'">


                            <input type="search" placeholder="Werk" class="form-control form-control-sm" [(ngModel)]="_filteredToWork" (change)="filterWork()" />

                        </ng-container>

                        <ng-container *ngIf="col.filter?.type == 'role'">


                            <input type="search" placeholder="Rolle" class="form-control form-control-sm" [(ngModel)]="_filteredToRole" (change)="filterRole()" />

                        </ng-container>


                        <app-company-selector *ngIf="col.filter?.type == 'company'"
                                              [type]="2"
                                              [showAdd]="false"
                                              [showDelete]="true"
                                              [showEdit]="false"
                                              [showSearch]="false"
                                              [companyId]="_filteredLocationId"
                                              (companyChange)="filterToLocation($event)"
                        ></app-company-selector>


                </tr>
                <tr>
                    <th *ngFor="let col of columnDef">
                        {{col.title}}</th>
                </tr>
                </thead>
                <tbody></tbody>

                <tfoot>
                <tr>
                    <th *ngFor="let col of columnDef">{{col.title}}</th>
                </tr>
                </tfoot>

            </table>


        </div>


    </div>
</div>
