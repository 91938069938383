
export class FileModel {
  artist_id: number;
  event_group_id: number;
  filename: string;
  group: string;
  filesize: number;
  filetype: string;
  key: string;
  url: string;
  uuid: string;

    public createFromDb(values) {
        this.artist_id = values.artist_id;
        this.event_group_id = values.event_group_id;
        this.filename = values.filename;
        this.filesize = values.filesize;
        this.filetype = values.filetype;
        this.group = values.group;
        this.key = values.key;
        this.url = values.url;
        this.uuid = values.uuid;
    }
}
