import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { WordTemplate } from '../models/word-template.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class WordTemplatesService {

    constructor(private http: HttpClient) {
    }

    public index(): Observable<WordTemplate[]> {
        return this.http.get('word-templates/')
            .pipe(
                map((data: any) => {
                    console.log(data.data.wordTemplates.data);
                        return data.data.wordTemplates.data
                    }
                )
            )
    }

    public get(id: number): Observable<WordTemplate> {
        return this.http.get( 'word-templates/' + id)
            .pipe(
                map((data: any) => {
                        return data.data
                    }
                )
            )
    }


    public create(wordTemplate): Observable<WordTemplate> {
        return this.http.post( 'word-templates/', wordTemplate).pipe(
            map((data: any) => {
                    return data.data
                }
            )
        )
    }

    public update(wordTemplate: WordTemplate): Observable<WordTemplate> {
        return this.http.put('word-templates/' + wordTemplate.id, wordTemplate).pipe(
            map((data: any) => {
                    return data.data
                }
            )
        )
    }

    public delete(id: number) {
        return this.http.delete('word-templates/' + id)
    }


}
