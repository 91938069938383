import {
  AfterViewInit,
  ApplicationRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements OnInit, AfterViewInit {


  @ViewChild('modal') public modal: ModalDirective;
  @ViewChild('modalTemplate') public modalTemplate: TemplateRef<any>;
  @Input() public modalContent: ViewContainerRef;

  @Input() public hasSubModal: boolean;
  @Input() public smallModal = false;
  @Input() public modalFooter: TemplateRef<ViewContainerRef>;
  @Input() public title: string;


  @Output() public onHide: EventEmitter<any> = new EventEmitter();
  @Output() public onShow: EventEmitter<any> = new EventEmitter();


  public isShown = false;
  constructor(private applicationRef: ApplicationRef) {
    this.onShow.subscribe( () => console.log('show modal') );
    this.onHide.subscribe( () => console.log('hide modal') );
  }

  ngAfterViewInit() {
    this.modal.onShow.subscribe(
      (hide) => this.onShow.next(true)
    );
    this.modal.onHide.subscribe(
      (hide) => this.onHide.next(true)
    );
  }

  // needed to no nest bootstrap modals
  getRootViewContainerRef(): ViewContainerRef {
    const appInstance = this.applicationRef.components[ 0 ].instance;
    if (!appInstance.viewContainerRef) {
      const appName = this.applicationRef.componentTypes[ 0 ].name;
      throw new Error(`Missing 'applicationRef' declaration in ${appName} constructor`);
    }
    return appInstance.viewContainerRef;
  }


  ngOnInit() {
    this.getRootViewContainerRef().createEmbeddedView(this.modalTemplate);
  }

  public show() {
    this.isShown = true;
    this.modal.show();
  }

  public hide() {
    this.isShown = false;
    console.log('hide modal', this.modal);
    this.modal.hide()
  }

}
