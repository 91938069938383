import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { SyncStateEnum } from '../../models/enums';
import { OfflineModeService } from '../../services/offline/offline-mode.service';
import { OfflineBaseService } from '../../services/offline/offline-base.service';
import { of } from 'rxjs';
import { catchError, debounceTime, switchMap, takeWhile, tap } from 'rxjs/operators';
import { TableCountService } from '../../services/table-count.service';

@Component({
    selector: 'app-sync',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './sync.component.html'
})
export class SyncComponent implements OnInit {

    public static url = 'sync';
    public status = '';
    public btnDisabled = false;
    public importRunning = false;
    public importFinished = false;
    public importError = false;
    public isUpdatingTableCount = false;
    public numberOfImported = 0;

    public dataUpdated: EventEmitter<void> = new EventEmitter();

    public state: SyncStateEnum = SyncStateEnum.new;
    public countOffline = {
        'companies': 0,
        'contacts': 0,
        'events': 0,
        'event_groups': 0,
        'travel_groups': 0,
        'roles': 0,
        'works': 0,
        'contact_notes': 0,
        'contact_role': 0,
        'file_event_group': 0,
        'sum': 0,
    };
    public countOnline = {
        'companies': 0,
        'contacts': 0,
        'events': 0,
        'event_groups': 0,
        'travel_groups': 0,
        'roles': 0,
        'works': 0,
        'contact_notes': 0,
        'contact_role': 0,
        'file_event_group': 0,
        'sum': 0,
    };


    constructor(
        public cdr: ChangeDetectorRef,
        private offlineModeService: OfflineModeService,
        private offlineBaseService: OfflineBaseService,
        private tableCountService: TableCountService
    ) {

        this.dataUpdated.pipe(
            debounceTime(500)
        ).subscribe(
            () => {
                this.updateTableCounts();
                this.cdr.detectChanges();
            }
        )
    }


    ngOnInit() {
        this.updateTableCounts();
    }

    createDbBackup() {
        this.btnDisabled = true;
        this.importRunning = true;
        this.importFinished = false;
        this.numberOfImported = 0;

        this.status = 'Prüfe Serviceworker...';
        this.offlineModeService.checkServiceWorker()
            .pipe(
                tap((respomse) => {
                    this.countOffline = {
                        'companies': 0,
                        'contacts': 0,
                        'events': 0,
                        'event_groups': 0,
                        'travel_groups': 0,
                        'roles': 0,
                        'works': 0,
                        'contact_notes': 0,
                        'contact_role': 0,
                        'file_event_group': 0,
                        'sum': 0,
                    };
                    this.cdr.detectChanges();
                }),
                tap(() => {
                    this.status = 'Serviceworker OK';
                }),
                switchMap(() => {
                    return this.offlineModeService.resetDatabase()
                }),
                catchError((err, caught) => {
                    console.error(err, caught)
                    this.importError = true;
                    return of({ message: err, numberOfImported: 0 })
                }),
                takeWhile((response: { message: string, numberOfImported: number }) => {
                    console.log(response.message);
                    this.status = response.message;
                    if (response.numberOfImported) {
                        this.numberOfImported = response.numberOfImported;
                        this.cdr.detectChanges();
                    }
                    return response.message !== 'done';
                })
            ).subscribe(
            (response: { message: string, numberOfImported: number }) => {
                console.log('subscribe success called');

            },
            (err) => {
                console.error(err);
                this.importError = true;
                this.importRunning = false;
                this.importFinished = false;
            },
            () => {
                this.btnDisabled = false;
                this.importRunning = false;
                this.importFinished = true;

                console.log('complete');
                this.dataUpdated.emit();
            }
        );
    }


    public updateTableCounts() {

        if (this.isUpdatingTableCount) {
            return;
        }
        this.isUpdatingTableCount = true;

        this.countOffline = {
            'companies': 0,
            'contacts': 0,
            'events': 0,
            'event_groups': 0,
            'travel_groups': 0,
            'roles': 0,
            'works': 0,
            'contact_notes': 0,
            'contact_role': 0,
            'file_event_group': 0,
            'sum': 0,
        };

        this.countOnline = {
            'companies': 0,
            'contacts': 0,
            'events': 0,
            'event_groups': 0,
            'travel_groups': 0,
            'roles': 0,
            'works': 0,
            'contact_notes': 0,
            'contact_role': 0,
            'file_event_group': 0,
            'sum': 0,
        };


        this.offlineBaseService.count('companies')
            .pipe(
                tap((v) => {
                    this.countOffline.companies = v;
                    this.countOffline.sum += v;
                }),
                switchMap(
                    () => this.offlineBaseService.count('contacts')
                ),
                tap((v) => {
                    this.countOffline.contacts = v;
                    this.countOffline.sum += v;
                }),
                switchMap(
                    () => this.offlineBaseService.count('events')
                ),
                tap((v) => {
                    console.log('count offline events', v)
                    this.countOffline.events = v;
                    this.countOffline.sum += v;
                }),
                switchMap(
                    () => this.offlineBaseService.count('event_groups')
                ),
                tap((v) => {
                    this.countOffline.event_groups = v;
                    this.countOffline.sum += v;
                }),
                switchMap(
                    () => this.offlineBaseService.count('travel_groups')
                ),
                tap((v) => {
                    this.countOffline.travel_groups = v;
                    this.countOffline.sum += v;
                }),
                switchMap(
                    () => this.offlineBaseService.count('roles')
                ),
                tap((v) => {
                    this.countOffline.roles = v;
                    this.countOffline.sum += v;
                }),
                switchMap(
                    () => this.offlineBaseService.count('works')
                ),
                tap((v) => {
                    this.countOffline.works = v;
                    this.countOffline.sum += v;
                }),
                switchMap(
                    () => this.offlineBaseService.count('contact_notes')
                ),
                tap((v) => {
                    this.countOffline.contact_notes = v;
                    this.countOffline.sum += v;
                }),
                switchMap(
                    () => this.offlineBaseService.count('contact_role')
                ),
                tap((v) => {
                    this.countOffline.contact_role = v;
                    this.countOffline.sum += v;
                }),
                switchMap(
                    () => this.offlineBaseService.count('file_event_group')
                ),
                tap((v) => {
                    this.countOffline.file_event_group = v;
                    this.countOffline.sum += v;
                }),
                switchMap(
                    () => this.tableCountService.getCount('companies')
                ),
                tap((v) => {
                    this.countOnline.companies = v;
                    this.countOnline.sum += v;
                }),
                switchMap(
                    () => this.tableCountService.getCount('contacts')
                ),
                tap((v) => {
                    this.countOnline.contacts = v;
                    this.countOnline.sum += v;
                }),
                switchMap(
                    () => this.tableCountService.getCount('events')
                ),
                tap((v) => {
                    this.countOnline.events = v;
                    this.countOnline.sum += v;
                }),
                switchMap(
                    () => this.tableCountService.getCount('event_groups')
                ),
                tap((v) => {
                    this.countOnline.event_groups = v;
                    this.countOnline.sum += v;
                }),
                switchMap(
                    () => this.tableCountService.getCount('travel_groups')
                ),
                tap((v) => {
                    this.countOnline.travel_groups = v;
                    this.countOnline.sum += v;
                }),
                switchMap(
                    () => this.offlineBaseService.count('roles')
                ),
                tap((v) => {
                    this.countOnline.roles = v;
                    this.countOnline.sum += v;
                }),
                switchMap(
                    () => this.offlineBaseService.count('works')
                ),
                tap((v) => {
                    this.countOnline.works = v;
                    this.countOnline.sum += v;
                }),
                switchMap(
                    () => this.offlineBaseService.count('contact_notes')
                ),
                tap((v) => {
                    this.countOnline.contact_notes = v;
                    this.countOnline.sum += v;
                }),
                switchMap(
                    () => this.offlineBaseService.count('contact_role')
                ),
                tap((v) => {
                    this.countOnline.contact_role = v;
                    this.countOnline.sum += v;
                }),
                switchMap(
                    () => this.offlineBaseService.count('file_event_group')
                ),
                tap((v) => {
                    this.countOnline.file_event_group = v;
                    this.countOnline.sum += v;
                }),
            )
            .subscribe((v) => {

                this.isUpdatingTableCount = false;
                this.cdr.detectChanges();
            },
                (err) => {
                    this.isUpdatingTableCount = false;
                    console.error(err)
                });
    }


}
