import { ContactModel } from './contact.model';
import { UserModel } from './user.model';
export class ContactNoteModel  {
    id: number;
    created_at: Date;
    updated_at: Date;
    contact: ContactModel;
    contact_id: number;
    user_id: number;
    user: UserModel;
    keywords: string|null;
    text: string|null;



    public createFromDatabase(data: any) {
        this.id = data.id;
        this.created_at = data.created_at;
        this.updated_at = data.updated_at;
        this.text = data.text;
        this.keywords = data.keywords;
/*
        const cont = new ContactModel();
        cont.createFromDatabase(data.contact);
        this.contact = cont;

        const user = new UserModel();
        user.createFromDatabase(data.user);
        this.user = user
*/

    }
}
