import { HostListener, Injectable } from '@angular/core';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Observable, Subject } from 'rxjs';





@Injectable()
export class HotkeyCommandService {

    public hotKeyEvents: Observable<KeyboardEvent>;

    private subject: Subject<KeyboardEvent>;

  constructor(private hotkeysService: HotkeysService) {

    this.subject = new Subject<KeyboardEvent>();
    this.hotKeyEvents = this.subject.asObservable();


    hotkeysService.add(new Hotkey('up', (event: KeyboardEvent, combo: string) => { this.subject.next(event); return true} ));
    hotkeysService.add(new Hotkey('shift+up', (event: KeyboardEvent, combo: string) => { this.subject.next(event); return true} ));
    hotkeysService.add(new Hotkey('down', (event: KeyboardEvent, combo: string) => { this.subject.next(event); return true} ));
    hotkeysService.add(new Hotkey('shift+down', (event: KeyboardEvent, combo: string) => { this.subject.next(event); return true} ));
    hotkeysService.add(new Hotkey('right', (event: KeyboardEvent, combo: string) => { this.subject.next(event); return true} ));
    hotkeysService.add(new Hotkey('shift+right', (event: KeyboardEvent, combo: string) => { this.subject.next(event); return true} ));
    hotkeysService.add(new Hotkey('left', (event: KeyboardEvent, combo: string) => { this.subject.next(event); return true} ));
    hotkeysService.add(new Hotkey('shift+left', (event: KeyboardEvent, combo: string) => { this.subject.next(event); return true} ));
    hotkeysService.add(new Hotkey('h', (event: KeyboardEvent, combo: string) => { this.subject.next(event); return true} ));
    hotkeysService.add(new Hotkey('home', (event: KeyboardEvent, combo: string) => { this.subject.next(event); return true} ));
    hotkeysService.add(new Hotkey('end', (event: KeyboardEvent, combo: string) => { this.subject.next(event); return true} ));
    hotkeysService.add(new Hotkey('enter', (event: KeyboardEvent, combo: string) => { this.subject.next(event); return true} ));
    hotkeysService.add(new Hotkey('pageup', (event: KeyboardEvent, combo: string) => { this.subject.next(event); return true} ));
    hotkeysService.add(new Hotkey('pagedown', (event: KeyboardEvent, combo: string) => { this.subject.next(event); return true} ));
    hotkeysService.add(new Hotkey('alt', (event: KeyboardEvent, combo: string) => { this.subject.next(event); return true} ));

  }
}
