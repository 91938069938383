<div class="input-group input-group-sm" *ngIf="isLoading">
  <i class="fa fa-spinner fa-spin"></i>
</div>
<div class="input-group input-group-sm" *ngIf="!isLoading">

  <ng-template #customItemTemplate let-contact="item" let-index="index">
    {{contact?.last_name}},<small> {{ contact?.first_name }} <span *ngIf="contact.city">({{contact?.city}})</span></small>

  </ng-template>

  <input placeholder="Suchen"
         #search
         [disabled]="readOnly || offline"
         id="searchContact"
         class="form-control has-feedback"
         [class.ng-dirty]="dirty"
         type="text"
         autocomplete="off"
         [(ngModel)]="query"
         (typeaheadOnSelect)="contactSelected($event)"
         (focusout)="ensureOnlyAllowedValue()"
         typeaheadOptionField="getName()"
         [typeaheadItemTemplate]="customItemTemplate"
         [typeaheadMinLength]="0"
         [typeaheadOptionsLimit]="100"
         [typeahead]="searchResults"

  >


  <span class="input-group-btn">
        <button class="btn btn-sm  btn-outline-primary" *ngIf="selectedContact && !readOnly && !offline"

                type="button"
                (click)="editContact()"><i class="fa fa-edit"></i>
        </button>
    </span>
  <span class="input-group-btn" *ngIf="!readOnly && !offline">
        <button class="btn btn-sm btn-outline-primary"
                type="button"
                (click)="addContact()">
            <i class="fa fa-plus"></i>
        </button>
    </span>

  <span class="input-group-btn" *ngIf="!readOnly && !offline">
        <button class="btn btn-sm btn-outline-primary"
                type="button"
                (click)="findContact()">
            <i class="fa fa-search"></i>
        </button>
    </span>
  <span class="input-group-btn">
        <button *ngIf="selectedContact !== null && !readOnly && !offline"
                class="btn btn-sm btn-outline-danger"
                type="button"
                (click)="deleteContact()">
            <i class="fa fa-trash"></i>
        </button>
    </span>
</div>



<app-modal #contactModal
           [modalContent]="mC"
           title="Kontakt"
           [modalFooter]="modalFooter"
           (onShow)="subModalShow($event)"
           (onHide)="subModalHide($event)"
></app-modal>
<ng-template #mC>
  <app-edit-contact
    (hide)="contactModal.hide()"
    [contact]="_contact"
    (contactUpdated)="selectContact($event)"
    (contactCreated)="selectContact($event)"
    (contactDeleted)="selectContact(null)"
  ></app-edit-contact>
</ng-template>
<ng-template #modalFooter></ng-template>





<app-modal #allContactsModal
           [modalContent]="mc2"
           title="Alle Kontakte"
           (onShow)="subModalShow($event)"
           (onHide)="subModalHide($event)"
></app-modal>
<ng-template #mc2>
  <div class="row-fluid">
    <app-contacts
      [useHotKeys]="false"
      [showSelectButton]="true"
      (onSubModalHide)="allContactsModal.hasSubModal = false"
      (onSubModalShow)="allContactsModal.hasSubModal = true"
      (contactSelected)="selectContact($event)"></app-contacts>
  </div>

</ng-template>
