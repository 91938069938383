<div class="row">
  <div class="col-12">
    <h1>Contacts</h1>
    <input type="number" [(ngModel)]="contact_id"/>
    <button class="btn btn-primary" (click)="getContact()">Contact</button>
    <button class="btn btn-primary" (click)="getContactOnline()">Contact Online</button>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <h1>EventGroup</h1>
    <input type="number" [(ngModel)]="event_group_id"/>
    <button class="btn btn-primary" (click)="getEventGroup()">Event Group</button>
    <button class="btn btn-primary" (click)="getEventGroupOnline()">Event Group Online</button>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <h1>Event</h1>
    <input type="number" [(ngModel)]="event_id"/>
    <input type="number" [(ngModel)]="events_contact_id"/>
    <button class="btn btn-primary" (click)="getEvent()">Event</button>
    <button class="btn btn-primary" (click)="getEventOnline()">EventOnline</button>
  </div>
</div>


