import { CostTypeEnum, CurrencyEnum, EventGroupStateEnum } from './enums';
import * as moment from 'moment';
import { EventModel } from './event.model';
import { Work } from './work.model';
import { FileModel } from './file.model';
import { BaseModel } from './base.model';
import { CompanyModel } from './company.model';
import { ContactModel } from './contact.model';

export class EventGroup extends BaseModel {
  id: number;
  state: EventGroupStateEnum;
  created_at: moment.Moment;
  updated_at: moment.Moment;
  location_id: number;
  location_contact_id: number;
  location_contact_for_artist_id: number;
  custom_title: string;
  use_custom_title: boolean;
  is_other_agency: boolean;
  is_new_production: boolean;

  is_guest_performance: boolean;
  guest_performance_name: string;

  work_id: number;
  work_name?: string;
  role_id?: string;
  director_id: number;
  conductor_id: number;
  notes_int?: string;
  notes_pub?: string;
  artist_id: number;
  events: EventModel[];

  fee: number;
  fee_different_for_events: boolean;
  rehearsal_fee?: number;
  rehearsal_fee_annotation?: string;
  rehearsal_fee_currency?: CurrencyEnum;
  currency: CurrencyEnum;
  all_in: boolean; // is the fee all in
  visible_for_artist: boolean;
  travel_cost: number;
  travel_cost_type: CostTypeEnum;
  travel_cost_currency: CurrencyEnum;
  hotel_cost: number;
  hotel_cost_type: CostTypeEnum;
  hotel_cost_currency: CurrencyEnum;
  contract_number: string;
  contract_requested: boolean;
  contract_received: boolean;
  contract_sent_to_artist: boolean;
  contract_signed_by_artist: boolean;
  contract_sent_to_location: boolean;
  contract_signed_by_location: boolean;
  contract_invoice_sent: boolean;

  contract_requested_time?: moment.Moment;
  contract_received_time?: moment.Moment;
  contract_sent_to_artist_time?: moment.Moment;
  contract_signed_by_artist_time?: moment.Moment;
  contract_sent_to_location_time?: moment.Moment;
  contract_signed_by_location_time?: moment.Moment;
  contract_invoice_sent_time?: moment.Moment;

  work?: Work;
  audit_first?: any;
  audit_latest?: any;
  attachments?: FileModel[];
  location?: CompanyModel;
  location_contact?: ContactModel;
  location_contact_for_artist?: ContactModel;

  history?: [ {
    date: Date,
    message: string
  } ];



  public createFromDatabase(data: any) {
    this.id = data.id;
    this.created_at = BaseModel.parseDateFromDatabase(data.created_at);
    this.updated_at = BaseModel.parseDateFromDatabase(data.updated_at);
    this.custom_title = data.custom_title;
    this.use_custom_title = data.use_custom_title;
    this.is_other_agency = data.is_other_agency;
    this.is_new_production = data.is_new_production;
    this.is_guest_performance = data.is_guest_performance;
    this.guest_performance_name = data.guest_performance_name;
    this.contract_number = data.contract_number;
    this.location_id = data.location_id;
    this.location_contact_id = data.location_contact_id;
    this.location_contact_for_artist_id = data.location_contact_for_artist_id;
    this.work_id = data.work_id;
    this.role_id = data.role_id;
    this.director_id = data.director_id;
    this.conductor_id = data.conductor_id;
    this.notes_int = data.notes_int;
    this.notes_pub = data.notes_pub;
    this.artist_id = data.artist_id;
    this.attachments = data.attachments;
    this.events = data.events;
    this.state = data.state;
    this.history = data.history;
    this.fee = data.fee;
    this.currency = data.currency;
    this.all_in = data.all_in;
    this.visible_for_artist = data.visible_for_artist;
    this.travel_cost = data.travel_cost;
    this.travel_cost_type = data.travel_cost_type;
    this.hotel_cost = data.hotel_cost;
    this.hotel_cost_type = data.hotel_cost_type;
    this.contract_requested = data.contract_requested;
    this.contract_received = data.contract_received;
    this.contract_sent_to_artist = data.contract_sent_to_artist;
    this.contract_signed_by_artist = data.contract_signed_by_artist;
    this.contract_sent_to_location = data.contract_sent_to_location;
    this.contract_signed_by_location = data.contract_signed_by_location;
    this.contract_invoice_sent = data.contract_invoice_sent;

    this.hotel_cost_currency = data.hotel_cost_currency;
    this.travel_cost_currency = data.travel_cost_currency;
    this.fee_different_for_events = data.fee_different_for_events;
    this.rehearsal_fee = data.rehearsal_fee;
    this.rehearsal_fee_annotation = data.rehearsal_fee_annotation;
    this.rehearsal_fee_currency = data.rehearsal_fee_currency;


    if (data.contract_requested_time) {
      this.contract_requested_time = BaseModel.parseDateFromDatabase(data.contract_requested_time);
    } else {
      this.contract_requested_time = null;
    }
    if (data.contract_received_time) {
      this.contract_received_time = BaseModel.parseDateFromDatabase(data.contract_received_time);
    } else {
      this.contract_received_time = null;
    }

    if (data.contract_sent_to_artist_time) {
      this.contract_sent_to_artist_time = BaseModel.parseDateFromDatabase(data.contract_sent_to_artist_time);
    } else {
      this.contract_sent_to_artist_time = null;
    }
    if (data.contract_signed_by_artist_time) {
      this.contract_signed_by_artist_time = BaseModel.parseDateFromDatabase(data.contract_signed_by_artist_time);
    } else {
      this.contract_signed_by_artist_time = null;
    }
    if (data.contract_sent_to_location_time) {
      this.contract_sent_to_location_time = BaseModel.parseDateFromDatabase(data.contract_sent_to_location_time);
    } else {
      this.contract_sent_to_location_time = null;
    }
    if (data.contract_signed_by_location_time) {
      this.contract_signed_by_location_time = BaseModel.parseDateFromDatabase(data.contract_signed_by_location_time);
    } else {
      this.contract_signed_by_location_time = null;
    }

    if (data.contract_invoice_sent_time) {
      this.contract_invoice_sent_time = BaseModel.parseDateFromDatabase(data.contract_invoice_sent_time);
    } else {
      this.contract_invoice_sent_time = null;
    }

    if (data.work) {
      this.work = data.work;
    }

    if (data.audit_first) {
      this.audit_first = data.audit_first;
    }
    if (data.audit_latest) {
      this.audit_latest = data.audit_latest;
    }

    if (data.attachments) {
      this.attachments = data.attachments;
    }

    if (data.location) {
      const loc = new CompanyModel();
      loc.createFromDatabase(data.location);
      this.location = loc;
    }


    if (data.location_contact) {
      const cont = new ContactModel();
      cont.createFromDatabase(data.location_contact);
      this.location_contact = cont;
    }
    if (data.location_contact_for_artist) {
      const cont = new ContactModel();
      cont.createFromDatabase(data.location_contact_for_artist);
      this.location_contact_for_artist = cont;
    }

    if (data.events !== null && data.events !== undefined) {
      this.events = [];
      data.events.forEach(
        (evData) => {
          const ev = new EventModel();
          ev.createFromDatabase(evData);
          this.events.push(ev)
        }
      )
    }
  }

}

