<div class="container-fluid pt-4" *ngIf="_contact_id">

  <div class="row">
    <div class="col-12 mb-2">
      <input class="form-control" id="keywords" name="keywords" placeholder="Schlagwort" type="text"
             [(ngModel)]="contactNote.keywords"/>
    </div>

    <div class="col-10 ">
      <textarea autosize id="text" class="form-control w-100" name="text" placeholder="Notizen"
                [(ngModel)]="contactNote.text"></textarea>
    </div>

    <div class="col-2">
      <button class="btn btn-primary" (click)="save()"
              [disabled]="contactNote.keywords === '' && contactNote.text === ''"
              [class.disabled]="contactNote.keywords === '' && contactNote.text === ''">Speichern
      </button>
    </div>

  </div>
  <hr>
  <div class="row-fluid mt-4">
    <div class="col-12" *ngIf="_contact_id">

      <table #dataTable datatable [dtOptions]="dtOptions" class="table table-striped row-border hover">
        <thead>
        <tr>

          <th>id</th>
          <th>Wer</th>
          <th>Schlagwort</th>
          <th>Text</th>
          <th>Erstellt am</th>
          <th>Bearbeitet am</th>
          <th>Aktionen</th>
        </tr>
        </thead>

      </table>


    </div>


  </div>

</div>
