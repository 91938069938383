<div class="container-fluid">
  <div class="row pt-4 row justify-content-between">
    <div class="col-12">
      <h2>Briefvorlagen</h2>
    </div>
  </div>

  <div class="row pt-4 row justify-content-between">
    <div class="col-12 text-right">
      <button (click)="edit('new')" class="btn btn-primary"><i class="fa fa-plus"></i> Neue Vorlage</button>
    </div>
  </div>


  <div class="row  pt-4">
    <div class="col-12">

      <table #dataTable datatable [dtOptions]="dtOptions" class="table table-striped row-border hover">
        <thead>
        <tr>

          <th>id</th>
          <th>Name</th>
          <th>Aktionen</th>
        </tr>
        </thead>

      </table>


    </div>



  </div>
</div>
