import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { AngularFireService } from '../../../services/angularfire.service';
import { RoleService } from '../../../../services/role.service';
import { WorkService } from '../../../../services/work.service';
import { Work } from '../../../../models/work.model';
import { Role } from '../../../../models/role.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-role-list',
    templateUrl: './role-list.component.html',
})
export class RoleListComponent implements OnInit, OnChanges {


    public allRoles: Role[] = [];
    public form: FormGroup;
    public name = '';
    public voice = '';
    public size = '';
    public fachpartie = false;

    public voiceList = [
      'Alt',
      'Bass',
      'Bassbariton',
      'Bassbuffo',
      'Charakterbariton',
      'Charaktertenor',
      'Countertenor',
      'Dirigent',
      'Dramatischer Alt',
      'Dramatischer Koloratursopran',
      'Dramatischer Mezzosopran',
      'Dramatischer Sopran',
      'Heldenbariton',
      'Heldentenor',
      'Jugendlich-dramatischer Sopran',
      'Jugendlicher Heldentenor',
      'Kavalierbariton',
      'Koloratur-Mezzosopran',
      'Lyrischer Bariton',
      'Lyrischer Koloratursopran',
      'Lyrischer Mezzosopran',
      'Lyrischer Sopran',
      'Lyrischer Tenor',
      'Regiesseur',
      'Schwerer Basbuffo',
      'Schwerer Bassbuffo',
      'Seriöser Bass',
      'Sopran',
      'Soubrette',
      'Spielalt',
      'Spielbariton',
      'Tenor',
      'Tenorbuffo',
      'Tiefer Alt',
    ];

    @Input('work') work: Work;

    constructor(
        private roleService: RoleService,
        private workService: WorkService,
        private fb: FormBuilder
    ) {
        this.form = this.fb.group({
            id: [ null ],
            name: [ null, [ Validators.required ] ],
            voice: [ null, [ ] ],
            role : []
        });
    }

    ngOnChanges(changes) {

    }
    ngOnInit() {

    }
    public updateRole(role: Role) {
        console.log('update Role', role);
        this.roleService.update(role).subscribe(
          (role: Role) => {
            console.log('role updated', role)
          }
        );
    }
    public addRole() {
        const data = {name: this.name, voice: this.voice, work_id: this.work.id, size: this.size, fachpartie: this.fachpartie};
        this.roleService.create(data).subscribe(
            (role: Role) => this.work.roles.push(role)
        );
        this.voice = this.name =  this.size =  '';


    }
}
