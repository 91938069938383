import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Work } from '../../../models/work.model';
import { Role } from '../../../models/role.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoleService } from '../../../services/role.service';
import { WorkService } from '../../../services/work.service';
import { ModalDirective } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-work-modal',
    templateUrl: './work-modal.component.html'
})
export class WorkModalComponent implements OnInit {

    @Input() public work: Work;
    @Input() public selectedRole: Role;

    @Output() public onShow = new EventEmitter<any>();
    @Output() public onHide = new EventEmitter<any>();
    @Output() public onWorkEdited = new EventEmitter<Work>();

    @ViewChild('modal') public modal: ModalDirective;

    public form: FormGroup;
    public categories: string[] = [
      'Oper/Operette',
      'Konzert mit Gesang',
      'Konzert nur Orchester',
      'Lied',
    ];

    private subscriptionToWorkChanges: Subscription;
    private subscriptionToRoleChanges: Subscription;

    private isCreating = false;

    constructor(private fb: FormBuilder,
                private roleService: RoleService,
                private workService: WorkService) {
        this.form = this.fb.group({
            id: [ null ],
            name: [ null, [ Validators.required ] ],
            short_name: [ null, [ Validators.required ] ],
            composer: [ null, [ Validators.required ] ],
            composer_short_name: [ null, [ Validators.required ] ],
            category: [ null, [ Validators.required ] ],
            roles: [],
        });

    }

    ngOnInit() {
        this.modal.onShow.subscribe(
            ($event) => this.onShow.emit($event)
        );
        this.modal.onHide.subscribe(
            ($event) => {
                this.onHide.emit($event);
                // stop receiving updates from changes to work
                if (this.subscriptionToWorkChanges) {
                    this.subscriptionToWorkChanges.unsubscribe();
                }
                if (this.subscriptionToRoleChanges) {
                    this.subscriptionToRoleChanges.unsubscribe();
                }
                this.resetForm();
            });
    }

    public hide(save = false) {
        if (save) {

        }
        this.modal.hide();
    }

    public show(workId: number = null): void {
        if (workId != null) {
            this.subscriptionToWorkChanges = this.workService.get(workId).subscribe(
                (work: Work) => {
                    this.setFormValues(work);
                    this.modal.show();
                }
            );
        } else {
            this.resetForm();
            this.modal.show();
        }
    }


    public updateOrCreate(hideAfterSave) {
        if (!this.form.valid) {
            return;
        }
        console.log(this.form.value, this.isCreating, this.form.value.id);

        if (this.form.value.id !== null && this.isCreating === false) {
            this.workService.update(this.form.value.id, this.form.value).subscribe(
                (work) => {
                    this.setFormValues(work);
                    this.onWorkEdited.emit(this.form.value);
                    if (hideAfterSave === true) {
                        this.hide();
                    }
                }
            );


        } else if (this.isCreating === false) {
            this.isCreating = true;
            this.workService.create(this.form.value).subscribe(
                (work) => {
                    this.setFormValues(work);
                    this.isCreating = false;
                    this.onWorkEdited.emit(this.form.value);
                    if (hideAfterSave === true) {
                        this.hide();
                    }
                }
            );
        }

    }

    private setFormValues(work: Work) {
        this.form.patchValue({
            id: work.id,
            name: work.name,
            short_name: work.short_name,
            composer: work.composer,
            composer_short_name: work.composer_short_name,
            category: work.category,
            roles: work.roles,
        });
        this.form.markAsUntouched({onlySelf: false});
        this.form.markAsPristine({onlySelf: false});
        this.work = this.form.value;
    }

    public resetForm() {
        this.form.reset({
            id: null,
            name: null,
            short_name: null,
            composer: null,
            composer_short_name: null,
            category: null,
            roles: null
        });
        this.work = null;
        this.form.markAsUntouched({onlySelf: false});
        this.form.markAsPristine({onlySelf: false});

    }

}
