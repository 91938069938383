import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoginComponent } from '../../pages/register/login/login.component';
import { AuthService } from '../../services/auth/auth.service';

import { CalendarComponent } from '../../pages/calendar/calendar.component';

@Injectable()
export class AuthenticatedOnlyButEmailNotVerified implements CanActivate {

  constructor(
      public auth: AuthService,
      private router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if ( !this.auth.isLoggedIn()) {
      this.router.navigate(['/' + LoginComponent.url]);
      return false;
    } else {

      this.router.navigate(['/' + CalendarComponent.url]);
      return true;

    }
  }
}
