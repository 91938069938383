import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ContactModel } from '../../models/contact.model';
import { EventGroupStatePipe } from '../../pipes/event-group-state.pipe';
import { EventModel } from '../../models/event.model';
import * as moment from 'moment';
import { EventGroupStateEnum } from '../../models/enums';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { ContactsService } from '../../services/contacts.service';
import { CompanyModel } from '../../models/company.model';


@Component({
    selector: 'app-event-group-table',
    templateUrl: './event-group-table.component.html',
    providers: [ EventGroupStatePipe ],
    changeDetection: ChangeDetectionStrategy.Default
})
export class EventGroupTableComponent implements OnInit, AfterViewInit {

    public static url = 'tabelle';

    public _filteredToArtist: ContactModel = null;
    public _filteredToWork: string = null;
    public _filterDateFrom: string = null;
    public _filteredToName: string = null;
    public _filteredToRole: string = null;
    public _filteredLocationId: number = null;

    private search: BehaviorSubject<{ location: any }> = new BehaviorSubject({
        location: null
    });

    @ViewChild(DataTableDirective)
    dataTable: DataTableDirective;
    dtOptions: DataTables.Settings = {};

    public columnDef;
    public query = '';

    constructor(private http: HttpClient,
                private router: Router,
                private contactsService: ContactsService,
                public eventGroupStatePipe: EventGroupStatePipe) {

        this.columnDef = [
            {
                title: 'Status',
                data: 'state',
                visible: true,
                type: 'num',
                orderable: true,
                searchable: 'true',
                render: (data, action, eventGroup) => {
                    return this.eventGroupStatePipe.transform(parseInt(data, 10))
                },
                filter: {
                    type: 'select',
                    filter_values: [
                        { name: 'Abgesagt', value: EventGroupStateEnum.cancelled },
                        { name: 'Bestätigt', value: EventGroupStateEnum.confirmed },
                        { name: 'Vertrag', value: EventGroupStateEnum.contract },
                        { name: 'Anfrage', value: EventGroupStateEnum.request },
                    ]
                }
            },
            {
                title: 'Name',
                data: 'events',
                visible: true,
                type: 'object',
                orderable: false,
                searchable: 'false',
                render: (events, action, eventGroup) => {

                    if (eventGroup.custom_title  && eventGroup.use_custom_title === '1') {
                        return eventGroup.custom_title;
                    }
                    if (events[ 0 ]) {
                        const source = events.sort((a, b) => moment(a.start) < moment(b.start))[ 0 ];
                        source.event_group = { ...eventGroup, events: null };
                        const event = new EventModel();
                        event.createFromDatabase(source);
                        return event.getDisplayName()
                    }
                    return '';
                },
                filter: {
                    type: 'name'
                }
            },
            {
                title: 'Beginn',
                data: 'events',
                visible: true,
                searchable: false,
                orderable: false,
                type: 'date',
                render: (events, action, eventGroup) => {
                    if (events[ 0 ]) {
                        const source = events.sort((a, b) => moment(a.start) < moment(b.start))[ 0 ];
                        source.event_group = { ...eventGroup, events: null };
                        const event = new EventModel();
                        event.createFromDatabase(source);
                        return event.start.format('DD.MM.Y')
                    }
                    return '';
                },
                filter: {
                    type: 'date_range'
                }
            },
            {
                title: 'Künstler',
                searchable: 'false',
                data: 'artist', visible: true, type: 'object', orderable: false, render: (artist) => {
                    if (artist) {
                        const cont = new ContactModel();
                        cont.createFromDatabase(artist);
                        return cont.getName()
                    }
                    return '';
                },
                filter: {
                    type: 'contact'
                }
            },
            {
                title: 'Veranstalter',
                data: 'location_id',
                visible: true,
                type: 'object',
                searchable: 'true',
                orderable: false,
                render: (data, action, eventGroup) => {
                    return data !== null ? eventGroup.location.name : '';
                },
                filter: {
                    type: 'company'
                }
            },
            {
                title: 'Werk',
                orderable: false,
                searchable: 'true',
                data: 'work.short_name',
                visible: true,
                nullable: true,
                type: 'string',
                filter: {
                    type: 'work'
                },
                render: (data) => {
                    if (data) {
                        return data;
                    } else {
                        return '';
                    }
                },
            },

            {
                title: 'Rolle',
                data: 'role.name',
                searchable: 'true',
                visible: true,
                nullable: true,
                type: 'object',
                orderable: false,
                render: (data) => {
                    if (data) {
                        return data;
                    } else {
                        return '';
                    }
                },
                filter: {
                    type: 'role'
                }
            },
            {
                title: 'Gage',
                data: 'gage',
                visible: true,
                type: 'object',
                orderable: false,
                searchable: 'false',
                render: (data, display, row) => {
                    if (row) {
                        if (row.fee_different_for_events === '1' || row.fee_different_for_events === true || row.fee_different_for_events === 1  ) {
                            return 'Gage verschieden';
                        } else {

                            let ret = '';
                            if (row.fee) {
                                ret += row.fee
                                if (row.currency) {
                                    ret += row.currency
                                }
                            }
                            return ret;
                        }
                    } else {
                        return '-';
                    }
                }
            },
            {
                title: 'Öffnen',
                data: null,
                searchable: false,
                orderable: false,
                render: (data, action, eventGroup) => {
                    return '<button class="btn btn-primary open-group">Öffnen</button>'
                }
            },
            // columns for search
            {
                data: 'artist_id',
                visible: false,
                searchable: 'true'
            },
            {
                data: 'custom_title',
                searchable: 'true',
                visible: false,
                orderable: false,
                render: (data) => {
                    if (data) {
                        return data;
                    } else {
                        return '';
                    }
                },
            },
            {
                data: 'location.city',
                searchable: 'true',
                nullable: true,
                visible: false,
                orderable: false,
                required: false,
                render: (data) => {
                    if (data) {
                        return data;
                    } else {
                        return '';
                    }
                },
            },
            {
                data: 'location.city_for_calendar',
                searchable: 'true',
                nullable: true,
                visible: false,
                orderable: false,
                required: false,
                render: (data) => {
                    if (data) {
                        return data;
                    } else {
                        return '';
                    }
                },
            },
            {
                data: 'location.name',
                searchable: 'true',
                visible: false,
                orderable: false,
                nullable: true,
                required: false,
                render: (data) => {
                    if (data) {
                        return data;
                    } else {
                        return '';
                    }
                },
            },
            {
                data: 'artist.last_name',
                searchable: 'true',
                visible: false,
                orderable: false,
                nullable: true,
                required: false,
                render: (data) => {
                    if (data) {
                        return data;
                    } else {
                        return '';
                    }
                },
            },
            {
                data: 'artist.first_name',
                searchable: 'true',
                visible: false,
                orderable: false,
                nullable: true,
                required: false,
                render: (data) => {
                    if (data) {
                        return data;
                    } else {
                        return '';
                    }
                },
            }
        ];
    }

    ngOnInit() {
        this.dtOptions = {

            columns: this.columnDef,
            order: [ [ 0, 'desc' ] ],
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: true,
            stateSave: false,
            responsive: false,
            searching: true,
            deferRender: true,
            deferLoading: 1,
            searchDelay: 1500,
            language: {
                emptyTable: 'Keine Daten in der Tabelle vorhanden',
                info: '_START_ bis _END_ von _TOTAL_ Einträgen',
                infoEmpty: '0 bis 0 von 0 Einträgen',
                infoFiltered: '(gefiltert von _MAX_ Einträgen)',
                infoPostFix: '',
                thousands: '.',
                lengthMenu: '_MENU_ Einträge anzeigen',
                loadingRecords: 'Wird geladen...',
                processing: '<h1>Bitte warten...</h1> <i class="fa fa-spin fa-spinner fa-4x"></i>',
                search: 'Suchen',
                zeroRecords: 'Keine Einträge vorhanden.',
                paginate: {
                    first: 'Erste',
                    previous: 'Zurück',
                    next: 'Nächste',
                    last: 'Letzte'
                },
                aria: {
                    sortAscending: ': aktivieren, um Spalte aufsteigend zu sortieren',
                    sortDescending: ': aktivieren, um Spalte absteigend zu sortieren'
                },
            },
            ajax: (request, drawCallback, settings) => {
                const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
                this.http.post('dt/event-groups', request, { headers: headers }).subscribe(
                    (data) => {
                        console.log('returned data', data);
                        drawCallback(data);
                    },
                    (err) => {
                        console.error('error', err)
                    }
                )
            },
            stateLoaded: (settings: any, data: any) => {
                console.log(data.columns)
                if (data.columns[ 4 ].search.search) {
                    // a artist filter was set
                    this.contactsService.get(data.columns[ 4 ].search.search).subscribe(
                        (con) => {
                            console.log('set seletcted art', con)
                            this._filteredToArtist = con
                        }
                    )
                }
                if (data.columns[ 6 ].search.search) {
                    // a artist filter was set
                    this._filteredLocationId = data.columns[ 6 ].search.search;
                    console.log('FILTER TO LOCATION', this._filteredLocationId)

                }
            },
            rowCallback: (row: Node, data, index: number) => {

                row.addEventListener('dblclick', () => {
                    this.onRowClick(data);
                });
                row.addEventListener('click', () => {
                    this.onRowClick(data);
                });

            },

        }
    }

    onRowClick(data) {
        window.open('/#/calendar/event-group/' + data.id);
    }

    searchIt(q) {
        this.dataTable.dtInstance.then(
            (dtInstance) => {
                dtInstance.search(q).draw();
            }
        )
    }

    filterToArtist(contact: ContactModel) {
        if (contact === null) {
            this.filter('artist_id', null)
        } else {
            this.filter('artist_id', contact.id)
        }
    }

    filterWork() {
        if (this._filteredToWork === null || this._filteredToWork === '') {
            this.filter('work.short_name', null)
        } else {
            this.filter('work.short_name', this._filteredToWork);
        }
    }

    filterRole() {
        if (this._filteredToRole === null || this._filteredToRole === '') {
            this.filter('role.name', null)
        } else {
            this.filter('role.name', this._filteredToRole);
        }
    }
    filterName() {
        if (this._filteredToName === null || this._filteredToName === '') {
            this.filter('custom_title', null)
        } else {
            this.filter('custom_title', this._filteredToName);
        }
    }
    filterDate() {
        if (this._filterDateFrom === null || this._filterDateFrom === '') {
            this.filter('events.start', null)
        } else {
            this.filter('events.start', this._filterDateFrom);
        }
    }

    filterToLocation(location: CompanyModel | null) {
        if (location === null) {
            this.filter('location_id', null)
        } else {
            this.filter('location_id', location.id)
        }
    }

    filter(column: string, data) {
        const search = this.search.getValue();
        if (data === 'null') {
            data = null;
        }
        search[ column ] = data;
        this.search.next(search);
    }


    ngAfterViewInit(): void {

        this.search.asObservable()
            .subscribe(
                (val) => {

                    console.log('search changed', val)

                    this.dataTable.dtInstance.then((dtInstance: DataTables.Api) => {

                        Object.keys(val).forEach(dataName => {
                            console.log(dataName, val[ dataName ]);
                            if (val[ dataName ] === null) {
                                dtInstance.column(this.columnDef.findIndex((elem) => elem.data === dataName)).search('');
                            } else {
                                dtInstance.column(this.columnDef.findIndex((elem) => elem.data === dataName)).search(val[ dataName ])
                            }

                        });
                        dtInstance.draw();


                    });
                }
            );

    }


}
